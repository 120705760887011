import React from 'react'
import {AvatarProps} from '@material-ui/core'
import {Avatar, AvatarFrameColor} from '../atoms'

type VerifiedAvatarProps = AvatarProps & {
  verifiedOrganizerLevel: number
}

const VerifiedAvatar: React.FC<VerifiedAvatarProps> = ({verifiedOrganizerLevel, ...props}) => {
  let frameColor = undefined
  switch (verifiedOrganizerLevel) {
    case 2:
      frameColor = AvatarFrameColor.GREEN
      break
    case 3:
      frameColor = AvatarFrameColor.PURPLE
      break
    case 4:
      frameColor = AvatarFrameColor.RED
      break
  }

  return (
    <Avatar frameColor={frameColor} {...props} />
  )
}

export default VerifiedAvatar
