import React from 'react'

import * as Sentry from '@sentry/node'
import {NextPage, NextPageContext} from 'next'
import {Button, SingleColumnLayout} from '@/components'
import {Typography, makeStyles} from '@material-ui/core'
import {useRouter} from 'next/router'

type ErrorContent = {
  statusCode: number
  imgPath: string
  description: string
}

const errorContents: ErrorContent[] = [
  {
    statusCode: 404,
    imgPath: '/images/not-found.svg',
    description: 'お探しのページは見つかりませんでした',
  },
  {
    statusCode: 500,
    imgPath: '/images/internal-server-error.svg',
    description: 'アクセスしようとしたページは表示できませんでした',
  },
]

const useErrorPageStyles = makeStyles(({spacing}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    marginTop: spacing(25),
  },
  description: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: spacing(8),
    marginBottom: spacing(10),
  },
  button: {
    width: 171,
    height: 44,
    marginBottom: spacing(84),
  },
}))

type ErrorPageProps = {
  statusCode: number
}

const ErrorPage: NextPage<ErrorPageProps> = ({...props}) => {
  // statusCodeが渡ってこない場合は500として扱う
  // yarn start の環境で発生するが詳細な理由は不明
  const statusCode = props.statusCode || 500

  const router = useRouter()
  const styles = useErrorPageStyles()

  const onPageTop = () => router.push('/')
  const content = errorContents.find((it) => it.statusCode === statusCode)!

  return (
    <>
      <SingleColumnLayout>
        <div className={styles.root}>
          <img className={styles.image} src={content.imgPath} alt={`error-${content.statusCode}`}/>
          <Typography className={styles.description}>{content.description}</Typography>
          <Button className={styles.button} onClick={onPageTop}>トップページに戻る</Button>
        </div>
      </SingleColumnLayout>
    </>
  )
}

export const getStaticProps: ({res, err, asPath}: NextPageContext) => Promise<{ props: ErrorPageProps }> = async ({res, err, asPath}: NextPageContext) => {
  const statusCode = res ? res.statusCode : err?.statusCode || 404

  // see: https://github.com/vercel/next.js/issues/8592
  // エラーが捕捉できない場合は送れないことを許容
  if (process.env.NODE_ENV === 'production') {
    if (err) {
      Sentry.captureException(err)
    }
    await Sentry.flush(2000)
  }

  return {props: {statusCode}}
}

export default ErrorPage
