import React from 'react'

type RankingIconProps = {
  index: number
  iconUrl: string
}

export const RankingIconFirst: React.FC<RankingIconProps> = ({...props}) => {
  return (
    <>
      <svg viewBox="4 9 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g filter="url(#filter0_d)">
          <path d="M51 28.3094C53.4752 26.8803 56.5248 26.8803 59 28.3094L81.3109 41.1906C83.7861 42.6197 85.3109 45.2607 85.3109 48.1188V73.8812C85.3109 76.7393 83.7861 79.3803 81.3109 80.8094L59 93.6906C56.5248 95.1197 53.4752 95.1197 51 93.6906L28.6891 80.8094C26.2139 79.3803 24.6891 76.7393 24.6891 73.8812V48.1188C24.6891 45.2607 26.2139 42.6197 28.6891 41.1906L51 28.3094Z" fill="url(#paint0_linear)"/>
        </g>
        <path d="M51 33.3092C53.4752 31.8801 56.5248 31.8801 59 33.3092L76.9808 43.6904C79.456 45.1194 80.9808 47.7604 80.9808 50.6186V71.381C80.9808 74.2391 79.456 76.8801 76.9808 78.3092L59 88.6904C56.5248 90.1194 53.4752 90.1194 51 88.6904L33.0193 78.3092C30.544 76.8801 29.0193 74.2391 29.0193 71.381L29.0193 50.6186C29.0193 47.7604 30.544 45.1194 33.0193 43.6904L51 33.3092Z" fill={`url(#pattern0-${props.index})`}/>
        <g filter="url(#filter1_d)">
          <path d="M54.7021 39.7194L46.0174 29.0617C45.8668 28.8769 45.9068 28.6027 46.1039 28.4686L54.7886 22.5599C54.9267 22.466 55.1081 22.466 55.2462 22.5599L63.9309 28.4686C64.128 28.6027 64.168 28.8769 64.0174 29.0617L55.3327 39.7194C55.1699 39.9191 54.8649 39.9191 54.7021 39.7194Z" fill="#FFC501"/>
        </g>
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="45" y="22" width="20" height="18">
          <path d="M54.7021 39.7194L46.0174 29.0617C45.8668 28.8769 45.9068 28.6027 46.1039 28.4686L54.7886 22.5599C54.9267 22.466 55.1081 22.466 55.2462 22.5599L63.9309 28.4686C64.128 28.6027 64.168 28.8769 64.0174 29.0617L55.3327 39.7194C55.1699 39.9191 54.8649 39.9191 54.7021 39.7194Z" fill="#FFC501"/>
        </mask>
        <g mask="url(#mask0)">
          <rect x="43.4175" y="20.0432" width="11.6001" height="22.4225" fill="#FFE601"/>
        </g>
        <g filter="url(#filter2_d)">
          <path d="M73.7565 50.9764L78.6306 38.1156C78.7152 37.8921 78.973 37.7894 79.1878 37.8934L88.6398 42.4711C88.7897 42.5437 88.8801 42.7006 88.8679 42.8669L88.1007 53.3533C88.0832 53.5916 87.8655 53.7637 87.6299 53.7255L74.0712 51.5222C73.8174 51.481 73.6653 51.2171 73.7565 50.9764Z" fill="#FFC501"/>
        </g>
        <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="73" y="37" width="16" height="17">
          <path d="M73.7565 50.9764L78.6306 38.1156C78.7152 37.8921 78.973 37.7894 79.1878 37.8934L88.6398 42.4711C88.7897 42.5437 88.8801 42.7006 88.8679 42.8669L88.1007 53.3533C88.0832 53.5916 87.8655 53.7637 87.6299 53.7255L74.0712 51.5222C73.8174 51.481 73.6653 51.2171 73.7565 50.9764Z" fill="#FFC501"/>
        </mask>
        <g mask="url(#mask1)">
          <rect width="11.6127" height="22.3982" transform="matrix(0.499457 0.866338 -0.865712 0.500543 85.1289 31.3511)" fill="#FFE601"/>
        </g>
        <g filter="url(#filter3_d)">
          <path d="M37.0579 50.9764L32.1839 38.1156C32.0992 37.8921 31.8414 37.7894 31.6267 37.8934L22.1747 42.4711C22.0248 42.5437 21.9344 42.7006 21.9465 42.8669L22.7138 53.3533C22.7312 53.5916 22.949 53.7637 23.1845 53.7255L36.7433 51.5222C36.9971 51.481 37.1492 51.2171 37.0579 50.9764Z" fill="#FFC501"/>
        </g>
        <mask id="mask2" mask-type="alpha" maskUnits="userSpaceOnUse" x="21" y="37" width="17" height="17">
          <path d="M37.0579 50.9764L32.1839 38.1156C32.0992 37.8921 31.8414 37.7894 31.6267 37.8934L22.1747 42.4711C22.0248 42.5437 21.9344 42.7006 21.9465 42.8669L22.7138 53.3533C22.7312 53.5916 22.949 53.7637 23.1845 53.7255L36.7433 51.5222C36.9971 51.481 37.1492 51.2171 37.0579 50.9764Z" fill="#FFC501"/>
        </mask>
        <g mask="url(#mask2)">
          <rect width="11.6127" height="22.3982" transform="matrix(-0.499457 0.866338 0.865712 0.500543 25.6855 31.3511)" fill="#FFE601"/>
        </g>
        <g filter="url(#filter4_d)">
          <path d="M74.8533 71.1831C75.6922 70.6987 76.7258 70.6987 77.5646 71.1831L85.6787 75.8677C86.5175 76.352 87.0343 77.2471 87.0343 78.2158V87.585C87.0343 88.5537 86.5175 89.4487 85.6787 89.9331L77.5646 94.6177C76.7258 95.102 75.6922 95.102 74.8533 94.6177L66.7393 89.9331C65.9004 89.4487 65.3837 88.5537 65.3837 87.585V78.2158C65.3837 77.2471 65.9004 76.352 66.7393 75.8677L74.8533 71.1831Z" fill="url(#paint1_linear)"/>
        </g>
        <path d="M77.7648 89.1995H75.4692V80.3507L72.7288 81.2006V79.3339L77.5186 77.6182H77.7648V89.1995Z" fill="white"/>
        <defs>
          <filter id="filter0_d" x="8.68912" y="11.2375" width="92.6218" height="99.5248" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="8"/>
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.772549 0 0 0 0 0 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <pattern id={`pattern0-${props.index}`} patternContentUnits="objectBoundingBox" width="1" height="1">
            <use href={`#image-${props.index}`} transform="translate(0 -0.0556872) scale(0.00236967)"/>
          </pattern>
          <filter id="filter1_d" x="24.2356" y="0.799109" width="61.5637" height="60.7604" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="10.8452"/>
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.772549 0 0 0 0 0 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <filter id="filter2_d" x="52.0394" y="16.1621" width="58.52" height="59.2589" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="10.8452"/>
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.772549 0 0 0 0 0 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <filter id="filter3_d" x="0.255041" y="16.1621" width="58.52" height="59.2589" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="10.8452"/>
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.772549 0 0 0 0 0 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <filter id="filter4_d" x="43.6933" y="49.1294" width="65.0314" height="67.5419" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="10.8452"/>
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.772549 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <linearGradient id="paint0_linear" x1="44.1593" y1="35.5454" x2="101.538" y2="38.5804" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFE600"/>
            <stop offset="1" stopColor="#FFB800"/>
          </linearGradient>
          <linearGradient id="paint1_linear" x1="72.3373" y1="73.8095" x2="92.8296" y2="74.8934" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFE600"/>
            <stop offset="1" stopColor="#FFB800"/>
          </linearGradient>
          <image id={`image-${props.index}`} width="422" height="469" href={props.iconUrl}/>
        </defs>
      </svg>
    </>
  )
}

export const RankingIconSecond: React.FC<RankingIconProps> = ({...props}) => {
  return (
    <>
      <svg viewBox="-3 2 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g filter="url(#r2_filter0_d)">
          <path d="M43 17.3094C45.4752 15.8803 48.5248 15.8803 51 17.3094L73.3109 30.1906C75.7861 31.6197 77.3109 34.2607 77.3109 37.1188V62.8812C77.3109 65.7393 75.7861 68.3803 73.3109 69.8094L51 82.6906C48.5248 84.1197 45.4752 84.1197 43 82.6906L20.6891 69.8094C18.2139 68.3803 16.6891 65.7393 16.6891 62.8812V37.1188C16.6891 34.2607 18.2139 31.6197 20.6891 30.1906L43 17.3094Z" fill="url(#r2_paint0_linear)"/>
        </g>
        <path d="M43 22.3092C45.4752 20.8801 48.5248 20.8801 51 22.3092L68.9808 32.6904C71.456 34.1194 72.9808 36.7604 72.9808 39.6186V60.381C72.9808 63.2391 71.456 65.8801 68.9808 67.3092L51 77.6904C48.5248 79.1194 45.4752 79.1194 43 77.6904L25.0192 67.3092C22.544 65.8801 21.0192 63.2391 21.0192 60.381L21.0192 39.6186C21.0192 36.7604 22.544 34.1194 25.0192 32.6904L43 22.3092Z" fill={`url(#r2_pattern0-${props.index})`}/>
        <g filter="url(#r2_filter1_d)">
          <path d="M66.8533 60.1831C67.6922 59.6987 68.7258 59.6987 69.5646 60.1831L77.6787 64.8677C78.5175 65.352 79.0343 66.2471 79.0343 67.2158V76.585C79.0343 77.5537 78.5175 78.4487 77.6787 78.9331L69.5646 83.6177C68.7258 84.102 67.6922 84.102 66.8533 83.6177L58.7393 78.9331C57.9004 78.4487 57.3837 77.5537 57.3837 76.585V67.2158C57.3837 66.2471 57.9004 65.352 58.7393 64.8677L66.8533 60.1831Z" fill="url(#r2_paint1_linear)"/>
        </g>
        <path d="M72.116 78.1995H64.1886V76.6267L67.9299 72.6392C68.4436 72.0779 68.8222 71.588 69.0658 71.1697C69.3147 70.7513 69.4391 70.3542 69.4391 69.9782C69.4391 69.4645 69.3094 69.0621 69.0499 68.7708C68.7904 68.4743 68.4197 68.326 67.9378 68.326C67.4189 68.326 67.0085 68.506 66.7066 68.8661C66.4101 69.2209 66.2618 69.6896 66.2618 70.2721H63.9583C63.9583 69.5678 64.1251 68.9244 64.4587 68.3419C64.7976 67.7594 65.2742 67.304 65.8885 66.9756C66.5028 66.642 67.1991 66.4752 67.9776 66.4752C69.1691 66.4752 70.0931 66.7612 70.7498 67.3331C71.4117 67.905 71.7427 68.7126 71.7427 69.7558C71.7427 70.3277 71.5944 70.9102 71.2978 71.5033C71.0013 72.0964 70.4929 72.7875 69.7727 73.5765L67.1435 76.3487H72.116V78.1995Z" fill="white"/>
        <defs>
          <filter id="r2_filter0_d" x="0.689117" y="0.237549" width="92.6218" height="99.5248" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="8"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.87451 0 0 0 0 0.87451 0 0 0 0 0.87451 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <pattern id={`r2_pattern0-${props.index}`} patternContentUnits="objectBoundingBox" width="1" height="1">
            <use href={`#image-${props.index}`} transform="scale(0.00344828)"/>
          </pattern>
          <filter id="r2_filter1_d" x="35.6933" y="38.1294" width="65.0314" height="67.5419" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="10.8452"/>
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.772549 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <linearGradient id="r2_paint0_linear" x1="36.1593" y1="24.5454" x2="93.5378" y2="27.5804" gradientUnits="userSpaceOnUse">
            <stop stopColor="#DFDFDF"/>
            <stop offset="1" stopColor="#888888"/>
          </linearGradient>
          <linearGradient id="r2_paint1_linear" x1="64.3373" y1="62.8095" x2="84.8296" y2="63.8934" gradientUnits="userSpaceOnUse">
            <stop stopColor="#DFDFDF"/>
            <stop offset="1" stopColor="#888888"/>
          </linearGradient>
          <image id={`image-${props.index}`} width="290" height="290" href={props.iconUrl}/>
        </defs>
      </svg>
    </>
  )
}

export const RankingIconThird: React.FC<RankingIconProps> = ({...props}) => {
  return (
    <>
      <svg viewBox="-12 -10 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <path d="M34 6.3094C36.4752 4.88034 39.5248 4.88034 42 6.3094L64.3109 19.1906C66.7861 20.6197 68.3109 23.2607 68.3109 26.1188V51.8812C68.3109 54.7393 66.7861 57.3803 64.3109 58.8094L42 71.6906C39.5248 73.1197 36.4752 73.1197 34 71.6906L11.6891 58.8094C9.2139 57.3803 7.68911 54.7393 7.68911 51.8812V26.1188C7.68911 23.2607 9.2139 20.6197 11.6891 19.1906L34 6.3094Z" fill="url(#r3_paint0_linear)"/>
        <path d="M34 11.3092C36.4752 9.88009 39.5248 9.88009 42 11.3092L59.9808 21.6904C62.456 23.1194 63.9808 25.7604 63.9808 28.6186L63.9808 49.381C63.9808 52.2391 62.456 54.8801 59.9808 56.3092L42 66.6904C39.5248 68.1194 36.4752 68.1194 34 66.6904L16.0192 56.3092C13.544 54.8801 12.0192 52.2391 12.0192 49.381L12.0192 28.6186C12.0192 25.7604 13.544 23.1194 16.0192 21.6904L34 11.3092Z" fill={`url(#r3_pattern0-${props.index})`}/>
        <g filter="url(#r3_filter0_d)">
          <path d="M57.8533 49.1831C58.6922 48.6987 59.7258 48.6987 60.5646 49.1831L68.6787 53.8677C69.5175 54.352 70.0343 55.2471 70.0343 56.2158V65.585C70.0343 66.5537 69.5175 67.4487 68.6787 67.9331L60.5646 72.6177C59.7258 73.102 58.6922 73.102 57.8533 72.6177L49.7393 67.9331C48.9004 67.4487 48.3837 66.5537 48.3837 65.585V56.2158C48.3837 55.2471 48.9004 54.352 49.7393 53.8677L57.8533 49.1831Z" fill="url(#r3_paint1_linear)"/>
        </g>
        <path d="M57.5239 60.4H58.7472C59.3297 60.4 59.7613 60.2544 60.042 59.9632C60.3226 59.6719 60.463 59.2853 60.463 58.8034C60.463 58.3374 60.3226 57.9747 60.042 57.7152C59.7666 57.4557 59.3853 57.326 58.8981 57.326C58.4586 57.326 58.0906 57.4478 57.794 57.6914C57.4975 57.9297 57.3492 58.2421 57.3492 58.6287H55.0536C55.0536 58.025 55.2151 57.4849 55.5381 57.0083C55.8665 56.5264 56.3219 56.1504 56.9044 55.8803C57.4922 55.6102 58.1382 55.4752 58.8425 55.4752C60.0658 55.4752 61.0243 55.7691 61.718 56.3569C62.4117 56.9394 62.7586 57.7443 62.7586 58.7717C62.7586 59.3012 62.597 59.7884 62.274 60.2332C61.951 60.678 61.5274 61.0196 61.0031 61.2579C61.6544 61.4909 62.139 61.8404 62.4567 62.3064C62.7797 62.7724 62.9413 63.3232 62.9413 63.9586C62.9413 64.9859 62.5653 65.8094 61.8133 66.429C61.0666 67.0485 60.0764 67.3583 58.8425 67.3583C57.6881 67.3583 56.7429 67.0538 56.0068 66.4449C55.276 65.8359 54.9106 65.031 54.9106 64.0301H57.2062C57.2062 64.4643 57.3677 64.8191 57.6908 65.0945C58.0191 65.3699 58.4215 65.5075 58.8981 65.5075C59.4436 65.5075 59.8699 65.3646 60.177 65.0786C60.4894 64.7874 60.6456 64.4034 60.6456 63.9268C60.6456 62.7724 60.0102 62.1952 58.7393 62.1952H57.5239V60.4Z" fill="white"/>
        <defs>
          <pattern id={`r3_pattern0-${props.index}`} patternContentUnits="objectBoundingBox" width="1" height="1">
            <use href={`#image-${props.index}`} transform="scale(0.00344828)"/>
          </pattern>
          <filter id="r3_filter0_d" x="26.6933" y="27.1294" width="65.0314" height="67.5419" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="10.8452"/>
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.772549 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <linearGradient id="r3_paint0_linear" x1="27.1593" y1="13.5454" x2="84.5378" y2="16.5804" gradientUnits="userSpaceOnUse">
            <stop stopColor="#9E5C17"/>
            <stop offset="1" stopColor="#E0913C"/>
          </linearGradient>
          <linearGradient id="r3_paint1_linear" x1="55.3373" y1="51.8095" x2="75.8296" y2="52.8934" gradientUnits="userSpaceOnUse">
            <stop stopColor="#9E5C17"/>
            <stop offset="1" stopColor="#E0913C"/>
          </linearGradient>
          <image id={`image-${props.index}`} width="290" height="290" href={props.iconUrl}/>
        </defs>
      </svg>
    </>
  )
}
