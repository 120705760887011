import {Title} from '@/components'
import {Link, LoadingError} from '@/components/atoms'
import {
  BracketType,
  GameFragment,
  OpenTournamentType,
  TournamentAddInput,
  TournamentUnitType,
  TournamentUpdateInput,
  useCoverImageSelectQuery,
  useGamesQuery,
  useMeQuery,
  useMyVerifiedOrganizerLevelQuery,
  useTournamentAddMutation,
  useTournamentUpdateMutation,
} from '@/graphql/client'
import {useSnackbar} from '@/hooks/acknowledge'
import {useErrorReport} from '@/hooks/error'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  Select,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import React, {useCallback, useState} from 'react'
import {Control, Controller, useForm} from 'react-hook-form'
import {
  DatePicker,
  DatePickerProps,
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers'
import jaLocale from 'date-fns/locale/ja'
import {ExtendedUtils} from '@/utils/date'
import {useRouter} from 'next/router'
import MutationLoading from '@/components/templates/layouts/mutation-loading'
import {
  addDays,
  addMonths,
  endOfDay,
  isAfter,
  isBefore,
  startOfDay,
} from 'date-fns'
import {serverConfig} from '@/env'
import {EditData} from '@/pages/tournaments/[id]/edit'
import useAxios from 'axios-hooks'
import {FileRejection, useDropzone} from 'react-dropzone'
import {composeClassNames, getUnitTypeLabel, unitTypeSuffix} from '@/utils'
import {LoadingPart} from '../../atoms/loading-part'
import ErrorPage from '@/pages/_error'
import ImageSelect, {
  ImageSelectProps,
} from '../../molecules/form/image-select'
import {
  getOpenTournamentLabel,
} from '@/utils/open-tournaments/open-tournament-type'

// チケットは現在１試合につき１枚使用する
const MATCH_TICKET_FEE = 1

// @todo セレクトBOXの影響範囲が広い場合にはカスタムhooksにする
const useSelectBoxStyle = makeStyles(() => ({
  root: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 14px',
  },
  icon: {
    fill: 'rgba(255, 255, 255, 0.9)',
  },
}))

const useLayout = makeStyles((theme) => ({
  precautions: {
    minHeight: 184,
    [theme.breakpoints.up('lg')]: {
      width: '800px',
    },
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
  },
  precautionsTitle: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
  },
  precautionsContentText: {
    marginTop: '4px',
  },
  precautionsContentTextUnderLine: {
    textDecoration: 'underline',
    margin: 0,
    display: 'contents',
  },
  formRoot: {
    padding: '24px 16px',
    [theme.breakpoints.up('lg')]: {
      padding: '40px 32px',
      width: '800px',
    },
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
  },
  formInput: {
    height: '40px',
  },
  formInputCompletion: {
    padding: '0px',
    '-webkit-box-shadow': '0 0 0 30px rgba(30,30,30,1.0) inset !important',
    '-webkit-text-fill-color': 'rgba(255,255,255,0.9)',
  },
  tournamentImagePick: {
    maxWidth: 400,
    width: '100%',
    height: 200,
    objectFit: 'contain',
  },
  victoryConditionText: {
    fontSize: '0.875em',
    marginLeft: theme.spacing(2),
  },
  select: {
    '&:before': {
      borderColor: '#FFFFFF',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderColor: '#FFFFFF',
    },
  },
  termsText: {
    textDecoration: 'underline',
    textDecorationColor: 'white',
  },
  uploadBtn: {
    display: 'none',
  },
  imageUploadWrapper: {
    display: 'inline-block',
  },
  dropzone: {
    width: '100%',
    height: '200px',
    border: 'none',
    background: 'none',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dropzoneWrap: {
    width: '100%',
    maxWidth: '400px',
  },
  isActive: {
    opacity: 0.4,
  },
  uploadNotice: {
    marginLeft: '14px',
    marginTop: '4px',
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: '0.857rem',
    fontWeight: 'bold',
    lineHeight: 1.5,
  },
  notice: {
    marginRight: '10px',
  },
  camera: {
    margin: 0,
    position: 'relative',
    top: '-206px',
    width: '100%',
    height: '200px',
    background: 'rgba(0, 0, 0, 0.6)',
    backgroundImage: 'url("/images/camera.png")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '36px 32px',
  },
  winConditionLink: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 5,
  },
  head: {
    marginBottom: 8,
  },
  inputContent: {
    marginBottom: 32,
  },
  contactContent: {
    marginBottom: 16,
  },
  contactEmail: {
    fontSize: 12,
    marginBottom: 16,
    margin: '3px 0 16px 14px',
  },
  helperText: {
    fontSize: 12,
    marginBottom: 32,
    margin: '3px 0 16px 14px',
    opacity: 0.6,
    fontWeight: 'bold',
  },
}))

type TournamentInput = {
  willStartTime?: string
  openEntryStartsTime?: string
  openEntryEndsTime?: string
  openCheckinStartsTime?: string
  openCheckinEndsTime?: string
  ticket?: 'monthly_ticket' | 'premium_ticket'
} & TournamentAddInput

export type ResultTournamentCover = {
  url: string
}

type FormLayoutProps = {
  mode: string
  editData?: EditData
}

export const FormLayout: React.FC<FormLayoutProps> = ({mode, editData}) => {
  const styles = useLayout()
  const selectStyles = useSelectBoxStyle()
  const router = useRouter()
  const {snackbar} = useSnackbar()
  const {errorReport} = useErrorReport()
  const {register, handleSubmit, watch, errors, control} =
    useForm<TournamentInput>({mode: 'onChange'})

  const [term, setTerm] = useState<boolean>(false)
  const [imageUrl, setImageUrl] = useState<string>('')
  const [selectedGameId, setSelectedGameId] = useState<number | null>(
    Number(editData?.gameId)
  )
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [isOpenTournament, setIsOpenTournament] = useState<boolean>(Boolean(editData?.openTournamentType))

  const {
    data: gamesData,
    loading: gamesLoading,
    error: gamesError,
  } = useGamesQuery()
  const {
    data: meData,
    loading: meLoading,
    error: meError,
  } = useMyVerifiedOrganizerLevelQuery()
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useMeQuery()
  const [tournamentAdd, {loading: mutationLoading}] =
    useTournamentAddMutation()
  const [tournamentUpdate, {loading: mutationUpdateLoading}] =
    useTournamentUpdateMutation()

  const editMode = mode === 'edit'
  const unitType = watch('unitType') as TournamentUnitType | undefined
  const bracketType = watch('bracketType') as BracketType | undefined

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections[0]) {
        const code = fileRejections[0].errors[0].code
        if (code === 'file-too-large') {
          snackbar.failed('アップロードできる画像容量は最大2MBです')
        }
        if (code === 'file-invalid-type') {
          snackbar.failed('アップロードできるファイル形式はpng / jpgです')
        }
      }
      const uploadImage = acceptedFiles[0]
      if (uploadImage) {
        const formData = new FormData()
        formData.append('image', uploadImage)
        try {
          executePost({
            data: formData,
          })
        } catch (e) {
          snackbar.failed('画像アップロードに失敗しました', e)
          errorReport.captureException(e)
        }
      }
    },
    []
  )

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    maxSize: 2097152,
  })

  const [{data: postData, loading: postLoading}, executePost] =
    useAxios<ResultTournamentCover>(
      {
        url: '/assets/tournament_cover',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      {manual: true}
    )

  React.useEffect(() => {
    if (postData?.url) {
      setImageUrl(postData.url)
    }
  }, [postData?.url])

  const onCreateSubmit = async (value: TournamentInput) => {
    // 作成中はボタンを連打できないようにする
    setTerm(false)
    // willStartAtフォーマット修正
    value.willStartAt =
      value.willStartAt + 'T' + value.willStartTime + ':00+0900'
    delete value.willStartTime

    if (isOpenTournament && value.openTournamentType) {

      value.openEntryStartsAt =
        value.openEntryStartsAt && value.openEntryStartsTime
          ? value.openEntryStartsAt +
            'T' +
            value.openEntryStartsTime +
            ':00+0900'
          : null
      delete value.openEntryStartsTime
      value.openEntryEndsAt =
        value.openEntryEndsAt && value.openEntryEndsTime
          ? value.openEntryEndsAt + 'T' + value.openEntryEndsTime + ':00+0900'
          : null
      delete value.openEntryEndsTime
      value.openCheckinStartsAt =
        value.openCheckinStartsAt && value.openCheckinStartsTime
          ? value.openCheckinStartsAt +
            'T' +
            value.openCheckinStartsTime +
            ':00+0900'
          : null
      delete value.openCheckinStartsTime
      value.openCheckinEndsAt =
        value.openEntryEndsAt && value.openCheckinEndsTime
          ? value.openCheckinEndsAt +
            'T' +
            value.openCheckinEndsTime +
            ':00+0900'
          : null
      delete value.openCheckinEndsTime
    } else {
      delete value.openTournamentType
      delete value.openEntryStartsAt
      delete value.openEntryStartsTime
      delete value.openEntryEndsAt
      delete value.openEntryEndsTime
      delete value.openCheckinStartsAt
      delete value.openCheckinStartsTime
      delete value.openCheckinEndsAt
      delete value.openCheckinEndsTime
    }

    const selectedTicket = value.ticket
    delete value.ticket

    const params: TournamentAddInput = {
      ...value,
      maxEntry: Number(value.maxEntry),
      scoreToWin:
        bracketType === BracketType.SingleElimination
          ? Number(value.scoreToWin)
          : -1,
      contactDiscord: value.contactDiscord || null,
    }
    const currentTicketCount = userData?.me.monthlyTicketCount ?? 0

    if (imageUrl) {
      params.coverTmpUrl = imageUrl
    }

    if (selectedTicket !== 'monthly_ticket') {
      snackbar.failed('使用するチケットを選択してください')
      setTerm(true)
    } else if (currentTicketCount - MATCH_TICKET_FEE < 0) {
      snackbar.failed('チケットが不足しています')
      setTerm(true)
    } else {
      try {
        const res = await tournamentAdd({
          variables: {input: {...params}},
        })
        const tournamentId = res.data?.tournamentAdd?.id ?? null
        router.push(tournamentId ? `/tournaments/${tournamentId}/detail` : '/')
      } catch (e) {
        setTerm(true)
        snackbar.failed('新規大会の作成に失敗しました', e)
        errorReport.captureException(e)
      }
    }
  }

  const onEditSubmit = async (value: TournamentInput) => {
    setTerm(false)
    if (!editData) {
      return <LoadingError />
    }

    if (!disabledEditOpenTournament) {
      if (isOpenTournament) {
        value.openEntryStartsAt =
          value.openEntryStartsAt && value.openEntryStartsTime
            ? value.openEntryStartsAt +
              'T' +
              value.openEntryStartsTime +
              ':00+0900'
            : null
        delete value.openEntryStartsTime
        value.openEntryEndsAt =
          value.openEntryEndsAt && value.openEntryEndsTime
            ? value.openEntryEndsAt + 'T' + value.openEntryEndsTime + ':00+0900'
            : null
        delete value.openEntryEndsTime
        value.openCheckinStartsAt =
          value.openCheckinStartsAt && value.openCheckinStartsTime
            ? value.openCheckinStartsAt +
              'T' +
              value.openCheckinStartsTime +
              ':00+0900'
            : null
        delete value.openCheckinStartsTime
        value.openCheckinEndsAt =
          value.openCheckinEndsAt && value.openCheckinEndsTime
            ? value.openCheckinEndsAt +
              'T' +
              value.openCheckinEndsTime +
              ':00+0900'
            : null
        delete value.openCheckinEndsTime
      } else {
        delete value.openTournamentType
        delete value.openEntryStartsAt
        delete value.openEntryStartsTime
        delete value.openEntryEndsAt
        delete value.openEntryEndsTime
        delete value.openCheckinStartsAt
        delete value.openCheckinStartsTime
        delete value.openCheckinEndsAt
        delete value.openCheckinEndsTime
      }
    }

    const params: TournamentUpdateInput = {
      tournamentId: editData.id,
      title: value.title,
      rules: value.rules,
      bracketType: value.bracketType,
      rewardNote: value.rewardNote,
      contactEmail: value.contactEmail,
      contactTwitter: value.contactTwitter,
      contactDiscord: value.contactDiscord || null,
      coverTmpUrl: value.coverTmpUrl,
      gameDefaultCoverId: value.gameDefaultCoverId,
      isPrivate: value.isPrivate,
      openEntryStartsAt: value.openEntryStartsAt,
      openEntryEndsAt: value.openEntryEndsAt,
      openCheckinStartsAt: value.openCheckinStartsAt,
      openCheckinEndsAt: value.openCheckinEndsAt,
    }
    if (imageUrl) {
      params.coverTmpUrl = imageUrl
    }
    try {
      await tournamentUpdate({variables: {input: {...params}}})
      snackbar.success('大会の更新に成功しました')
      router.push(`/tournaments/${editData.id}/detail`)
    } catch (e) {
      snackbar.failed('大会の更新に失敗しました', e)
      errorReport.captureException(e)
    }
  }

  const onOpenDialog = () => {
    if (Object.keys(errors).length) {
      snackbar.failed('未入力項目があります')
      setShowDialog(false)
    } else {
      setShowDialog(true)
    }
  }

  const [selectedDate, setSelectedDate] = React.useState(
    new Date('2014-08-18T21:11:54')
  )
  const [selectedOpenEntryStartsAt, setSelectedOpenEntryStartsAt] =
    React.useState(new Date('2014-08-18T21:11:54'))
  const [selectedOpenEntryEndsAt, setSelectedOpenEntryEndsAt] = React.useState(
    new Date('2014-08-18T21:11:54')
  )
  const [selectedOpenCheckinStartsAt, setSelectedOpenCheckinStartsAt] =
    React.useState(new Date('2014-08-18T21:11:54'))
  const [selectedOpenCheckinEndsAt, setSelectedOpenCheckinEndsAt] =
    React.useState(new Date('2014-08-18T21:11:54'))

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setSelectedDate(date)
    }
  }
  const today = new Date()
  const expiredDate = new Date(today.getFullYear(), today.getMonth() + 1, 0)
  const formattedExpiredDate = `${expiredDate.getFullYear()}/${
    expiredDate.getMonth() + 1
  }/${expiredDate.getDate()}`

  const disabledEditOpenTournament =
    !isOpenTournament ||
    (!!editData?.openEntryEndsAt && new Date(editData.openEntryEndsAt) < today)

  if (gamesLoading || meLoading || userLoading) {
    return <LoadingPart/>
  }


  if (gamesError || meError || userError) {
    return <ErrorPage statusCode={500} />
  }

  if (!gamesData ||!meData || !userData) {
    return <></>
  }

  const userId = userData.me.id
  const games = (gamesData.games.nodes ?? []) as GameFragment[]
  const verifiedOrganizerLevel = meData.me.verifiedOrganizerLevel as number
  const enabledGames = games.filter((it) => it.enabled)
  const editableOpenTournamentTypes =
    userData.me.editableOpenTournamentTypes?? []
  const openTournamentTypes = Object.values(OpenTournamentType).filter((type) => editableOpenTournamentTypes.includes(type))
  const getMaxEntryLabel = (): string => {
    return unitType === TournamentUnitType.Single
      ? '参加上限数'
      : '参加上限チーム数'
  }

  const termLabel = (
    <>
      <Link className={styles.termsText} href="/terms" target="_blank">
        規約
      </Link>
      に同意する
    </>
  )

  const coverForm =
    verifiedOrganizerLevel > 1 ? (
      <>
        <Box>
          <FormControl variant="outlined" className={styles.dropzoneWrap}>
            <label className={styles.imageUploadWrapper}>
              <Controller
                name="coverTmpUrl"
                control={control}
                render={() => (
                  <Paper
                    className={styles.dropzone}
                    {...getRootProps()}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <input
                      {...getInputProps()}
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      className={styles.uploadBtn}
                      disabled={postLoading}
                    />
                    {(() => {
                      if (postLoading) {
                        return <CircularProgress />
                      } else if (imageUrl || editData?.coverUrl) {
                        return (
                          <>
                            <img
                              src={imageUrl || editData?.coverUrl}
                              className={styles.tournamentImagePick}
                              alt="画像を選択"
                            />
                          </>
                        )
                      } else {
                        return (
                          <img
                            src="/images/upload-game-image.png"
                            className={
                              isDragActive
                                ? composeClassNames(
                                  styles.tournamentImagePick,
                                  styles.isActive
                                )
                                : styles.tournamentImagePick
                            }
                            alt="画像を選択"
                          />
                        )
                      }
                    })()}
                  </Paper>
                )}
              />
            </label>
          </FormControl>
        </Box>
        <Typography className={styles.uploadNotice}>
          <span className={styles.notice}>
            推奨サイズ：比率2：1、800 x 400以上
          </span>
          <span className={styles.notice}>拡張子：png / jpg</span>
          <span>最大画像容量：2MB未満</span>
        </Typography>
      </>
    ) : (
      <CoverImageSelect
        name="gameDefaultCoverId"
        inputRef={register}
        gameId={selectedGameId}
        defaultValue={
          editMode ? Number(editData?.gameDefaultCoverId) : undefined
        }
      />
    )
  return (
    <>
      {mutationLoading && <MutationLoading />}
      {mutationUpdateLoading && <MutationLoading />}
      {editMode ? (
        <Title variant="h1">大会の編集</Title>
      ) : (
        <Title variant="h1">新規大会の作成</Title>
      )}
      <Box>
        <Card className={styles.precautions}>
          <Box className={styles.precautionsTitle} py={4} px={6}>
            <Typography variant="subtitle1" color="textPrimary">
              注意事項
            </Typography>
          </Box>
          <Box pt={4} pb={6} pl={5} pr={11}>
            <Typography color="textSecondary">
              大会の主催者および、大会で優勝したユーザーにはAdictor
              Coinが発行されますが、
              <Link href={'/help/ac'} passHref target="_blank">
                <span className={styles.precautionsContentTextUnderLine}>
                  一部の条件に該当する場合
                </span>
              </Link>
              、付与されないケースがあります。
            </Typography>
            <Typography
              variant="subtitle1"
              color="primary"
              className={styles.precautionsContentText}
            >
              ※一度公開した大会の編集はできません（開催日・ルール・参加上限数などすべて)
              <br />
              ※フォーム内容は全項目入力必須です
              {/* ルールや日程が未定の場合、下書き機能を使ってください。 */}
            </Typography>
          </Box>
        </Card>
      </Box>
      <Box mt={8}>
        <Card className={styles.formRoot}>
          <form noValidate autoComplete="off">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Typography
                className={styles.head}
                variant="subtitle1"
                color="textPrimary"
              >
                大会タイトル
              </Typography>
              <TextField
                className={styles.inputContent}
                inputRef={register({required: true, maxLength: 64})}
                error={Boolean(errors.title)}
                name="title"
                placeholder="大会タイトルを入力"
                helperText="最大64文字です"
                fullWidth
                inputProps={{style: {height: '40px', padding: '0 14px'}}}
                defaultValue={editData?.title}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              maxWidth={360}
            >
              <Typography
                className={styles.head}
                variant="subtitle1"
                color="textPrimary"
              >
                ゲームタイトル
              </Typography>
              <GameTitleSelect
                inputRef={register({required: true})}
                games={editMode ? games : enabledGames}
                editMode={editMode}
                error={Boolean(errors.gameId)}
                onChange={(id) => setSelectedGameId(id)}
                defaultValue={editMode ? Number(editData?.gameId) : undefined}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              className={styles.inputContent}
            >
              <Typography
                className={styles.head}
                variant="subtitle1"
                color="textPrimary"
              >
                大会サムネイル
              </Typography>
              {coverForm}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              maxWidth={418}
            >
              <Box maxWidth={200} mr={12}>
                <TournamentDatetimePicker
                  title="開始日"
                  name="willStartAt"
                  placeholder="開始日"
                  helperText="3日後〜3ヶ月後までが指定可能です"
                  value={selectedDate}
                  control={control}
                  inputRef={register({required: true})}
                  onChange={(date) => handleDateChange(date)}
                  defaultValue={editData ? new Date(editData.willStartAt) : ''}
                  rules={
                    editMode
                      ? {}
                      : {
                        validate: (data: Date) => {
                          // TODO: 本来は Timzone を意識した作るにするべき（送信部分についても）
                          const target = startOfDay(new Date(data))

                          // 本番以外では開催日を当日に設定可能とする
                          const minInterval = serverConfig.isPrd ? 3 : 0
                          const today = new Date()
                          const minDate = endOfDay(
                            addDays(addDays(today, minInterval), -1)
                          )
                          const maxDate = startOfDay(
                            addDays(addMonths(today, 3), 1)
                          )
                          return (
                            isAfter(target, minDate) &&
                              isBefore(target, maxDate)
                          )
                        },
                      }
                  }
                />
              </Box>
              <Box maxWidth={200}>
                <TournamentDatetimePicker
                  isTime
                  title="開始時間"
                  name="willStartTime"
                  placeholder="開始時間"
                  value={selectedDate}
                  control={control}
                  inputRef={register({required: true})}
                  defaultValue={editData ? new Date(editData.willStartAt) : ''}
                  onChange={(date) => handleDateChange(date)}
                  helperText=" "
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              maxWidth={360}
            >
              <Typography
                className={styles.head}
                variant="subtitle1"
                color="textPrimary"
              >
                トーナメント形式
              </Typography>
              <FormControl variant="outlined">
                <Controller
                  as={
                    <Select
                      name="bracketType"
                      inputProps={{
                        classes: {
                          root: selectStyles.root,
                          icon: selectStyles.icon,
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        形式を選択
                      </MenuItem>
                      <MenuItem value={BracketType.SingleElimination}>
                        シングルエリミネーション
                      </MenuItem>
                      <MenuItem value={BracketType.Custom}>カスタム</MenuItem>
                    </Select>
                  }
                  inputRef={register}
                  error={Boolean(errors.bracketType)}
                  name="bracketType"
                  rules={{required: true}}
                  control={control}
                  defaultValue={
                    editData?.bracketType || BracketType.SingleElimination
                  }
                />
              </FormControl>
              <Typography className={styles.helperText} variant="body1">
                「カスタム」は、バトルロイヤルなどのトーナメント形式以外の大会を実施する際に、
                主催者が参加者の着順を任意に指定できる形式です。
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              maxWidth={200}
              mb={8}
            >
              <Typography
                className={styles.head}
                variant="subtitle1"
                color="textPrimary"
              >
                対戦形式
              </Typography>
              <FormControl variant="outlined">
                <Controller
                  as={
                    <Select
                      name="bracketType"
                      inputProps={{
                        classes: {
                          root: selectStyles.root,
                          icon: selectStyles.icon,
                        },
                      }}
                      disabled={editMode}
                    >
                      <MenuItem value="" disabled>
                        形式を選択
                      </MenuItem>
                      {[
                        TournamentUnitType.Single,
                        TournamentUnitType.Team_2Vs2,
                        TournamentUnitType.Team_3Vs3,
                        TournamentUnitType.Team_4Vs4,
                        TournamentUnitType.Team_5Vs5,
                      ].map((it, idx) => {
                        return (
                          <MenuItem key={idx} value={it}>
                            {getUnitTypeLabel(it, bracketType)}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  }
                  inputRef={register}
                  error={Boolean(errors.unitType)}
                  name="unitType"
                  rules={{required: true}}
                  control={control}
                  defaultValue={editData?.unitType || TournamentUnitType.Single}
                />
              </FormControl>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              mb={8}
              maxWidth={200}
            >
              <Typography
                className={styles.head}
                variant="subtitle1"
                color="textPrimary"
              >
                {getMaxEntryLabel()}
              </Typography>
              <TextField
                name="maxEntry"
                inputRef={register({required: true, min: 16, max: 128})}
                error={Boolean(errors.maxEntry)}
                placeholder={getMaxEntryLabel()}
                type="number"
                InputLabelProps={{shrink: false}}
                inputProps={{
                  classes: {root: selectStyles.root, icon: selectStyles.icon},
                  style: {height: '40px', padding: '0 14px'},
                }}
                helperText={`16〜128${unitTypeSuffix(
                  unitType
                )}の間で指定可能です`}
                defaultValue={editData?.maxEntry}
                disabled={editMode}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              mb={8}
            >
              <Typography
                className={styles.head}
                variant="subtitle1"
                color="textPrimary"
              >
                抽選形式
              </Typography>
              <Typography variant="h4" color="textPrimary">
                エントリー先着
              </Typography>
            </Box>

            {/* 勝利条件はシングルエリミネーションの場合のみ表示 */}
            {bracketType === BracketType.SingleElimination && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                mb={8}
                maxWidth={250}
              >
                <Box display="flex" mb={2}>
                  <Typography variant="subtitle1" color="textPrimary">
                    勝利条件
                  </Typography>
                  <Link
                    className={styles.winConditionLink}
                    href="https://app.adictor.jp/help/winconditions"
                    target="_blank"
                  >
                    <HelpOutlineIcon />
                  </Link>
                </Box>
                <FormControl variant="outlined">
                  <Controller
                    as={
                      <Box display="flex" alignItems="center">
                        <TextField
                          placeholder="勝利条件"
                          type="number"
                          InputLabelProps={{shrink: false}}
                          name="scoreToWin"
                          error={Boolean(errors.scoreToWin)}
                          inputProps={{
                            classes: {
                              root: selectStyles.root,
                              icon: selectStyles.icon,
                            },
                            style: {
                              maxWidth: '140px',
                              height: '40px',
                              padding: '0 14px',
                            },
                          }}
                          helperText="1〜10の間で指定可能です"
                          inputRef={register({
                            required: true,
                            min: 1,
                            max: 10,
                          })}
                          defaultValue={editData?.scoreToWin}
                          disabled={editMode}
                        />
                        <Typography
                          className={styles.victoryConditionText}
                          color="textSecondary"
                        >
                          勝先取
                        </Typography>
                      </Box>
                    }
                    name="scoreToWin"
                    rules={{
                      required: true,

                      min: 1,
                      max: 10,
                    }}
                    control={control}
                    defaultValue=""
                  />
                </FormControl>
              </Box>
            )}

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              mb={8}
            >
              <Typography
                className={styles.head}
                variant="subtitle1"
                color="textPrimary"
              >
                ルール
              </Typography>
              <TextField
                name="rules"
                placeholder="ゲームのルールを記載"
                type="text"
                multiline
                rows={4}
                InputLabelProps={{shrink: false}}
                inputRef={register({required: true, maxLength: 5000})}
                error={Boolean(errors.rules)}
                inputProps={{
                  classes: {root: selectStyles.root, icon: selectStyles.icon},
                }}
                helperText="最大5,000文字です"
                defaultValue={editData?.rules}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              mb={8}
            >
              <Typography
                className={styles.head}
                variant="subtitle1"
                color="textPrimary"
              >
                賞品・賞金
              </Typography>
              <TextField
                name="rewardNote"
                placeholder="賞品・賞金や、獲得方法について記載"
                type="text"
                multiline
                rows={4}
                inputRef={register({required: true, maxLength: 1000})}
                error={Boolean(errors.rewardNote)}
                InputLabelProps={{shrink: false}}
                inputProps={{
                  classes: {root: selectStyles.root, icon: selectStyles.icon},
                }}
                helperText="最大1,000文字です"
                defaultValue={editData?.rewardNote}
              />
            </Box>
            {verifiedOrganizerLevel > 1 && (
              <Box mb={8} display="flex" justifyContent="flex-start">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isPrivate"
                      color="primary"
                      inputRef={register}
                      defaultChecked={editData?.isPrivate}
                    />
                  }
                  label="大会を非公開にする（注目の大会・カレンダーに表示されません）"
                />
              </Box>
            )}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              mb={8}
            >
              <Typography
                className={styles.head}
                variant="subtitle1"
                color="textPrimary"
              >
                連絡先
              </Typography>
              <TextField
                name="contactEmail"
                inputRef={register({
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
                error={Boolean(errors.contactEmail)}
                placeholder="contact@your-mail.com"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src="/images/icon/mail.svg" alt="mail" />
                    </InputAdornment>
                  ),
                  style: {height: '40px', padding: '0 14px'},
                  classes: {input: styles.formInputCompletion},
                }}
                defaultValue={editData?.contactEmail}
              />
              <Typography
                className={styles.contactEmail}
                variant="body1"
                color="primary"
              >
                ※大会前日にお知らせが届きます
              </Typography>
              <TextField
                name="contactTwitter"
                inputRef={register({
                  required: true,
                  max: 15,
                  pattern: /^[A-Z0-9_]+$/i,
                })}
                error={Boolean(errors.contactTwitter)}
                placeholder="Twitter Username (ex: AdictorOfficial)"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src="/images/icon/twitter.svg" alt="twitter" />
                    </InputAdornment>
                  ),
                  style: {height: '40px', padding: '0 14px'},
                }}
                helperText="IDのみ入力して下さい（@AdictorOfficialの場合「AdictorOfficial」）"
                defaultValue={editData?.contactTwitter}
                className={styles.contactContent}
              />
              <TextField
                name="contactDiscord"
                inputRef={register({pattern: /^https:\/\/discord.gg\/.+$/i})}
                error={Boolean(errors.contactDiscord)}
                placeholder="Discord invite link (ex: https://discord.gg/CeEJzqh)"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src="/images/icon/discord.svg" alt="discord" />
                    </InputAdornment>
                  ),
                  style: {
                    height: '40px',
                    padding: '0 14px',
                    maxWidth: '50ch',
                  },
                }}
                helperText="招待リンクを入力して下さい（招待期限にご注意ください）"
                defaultValue={editData?.contactDiscord}
                className={styles.contactContent}
              />
            </Box>
            {
              openTournamentTypes.length > 0 && (!editMode || Boolean(editData?.openTournamentType)) && (
                <Box mb={8}>
                  <Box mb={8}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isOpenTournament"
                          color="primary"
                          disabled={editMode}
                          defaultChecked={Boolean(editData?.openTournamentType)}
                          onChange={() => setIsOpenTournament(!isOpenTournament)}
                        />
                      }
                      label="オープン大会を公開する"
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    maxWidth={200}
                    mb={8}
                  >
                    <Typography
                      className={styles.head}
                      variant="subtitle1"
                      color="textPrimary"
                    >
                    オープン大会の種類
                    </Typography>
                    <FormControl variant="outlined">
                      <Controller
                        defaultValue={editData?.openTournamentType ?? ''}
                        as={
                          <Select
                            displayEmpty
                            inputProps={{
                              classes: {
                                root: selectStyles.root,
                                icon: selectStyles.icon,
                              },
                            }}
                            disabled={!isOpenTournament || editMode}
                            defaultValue={editData?.openTournamentType ?? ''}
                          >
                            <MenuItem value="" disabled>
                            形式を選択
                            </MenuItem>
                            {openTournamentTypes.map((it, idx) => {
                              return (
                                <MenuItem key={idx} value={it}>
                                  {getOpenTournamentLabel(it)}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        }
                        error={Boolean(errors.openTournamentType)}
                        name="openTournamentType"
                        rules={{required: isOpenTournament}}
                        control={control}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={4}>
                    <Typography
                      className={styles.head}
                      variant="subtitle1"
                      color="textPrimary"
                    >
                    オープン大会エントリー
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    maxWidth={418}
                  >
                    <Box maxWidth={200} mr={12}>
                      <TournamentDatetimePicker
                        title="開始日"
                        name="openEntryStartsAt"
                        placeholder="開始日"
                        value={selectedOpenEntryStartsAt}
                        disabled={disabledEditOpenTournament}
                        control={control}
                        inputRef={register({required: isOpenTournament})}
                        helperText="エントリー開始日時は試合開始予定日時よりも前に設定してください"
                        error={Boolean(errors.openEntryStartsAt)}
                        onChange={(date: Date | null) => {
                          if (date) {
                            setSelectedOpenEntryStartsAt(date)
                          }
                        }}
                        defaultValue={
                        editData?.openEntryStartsAt
                          ? new Date(editData.openEntryStartsAt)
                          : ''
                        }
                      />
                    </Box>
                    <Box maxWidth={200}>
                      <TournamentDatetimePicker
                        isTime
                        title="開始時間"
                        name="openEntryStartsTime"
                        placeholder="開始時間"
                        value={selectedOpenEntryStartsAt}
                        error={Boolean(errors.openEntryStartsAt)}
                        disabled={disabledEditOpenTournament}
                        control={control}
                        inputRef={register({required: isOpenTournament})}
                        onChange={(date: Date | null) => {
                          if (date) {
                            setSelectedOpenEntryStartsAt(date)
                          }
                        }}
                        defaultValue={
                        editData?.openEntryStartsAt
                          ? new Date(editData.openEntryStartsAt)
                          : ''
                        }
                      />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    maxWidth={418}
                  >
                    <Box maxWidth={200} mr={12}>
                      <TournamentDatetimePicker
                        title="終了日"
                        name="openEntryEndsAt"
                        placeholder="終了日"
                        value={selectedOpenEntryEndsAt}
                        disabled={disabledEditOpenTournament}
                        error={Boolean(errors.openEntryEndsAt)}
                        helperText="エントリー終了日時は試合開始予定日時前、エントリー開始時刻後で設定してください"
                        control={control}
                        inputRef={register({required: isOpenTournament})}
                        onChange={(date: Date | null) => {
                          if (date) {
                            setSelectedOpenEntryEndsAt(date)
                          }
                        }}
                        defaultValue={
                        editData?.openEntryEndsAt
                          ? new Date(editData.openEntryEndsAt)
                          : ''
                        }
                      />
                    </Box>
                    <Box maxWidth={200}>
                      <TournamentDatetimePicker
                        isTime
                        title="終了時間"
                        name="openEntryEndsTime"
                        placeholder="終了時間"
                        value={selectedOpenEntryEndsAt}
                        disabled={disabledEditOpenTournament}
                        error={Boolean(errors.openEntryEndsAt)}
                        control={control}
                        inputRef={register({required: isOpenTournament})}
                        onChange={(date: Date | null) => {
                          if (date) {
                            setSelectedOpenEntryEndsAt(date)
                          }
                        }}
                        defaultValue={
                        editData?.openEntryEndsAt
                          ? new Date(editData.openEntryEndsAt)
                          : ''
                        }
                      />
                    </Box>
                  </Box>
                  <Box mb={4}>
                    <Typography
                      className={styles.head}
                      variant="subtitle1"
                      color="textPrimary"
                    >
                    オープン大会チェックイン
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    maxWidth={418}
                  >
                    <Box maxWidth={200} mr={12}>
                      <TournamentDatetimePicker
                        title="開始日"
                        name="openCheckinStartsAt"
                        placeholder="開始日"
                        value={selectedOpenCheckinStartsAt}
                        disabled={disabledEditOpenTournament}
                        error={Boolean(errors.openCheckinStartsAt)}
                        control={control}
                        inputRef={register({required: isOpenTournament})}
                        helperText="チェックイン開始日時は試合開始予定日時前、エントリー終了時刻後で設定してください"
                        onChange={(date: Date | null) => {
                          if (date) {
                            setSelectedOpenCheckinStartsAt(date)
                          }
                        }}
                        defaultValue={
                        editData?.openCheckinStartsAt
                          ? new Date(editData.openCheckinStartsAt)
                          : ''
                        }
                      />
                    </Box>
                    <Box maxWidth={200}>
                      <TournamentDatetimePicker
                        isTime
                        title="開始時間"
                        name="openCheckinStartsTime"
                        placeholder="開始時間"
                        value={selectedOpenCheckinStartsAt}
                        disabled={disabledEditOpenTournament}
                        error={Boolean(errors.openCheckinStartsAt)}
                        control={control}
                        inputRef={register({required: isOpenTournament})}
                        onChange={(date: Date | null) => {
                          if (date) {
                            setSelectedOpenCheckinStartsAt(date)
                          }
                        }}
                        defaultValue={
                        editData?.openCheckinStartsAt
                          ? new Date(editData.openCheckinStartsAt)
                          : ''
                        }
                      />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    maxWidth={418}
                  >
                    <Box maxWidth={200} mr={12}>
                      <TournamentDatetimePicker
                        title="終了日"
                        name="openCheckinEndsAt"
                        placeholder="終了日"
                        value={selectedOpenCheckinEndsAt}
                        disabled={disabledEditOpenTournament}
                        error={Boolean(errors.openCheckinEndsAt)}
                        helperText="チェックイン終了日時は試合開始予定日時前、チェックイン開始時刻後で設定してください"
                        control={control}
                        inputRef={register({required: isOpenTournament})}
                        onChange={(date: Date | null) => {
                          if (date) {
                            setSelectedOpenCheckinEndsAt(date)
                          }
                        }}
                        defaultValue={
                        editData?.openCheckinEndsAt
                          ? new Date(editData.openCheckinEndsAt)
                          : ''
                        }
                      />
                    </Box>
                    <Box maxWidth={200}>
                      <TournamentDatetimePicker
                        isTime
                        title="終了時間"
                        name="openCheckinEndsTime"
                        placeholder="終了時間"
                        value={selectedOpenCheckinEndsAt}
                        error={Boolean(errors.openCheckinEndsAt)}
                        disabled={disabledEditOpenTournament}
                        control={control}
                        inputRef={register({required: isOpenTournament})}
                        onChange={(date: Date | null) => {
                          if (date) {
                            setSelectedOpenCheckinEndsAt(date)
                          }
                        }}
                        defaultValue={
                        editData?.openCheckinEndsAt
                          ? new Date(editData.openCheckinEndsAt)
                          : ''
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              )
            }
            <Typography
              className={styles.helperText}
              variant="body1"
              style={{marginTop: '-16px'}}
            >
              開始予定時刻から15分を過ぎて開始した場合はACが発行されません
            </Typography>
            {/*@todo Phase2で実装する際にコメントアウト*/}
            {/*<Box display='flex' flexDirection="column" justifyContent="space-between" mb={8} height={70}>*/}
            {/*  <Typography variant="subtitle1" color="textPrimary">実況配信用のURL(任意)</Typography>*/}
            {/*  <TextField placeholder="Youtube・Twitch等のURL" type="text" InputLabelProps={{shrink: false}}*/}
            {/*    inputProps={{style: {height: '40px', padding: '0 14px'}}}*/}
            {/*  />*/}
            {/*</Box>*/}
            {!editMode && (
              <Box mb={8}>
                <FormControlLabel
                  control={<Checkbox name="term" color="primary" />}
                  label={termLabel}
                  onChange={() => setTerm(!term)}
                />
              </Box>
            )}
            <Box display="flex" justifyContent="flex-end">
              {editMode ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{width: '101px', height: '44px'}}
                  onClick={handleSubmit(onEditSubmit)}
                >
                  更新する
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  style={{width: '101px', height: '44px'}}
                  disabled={!term}
                  onClick={onOpenDialog}
                >
                  公開する
                </Button>
              )}
            </Box>
            <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
              <Card style={{maxWidth: '100%', width: '450px'}}>
                <CardHeader
                  title="開催チケットが消費されます"
                  style={{borderBottom: '1px solid rgba(225,225,225,0.12)'}}
                />
                <CardContent>
                  <Typography>使用するチケットを選択してください。</Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    margin="16px 0"
                    border="1.5px solid rgba(225,225,225,0.12)"
                    height="80px"
                  >
                    <FormControlLabel
                      label=""
                      control={
                        <Radio
                          name="ticket"
                          value="monthly_ticket"
                          inputRef={register}
                        />
                      }
                      style={{marginLeft: '14px', marginRight: '-5px'}}
                    />
                    <img src="/images/tournament_ticket.svg" />
                    <Box>
                      <Typography variant="subtitle1">開催チケット</Typography>
                      <Box display="flex">
                        <Typography variant="body2" color="textSecondary">
                          所持数： {userData.me.monthlyTicketCount} 枚
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{marginLeft: '24px'}}
                        >
                          有効期限： {formattedExpiredDate}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Typography
                    color="primary"
                    style={{fontSize: '12px', padding: '16px 0'}}
                  >
                    ※公開すると選択した開催チケットが１枚消費されます。
                  </Typography>
                  <Box display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        width: '101px',
                        height: '44px',
                        margin: '24px 0 16px',
                      }}
                      onClick={handleSubmit(onCreateSubmit)}
                      disabled={!term}
                      data-userId={userId}
                    >
                      公開する
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Dialog>
          </form>
        </Card>
      </Box>
    </>
  )
}

type CoverImageSelectProp = Omit<ImageSelectProps, 'imageData'> & {
  gameId: number | null
}
const CoverImageSelect = ({gameId, ...props}: CoverImageSelectProp) => {
  const {data} = useCoverImageSelectQuery()

  if (
    typeof data === 'undefined' ||
    typeof data.gameDefaultCovers.nodes === 'undefined'
  ) {
    return <></>
  }

  const imageData = [
    {value: '', url: 'https://asset.adictor.jp/default/tournament_cover.png'},
  ]
  if (data.gameDefaultCovers.nodes) {
    imageData.push(
      ...data.gameDefaultCovers.nodes
        ?.filter((node) => Number(node?.game.id) === gameId)
        .map((node) => {
          return {value: String(node?.id), url: String(node?.coverUrl)}
        })
    )
  }

  return <ImageSelect imageData={imageData} {...props} />
}

type GameTitleSelectProps = {
  inputRef: any
  games: GameFragment[]
  editMode: boolean
  error: boolean
  onChange?: (value: number) => void
  defaultValue?: number
}

const GameTitleSelect = ({
  inputRef,
  games,
  editMode,
  error,
  onChange,
  defaultValue,
}: GameTitleSelectProps) => {
  const selectStyles = useSelectBoxStyle()
  const [selectedValue, setSelectedValue] = useState<string>(
    defaultValue ? String(defaultValue) : ''
  )

  return (
    <>
      <input type="hidden" ref={inputRef} name="gameId" value={selectedValue} />
      <FormControl style={{marginBottom: 32}} variant="outlined">
        <Select
          displayEmpty
          inputProps={{
            classes: {root: selectStyles.root, icon: selectStyles.icon},
          }}
          disabled={editMode}
          error={error}
          onChange={(evt) => {
            setSelectedValue(String(evt.target.value))
            if (typeof onChange !== 'undefined')
              onChange(Number(evt.target.value))
          }}
          value={selectedValue}
        >
          <MenuItem value="" disabled>
            ゲームを選択
          </MenuItem>
          {games.map((game, index) => {
            return (
              <MenuItem value={game.id} key={index}>
                {game.title}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </>
  )
}

type TournamentDatetimePickerProps = {
  title: string
  placeholder: string
  isTime?: boolean
  control?: Control
  rules?: any
}

const useDatetimePickerStyles = makeStyles(() => ({
  head: {
    marginBottom: '8px',
  },
  inputContent: {
    marginBottom: '16px',
  },
}))

const TournamentDatetimePicker = (
  props: TournamentDatetimePickerProps & DatePickerProps
) => {
  const styles = useDatetimePickerStyles()
  const {
    inputRef,
    error,
    onChange,
    defaultValue,
    control,
    value,
    title,
    placeholder,
    name,
    rules,
    isTime = false,
    helperText = ' ',
    disabled,
  } = props
  return (
    <>
      <Typography
        className={styles.head}
        variant="subtitle1"
        color="textPrimary"
      >
        {title}
      </Typography>
      <FormControl className={styles.inputContent} variant="outlined">
        <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
          <Controller
            as={
              isTime ? (
                <TimePicker
                  ampm={false}
                  value={value}
                  InputProps={{
                    style: {height: '40px', padding: '0 4px'},
                  }}
                  onChange={onChange}
                  helperText = ""
                />
              ) : (
                <DatePicker
                  value={value}
                  onChange={onChange}
                  InputProps={{
                    style: {height: '40px', padding: '0 4px'},
                  }}
                  inputVariant="outlined"
                  placeholder={placeholder}
                  format="yyyy-MM-dd"
                  animateYearScrolling
                  okLabel="決定"
                  cancelLabel="キャンセル"
                  helperText={helperText}
                />
              )
            }
            control={control}
            name={name ?? ''}
            inputRef={inputRef}
            error={error}
            rules={rules}
            defaultValue={defaultValue}
            disabled={disabled}
            placeholder={placeholder}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
    </>
  )
}
