import React from 'react'
import {makeStyles} from '@material-ui/core'
import {Box, Typography} from '@material-ui/core/'
import {TournamentUnitType, UserFragment} from '@/graphql/client'
import {Button, TeamEntryFormType} from '@/components'
import {SelectableUserList} from '@/components/organisms/tournament-team/selectable-user-list'
import {getMemberCountByUnit} from '@/utils'


const useChoseMemberContentStyles = makeStyles(({spacing}) => ({
  root: {

  },
  subtitle: {
    marginBottom: spacing(1),
  },
  description: {
    marginBottom: spacing(4),
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginTop: spacing(10),
    marginBottom: spacing(12),
    width: 73,
    height: 44,
  },
}))

type ChoseMemberContentProps = {
  mode: TeamEntryFormType
  initialSelectedIds?: string[]
  users: UserFragment[]
  gameId: string
  unitType: TournamentUnitType
  onNext: (userIds: string[]) => void
  onBack: () => void
}

export const ChoseMemberContent: React.FC<ChoseMemberContentProps> = (props) => {
  const styles = useChoseMemberContentStyles()
  const {mode, initialSelectedIds, users, gameId, unitType, onNext, onBack} = props

  const [selectedMemberIds, setSelectedMemberIds] = React.useState<string[]>(initialSelectedIds ?? [])

  const handleMemberSelected = (userIds: string[]) => setSelectedMemberIds(userIds)
  // NOTE: 編集モードの場合はクラン選択はできない
  const editMode = mode === 'edit'

  return (
    <Box className={styles.root}>
      <Typography className={styles.description} variant={'body2'}>
          参加するメンバーを選択してください
      </Typography>

      <Box>
        <Typography className={styles.subtitle} variant={'subtitle2'}>チームメンバー</Typography>
        <Typography className={styles.description} variant={'body1'}>{getMemberCountByUnit(unitType)}名選択してください</Typography>
        <SelectableUserList
          initialSelectedIds={initialSelectedIds}
          users={users}
          gameId={gameId}
          handleChange={handleMemberSelected}
          maxSelectNum={getMemberCountByUnit(unitType)}
          outlined
        />
      </Box>

      <Box className={styles.buttons}>
        <Button
          outlined
          className={styles.button}
          onClick={onBack}
          disabled={editMode}
        >
          前へ
        </Button>

        <Box width={16}/>

        <Button
          className={styles.button}
          onClick={() => onNext(selectedMemberIds)}
          disabled={selectedMemberIds.length !== getMemberCountByUnit(unitType)}
        >
            次へ
        </Button>
      </Box>
    </Box>
  )
}
