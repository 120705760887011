import React from 'react'
import {
  Box,
  Button,
  Grid,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import {useRouter} from 'next/router'
import {
  ClanInviteLinkExecuteInput,
  useClanInviteLinkExecuteMutation,
  useClanInviteLinkQuery,
} from '@/graphql/client'
import {LoadingPart} from '../../atoms/loading-part'
import ErrorPage from '@/pages/_error'
import {LoadingError} from '@/components'
import {useSnackbar} from '@/hooks/acknowledge'
import {useAuth0} from '@auth0/auth0-react'
import Head from 'next/head'

const useClanInviteLayout = makeStyles(() =>
  createStyles({
    root: {
      marginTop: '48px',
      textAlign: 'center',
    },
    clanIcon: {
      display: 'block',
      margin: '0 auto 16px',
      width: '80px',
      height: '80px',
      objectFit: 'cover',
      borderRadius: '4px',
    },
    text: {
      marginBottom: '32px',
      fontSize: '18px',
    },
    btnWrap: {
      marginBottom: '416px',
    },
    cancelBtn: {
      marginRight: '16px',
      width: '101px',
      height: '44px',
      border: '1px solid rgba(85, 195, 65, 0.5)',
      color: '#55C341',
    },
    joinBtn: {
      width: '101px',
      height: '44px',
      background: '#55C341',
    },
  })
)

export const ClanInvite = () => {
  const styles = useClanInviteLayout()
  const router = useRouter()
  const {snackbar} = useSnackbar()
  const {isAuthenticated, loginWithRedirect} = useAuth0()

  const inviteLinkUuid = router.query.uuid as string

  // SSR: query を編集するときは pages/clan/invite/[uuid].tsx も同様に要変更
  const {data, loading, error} = useClanInviteLinkQuery({variables: {uuid: inviteLinkUuid}})

  const [executeLink] = useClanInviteLinkExecuteMutation()

  if (loading) {
    return <LoadingPart />
  }

  if (error) {
    return <LoadingError />
  }

  if (!data) {
    return <ErrorPage statusCode={404}/>
  }

  const link = data.clanInviteLink!

  const handleApprove = async () => {
    if (!isAuthenticated) {
      return loginWithRedirect({appState: {returnTo: window.location.pathname}})
    }

    const input: ClanInviteLinkExecuteInput = {
      uuid: inviteLinkUuid,
    }

    try {
      await executeLink({variables: {input}})
      await router.push(`/clan/${link.clan.id}/detail`)
      snackbar.success(`${link.clan.name}に参加しました`)
    } catch (e) {
      snackbar.failed('クランへの参加に失敗しました', e)
    }
  }

  const handleReject = async () => await router.push('/')

  const title = `${link.clan.name}に参加 | Adictor`
  const description = link.clan.description

  return (
    <Box className={styles.root}>
      <Head>
        <title>{title}</title>
        <meta name='twitter:title' content={title}/>
        <meta name='description' content={description}/>
        <meta name='twitter:description' content={description}/>
        <meta property="og:image" content={link.clan.coverUrl} />
      </Head>

      <img src={link.clan.logoUrl} alt="clan icon" className={styles.clanIcon}/>
      { link.isExpired &&
        <Typography variant="h2" className={styles.text}>この招待リンクは無効です。</Typography>
      }

      { !link.isExpired &&
          <>
            <Typography variant="h2" className={styles.text}>{link.clan.name}からクランに招待されています。<br />参加しますか？</Typography>
            <Grid container justify="center" className={styles.btnWrap}>
              <Button className={styles.cancelBtn} onClick={handleReject}>拒否する</Button>
              <Button className={styles.joinBtn} onClick={handleApprove}>参加する</Button>
            </Grid>
          </>
      }
    </Box>
  )
}
