import React from 'react'

import {
  GridList,
  GridListTile,
  Typography,
  useTheme,
} from '@material-ui/core'
import {useIsLg} from '@/hooks'
import {TournamentCard} from '../molecules'
import {TournamentCardFragment, useTournamentsByTermQuery} from '@/graphql/client'
import {endOfDay, startOfDay} from 'date-fns'
import {LoadingPart} from '@/components'

type CalendarModalContentsProps = {
  date: Date
  gameId: string
}

export const CalendarModalContents: React.FC<CalendarModalContentsProps> = (({date, gameId}) => {
  const lg = useIsLg()
  const {spacing} = useTheme()

  const {data, loading} = useTournamentsByTermQuery({
    variables: {
      gameId: gameId,
      from: startOfDay(date),
      to: endOfDay(date),
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  if (loading) { return <LoadingPart /> }

  const tournaments = data?.tournamentsByTerm?.nodes as TournamentCardFragment[]
  if (!tournaments[0]) {
    return (
      <Typography variant="body1"> 開催予定の大会がありません。</Typography>
    )
  }

  return (
    <GridList
      cellHeight={278}
      cols={lg ? 2 : 1}
      spacing={spacing(3)}
    >
      {tournaments.map((item) => (
        <GridListTile
          key={item.id}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: spacing(1),
          }}
        >
          <TournamentCard
            id={item.id}
            title={item.title}
            time={item.willStartAt}
            coverPreviewUrl={item.coverPreviewUrl}
            status={item.status}
            unitType={item.unitType}
            openTournamentType={item.openTournamentType}
            participants={{max: item.maxEntry, current: item.entryNum}}
            verifiedOrganizerLevel={item.organizer.verifiedOrganizerLevel}
            sponsorImage={item.sponsorCampaign?.sponsor?.iconUrl}
            sponsorLandingUrl={item.sponsorCampaign?.landingUrl}
          />
        </GridListTile>
      ))
      }
    </GridList>
  )
})
