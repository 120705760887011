export const removeInlineColorStyle = (only?: string) => (html: string) => {

  const target = only
    ? only.split('').map(c => /#|[0-9]/.test(c) ? c : `[${c.toUpperCase()}|${c.toLowerCase()}]`).join('')
    : '#[0-9a-zA-Z]*'

  const regExp = new RegExp(`([^-])color:${target};?`, 'g')

  return html.replace(regExp, (_, sep) => sep)

}

export const removeBlackColorStyle = removeInlineColorStyle('#000000')

export const composeClassNames = (...classNames: (string|undefined|false)[]) => classNames.filter(className => !!className).join(' ')
