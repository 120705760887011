import React from 'react'

import {
  MaxWidthPaddingContainer,
  MaxWidthPaddingContainerProps,
  appBarHeight,
} from '../../atoms'

import {
  PlainAppBar,
} from '../../molecules'

import {
  Footer,
} from '../../organisms'

import {
  Theme,
  makeStyles,
} from '@material-ui/core'

const useSingleColumnLayoutStyles = makeStyles<Theme, {paddingTop: number}>(() => ({
  contentContainer: {
    paddingTop: props => props.paddingTop,
  },
}))

type SingleColumnLayoutProps = {
  children: MaxWidthPaddingContainerProps['children']
}

export const SingleColumnLayout: React.FC<SingleColumnLayoutProps> = ({children}) => {

  const styles = useSingleColumnLayoutStyles({paddingTop: appBarHeight})

  return (
    <>
      <PlainAppBar />
      <MaxWidthPaddingContainer className={styles.contentContainer} padding={16}>
        {children}
      </MaxWidthPaddingContainer>
      <MaxWidthPaddingContainer padding={16}>
        <Footer />
      </MaxWidthPaddingContainer>
    </>
  )

}
