import React from 'react'
import {
  ClanFragment,
  TournamentDetailQuery,
  TournamentForceTeamEntryRemoveInput,
  TournamentTeamFragment,
  useMeQuery,
  useTournamentDetailQuery,
  useTournamentTeamForceEntryRemoveMutation,
} from '@/graphql/client'
import {Box} from '@material-ui/core'
import {Button, ClanListItemGrid, TeamEntryDetailModal, TeamEntryModal} from '@/components'
import {useSnackbar} from '@/hooks/acknowledge'
import {serverConfig} from '@/env'
import {useSwitchUser} from '@/hooks'
import MessageEmptyState from '@/components/atoms/message-empty-state'

type TournamentTeamsContentProps = TournamentDetailQuery

export const TournamentTeamsContent: React.FC<TournamentTeamsContentProps> = (props: TournamentTeamsContentProps) => {
  const {snackbar} = useSnackbar()

  const {switchUser, resetUser} = useSwitchUser()
  const switchOrganizer = () => switchUser(tournament.organizer.id)

  const tournament = props.tournament
  const clans = tournament.entryTeams.map(it => it.clan)

  const [showDetailModal, setShowDetailModal] = React.useState<boolean>(false)
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false)
  const [selectedClanId, setSelectedClanId] = React.useState<string | null>(null)

  const {data: meData} = useMeQuery()
  const {refetch: fetchTournament} = useTournamentDetailQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {id: tournament.id},
  })
  const [forceEntryRemove] = useTournamentTeamForceEntryRemoveMutation()

  const myId = meData ? meData.me.id : '-1'
  const isOrganizerOrModerator = tournament.organizer.id === myId || !!meData?.me.isTournamentModerator

  const getTeamForClan = (): TournamentTeamFragment => {
    return tournament.entryTeams.find(it => it.clan.id === selectedClanId)!
  }

  const getCanEditCurentTeam = (): boolean => {
    const team = getTeamForClan()
    return Boolean(team.users.find(it => it.id === myId) || team.managers.find(it => it.id === myId))
  }

  const handleClick = (clan: ClanFragment) => {
    setSelectedClanId(clan.id)
    setShowDetailModal(true)
  }

  const handleEdit = () => {
    setShowDetailModal(false)
    setShowEditModal(true)
  }

  const handleForceEntryRemove = async () => {
    const team = getTeamForClan()
    const input: TournamentForceTeamEntryRemoveInput = {
      tournamentId: tournament.id,
      tournamentTeamId: team.id,
    }

    try {
      await forceEntryRemove({variables: {input}})
      await fetchTournament()
      setSelectedClanId(null)
      snackbar.success(`${team.clan.name}のエントリーを解除しました`)
    } catch (e) {
      snackbar.failed(`${team.clan.name}のエントリー解除に失敗しました`, e)
    }
  }

  return (
    <>
      <Box
        mt={6}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        maxWidth={800}
        mx="auto"
      >
        {!serverConfig.isPrd &&
          <>
            <Button style={{margin: 16}} outlined onClick={switchOrganizer}>主催者に切り替え</Button>
            <Button style={{margin: 16}} outlined onClick={resetUser}>元のユーザに戻る</Button>
          </>
        }

        { clans[0] ? (
          <ClanListItemGrid clans={clans} onClick={handleClick}/>
        ) : (
          <MessageEmptyState message='まだエントリーしているチームはいません'/>
        )}
      </Box>

      {/* 詳細モーダル */}
      {selectedClanId && (
        <TeamEntryDetailModal
          open={showDetailModal}
          gameId={tournament.game.id}
          team={getTeamForClan()}
          status={tournament.status}
          canEdit={getCanEditCurentTeam()}
          onEdit={handleEdit}
          canForceExit={isOrganizerOrModerator}
          onForceExit={() => handleForceEntryRemove()}
          onClose={() => setShowDetailModal(false)}
        />
      )}

      {/* 編集モーダル */}
      {selectedClanId && (
        <TeamEntryModal
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
          mode={'edit'}
          team={getTeamForClan()}
          tournamentId={tournament.id}
          gameId={tournament.game.id}
          unitType={tournament.unitType}
        />
      )}
    </>
  )
}

