import React from 'react'
import {TournamentAwardType} from '@/graphql/client'
import {Box, createStyles, makeStyles} from '@material-ui/core/'
import {UnitJudgeListItem} from '@/components/molecules'
import {CustomTournamentUnitFragment} from '@/types/tournament'

const resultStyle = makeStyles(() =>
  createStyles({
    head: {
      fontWeight: 'bold',
      opacity: 0.6,
      marginBottom: 8,
    },
  })
)

type CustomTournamentResultProps = {
  units: CustomTournamentUnitFragment[]
}

export const CustomTournamentResult: React.FC<CustomTournamentResultProps> = ({units}) => {
  const styles = resultStyle()

  const listItem = (unit: CustomTournamentUnitFragment, key: number) => {
    return (
      <UnitJudgeListItem
        key={key}
        unit={unit}
        awardType={unit.awardType}
        mode={'show'}
      />
    )
  }

  const rankers = units.filter(it => ![TournamentAwardType.Participation, TournamentAwardType.Eliminated].includes(it.awardType))
  const participants = units.filter(it => it.awardType === TournamentAwardType.Participation)
  const eliminates = units.filter(it => it.awardType === TournamentAwardType.Eliminated)

  return (
    <>
      {rankers[0] &&
        <>
          <Box className={styles.head}>入賞者</Box>
          {rankers.map((it, idx) => listItem(it, idx))}
        </>
      }

      {participants[0] &&
        <>
          <Box className={styles.head}>参加者</Box>
          {participants.map((it, idx) => listItem(it, idx))}
        </>
      }

      {eliminates[0] &&
        <>
          <Box className={styles.head}>失格者</Box>
          {eliminates.map((it, idx) => listItem(it, idx))}
        </>
      }
    </>
  )
}
