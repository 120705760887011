import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import {FC, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {
  TournamentSettingSaveInput,
  useTournamentSettingQuery,
  useTournamentSettingSaveMutation,
} from '@/graphql/client'
import Loading from '../../templates/layouts/loading'
import {useSnackbar} from '@/hooks/acknowledge/snackbar'
import {Button as DiscordButton} from '@/components/molecules/discord'
import {Button as RSOButton} from '@/components/molecules/rso'

type HanakinConfigSaveInput = {
  riot_id: string
  roles?: string[]
  max_rank?: string
  desired_clan: boolean
  vc?: string[]
}

const DesireRoleList = [
  '第一希望',
  '第二希望',
  '第三希望',
  '第四希望',
  '第五希望',
]
const LolRole = ['TOP', 'JG', 'MID', 'ADC', 'SUP']
const LolRank = [
  'Iron',
  'Bronze',
  'Silver',
  'Gold',
  'Platinum',
  'Emerald',
  'Diamond',
  'Master',
  'Grandmaster',
  'Challenger',
]
const VcList = [
  {
    label: 'ゲーム内',
    value: 'in_game',
  },
  {
    label: 'パラレル',
    value: 'parallel',
  },
  {
    label: 'LINE',
    value: 'line',
  },
  {
    label: 'Discord',
    value: 'discord',
  },
]

const useRootStyles = makeStyles(({spacing}) =>
  createStyles({
    root: {paddingTop: spacing(8)},

    selectButton: {
      marginBottom: '16px',
      padding: '10px 16px',
      border: '1px solid',
      borderRadius: '4px',
      maxWidth: '180px',
    },
  })
)

export const OpenTournament: FC<unknown> = () => {
  const [tournamentType, setTournamentType] = useState('hanakin')
  const styles = useRootStyles()
  const {data, loading} = useTournamentSettingQuery({
    variables: {tournamentType: 'hanakin'},
    fetchPolicy: 'network-only',
  })
  const isHanakin= tournamentType === 'hanakin'
  const tournamentConfig = data?.tournamentSetting?.config
  const editData = {
    riot_id: tournamentConfig?.riot_id ?? '',
    roles: tournamentConfig?.roles ?? ['', '', '', '', ''],
    max_rank: tournamentConfig?.max_rank ?? '',
    desired_clan: tournamentConfig?.desired_clan ?? 'true',
    vc: tournamentConfig?.vc ?? [],
  }
  return (
    <>
      <Box className={styles.root}>
        <Button
          className={styles.selectButton}
          onClick={()=>setTournamentType('hanakin')}
          style={{
            borderColor: isHanakin ? '#55C341' : 'rgba(255, 255, 255, 0.38)',
          }}
        >
          <img
            width="32px"
            src="/images/hanakin_icon.png"
            style={{marginRight: '8px'}}
          />
          <Typography
            variant="subtitle2"
            color={isHanakin ? 'primary' : 'textSecondary'}
          >
            ワイリフ花金杯
          </Typography>
        </Button>
        {loading || !data ? <Loading /> : <HanakinForm tournamentType={tournamentType} editData={editData} />}
      </Box>
    </>
  )
}

const useFormStyles = makeStyles(({spacing}) =>
  createStyles({
    title: {
      paddingRight: spacing(8),
      paddingLeft: spacing(8),
      paddingTop: spacing(4),
      paddingBottom: spacing(4),
    },
    content: {
      padding: spacing(8),
    },
    formGroup: {
      marginBottom: spacing(6),
    },
    label: {
      marginBottom: spacing(2),
    },
    icon: {
      fill: 'rgba(255, 255, 255, 0.6)',
    },
    select: {
      marginTop: '6px',
      marginBottom: '6px',
      marginRight: '12px',
      minWidth: '120px',
    },
    input: {
      marginTop: spacing(4),
      marginBottom: spacing(4),
    },
    buttonList: {
      marginBottom: '16px',
      color: 'rgba(255, 255, 255, 0.38)',
    },
    toggleButton: {
      borderColor: 'rgba(255, 255, 255, 0.38)',
    },
    radioBox: {
      display: 'flex',
    },
  })
)

const HanakinForm = ({editData, tournamentType}: { editData: Required<HanakinConfigSaveInput>, tournamentType: string }) => {
  const isAnywhereRole = editData.roles[0] === 'ANYWHERE'

  const [showSelect, setShowSelect] = useState(!isAnywhereRole)
  const [checkedVcs, setCheckedVcs] = useState(editData.vc)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)

  const styles = useFormStyles()
  const {snackbar} = useSnackbar()

  const [tournamentSave] = useTournamentSettingSaveMutation()

  const {control, handleSubmit} =
    useForm<HanakinConfigSaveInput>({
      defaultValues: editData,
    })

  const handleChange = (e:any) => {
    if (checkedVcs.includes(e.target.value)) {
      setCheckedVcs(
        checkedVcs.filter((checkedValue) => checkedValue !== e.target.value)
      )
    } else {
      setCheckedVcs([...checkedVcs, e.target.value])
    }
  }

  const onSubmit = async (input: any, e: any) => {
    e.preventDefault()
    const roleArray = [
      input['role-0'],
      input['role-1'],
      input['role-2'],
      input['role-3'],
      input['role-4'],
    ]
    const inputRoles = !showSelect
      ? ['ANYWHERE']
      : roleArray.filter((item: string) => item !== '')
    const checkInputRoles = inputRoles.filter((item: string, index) => inputRoles.indexOf(item) !== index)

    if (showSelect) {
      if (inputRoles.length !== 5) {
        snackbar.failed('希望ロールは全て記入してください')
        return
      }
      if (checkInputRoles.length > 0) {
        snackbar.failed('希望ロールは重複しないように入力してください')
        return
      }
    }
    const setTournamentSettingConfig: HanakinConfigSaveInput = {
      riot_id: input.riot_id,
      roles: inputRoles,
      max_rank: input.max_rank,
      desired_clan: input.desired_clan,
      vc: checkedVcs,
    }

    const sendData: TournamentSettingSaveInput = {
      tournamentType: tournamentType,
      config: setTournamentSettingConfig,
    }
    try {
      setIsSubmitDisabled(true)
      await tournamentSave({variables: {input: sendData}}).then(() => {
        snackbar.success('大会設定の登録が完了しました。')
      })
    } catch (e) {
      snackbar.failed('大会設定の更新に失敗しました。', e)
    }
    setIsSubmitDisabled(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <Typography className={styles.title} variant="h4">
          ワイリフ花金杯
        </Typography>
        <Divider />
        <CardContent className={styles.content}>
          <Grid container>
            <Grid item className={styles.formGroup} xs={12} lg={4}>
              <Typography variant="subtitle1" className={styles.label}>
                Riot ID
              </Typography>
              <RSOButton />
            </Grid>
            <Grid item className={styles.formGroup} xs={12} lg={4}>
              <Typography variant="subtitle1" className={styles.label}>
                Discord
              </Typography>
              <DiscordButton />
            </Grid>
          </Grid>
          <Box className={styles.formGroup}>
            <Typography variant="subtitle1" className={styles.label}>
              ロール
            </Typography>
            <Box className={styles.buttonList}>
              <ButtonGroup>
                <Button
                  variant="outlined"
                  className={styles.toggleButton}
                  onClick={() => setShowSelect(true)}
                  style={{
                    color: showSelect ? '#55C341' : 'rgba(255, 255, 255, 0.38)',
                    borderColor: showSelect
                      ? '#55C341'
                      : 'rgba(255, 255, 255, 0.38)',
                  }}
                >
                  希望ロールを選択
                </Button>
                <Button
                  variant="outlined"
                  className={styles.toggleButton}
                  onClick={() => {
                    setShowSelect(false)
                  }}
                  style={{
                    color: !showSelect
                      ? '#55C341'
                      : 'rgba(255, 255, 255, 0.38)',
                    borderColor: !showSelect
                      ? '#55C341'
                      : 'rgba(255, 255, 255, 0.38)',
                  }}
                >
                  fill (どこでもOK)
                </Button>
              </ButtonGroup>
            </Box>
            {showSelect &&
              DesireRoleList.map((label, index) => (
                <FormControl
                  key={label}
                  className={styles.select}
                  size="small"
                  variant="outlined"
                >
                  <InputLabel id={`role-${index}-label`}>
                    {label}
                  </InputLabel>
                  <Controller
                    name={`role-${index}`}
                    control={control}
                    defaultValue={
                      editData.roles[index] !== 'ANYWHERE'
                        ? editData.roles[index] ?? ''
                        : ''
                    }
                    as={
                      <Select
                        inputProps={{
                          classes: {
                            icon: styles.icon,
                          },
                        }}
                        autoWidth
                        labelId={`role-${index}-label`}
                        label={label}
                        displayEmpty
                      >
                        {LolRole.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  />
                </FormControl>
              ))}
          </Box>
          <Box className={styles.formGroup}>
            <Typography variant="subtitle1" className={styles.label}>
              最高到達ランク（任意）
            </Typography>
            <FormControl fullWidth size="small" variant="outlined">
              <InputLabel id="max-rank-label">
                ランクを選択
              </InputLabel>
              <Controller
                name="max_rank"
                control={control}
                defaultValue={editData.max_rank}
                as={
                  <Select
                    inputProps={{
                      classes: {
                        icon: styles.icon,
                      },
                    }}
                    labelId="max-rank-label"
                    label="ランクを選択"
                    defaultValue={editData.max_rank}
                    displayEmpty
                    style={{maxWidth: '200px'}}
                    variant="outlined"
                  >
                    <MenuItem value=""></MenuItem>
                    {LolRank.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                }
              />
            </FormControl>
          </Box>
          <Box className={styles.formGroup}>
            <Typography variant="subtitle1" className={styles.label}>
              クラン参加希望
            </Typography>
            <Box>
              <Controller
                name="desired_clan"
                control={control}
                defaultValue={editData.desired_clan}
                as={
                  <RadioGroup aria-label="desired_clan" row>
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" size="small" />}
                      label="希望する"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary" size="small" />}
                      label="希望しない"
                    />
                  </RadioGroup>
                }
              />
            </Box>
          </Box>
          <Box className={styles.formGroup}>
            <Typography variant="subtitle1" className={styles.label}>
              VC（複数選択可）
            </Typography>
            <RadioGroup>
              {VcList.map((option) => (
                <FormControlLabel
                  key={option.label}
                  label={option.label}
                  control={
                    <Checkbox
                      color="primary"
                      size="small"
                      value={option.value}
                      onChange={handleChange}
                      defaultChecked={editData.vc.includes(option.value)}
                    />
                  }
                />
              ))}
            </RadioGroup>
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{marginBottom: '8px'}}
              disabled={isSubmitDisabled}
            >
              更新する
            </Button>
          </Box>
        </CardContent>
      </Card>
    </form>
  )
}
