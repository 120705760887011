import {Avatar, Button, LoadingPart} from '@/components/atoms'
import {UserFragment, useGiftAddCoinMutation, useMyWalletQuery} from '@/graphql/client'
import {useSnackbar} from '@/hooks/acknowledge'
import {useClientStateContext} from '@/hooks/apollo'
import {
  Box,
  Checkbox,
  Fade,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import SendIcon from '@material-ui/icons/Send'
import {useState} from 'react'

const useStyles = makeStyles(({spacing}) =>
  createStyles({
    modal: {
      justifyContent:'center',
      alignItems: 'center',
      display: 'flex',
      margin: spacing(4),
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '20px 16px',
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    },
  })
)

type GiftModalProps = {
  open: boolean
  onClose: () => void
  user: UserFragment
}

type steps = 'send_coin' | 'confirm'
export const GiftModal = ({open, onClose, user}: GiftModalProps) => {
  const styles = useStyles()
  const {authState} = useClientStateContext()
  const [step, setStep] = useState<steps>('send_coin')
  const [amount, setAmount] = useState(0)
  const [message, setMessage] = useState('')
  const [giftAddCoin, {loading}] = useGiftAddCoinMutation()
  const {snackbar} = useSnackbar()

  const handleClose = () => {
    setStep('send_coin')
    setAmount(0)
    setMessage('')
    onClose()
  }
  const handleSubmit = async () => {
    const input = {
      toId: user.id,
      amount,
      message,
    }
    try {
      await giftAddCoin({variables: {input}})
      snackbar.success('ギフトを送付しました!')
    } catch (e) {
      snackbar.failed('ギフトの送付に失敗しました', e)
    }
    handleClose()
  }

  let content: React.ReactNode
  if (user.isDeleted) {
    content = '退会したユーザーにはギフトを送れません。'
  // TODO: meQuery を meContext に置き換えてから実装したい
  //} else if (??? === user.id) {
  //  content = '自分自身にはギフトを送れません。'
  } else if (authState !== 'Authenticated') {
    content = 'ギフトを送るにはログインが必要です。'
  } else if (loading) {
    content = <LoadingPart />
  } else if (step === 'send_coin') {
    content = (
      <SendCoinForm
        user={user}
        amount={amount}
        setAmount={setAmount}
        message={message}
        setMessage={setMessage}
        proceed={() => setStep('confirm')}
      />
    )
  } else if (step === 'confirm') {
    content = (
      <GiftConfirmation
        user={user}
        amount={amount}
        message={message}
        back={() => setStep('send_coin')}
        proceed={handleSubmit}
      />
    )
  }

  return (
    <Modal open={open} onClose={handleClose} className={styles.modal}>
      <Fade in={open}>
        <Box
          width="100%"
          maxWidth={444}
          display="flex"
          flexDirection="column"
          borderRadius={4}
          bgcolor="#232323"
        >
          <div className={styles.header}>
            <Typography variant="h4">ギフトを送る</Typography>
            <IconButton size="small" onClick={handleClose}><CloseIcon /></IconButton>
          </div>
          <Box padding={4} minHeight="30vh">{content}</Box>
        </Box>
      </Fade>
    </Modal>
  )
}

type SendCoinFormProps = {
  user: UserFragment
  amount: number
  setAmount: (amount: number) => void
  message: string
  setMessage: (message: string) => void
  proceed: () => void
}
const SendCoinForm = ({user, amount, setAmount, message, setMessage, proceed}: SendCoinFormProps) => {
  const {data, error, loading} = useMyWalletQuery({fetchPolicy: 'network-only'})

  if (loading) return <LoadingPart />
  if (error) return <Box>{error.message}</Box>

  const current = data?.myWallet.availableCoin ?? 0
  const balance = current - (Number.isNaN(amount) ? 0 : amount)
  const updateAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(e.target.value ?? 0)
    if (value < 0) value = 0
    // input を空欄にできるように、value が NaN の場合はそのままにする
    setAmount(value)
  }

  const insufficient = balance < 0
  const tooLong = message.length > 64

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" marginY={5}>
        <Typography variant="subtitle1">現在の保有コイン</Typography>
        <Typography variant="body1">{current} AC</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" marginY={10}>
        <Typography variant="subtitle1">送付後の残コイン</Typography>
        <Typography variant="body1">
          {insufficient ? current : balance} AC
        </Typography>
      </Box>
      <Box marginY={10}>
        <InputLabel htmlFor="amount">
          <Typography variant="subtitle1" color="textPrimary">送る枚数</Typography>
        </InputLabel>
        <TextField
          type="number"
          name="amount"
          value={amount}
          onChange={updateAmount}
          variant="outlined"
          size="small"
          error={insufficient}
          helperText={insufficient && '保有コインが不足しています'}
          InputProps={{
            endAdornment: <InputAdornment position="end">AC</InputAdornment>,
          }}
        />
      </Box>
      <Box marginY={8}>
        <InputLabel htmlFor="message">
          <Typography variant="subtitle1" color="textPrimary">メッセージ（任意）</Typography>
        </InputLabel>
        <TextField
          type="text"
          name="message"
          value={message}
          onChange={e => setMessage(e.target.value ?? '')}
          variant="outlined"
          size="small"
          fullWidth
          multiline
          error={tooLong}
          helperText={tooLong && '0~64文字で入力してください'}
        />
      </Box>
      <Box marginY={8}>
        <Typography variant="subtitle1">送り先</Typography>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="row"
          style={{gap: '8px'}}
          borderRadius={4}
          bgcolor="rgba(255, 255, 255, 0.12)"
          padding={6}
        >
          <Avatar src={user.profile.thumbnailUrl} />
          <Typography variant="subtitle2">{user.profile.displayName}</Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" marginY={8}>
        <Button
          onClick={proceed}
          disabled={insufficient || tooLong || amount === 0 || Number.isNaN(amount)}
          size="large"
        >
          <Typography variant="subtitle2">内容確認</Typography>
        </Button>
      </Box>
    </Box>
  )
}

const useGiftConfirmationStlyes = makeStyles(() => createStyles({
  icon: {
    width: '64px',
    height: '68px',
  },
  // アイコンは影が画像に含まれていて実際のサイズより小さく見えるので、アバターを縮小して釣り合いをとる
  // 左右のバランスをとるため、サイズが (画像 + マージン) = アイコン になるようにする
  avatar: {
    width: '60px',
    height: '60px',
    margin: '4px 2px',
  },
  send: {
    width: '24px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
  },
}))

type GiftConfirmationProps = {
  user: UserFragment
  amount: number
  message: string
  back: () => void
  proceed: () => void
}
const GiftConfirmation = ({user, amount, message, back, proceed}: GiftConfirmationProps) => {
  const styles = useGiftConfirmationStlyes()
  const [checked, setChecked] = useState(false)

  return (
    <Box marginY={4}>
      <Typography variant="h3" align="center">本当に送付してよろしいですか？</Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        marginY={10}
        marginX="auto"
        maxWidth={280}
      >
        <Box width="100%" display="flex" flexDirection="column" alignItems="center">
          <img src="/images/adictor-coin.svg" width={64} height={68} alt="adictor coin logo" />
          <Typography variant="subtitle1">{amount} AC</Typography>
        </Box>
        <Box width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="space-around">
          <SendIcon color="primary" className={styles.send} />
        </Box>
        <Box width="100%" display="flex" flexDirection="column" alignItems="center">
          <Avatar src={user.profile.thumbnailUrl} className={styles.avatar} />
          <Typography variant="subtitle1">{user.profile.displayName}</Typography>
        </Box>
      </Box>
      {message &&
      <Box
        display="flex"
        alignItems="center"
        flexDirection="row"
        borderRadius={4}
        bgcolor="rgba(255, 255, 255, 0.12)"
        padding={4}
        marginY={10}
      >
        <Typography>{message}</Typography>
      </Box>}
      <Box marginY={10}>
        <label>
          <Checkbox color="primary" checked={checked} onChange={e => setChecked(e.target.checked)}/>
          ギフトの内容に間違いがないことを確認しました
        </label>
      </Box>
      <Box className={styles.buttons}>
        <Button
          onClick={() => back()}
          variant="outlined"
          size="large"
        >
          <Typography variant="subtitle2">修正する</Typography>
        </Button>
        <Button
          onClick={proceed}
          disabled={!checked}
          size="large"
        >
          <Typography variant="subtitle2">ギフトを送る</Typography>
        </Button>
      </Box>
    </Box>
  )
}
