import React from 'react'

import {
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
} from '@material-ui/core'

export type ButtonProps = MUIButtonProps & {
  outlined?: boolean
}

export const Button: React.FC<ButtonProps> = ({variant='contained', outlined=false, ...props}) => {

  return (
    <MUIButton color='primary' variant={!outlined ? variant : 'outlined'} {...props} />
  )

}
