import React from 'react'

import {
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core/'

import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles'

import {
  Link,
  LinkProps,
} from '../../atoms'

import {
  composeClassNames,
} from '@/utils'

const useDrawerMenuListItemStyles = makeStyles(({palette}) => createStyles({
  menuListItem: {
    height: 50,
    borderLeftWidth: 6,
    borderLeftColor: palette.contentBackground,
    borderLeftStyle: 'solid',
  },
  activeMenuListItem: {
    borderLeftColor: palette.primary.main,
  },
  inactiveMenuListItem: {
    color: palette.text.disabled,
  },
}))

type DrawerMenuListItemProps = LinkProps & {
  title: string
  active?: boolean
}

export const DrawerMenuListItem: React.FC<DrawerMenuListItemProps> = ({title, active=false, ...props}) => {

  const styles = useDrawerMenuListItemStyles()

  const statusClassName = active ? styles.activeMenuListItem : styles.inactiveMenuListItem

  return (
    <Link {...props}>
      <ListItem button className={composeClassNames(styles.menuListItem, statusClassName)}>
        <ListItemText disableTypography>
          <Typography variant='subtitle1'>{title}</Typography>
        </ListItemText>
      </ListItem>
    </Link>
  )
}
