import {
  Maybe,
  TournamentConnection,
  TournamentEdge,
  useEnteredTournamentsQuery,
  useFeaturedTournamentsQuery,
} from '@/graphql/client'
import React, {useEffect, useState} from 'react'

import {
  Box,
  BoxProps,
  createStyles,
  makeStyles,
} from '@material-ui/core'

import {
  TournamentCardGrid,
} from '../../molecules'

import {
  Button,
  Center,
  LoadingPart,
  Title,
} from '../../atoms'
import MutationLoading from '@/components/templates/layouts/mutation-loading'
import {useGameContext} from '@/hooks/game'

type TournamentCardListProps = BoxProps & {
  gridProps?: Array<Maybe<TournamentEdge>> | null
  subtitle?: string
  loading?: boolean
  hasMore?: boolean
  onViewMore: () => void
}

const TournamentCardList: React.FC<TournamentCardListProps> = ({subtitle, gridProps, hasMore, onViewMore, loading, ...props}) => {
  return (
    <Box {...props}>
      {!!subtitle && <Title variant='h3'>{subtitle}</Title>}
      <TournamentCardGrid style={{marginBottom: 40}} data={gridProps} />
      <Center>
        {hasMore && <Button outlined onClick={onViewMore} disabled={loading}>View more</Button>}
        {loading && <MutationLoading />}
      </Center>
    </Box>
  )
}

type TournamentCardListContainerProps = BoxProps & {
  res?: TournamentConnection
  error: any
  loading: boolean
  fetchMore: any
  refetch: any
  subtitle: string
}

const TournamentCardListContainer: React.FC<TournamentCardListContainerProps> = ({res, error, loading, fetchMore, refetch, subtitle, ...props}) => {
  const [viewMore, setViewMore] = useState<boolean>(false)

  useEffect(() => {
    refetch()
  }, [])

  const onViewMore = async () => {
    setViewMore(true)
    await fetchMore({
      variables: {
        after: res?.pageInfo.endCursor,
        first: 6,
      },
    })
  }

  // viewMore状態の場合は Loading のみの表示はしない
  if (loading && !viewMore) {
    return <LoadingPart />
  }

  return (
    <>
      <TournamentCardList subtitle={subtitle} gridProps={res?.edges} onViewMore={onViewMore} hasMore={res?.pageInfo.hasNextPage} loading={loading} {...props} />
    </>
  )
}

const featuredTournamentCardListStyles = makeStyles(() => createStyles({
  wrap: {
    marginTop: 32,
  },
}))

export const FeaturedTournamentCardList: React.FC = () => {
  const styles = featuredTournamentCardListStyles()
  const {gameId} = useGameContext()
  const {data, error, loading, fetchMore, refetch} = useFeaturedTournamentsQuery({
    variables: {
      gameId: gameId,
      first: 6,
    },
    notifyOnNetworkStatusChange: true, // これがないとViewMore時にLoadingがtrueにならない
  })

  const res = data?.featuredTournaments

  return (
    <Box className={styles.wrap}>
      <TournamentCardListContainer subtitle='注目の大会' res={res} error={error} loading={loading} fetchMore={fetchMore} refetch={refetch} />
    </Box>
  )
}

export const EnteredTournamentCardList: React.FC = () => {
  const {data, error, loading, fetchMore, refetch} = useEnteredTournamentsQuery({
    variables: {
      first: 6,
    },
    notifyOnNetworkStatusChange: true, // これがないとViewMore時にLoadingがtrueにならない
  })

  const res = data?.enteredTournaments

  return (
    <TournamentCardListContainer subtitle='' res={res} error={error} loading={loading} fetchMore={fetchMore} refetch={refetch} />
  )
}
