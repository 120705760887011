import {
  ChatMessageAddInput,
  ChatMessageType, SingleEliminationTournamentBracket,
  TournamentNodeFragment,
  TournamentStatus,
  useChatMessageAddMutation,
  useChatMessagesQuery, useJoinChatRequestAddMutation, useMeQuery, useTournamentBracketForHeaderQuery,
} from '@/graphql/client'
import React, {useEffect, useRef, useState} from 'react'

import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'

import {ResultTournamentCover} from '../tournaments/tournament-form'
import {useForm} from 'react-hook-form'

import {
  Avatar,
  Button,
} from '@/components/atoms'

import {useSnackbar} from '@/hooks/acknowledge'
import {useErrorReport} from '@/hooks/error'
import {format, parseISO} from 'date-fns'
import ja from 'date-fns/locale/ja'
import useAxios from 'axios-hooks'
import Linkify from 'react-linkify'
import {ChatOrganizerDisplay} from '@/pages/tournaments/[id]/nodes/[node_id]/battle'
import {getTournamentNodeUnitUsers} from '@/utils'
import {isTournamentNodeFragment} from '@/types/tournament'
import {useIsLg} from '@/hooks'

const useTournamentBattleStyle = makeStyles(({breakpoints}) =>
  createStyles({
    chatWrap: {
      background: '#1E1E1E',
      height: '100%',
      maxHeight: '832px',
      position: 'relative',
      [breakpoints.up('lg')]: {
        maxHeight: 'initial',
      },
    },
    chatHeader: {
      height: '67px',
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      display: 'flex',
      alignItems: 'center',
      padding: '17px 16px',
      boxSizing: 'border-box',
    },
    chatHeadImgs: {
      width: 'calc(100% - 67px - 103px)',
      display: 'flex',
      justifyContent: 'flex-end',
      boxSizing: 'border-box',
      padding: '0 8px',
    },
    avatar: {
      width: '32px',
      height: '32px',
      marginLeft: '-10px',
    },
    chatavatar: {
      width: '24px',
      height: '24px',
    },
    chatHeadTxt: {
      width: '67px',
    },
    callAdmin: {
      width: '110px',
      padding: '2px 10px',
      background: 'none',
      color: '#55C341',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    chatBottom: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      background: '#232323',
      padding: '19px 16px',
      display: 'flex',
      alignItems: 'center',
      [breakpoints.up('lg')]: {
        width: '375px',
      },
    },
    sendicon: {
      width: '22px',
      height: '19px',
    },
    imgicon: {
      width: '20px',
      height: '20px',
    },
    chatBody: {
      height: 'calc(100vh - 76px - 67px)',
      overflow: 'auto',
      boxSizing: 'border-box',
      padding: '20px 16px',
      [breakpoints.up('lg')]: {
        height: '100%',
      },
    },
    chatTxtInputWrap: {
      padding: '0 16px',
      width: 'calc(100% - 22px - 20px)',
    },
    chatTxtInput: {
      width: '100%',
      boxSizing: 'border-box',
      padding: '0 12px',
      border: '1px solid rgba(255, 255, 255, 0.12)',
      background: 'none',
      borderRadius: '22px',
      height: '36px',
      color: '#fff',
      '&:focus': {
        outline: '2px solid #55C341',
      },
    },
    chatIndex: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      marginBottom: '24px',
    },
    chatIndexMe: {
      justifyContent: 'flex-end',
      '& > div:nth-of-type(1)': {
        order: '2',
      },
    },
    adminlabel: {
      fontSize: '10px',
      padding: '2px 8px',
      color: '#fff',
      background: '#F3C000',
      borderRadius: '10px',
      fontWeight: 'bold',
      marginRight: '5px',
    },
    chatAvatar: {
      width: '24px',
    },
    chatContent: {
      width: 'auto',
      marginLeft: '8px',
      maxWidth: '240px',
      boxSizing: 'border-box',
    },
    chatContentMe: {
      marginRight: '8px',
      marginLeft: 'auto',
    },
    chatUserContent: {
      fontSize: '14px',
      lineHeight: '160.1%',
      padding: '14px',
      background: 'rgba(255, 255, 255, 0.12)',
      borderRadius: '16px',
      marginBottom: '4px',
      wordWrap: 'break-word',
    },
    chatUserContentMe: {
      background: '#55C341',
    },
    chatImageContent: {
      position: 'relative',
      borderRadius: '16px',
      marginBottom: '4px',
      overflow: 'hidden',
      '& > img': {
        maxWidth: '100%',
        width: 'auto',
        height: 'auto',
      },
    },
    chatProgressContent: {
      borderRadius: '16px',
      background: '#E5E5E5',
      width: '200px',
      height: '116px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    progress: {
      color: '#55C341',
      width: '30px',
      height: '30px',
    },
    chatUserName: {
      fontSize: '12px',
      lineHeight: '18px',
      color: 'rgba(255, 255, 255, 0.6)',
      marginBottom: '4px',
    },
    chatUserNameMe: {
      textAlign: 'right',
    },
    chatDate: {
      fontSize: '12px',
      lineHeight: '18px',
      color: 'rgba(255, 255, 255, 0.6)',
    },
    chatDateMe: {
      textAlign: 'right',
    },
    chatDateAlert: {
      color: '#F44336',
    },
    chatNotice: {
      width: '240px',
      background: '#232323',
      padding: '12px',
      margin: '0 auto 24px',
      textAlign: 'center',
      borderRadius: '8px',
    },
    uploadFaild: {
    },
    inputImage: {
      display: 'none',
    },
    // ボタン部分のCSSは仮
    imageButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      '&:focus': {
        outline: 'none',
      },
    },
    sendButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      '&:focus': {
        outline: 'none',
      },
    },
    image: {
      width: '201px',
      borderRadius: '8px',
    },
    organizer: {
      marginRight: '4px',
      width: '47px',
      height: '16px',
      fontWeight: 'bold',
      fontSize: '10px',
      lineHeight: '15px',
      position: 'relative',
      borderRadius: '10px',
      padding: '0 8px',
      background: '#F3C000',
    },
  })
)

enum JoinChatRequestStatus {
  None,
  Requested,
  Joined,
}

type TournamentBattleChatProps = {
  tournamentId: string
  node: TournamentNodeFragment
  toggleChatState?: () => void
  organizer: ChatOrganizerDisplay
  tournamentStatus: TournamentStatus | undefined
}

export const TournamentBattleChat: React.FC<TournamentBattleChatProps> = ({node, ...props}) => {
  const styles = useTournamentBattleStyle()

  const {data: meData} = useMeQuery()

  //
  // fetchMore で更新分のメッセージだけを取得するようにしているが、これを実現するためには色々と癖があるので注意が必要
  //
  // - useQuery のところで first に小さい値を指定する必要がある（値が大きいと fetchMore の際に2回クエリーが発行されて、片方は全データ取得となる）
  //   - この影響で初回は 1件(useQuery) + n件(fetchMore, 5秒後) という取得になってしまっている。
  //   - 見た目の違和感を避けるため counter が 0 (初回) の時はメッセージを表示しない実装となっている。
  //   - これにより表示は必ず5秒後になってしまっている。この点はもう少し改良の余地がある気はしている。
  // - 一旦末尾まで到達すると endCursor が null になってしまい差分取得ができなくなる。そのため useState で endCursor を保存している。
  // - 同様の理由で初回0件の時は endCursor が null になるので、代わりに refetch を使う必要がある
  // - useState で counter という変数を利用して fetchMore の定期実行をしているが、試行錯誤でこうしないと動かなかった感じがしてそうなっている。
  //   - 実際は別の問題な感じもしているので、実は不要かもしれない（が極端に問題があるわけでもなさそう）
  //
  const {data, refetch, fetchMore} = useChatMessagesQuery({
    variables: {
      tournamentId: props.tournamentId,
      nodeId: node.nodeId,
      first: 1,
    },
  })

  const [joinChatStatus, setJoinChatStatus] = useState(JoinChatRequestStatus.None)
  const {data: tournamentBracketData} = useTournamentBracketForHeaderQuery({
    variables: {
      id: props.tournamentId,
    },
  })

  const [endCursor, setEndCursor] = useState<string|null>(null)
  const [counter, setCounter] = useState<number>(0)
  const [showImageUploadError, setShowImageUploadError] = useState<boolean>(false)
  const [showSendChatError, setShowSendChatError] = useState<boolean>(false)
  const [chatMessage, setChatMessage] = useState<string>('')
  const {snackbar} = useSnackbar()
  const {errorReport} = useErrorReport()
  const inputRef = useRef()
  const isLg  = useIsLg()

  useEffect(() => {
    if (data?.chatMessage.pageInfo.endCursor) {
      setEndCursor(data?.chatMessage.pageInfo.endCursor)
    }
  }, [data?.chatMessage.pageInfo.endCursor])

  useEffect(() => {
    (async () => {
      try {
        if (endCursor) {
          await fetchMore({
            variables: {
              after: endCursor,
              first: 1000,
            },
          })
        } else {
          await refetch()
        }
      } catch (e) {
        errorReport.captureException(e)
      }
    })()
  }, [counter])

  useEffect(() => {
    const timerId = setTimeout(() => {
      setCounter(counter + 1)
    }, 5000)
    return () => clearTimeout(timerId)
  }, [counter])

  const [chatMessageAdd] = useChatMessageAddMutation()

  const {register, handleSubmit, reset} = useForm<ChatMessageAddInput>({mode: 'onChange'})

  const [joinChatRequestAdd] = useJoinChatRequestAddMutation()
  const handleJoinChatRequestAdd = async () => {
    try {
      await joinChatRequestAdd({variables: {
        input: {
          tournamentId: props.tournamentId,
          nodeId: node.nodeId,
        },
      }})
      setJoinChatStatus(JoinChatRequestStatus.Requested)
    } catch (e) {
      snackbar.failed('チャット参加リクエストに失敗しました', e)
    }
  }

  const [
    {data: postData, loading: postLoading, error: postError},
    executePost,
  ] = useAxios<ResultTournamentCover>(
    {
      url: '/assets/chat_image',
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-USER-ID': meData?.me.id,
      },
    },
    {manual: true}
  )

  React.useEffect(() => {
    if (postData?.url) {
      const msg: ChatMessageAddInput = {
        tournamentId: props.tournamentId,
        nodeId: node.nodeId,
        messageType: ChatMessageType.Image,
        body: postData?.url,
      }
      try {
        chatMessageAdd({
          variables: {
            input: msg,
          },
        })
        reset()
        setCounter(counter + 1)
      } catch (e) {
        reset()
        showError('image')

      }
    }
  }, [postData?.url])

  const onTextMessageSend = async (value: any) => {
    if (value.body === '') {
      return
    }

    const msg: ChatMessageAddInput = {
      tournamentId: props.tournamentId,
      nodeId: node.nodeId,
      messageType: ChatMessageType.Text,
      body: value.body,
    }
    try {
      await chatMessageAdd({
        variables: {
          input: msg,
        },
      })
      reset()
      setCounter(counter + 1)
    } catch (e) {
      showError('message', value.body)
      reset()
    }
  }

  const handleImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return
    }
    const uploadImage = event.target.files[0]
    const formData = new FormData()
    formData.append('image', uploadImage)
    formData.append('tournament_id', props.tournamentId)
    formData.append('node_id', node.nodeId)
    try {
      await executePost({
        data: formData,
      })
    } catch (e) {
      showError('image')
    }
  }

  const chatBodyHeight = () => {
    const isSpview = window.innerWidth < 560
    let height = 0
    const chatHeaderHeight = window.document.getElementById('header')?.clientHeight
    const formHeight = window.document.getElementById('form')?.clientHeight
    if (isSpview) {
      height = window.document.documentElement.clientHeight
    } else {
      height = window.document.getElementById('wrap')?.offsetHeight || 0
    }
    if (chatHeaderHeight && formHeight && height) {
      window.document.getElementById('scroll-inner')?.style.setProperty('height', `${height - (chatHeaderHeight + formHeight)}px`)
    }

  }

  useEffect(() => {
    window.addEventListener('resize', chatBodyHeight)
    chatBodyHeight()
  }, [inputRef])

  useEffect(() => {
    const scrollTarget = document.getElementById('scroll-inner')
    const scrollHeight = scrollTarget?.scrollHeight
    if (scrollTarget && scrollHeight !== undefined) {
      scrollTarget.scrollTop = scrollHeight
    }
  }, [data?.chatMessage.edges, postLoading, showImageUploadError, showSendChatError])

  const userlist = () => {
    if (isTournamentNodeFragment(node)) {
      return getTournamentNodeUnitUsers(node).map((user) =>
        <Avatar key={user.id} src={user.profile.thumbnailUrl} className={styles.avatar} />
      )
    } else {
      return <></>
    }
  }

  const rawMessages = data?.chatMessage.edges?.map((x) => x!.node)
  const messages = (rawMessages && counter !== 0) ? rawMessages.sort((x, y) => {
    return x!.sentAt - y!.sentAt
  }) : []

  useEffect(() => {
    if (!messages) return
    messages.forEach((message) => {
      if (message?.user.id === props.organizer.id) {
        setJoinChatStatus(JoinChatRequestStatus.Joined)
      }
    })
  }, [messages])

  useEffect(() => {
    (tournamentBracketData?.tournament.bracket as SingleEliminationTournamentBracket|null)?.joinChatRequests.forEach((bracket) => {
      if (bracket === undefined) {
        return
      }
      if (bracket.node.nodeId === node.nodeId) {
        setJoinChatStatus(JoinChatRequestStatus.Requested)
      }
    })
  }, [tournamentBracketData])


  const showError = (type: string, text?: string) => {
    if (type === 'message' && text) {
      setShowSendChatError(true)
      setChatMessage(text)
      const timerId = setTimeout(() => {
        setShowSendChatError(false)
      }, 5000)
      return () => clearTimeout(timerId)
    } else if (type === 'image') {
      handleImageError()
    }
  }

  useEffect(() => {
    if (postError) {
      handleImageError()
    }
  }, [postError])

  const handleImageError = () => {
    setShowImageUploadError(true)
    const timerId = setTimeout(() => {
      setShowImageUploadError(false)
    }, 10000)
    return () => clearTimeout(timerId)
  }

  const joinChatRequestDisabled = props.tournamentStatus !== TournamentStatus.InProgress || (joinChatStatus === JoinChatRequestStatus.Joined || joinChatStatus === JoinChatRequestStatus.Requested)

  const NoticeMessageComponents = ({isPostLoading, isImageUploadError}:{isPostLoading?: boolean, isImageUploadError?: boolean}) => {
    return (
      <Box className={styles.chatIndex + ' ' + styles.chatIndexMe}>
        <Box className={styles.chatAvatar}>
          <Avatar src={meData?.me.profile.thumbnailUrl} className={styles.chatavatar} />
        </Box>
        <Box className={styles.chatContent + ' ' + styles.chatContentMe}>
          <Typography className={styles.chatUserName + ' ' + styles.chatUserNameMe}>{meData?.me.profile.displayName}</Typography>
          <Box className={ isPostLoading||isImageUploadError ? styles.chatProgressContent : '' }>
            {isPostLoading && <CircularProgress className={styles.progress} /> }
            {
              isImageUploadError ? <img className={styles.uploadFaild} src='/images/uploda_faild.svg' /> :
                !isPostLoading  &&
                <Typography className={styles.chatUserContent + ' ' + styles.chatUserContentMe}>
                  {chatMessage}
                </Typography>
            }
          </Box>
          <Typography className={styles.chatDate + ' ' + styles.chatDateMe + ' ' + `${isPostLoading ? '' : styles.chatDateAlert}`}>
            {isPostLoading?'送信中…':'送信失敗'}
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box className={styles.chatWrap} id="wrap">
      <Box className={styles.chatHeader} id="header">
        <h3 className={styles.chatHeadTxt}>
          {isLg ? <span>チャット</span> : <img src='/images/arrow.png' onClick={props.toggleChatState} />}
        </h3>
        <Box className={styles.chatHeadImgs}>
          {joinChatStatus === JoinChatRequestStatus.Joined &&
            <Avatar src={props.organizer.thumbnailUrl} className={styles.avatar} />
          }
          {userlist}
        </Box>
        {meData?.me.id !== props.organizer.id &&
          <Button outlined className={styles.callAdmin} onClick={handleJoinChatRequestAdd} disabled={joinChatRequestDisabled}>主催者を呼ぶ</Button>
        }
      </Box>
      <Box className={styles.chatBody} id="scroll-inner">

        <Box className={styles.chatNotice}>
          対戦相手と連絡を取り合って、バトルを開始しましょう
        </Box>
        {messages.map((x: any) => {
          const isMe = x.user.id === meData?.me.id
          return (
            <div key={x.id}>
              <Box className={`${styles.chatIndex} ${isMe ? styles.chatIndexMe : ''}`}>
                <Box className={styles.chatAvatar}>
                  <Avatar src={x.user.profile.thumbnailUrl} className={styles.chatavatar} />
                </Box>
                <Box className={`${styles.chatContent} ${isMe ? styles.chatContentMe : ''}`}>
                  <Grid container>
                    {x.user.id === props.organizer.id && <Typography className={styles.organizer}>主催者</Typography>}
                    <Typography className={`${styles.chatUserName} ${isMe ? styles.chatUserNameMe : ''}`}>{x.user.profile.displayName}</Typography>
                  </Grid>
                  {x.messageType === ChatMessageType.Image ?
                    <img src={x.body} alt="" className={styles.image} /> :
                    <Typography className={`${styles.chatUserContent} ${isMe ? styles.chatUserContentMe: ''}`}>
                      <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                          <a target="_blank" href={decoratedHref} key={key} rel="noopener noreferrer">
                            {decoratedText}
                          </a>)}
                      >
                        {x.body}
                      </Linkify>
                    </Typography>
                  }
                  <Typography className={`${styles.chatDate} ${isMe ? styles.chatDateMe : ''}`}>
                    {format(parseISO(x.sentAt), 'yyyy/MM/dd HH:mm', {locale: ja})}
                  </Typography>
                </Box>
              </Box>
            </div>
          )
        })}
        {postLoading && <NoticeMessageComponents isPostLoading />}
        {(postError && showImageUploadError) && <NoticeMessageComponents isImageUploadError />}
        {showSendChatError && <NoticeMessageComponents />}

        {joinChatStatus === JoinChatRequestStatus.Requested &&
          <Box className={styles.chatNotice}>
            主催者を招待しています…
          </Box>
        }
      </Box>
      {props.tournamentStatus === TournamentStatus.InProgress &&
        <form onSubmit={handleSubmit(onTextMessageSend)}>
          <Box className={styles.chatBottom} id="form">
            <label className={styles.imageButton}>
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                className={styles.inputImage}
                onChange={(e) => handleImage(e)}
              />
              <img className={styles.imgicon} src='/images/Icon_image.svg' />
            </label>
            <Box className={styles.chatTxtInputWrap}>
              <input
                autoComplete='off'
                className={styles.chatTxtInput}
                type='text'
                placeholder='メッセージを入力'
                name='body'
                ref={register}
                disabled={false}
                onClick={chatBodyHeight}
              />
            </Box>
            <button type='submit' className={styles.sendButton}>
              <img className={styles.sendicon} src='/images/Icon_send.svg' />
            </button>
          </Box>
        </form>
      }
    </Box>
  )
}
