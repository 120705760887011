import {
  Box,
  ButtonBase,
  Link,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import {Avatar, SquareAvatar} from '@/components'
import React from 'react'
import {
  TournamentJoinChatRequestFragment,
  TournamentNodeStatus,
} from '@/graphql/client'
import {getTournamentNodeUnitThumbnailUrl, getTournamentNodeUnits, getTournamentUnitDisplayName} from '@/utils'
import {SingleEliminationUnitFragment, isTournamentNodeFragment} from '@/types/tournament'

type JoinChatRequestListProp = {
  joinChatReaquests: TournamentJoinChatRequestFragment[] | undefined
  tournamentId: string
}

const useJoinChatRequestListStyle = makeStyles(() =>
  createStyles({
    roundBoxIndexOuter: {
      border: '1px solid rgba(255, 255, 255, 0.12)',
      borderRadius: '4px',
      position: 'relative',
      width: '200px',
      margin: '0 auto 24px',
    },
    roundOuterMargin: {
      marginTop: '30px',
    },
    roundMatch: {
      background: '#232323',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '4px 8px 4px 7px',
      position: 'relative',
      minHeight: '40px',
      '&:nth-of-type(1)': {
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      },
    },
    inner: {
      width: '100%',
    },
    avatar: {
      width: '32px',
      height: '32px',
      marginRight: '7px',
    },
    userName: {
      lineHeight: '1',
    },
    score: {
      color: 'rgba(255, 255, 255, 0.6)',
      textAlign: 'right',
      right: '8px',
      top: '11px',
      fontSize: '15px',
      lineHeight: '18px',
      marginLeft: 'auto',
    },
    roundBoxButton: {
      width: '100%',
    },
    roundBoxLink: {
      width: '100%',
      color: 'inherit',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    roundStatus: {
      fontWeight: 'bold',
      fontSize: '10px',
      lineHeight: '15px',
      position: 'absolute',
      left: '0',
      top: '-20px',
      borderRadius: '10px',
      padding: '0 8px',
    },
    roundStatusCONFIRM: {
      background: '#489FB5',
    },
    roundStatusIN_REVIEW: {
      background: '#477755',
    },
    roundStatusPENDING: {
      background: 'rgba(255, 255, 255, 0.38)',
    },
    roundStatusIN_BATTLE: {
      background: '#F3C000',
    },
    roundStatusHIDE: {
      display: 'none',
    },
  })
)

export const JoinChatRequestList: React.FC<JoinChatRequestListProp> = ({joinChatReaquests, tournamentId}) => {
  const styles = useJoinChatRequestListStyle()
  if (joinChatReaquests === undefined) {
    return (<></>)
  }
  const nodes = joinChatReaquests

  const getNodeStatus = (status: TournamentNodeStatus): string => {
    switch (status) {
      case TournamentNodeStatus.Pending:
        return '待機中'
      case TournamentNodeStatus.InBattle:
        return '対戦中'
      case TournamentNodeStatus.InReview:
        return '承認待ち'
      case TournamentNodeStatus.Confirm:
        return '確認済み'
      default:
        return ''
    }
  }

  const getRoundStyle = (result: string): string => {
    if (result === 'CONFIRM') {
      return styles.roundStatusCONFIRM
    } else if (result === 'IN_BATTLE') {
      return styles.roundStatusIN_BATTLE
    } else if (result === 'IN_REVIEW') {
      return styles.roundStatusIN_REVIEW
    } else if (result === 'PENDING') {
      return styles.roundStatusPENDING
    }
    return styles.roundStatus
  }

  const nodeList = nodes.map((node, index) => {
    const units = getTournamentNodeUnits(node.node)
    const users: (SingleEliminationUnitFragment | null)[] = []
    users.push(units.find((x) => x.index == 0) || null)
    users.push(units.find((x) => x.index == 1) || null)

    return (
      <Box key={index} className={styles.roundBoxIndexOuter + ' ' + styles.roundOuterMargin}>
        <Typography variant='caption' className={styles.roundStatus + ' ' + (!node.node.status ? styles.roundStatusHIDE : getRoundStyle(node.node.status))}>{getNodeStatus(node.node.status)}</Typography>
        <ButtonBase className={styles.roundBoxButton}>
          <Link href={`/tournaments/${tournamentId}/nodes/${node.node.nodeId}/battle`} className={styles.roundBoxLink}>
            {
              users.map((player, index2) => {
                const thumbnailUrl = player ? getTournamentNodeUnitThumbnailUrl(player!) : ''
                const displayName = player ? getTournamentUnitDisplayName(player!) : ''

                return (
                  <Box key={`${index}-${index2}`} className={styles.roundMatch}>
                    {player &&
                    <Box className={styles.inner} key={index2} display="flex" alignItems="center">
                      {isTournamentNodeFragment(player) ? (
                        <Avatar src={thumbnailUrl} className={styles.avatar} />
                      ) : (
                        <SquareAvatar src={thumbnailUrl} className={styles.avatar} />
                      )}
                      <Typography variant='subtitle2' className={styles.userName}>{displayName}</Typography>
                    </Box>
                    }
                  </Box>
                )
              })
            }
          </Link>
        </ButtonBase>
      </Box>
    )
  })

  return (
    <>
      {nodeList}
    </>
  )
}
