import React from 'react'

import {
  Drawer as MuiDrawer,
} from '@material-ui/core/'

import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles'

const useStyles = makeStyles<Theme, {width: number}>((theme) =>
  createStyles({
    drawer: ({width}) => ({
      [theme.breakpoints.up('lg')]: {
        width,
        flexShrink: 0,
      },
    }),
    hiddenXs: {
      display: 'none',
      [theme.breakpoints.up('lg')]: {
        display: 'block',
      },
    },
    hiddenLg: {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    drawerPaper: ({width}) => ({
      // backgroundColor: theme.palette.contentBackground,
      width,
    }),
  }),
)

type DrawerProps = {
  width?: number
  isMobileOpen?: boolean
  onMobileClose?: () => void
}

export const Drawer: React.FC<DrawerProps> = ({children, isMobileOpen, onMobileClose, width=250}) => {

  const styles = useStyles({width})
  const theme = useTheme()

  return (
    <nav className={styles.drawer} aria-label="mailbox folders">
      {/** モバイル用 */}
      <MuiDrawer
        className={styles.hiddenLg}
        variant="temporary"
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={isMobileOpen}
        onClose={onMobileClose}
        classes={{
          paper: styles.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {children}
      </MuiDrawer>
      {/** PC用 */}
      <MuiDrawer
        className={styles.hiddenXs}
        classes={{
          paper: styles.drawerPaper,
        }}
        variant="permanent"
        open
      >
        {children}
      </MuiDrawer>
    </nav>
  )

}
