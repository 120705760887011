import {User} from '@sentry/types'
import {
  CustomBracket,
  CustomTournamentUnitFragment,
  SingleEliminationNodeFragment,
  SingleEliminationUnitFragment,
  TournamentUnitFragment,
  isCustomTournamentBracket,
  isCustomTournamentPlayerFragment,
  isCustomTournamentTeamFragment,
  isOpenCustomTournamentBracket,
  isOpenCustomTournamentTeamFragment,
  isOpenTournamentNodeFragment,
  isOpenTournamentTeamFragment,
  isTeamCustomTournamentBracket,
  isTournamentNodeFragment,
  isTournamentPlayerFragment,
  isTournamentTeamFragment,
  isTournamentTeamNodeFragment,
} from '@/types/tournament'
import {TournamentNodeConfirmRequestUnitInput} from '@/graphql/client'

export const getTournamentNodeUnits = (fragment: any): SingleEliminationUnitFragment[] => {
  if (isTournamentNodeFragment(fragment)) {
    return fragment.players
  } else if (isTournamentTeamNodeFragment(fragment)) {
    return fragment.teams
  } else if (isOpenTournamentNodeFragment(fragment)) {
    return fragment.teams
  } else {
    return []
  }
}

export const getCustomTournamentUnits = (fragment: CustomBracket): CustomTournamentUnitFragment[] => {
  if (isCustomTournamentBracket(fragment)) {
    return fragment.players
  } else if (isTeamCustomTournamentBracket(fragment)) {
    return fragment.teams
  } else if (isOpenCustomTournamentBracket(fragment)) {
    return fragment.teams
  } else {
    return []
  }
}

export const getTournamentNodeUnitUsers = (fragment: SingleEliminationNodeFragment): User[] => {
  let users: User[] = []

  if (isTournamentNodeFragment(fragment)) {
    fragment.players.forEach(it => it.user && users.push(it.user))
  } else if (isTournamentTeamNodeFragment(fragment)) {
    fragment.teams.forEach(it => users = users.concat(it.team.users))
    fragment.teams.forEach(it => users = users.concat(it.team.managers))
  } else if (isOpenTournamentNodeFragment(fragment)) {
    fragment.teams.forEach(it => users = users.concat(it.team.users.map(it => it.user)))
  }

  return users
}

export const getTournamentNodeUnitThumbnailUrl = (fragment: TournamentUnitFragment): string => {
  if (isTournamentPlayerFragment(fragment) || isCustomTournamentPlayerFragment(fragment)) {
    return fragment.user?.profile.thumbnailUrl ?? ''
  } else if (isTournamentTeamFragment(fragment) || isCustomTournamentTeamFragment(fragment)) {
    return fragment.team.clan.logoUrl
  } else if (isOpenTournamentTeamFragment(fragment) || isOpenCustomTournamentTeamFragment(fragment)) {
    const type = fragment.team.users[0].tournament.openTournamentType
    const name = fragment.team.teamName
    if (!type) return ''
    return getOpenTournamentThumbnailUrl(type, name)
  }

  return ''
}

export const getOpenTournamentThumbnailUrl = (type: string, name: string): string => {
  return `/images/${type}/team-avatars/${name}.png`
}

export const getTournamentUnitDisplayName = (fragment: TournamentUnitFragment): string => {
  if (isTournamentPlayerFragment(fragment) || isCustomTournamentPlayerFragment(fragment)) {
    return fragment.user?.profile.displayName ?? ''
  } else if (isTournamentTeamFragment(fragment) || isCustomTournamentTeamFragment(fragment)) {
    return fragment.team.clan.name
  } else if (isOpenTournamentTeamFragment(fragment) || isOpenCustomTournamentTeamFragment(fragment)) {
    return fragment.team.teamName
  }

  return ''
}

export const getTournamentUnitUnitId = (fragment: TournamentUnitFragment): string => {
  if (isTournamentPlayerFragment(fragment) || isCustomTournamentPlayerFragment(fragment)) {
    return fragment.user?.id ?? ''
  } else if (isTournamentTeamFragment(fragment) || isCustomTournamentTeamFragment(fragment)) {
    return fragment.team.id
  } else if (isOpenTournamentTeamFragment(fragment) || isOpenCustomTournamentTeamFragment(fragment)) {
    return fragment.team.teamName
  }

  return ''
}

export const getTournamentUnitConfirmRequest = (fragment: TournamentUnitFragment, score: number): TournamentNodeConfirmRequestUnitInput => {
  if (isOpenTournamentTeamFragment(fragment) || isOpenCustomTournamentTeamFragment(fragment)) {
    return {
      teamName: getTournamentUnitUnitId(fragment),
      score,
    }
  } else {
    return {
      unitId: getTournamentUnitUnitId(fragment),
      score,
    }
  }
}
