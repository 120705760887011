import {IconButton, Menu, MenuItem} from '@material-ui/core'
import React from 'react'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

const useManageMemberOwnerMenuStyle = makeStyles(() =>
  createStyles({
    iconBtn: {
      padding: 0,
    },
    delete: {
      color: '#F44336',
    },
  })
)

type ManageMemberOwnerMenuProps = {
  isManager: boolean
  onTransferOwner: () => void
  onMakeManager: () => void
  onMakeMember: () => void
  onRemoveMember: () => void
}

export const ManageMemberOwnerMenu: React.FC<ManageMemberOwnerMenuProps> = ({
  isManager,
  onTransferOwner,
  onMakeManager,
  onMakeMember,
  onRemoveMember,
}) => {
  const styles = useManageMemberOwnerMenuStyle()
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchor(event.currentTarget)
  const handleClose = () => setAnchor(null)
  const runActionAndClose = (fn: () => void) => {
    fn()
    handleClose()
  }

  const handleTranferOwner = () => runActionAndClose(onTransferOwner)
  const handleMakeManager = () => runActionAndClose(onMakeManager)
  const handleMakeMember = () => runActionAndClose(onMakeMember)
  const handleRemoveMember = () => runActionAndClose(onRemoveMember)

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={styles.iconBtn}
      >
        <MoreHorizIcon />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleTranferOwner}>管理者に変更</MenuItem>
        { !isManager && <MenuItem onClick={handleMakeManager}>マネージャーに変更</MenuItem> }
        { isManager && <MenuItem onClick={handleMakeMember}>メンバーに変更</MenuItem> }
        <MenuItem onClick={handleRemoveMember} className={styles.delete}>クランから除名</MenuItem>
      </Menu>
    </>
  )
}
