import {IconButton, Menu, MenuItem} from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import React from 'react'

export type SettingOption = {
  title: string
  style?: string
  type?: string
}

type SettingProps = {
  options: string
  clickAction?: (args0: React.MouseEvent<HTMLElement>) => void
  iconStyle?:string
  isOpen: boolean
  handleClick: (args0: React.MouseEvent<HTMLElement>) => void
  handleClose: () => void
  anchorEl: null | HTMLElement
}

const ITEM_HEIGHT = 40

export const Setting: React.FC<SettingProps> = (props) => {
  const {options, clickAction, iconStyle, isOpen, handleClick, handleClose, anchorEl} = props
  const settingOption: SettingOption[] = JSON.parse(options)

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={iconStyle}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            minHeight: ITEM_HEIGHT,
            width: '120px',
          },
        }}
      >
        {settingOption.map((option, index) => {
          return (
            <MenuItem key={index} onClick={clickAction} className={option.style} data-type={option.type}>
              {option.title}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}
