import React from 'react'
import {Box, Card, Typography, createStyles, makeStyles} from '@material-ui/core'
import {RankingIconFirst, RankingIconSecond, RankingIconThird} from '@/components/atoms/ranking-icon'
import {
  Tournament,
  TournamentAwardType,
  TournamentClanAwardType,
  TournamentUnitType,
  useTournamentClanRankingQuery,
  useTournamentOpenRankingQuery,
  useTournamentUserRankingQuery,
} from '@/graphql/client'
import {getOpenTournamentThumbnailUrl, getTournamentAwardTypeLabel, getTournamentClanAwardTypeLabel} from '@/utils'
import {useRouter} from 'next/router'

const tournamentResultLayout = makeStyles(() =>
  createStyles({
    resultHeader: {
      fontSize: 22,
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'center',
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    },
    resultBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    resultWrap: {
      width: 300,
      display: 'flex',
      alignItems: 'center',
      marginBottom: 16,
    },
    awardDescription: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '200px',
    },
    rank: {
      fontSize: 12,
      color: 'rgba(255, 255, 255, 0.6)',
    },
    rankingIconWrap: {
      width: 75,
      height: 75,
      marginRight: 16,
    },
    winnersName: {
      fontWeight: 'bold',
    },
  })
)


type TournamentResultProps = {
  tournamentHeader: Pick<Tournament, 'id' | 'unitType' | 'openTournamentType'>
}

type AwardType = TournamentAwardType|TournamentClanAwardType

type RankingInfo = {
  iconUrl: string
  displayName: string
  awardType: AwardType
  model: 'user' | 'clan' | 'open'
  modelId: string
}

export const TournamentResult: React.FC<TournamentResultProps> = (props) => {
  const styles = tournamentResultLayout()
  const router = useRouter()

  const tournamentId = props.tournamentHeader.id
  const unitType = props.tournamentHeader.unitType
  const isOpen = !!props.tournamentHeader.openTournamentType

  const {data: userRankingData} = useTournamentUserRankingQuery({
    variables: {
      tournamentId,
      first: 12,
    },
    skip: unitType !== TournamentUnitType.Single || isOpen,
  })

  const {data: clanRankingData} = useTournamentClanRankingQuery({
    variables: {
      tournamentId,
      first: 12,
    },
    skip: unitType === TournamentUnitType.Single || isOpen,
  })

  const {data: openRankingData} = useTournamentOpenRankingQuery({
    variables: {
      tournamentId,
      first: 12,
    },
    skip: !isOpen,
  })

  if (!userRankingData && !clanRankingData && !openRankingData) {
    return <></>
  }

  const ranking: RankingInfo[] = []

  if (unitType === TournamentUnitType.Single) {
    userRankingData?.tournamentUserRanking.nodes?.forEach((it) => {
      if (it) {
        ranking.push({
          iconUrl: it.user.profile.thumbnailUrl,
          displayName: it.user.profile.displayName,
          awardType: it.awardType,
          model: 'user',
          modelId: it.user.id,
        })
      }
    })
  } else {
    if (isOpen) {
      openRankingData?.tournamentOpenRanking.nodes?.forEach((it) => {
        if (it) {
          const type = it.tournament.openTournamentType as string
          ranking.push({
            iconUrl: getOpenTournamentThumbnailUrl(type, it.teamName),
            displayName: it.teamName,
            awardType: it.awardType,
            model: 'open',
            modelId: it.id,
          })
        }
      })
    } else {
      clanRankingData?.tournamentClanRanking.nodes?.forEach((it) => {
        if (it) {
          ranking.push({
            iconUrl: it.clan.logoUrl,
            displayName: it.clan.name,
            awardType: it.awardType,
            model: 'clan',
            modelId: it.clan.id,
          })
        }
      })
    }
  }

  const rankIcon = (awardType: AwardType, index: number, iconUrl: string) => {
    switch (awardType) {
      case TournamentAwardType.First:
      case TournamentClanAwardType.First:
        return <RankingIconFirst index={index} iconUrl={iconUrl}/>
      case TournamentAwardType.Second:
      case TournamentClanAwardType.Second:
        return <RankingIconSecond index={index} iconUrl={iconUrl}/>
      case TournamentAwardType.Third:
      case TournamentClanAwardType.Third:
        return <RankingIconThird index={index} iconUrl={iconUrl}/>
      default:
        return <></>
    }
  }

  const getAwardTypeLabel = (awardType: AwardType): string => {
    switch (awardType) {
      case TournamentAwardType.First:
      case TournamentAwardType.Second:
      case TournamentAwardType.Third:
        return getTournamentAwardTypeLabel(awardType)
      case TournamentClanAwardType.First:
      case TournamentClanAwardType.Second:
      case TournamentClanAwardType.Third:
        return getTournamentClanAwardTypeLabel(awardType)
      default:
        return ''
    }
  }

  const handleClick = async (info: RankingInfo) => {
    if (info.model === 'user') {
      await router.push(`/users/${info.modelId}/profile`)
    } else if (info.model === 'clan') {
      await router.push(`/clan/${info.modelId}/detail`)
    }
  }

  return (
    <Box mt={8}>
      <Card>
        <Box className={styles.resultHeader} py={4}>
            大会結果
        </Box>
        <Box className={styles.resultBox} py={8}>
          {ranking.map((info, index: number) => (
            <Box key={index} className={styles.resultWrap} onClick={() => handleClick(info)}>
              <div className={styles.rankingIconWrap}>
                {rankIcon(info.awardType, index, info.iconUrl)}
              </div>
              <Box className={styles.awardDescription}>
                <Typography className={styles.rank}>{getAwardTypeLabel(info.awardType)}</Typography>
                <Typography className={styles.winnersName}>{info.displayName}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Card>
    </Box>
  )
}
