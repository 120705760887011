import {createStyles, makeStyles} from '@material-ui/core'
import Carousel from 'react-material-ui-carousel'
import {Link} from '@/components'

export type IndexCarouselItemType = {
  image_url: string
  link_url?: string
}

const carouselStyles = makeStyles(() =>
  createStyles({
    indicator: {
      color: '#1E1E1E',
      '&:hover': {
        color: 'rgba(255, 255, 255, 0.38)',
      },
    },
    navButtons: {
      borderWidth: '1px',
      borderColor: '#FFFFFF',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
      },
    },
  })
)

const carouselItemStyles = makeStyles(() =>
  createStyles({
    carouselImg: {
      objectFit: 'cover',
      aspectRatio: '2',
    },
  })
)

const IndexCarouselItem = (props: IndexCarouselItemType) => {
  const styles = carouselItemStyles()
  if (props.link_url) {
    return (
      <Link target="_blank" href={props.link_url} as={props.link_url}>
        <img
          width="100%"
          className={styles.carouselImg}
          src={props.image_url}
        />
      </Link>
    )
  } else {
    return (
      <img
        width="100%"
        className={styles.carouselImg}
        src={props.image_url}
      />
    )
  }
}

export const IndexCarousel = ({carouselItems=[]}:
{ carouselItems: IndexCarouselItemType[] }) => {
  if (carouselItems.length === 0) return null

  const styles = carouselStyles()
  return (
    <Carousel
      interval={5000}
      indicatorIconButtonProps={{
        className: styles.indicator,
      }}
      activeIndicatorIconButtonProps={{
        style: {color: '#55C341'},
      }}
      navButtonsAlwaysVisible
      navButtonsProps={{
        className: styles.navButtons,
      }}
    >
      {
        carouselItems.map((item, i) => (
          <IndexCarouselItem
            key={i}
            image_url={item.image_url}
            link_url={item.link_url}
          />
        ))
      }
    </Carousel>
  )
}
