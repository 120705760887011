import {t} from '@/validation-messages'
import {ApolloError} from '@apollo/client'
import {useSnackbar as useNotistackSnackbar} from 'notistack'

const DEFAULT_AUTO_HIDE_DURATION = 2_000

const translate = (code: string) => {
  const translated = t(code)
  return translated ?? code
}

export function useSnackbar() {
  const {enqueueSnackbar} = useNotistackSnackbar()

  const notifySuccess = (message: string) => {
    enqueueSnackbar(message, {
      variant: 'success',
      autoHideDuration: DEFAULT_AUTO_HIDE_DURATION,
      style: {whiteSpace: 'pre-line'},
    })
  }

  const notifyError = (message: string, exception?: any) => {
    const code = (exception as ApolloError)?.graphQLErrors?.[0]?.extensions?.code
    const msg = code ? `${message}\n[${translate(code)}]` : message

    enqueueSnackbar(msg, {
      variant: 'error',
      autoHideDuration: DEFAULT_AUTO_HIDE_DURATION,
      style: {whiteSpace: 'pre-line'},
    })
  }

  const snackbar = {
    success: notifySuccess,
    failed: notifyError,
  }

  return {snackbar}
}
