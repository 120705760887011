import {Box, Modal, makeStyles} from '@material-ui/core'
import React, {useState} from 'react'
import Calendar, {CalendarTileProperties, ViewCallbackProperties} from 'react-calendar'
import {DateCount, TournamentCountsByTermQuery} from '@/graphql/client'
import {useIsLg} from '@/hooks'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import {CalendarModalContents} from '../../molecules'
import {format, isSameDay} from 'date-fns'
import ja from 'date-fns/locale/ja'
import {LoadingPart} from '../../atoms'

const useCalendarContentsStyle = makeStyles(theme => ({
  calendar: {
    margin: '0 auto',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      margin: 'initial',
      marginTop: '-45px',
    },
  },
  calendarButton: {
    display: 'block',
    margin: '0 auto',
    paddingTop: '7px',
    width: '100%',
    height: '33px',
    fontWeight: 'bold',
    fontSize: '13px',
    color: theme.palette.primary.main,
    borderRadius: '4px',
    '&:hover': {
      textDecoration: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '20px auto 0',
      width: '100px',
      background: 'rgba(85, 195, 65, 0.08)',
    },
  },
  attention: {
    marginRight: '3px',
    fontSize: '18px',
  },
  modalWap: {
    borderRadius: '4px',
    position: 'absolute',
    width: 'calc(100vw - 20px)',
    minHeight: '400px',
    maxHeight: '501px',
    top: '50%',
    left: '50%',
    background: '#232323',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    [theme.breakpoints.up('lg')]: {
      width: '700px',
    },
  },
  modalHeader: {
    fontSize: '18px',
    lineHeight: '27px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.005em',
    padding: '18px 16px',
    margin: 0,
    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    justifyContent: 'space-between',
  },
  modalCloseBtn: {
    border: '0 none',
    margin: '0',
    padding: '0',
    background: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
  modalBody: {
    padding: '24px 16px',
    maxHeight: '437px',
    overflowY: 'auto',
    [theme.breakpoints.up('lg')]: {
    },
  },
  cardList: {
    width: '276px',
    height: '250px',
  },
  icon: {
    marginTop: '15px',
    width: '6px',
    height: '6px',
  },
}))

type CalendarContentsProps = {
  data: TournamentCountsByTermQuery | undefined
  gameId: string
  loading: boolean
  fetchMore: any
  startDate: Date
  onStartDateChange: (date: Date) => void
}

export const CalendarContents: React.FC<CalendarContentsProps> = ({data, gameId, ...props}) => {
  const styles = useCalendarContentsStyle()
  const {loading, startDate} = props
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const lg = useIsLg()

  if (loading) {
    return <LoadingPart />
  }

  const counts = (data?.tournamentCountsByTerm || []) as DateCount[]

  const handleOpen = (date: Date) => {
    setSelectedDate(date)
    setShowModal(true)
  }
  const handleClose = () => {
    setShowModal(false)
  }

  const tileContent = (properties: CalendarTileProperties) => {
    const date = new Date(properties.date)
    const count = counts.find(it => isSameDay(new Date(it.date), date))

    if (!count) return <></>

    return (
      <Box className={styles.calendarButton}>
        {lg ?
          (<div><span className={styles.attention}>{count.count}</span>件の大会</div>):
          (<FiberManualRecordIcon color="primary" className={styles.icon} />)
        }
      </Box>
    )
  }

  return (
    <>
      <Calendar
        locale="ja-JP"
        tileContent={tileContent}
        className={styles.calendar}
        minDetail="year"
        nextLabel={<img src="/images/next.svg" alt="next"/>}
        prevLabel={<img src="/images/prev.svg" alt="prev"/>}
        onClickDay={(date: Date) => handleOpen(date)}
        onActiveStartDateChange={({activeStartDate}: ViewCallbackProperties) => props.onStartDateChange(activeStartDate)}
        showNeighboringMonth={false}
        activeStartDate={startDate}
      />
      <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={styles.modalWap}>
          <h2 className={styles.modalHeader}>
            <span>{format(selectedDate, 'MM月dd日', {locale: ja})}に開催予定の大会</span>
            <button type={'button'} className={styles.modalCloseBtn} onClick={handleClose}><img src='/images/close.svg' /></button>
          </h2>
          <div className={styles.modalBody}>
            <CalendarModalContents date={selectedDate} gameId={gameId} />
          </div>
        </div>
      </Modal>
    </>
  )
}
