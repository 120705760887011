import React from 'react'
import {TournamentAnnouncementFragment, TournamentDetailQuery} from '@/graphql/client'
import {Box, CardContent, CardHeader, Typography, makeStyles} from '@material-ui/core'
import {AnnouncementAction, Avatar, Card, Link} from '@/components'
import {createStyles} from '@material-ui/core/'
import {composeClassNames, truncateFullWidth} from '@/utils'
import {format, parseISO} from 'date-fns'
import {Linkify} from '@/components/atoms/linkify'

const useAnnouncementStyle = makeStyles(({spacing, palette}) => {
  return createStyles({
    container: {
      marginTop: '32px',
      maxWidth: '800px',
    },
    header: {
      paddingBottom: spacing(3),
    },
    organizerIndicator: {
      borderLeft: `${spacing(1)}px ${palette.primary.main} solid`,
    },
    authorUsername: {
      fontWeight: 'bold',
      letterSpacing: '0.005rem',
    },
    authoredDate: {
      fontSize: '12px',
      letterSpacing: '0.005rem',
    },
    content: {
      paddingTop: spacing(3),
    },
    body: {
      fontSize: '14px',
      minHeight: '1rem',
    },
    deletedBody: {
      color: palette.text.disabled,
      fontStyle: 'oblique',
    },
  })
})

type AnnouncementType = {
  announcement: TournamentAnnouncementFragment
  tournament: TournamentDetailQuery['tournament']
  currentUserId: string
  onDelete: (id: string) => void
}

export const Announcement = ({announcement, tournament, currentUserId, onDelete}: AnnouncementType) => {
  const styles = useAnnouncementStyle()
  const lines = announcement.body.split(/\n/g)
  const byOrganizer = announcement.user.id === tournament.organizer.id

  return (
    <Box mx='auto' key={announcement.id} className={styles.container}>
      <Card>
        <CardHeader
          avatar={
            <Link href={`/users/${announcement.user.id}/profile`}>
              <Avatar alt={announcement.user.profile.displayName} src={announcement.user.profile.thumbnailUrl}/>
            </Link>
          }
          title={
            <Link href={`/users/${announcement.user.id}/profile`}>
              <Typography className={styles.authorUsername} color={byOrganizer ? 'primary' : 'textPrimary'}>
                {truncateFullWidth(announcement.user.profile.displayName, 23)}
              </Typography>
            </Link>
          }
          subheader={format(parseISO(announcement.createdAt), 'yyyy年M月d日 HH:mm')}
          subheaderTypographyProps={{color: 'textSecondary', className: styles.authoredDate}}
          className={composeClassNames(styles.header, byOrganizer && styles.organizerIndicator)}
          action={
            <AnnouncementAction
              userId={currentUserId}
              tournamentId={tournament.id}
              onDelete={!announcement.isDeleted && announcement.user.id === currentUserId && (() => { onDelete(announcement.id) })}
            />
          }
        />
        <CardContent className={styles.content}>
          {lines.map((line, index) =>
            <Typography
              className={composeClassNames(styles.body, announcement.isDeleted && styles.deletedBody)}
              key={index}
            >
              <Linkify>{line}</Linkify>
            </Typography>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}
