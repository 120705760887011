import React from 'react'
import {Box, Card, CardActionArea, Radio, makeStyles} from '@material-ui/core'
import {Typography} from '@material-ui/core/'
import {ClanFragment} from '@/graphql/client'
import {truncateFullWidth} from '@/utils'

const useSelectableClanListItemStyles = makeStyles(({palette}) => ({
  container: {
    display: 'flex',
    padding: '16px 16px 16px 0px',
    height: '80px',
    borderRadius: '4px',
    border: '1px solid rgba(255, 255, 255, 0.12)',
  },
  clanLogo: {
    width: '48px',
    height: '48px',
    objectFit: 'cover',
    borderRadius: '4px',
  },
  nameContainer: {
    marginLeft: '12px',
  },
  clanName: {
    fontSize: '15px',
    textOverflow: 'ellipsis',
  },
  level: {
    fontSize: '14px',
  },
  member: {
    color: palette.text.secondary,
    fontSize: '10px',
  },
  number: {
    fontSize: '12px',
  },
}))

type SelectableClanListItemProps = {
  clan: ClanFragment
  checked: boolean
  onClick: (clan: ClanFragment) => void
}

export const SelectableClanListItem: React.FC<SelectableClanListItemProps> = (props) => {
  const styles = useSelectableClanListItemStyles()
  const {clan, checked, onClick} = props

  return (
    <Card onClick={() => onClick(clan)}>
      <CardActionArea>
        <Box className={styles.container}>
          <Radio checked={checked}/>
          <Box>
            <img src={clan.logoUrl} alt="clan logo" className={styles.clanLogo}/>
          </Box>
          <Box flexGrow={1} className={styles.nameContainer}>
            <Typography variant="subtitle2" className={styles.clanName}>{truncateFullWidth(clan.name, 10)}</Typography>
          </Box>
          <Box alignSelf="flex-end">
            <Typography variant="caption" className={styles.member}>
              <span className={styles.number}>{clan.memberNum}</span>人が所属
            </Typography>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  )
}
