import React from 'react'
import Router from 'next/router'
import NProgress from 'nprogress'
import Head from 'next/head'

NProgress.configure({easing: 'ease', speed: 300, showSpinner: false})

Router.events.on('routeChangeStart', () => {
  NProgress.start()
})

Router.events.on('routeChangeComplete', () => {
  NProgress.done()
})

Router.events.on('routeChangeError', () => {
  NProgress.done()
})

export const LoadingIndicator: React.FC = () => {

  return (
    <div>
      <Head>
        <link
          rel="stylesheet"
          type="text/css"
          href="/styles/nprogress.css"
        />
      </Head>
    </div>
  )

}
