import {serverConfig} from '@/env'
import React, {useState} from 'react'
import {
  Box,
  Card,
  CircularProgress,
  InputBase, Modal, Typography, makeStyles,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import {
  composeClassNames, debounce,
} from '@/utils'
import {
  Button, LoadingError,
  UserListItemGrid,
} from '@/components'
import {
  Profile,
  UserEntryFragment,
  useMeQuery, useTournamentDetailQuery,
} from '@/graphql/client'
import {useSwitchUser} from '@/hooks'

const useSwitchUserStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    width: '80vw',
  },
  flexRawContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  searchTextField: {
    flex: 1,
  },
  searchIcon: {
    margin: theme.spacing(2),
  },
  userList: {
    height:'80vh',
  },
  userChangeBtn: {
    margin: '0 20px 20px 0',
  },
}))

type SwitchTournamentUsersProps = {
  tournamentId: string
} & React.ComponentProps<typeof Box>

const SwitchTournamentUsers: React.FC<SwitchTournamentUsersProps> = ({className, tournamentId}) => {

  const styles = useSwitchUserStyles()
  const {switchUser, resetUser} = useSwitchUser()

  // states

  const [keyword, setKeyword] = useState<string>('')

  // queries

  const {data, error: meError, loading: meLoading} = useMeQuery()
  const me: Profile | undefined = data?.me?.profile

  const {data : debugData, error: tournamentError, loading: tournamentLoading} = useTournamentDetailQuery({
    variables: {
      id: tournamentId,
    },
  })

  // handlers

  const debounceSetKeyword = debounce((newValue) => {
    setKeyword(newValue)
  }, 500)

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debounceSetKeyword(event.target.value)
  }

  const filteredUsers = (): UserEntryFragment[] => {
    if (keyword === '') {
      return debugData?.tournament.entries || []
    }
    return debugData?.tournament.entries.filter((it: UserEntryFragment) => it.profile.displayName.toLowerCase().includes(keyword.toLowerCase())) || []
  }

  if (meError || tournamentError) {
    return <LoadingError/>
  }

  return (
    <Box className={composeClassNames(styles.root, className)}>
      <Typography>現在のユーザー: { me ? `${me.displayName}（ID: ${me.id}）` : '未ログイン' }</Typography>
      <hr/>
      <Box className={styles.flexRawContainer}>
        <SearchIcon className={styles.searchIcon}/>
        <InputBase
          className={styles.searchTextField}
          placeholder="ユーザー名を入力"
          onChange={handleOnChange}
          autoFocus
        />
      </Box>
      <Box className={styles.flexRawContainer}>
        <Button style={{margin: 8}} outlined onClick={() => switchUser(debugData!.tournament.organizer.id)}>
          主催者に切り替え
        </Button>
        <Button style={{margin: 8}} outlined onClick={resetUser}>
          元のユーザに戻る
        </Button>
      </Box>
      {(meLoading || tournamentLoading) ? (
        <Box>
          <CircularProgress />
        </Box>
      ) : (
        <UserListItemGrid
          users={filteredUsers()}
          gameId={debugData!.tournament.game.id}
          className={styles.userList}
          handleClick={(it) => switchUser(it.id)}
        />
      )}
    </Box>
  )
}

export const DebugSwitchTournamentUsersButton: React.FC<SwitchTournamentUsersProps> = ({...props}) => {
  const styles = useSwitchUserStyles()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  if (serverConfig.isPrd) {
    return <></>
  }
  return (
    <>
      <Button className={styles.userChangeBtn} outlined onClick={() => setIsOpen(true)} >ユーザー切り替え</Button>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card>
          <SwitchTournamentUsers {...props}/>
        </Card>
      </Modal>
    </>
  )
}
