import getConfig from 'next/config'

const {serverRuntimeConfig} = getConfig()

export type MicroCmsConfig = {
  apiKey: string
  endpoint: string
}

export const microcmsConfig: MicroCmsConfig = {
  apiKey: serverRuntimeConfig.MICROCMS_API_KEY,
  endpoint: serverRuntimeConfig.MICROCMS_ENDPOINT,
}
