import {useIsLg} from '@/hooks'
import {Card, CardContent, Theme, Typography, createStyles, makeStyles} from '@material-ui/core'
import {FC} from 'react'

type UserTicketProps = {
  monthlyTicketCount: number
}
const useAvailableTicketsProps = makeStyles<Theme>(() =>
  createStyles({
    header: {
      fontSize: '18px',
      lineHeight: '27px',
      marginBottom: '16px',
    },
    contents: {
      display: 'flex',
      alignItems: 'center',
      '&:last-child': {
        paddingBottom: '16px',
      },
      '@media (max-width: 560px)': {
        paddingLeft: '0',
        paddingRight: '16px',
      },
    },
    spacer: {
      flex: '1 1 0%',
      placeSelf: 'stretch',
    },
    expiredDate: {
      paddingLeft: '32px',
      paddingRight: '32px',
    },
  })
)


export const UserTicket: FC<UserTicketProps> = ({monthlyTicketCount}) => {
  const styles = useAvailableTicketsProps()
  const isShowExpiredDate = useIsLg()
  const today = new Date()
  const expiredDate = new Date(today.getFullYear(), today.getMonth()+1, 0)
  const formattedExpiredDate = `${expiredDate.getFullYear()}/${expiredDate.getMonth()+1}/${expiredDate.getDate()}`
  return (
    <>
      <Typography className={styles.header} variant="h4">
        大会チケット
      </Typography>
      <Card>
        <CardContent className={styles.contents}>
          <img src="/images/tournament_ticket.svg" />
          <Typography>開催チケット</Typography>
          <div className={styles.spacer}/>
          <Typography >{monthlyTicketCount} 枚</Typography>
          {isShowExpiredDate && <Typography className={styles.expiredDate} variant='body1' color="textSecondary">有効期限: {formattedExpiredDate}</Typography>}
        </CardContent>
      </Card>
    </>
  )
}
