import React from 'react'
import {UserFragment} from '@/graphql/client'
import {GridList, GridListTile, useTheme} from '@material-ui/core'
import {SelectableUserListItem} from '@/components/organisms/tournament-team/selectable-user-list-item'

export type SelectableUserListProps = {
  users: UserFragment[]
  initialSelectedIds?: string[]
  gameId: string
  handleChange: (userIds: string[]) => void
  maxSelectNum: number
  outlined?: boolean
}

export const SelectableUserList: React.FC<SelectableUserListProps> = ({initialSelectedIds=[], outlined=false, ...props}) => {
  const {spacing} = useTheme()
  const {users, gameId, handleChange, maxSelectNum} = props

  const [selectedUserIds, setSelectedUserIds] = React.useState<string[]>(initialSelectedIds)

  const handleSelect = (userId: string) => {
    if (selectedUserIds.includes(userId)) {
      setSelectedUserIds(selectedUserIds.filter(it => it !== userId))
    } else {
      if (maxSelectNum <= selectedUserIds.length) {
        return
      }
      setSelectedUserIds([...selectedUserIds, userId])
    }
  }

  React.useEffect(() => {
    handleChange(selectedUserIds)
  }, [selectedUserIds])

  return (
    <GridList
      cellHeight={88}
      cols={1}
      spacing={spacing(2)}
      style={{marginTop: 0}}
      {...props}
    >
      {users.map((user, index) => {
        return (
          <GridListTile key={index}>
            <SelectableUserListItem
              user={user}
              gameId={gameId}
              selected={selectedUserIds.includes(user.id)}
              handleSelect={() => handleSelect(user.id)}
            />
          </GridListTile>
        )
      })}
    </GridList>
  )
}
