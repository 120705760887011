import React, {
  useState,
} from 'react'

import {
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles'

import {
  PaddingContainer,
  PaddingContainerProps,
} from '../../atoms'

import {
  Drawer,
  DrawerContent,
  Footer,
  IndexCarousel,
  IndexCarouselItemType,
  MobileAppBar,
} from '../../organisms'

import {
  composeClassNames,
} from '@/utils'

import {
  useIsLg,
} from '@/hooks'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    toolbar: {
      ...theme.mixins.toolbar,
      height: 70,
    },
    content: {
      flexGrow: 1,
    },
    contentWidthController: {
      maxWidth: '1200px',
    },
    hiddenLg: {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
  }),
)

type DrawerLayoutProps = {
  drawerWidth?: number
  children: PaddingContainerProps['children']
  carouselItems?: IndexCarouselItemType[]
  tournamentId?: string
}

export const DrawerLayout: React.FC<DrawerLayoutProps> = ({children, drawerWidth=250, carouselItems, tournamentId}) => {

  const classes = useStyles()
  const [isMobileOpen, setIsMobileOpen] = useState(false)

  const isLg = useIsLg()

  const handleToggleMobileDrawer = () => {
    setIsMobileOpen(isMobileOpen => !isMobileOpen)
  }

  const handleCloseMobileDrawer = () => {
    setIsMobileOpen(false)
  }
  return (
    <div className={classes.root}>
      <MobileAppBar
        drawerWidth={drawerWidth}
        onToggleDrawer={handleToggleMobileDrawer}
      />
      <Drawer
        width={drawerWidth}
        isMobileOpen={isMobileOpen}
        onMobileClose={handleCloseMobileDrawer}
      >
        <DrawerContent tournamentId={ tournamentId }/>
      </Drawer>
      <main className={classes.content}>
        <div className={composeClassNames(classes.toolbar, classes.hiddenLg)} />
        {carouselItems && <IndexCarousel carouselItems={ carouselItems } />}
        <PaddingContainer
          padding={isLg ? 54 : 16}
          className={classes.contentWidthController}
        >
          <PaddingContainer
            padding={isLg ? 0 : 0}
            style={{paddingTop: carouselItems ? 16 : 40, paddingBottom: 160}}
          >
            {children}
          </PaddingContainer>
        </PaddingContainer>
        <Footer />
      </main>
    </div>
  )

}
