import {Avatar, JoinChatRequestList, SquareAvatar} from '@/components'
import {
  SingleEliminationTournamentBracket,
  TournamentNodeFragment,
  useTournamentBracketForHeaderQuery,
} from '@/graphql/client'
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Link,
  Modal,
  Typography,
  createStyles, makeStyles,
} from '@material-ui/core'
import React, {useState} from 'react'
import {LoadingPart} from '../../atoms/loading-part'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import {SingleEliminationUnitFragment, isTournamentPlayerFragment} from '@/types/tournament'
import {getTournamentNodeUnitThumbnailUrl, getTournamentNodeUnits, getTournamentUnitDisplayName} from '@/utils'

const useLayout = makeStyles(({breakpoints}) =>
  createStyles({
    title: {
      marginBottom: '16px',
      [breakpoints.up('lg')]: {
        marginBottom: 'initial',
      },
    },
    dashboardHead: {
      background: '#1E1E1E',
      border: '1px solid #000000',
      boxSizing: 'border-box',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '4px',
      padding: '16px 24px 32px',
      margin: '0 -16px 20px -16px',
      [breakpoints.up('lg')]: {
        margin: 0,
        marginBottom: '20px',
      },
    },
    buttonsWrap: {
      display: 'flex',
      marginTop: '24px',
    },
    acceptBtns: {
      border: '1px solid rgba(255, 255, 255, 0.12)',
      color: 'rgba(255, 255, 255, 0.38)',
      width: 'calc(50% - 5px)',
      boxSizing: 'border-box',
      borderRadius: '4px',
      padding: '18px 16px',
      textAlign: 'left',
      display: 'flex',
      justifyContent: 'space-between',
      [breakpoints.up('lg')]: {
        width: '192px',
        marginRight: '16px',
      },
    },
    reloadBtn: {
      border: '1px solid #55C341',
      color: '#55C341',
      width: 'calc(50% - 5px)',
      boxSizing: 'border-box',
      borderRadius: '4px',
      padding: '18px 16px',
      marginRight: '16px',
      textAlign: 'left',
      display: 'flex',
      [breakpoints.up('lg')]: {
        width: '192px',
      },
    },
    acceptBtnsActive: {
      border: '1px solid #55C341',
      color: '#55C341',
    },
    acceptTxt: {

    },
    acceptNum: {

    },
    reloadIcon: {
      marginRight: '10px',
      verticalAlign: 'middle',
    },
    progress: {
      marginRight: '10px',
    },
    modalHeader: {
      fontSize: '18px',
      lineHeight: '27px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.005em',
      padding: '18px 16px',
      margin: 0,
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      marginBottom: '24px',
      justifyContent: 'space-between',
    },
    modalCloseBtn: {
      border: '0 none',
      margin: '0',
      padding: '0',
      background: 'none',
      outline: 'none',
      cursor: 'pointer',
    },
  })
)

const modalContentLayout = makeStyles(() =>
  createStyles({
    roundBoxIndexOuter: {
      border: '1px solid rgba(255, 255, 255, 0.12)',
      borderRadius: '4px',
      position: 'relative',
      width: '200px',
      margin: '0 auto 24px',
    },
    roundOuterMargin: {
      marginTop: '30px',
    },
    roundMatch: {
      background: '#232323',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '4px 8px 4px 7px',
      position: 'relative',
      minHeight: '40px',
      '&:nth-of-type(1)': {
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      },
      '&:nth-of-type(2)': {

      },
    },
    inner: {
      width: '100%',
    },
    avatar: {
      width: '32px',
      height: '32px',
      marginRight: '7px',
    },
    userName: {
      lineHeight: '1',
    },
    score: {
      color: 'rgba(255, 255, 255, 0.6)',
      textAlign: 'right',
      right: '8px',
      top: '11px',
      fontSize: '15px',
      lineHeight: '18px',
      marginLeft: 'auto',
    },
    roundBoxButton: {
      width: '100%',
    },
    roundBoxLink: {
      width: '100%',
      color: 'inherit',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    roundStatus: {
      fontWeight: 'bold',
      fontSize: '10px',
      lineHeight: '15px',
      position: 'absolute',
      left: '0',
      top: '-20px',
      borderRadius: '10px',
      padding: '0 8px',
    },
    roundStatusCONFIRM: {
      background: '#489FB5',
    },
    roundStatusIN_REVIEW: {
      background: '#477755',
    },
    roundStatusPENDING: {
      background: 'rgba(255, 255, 255, 0.38)',
    },
    roundStatusIN_BATTLE: {
      background: '#F3C000',
    },
    roundStatusHIDE: {
      display: 'none',
    },
  })
)

const userModalStyle: React.CSSProperties = {
  borderRadius: '4px',
  position: 'absolute',
  width: 'calc(100vw - 20px)',
  maxWidth: '400px',
  border: '2px solid #000',
  top: '50%',
  left: '50%',
  background: '#232323',
  transform: 'translate(-50%, -50%)',
  outline: 'none',
}

type TournamentDashboardNodelistProp = {
  nodes: TournamentNodeFragment[]
  tournamentId: string
}

const TournamentDashboardNodelist: React.FC<TournamentDashboardNodelistProp> = ({nodes, tournamentId}) => {
  const styles = modalContentLayout()

  const nodeList = nodes.map((node, index) => {
    const units = getTournamentNodeUnits(node)
    const players: (SingleEliminationUnitFragment | null)[] = []
    players.push(units.find((x) => x.index == 0) || null)
    players.push(units.find((x) => x.index == 1) || null)

    return (
      <Box key={index} className={styles.roundBoxIndexOuter}>
        <ButtonBase className={styles.roundBoxButton}>
          <Link href={`/tournaments/${tournamentId}/nodes/${node.nodeId}/battle`} className={styles.roundBoxLink}>
            {
              players.map((player, index2) => {
                const thumbnail = getTournamentNodeUnitThumbnailUrl(player!)

                return (
                  <Box key={`${index}-${index2}`} className={styles.roundMatch}>
                    {player &&
                    <Box className={styles.inner} key={index2} display="flex" alignItems="center">
                      {isTournamentPlayerFragment(player) ?
                        <Avatar className={styles.avatar} src={thumbnail} /> :
                        <SquareAvatar className={styles.avatar} src={thumbnail} />
                      }
                      <Typography variant='subtitle2' className={styles.userName}>{getTournamentUnitDisplayName(player)}</Typography>
                      <Typography className={styles.score}>{player!.score}</Typography>
                    </Box>
                    }
                  </Box>
                )
              })
            }
          </Link>
        </ButtonBase>
      </Box>
    )
  })

  return (
    <>
      {nodeList}
    </>
  )
}

type TournamentDashboardHeaderProp = {
  tournamentId: string
}

enum ModalTypeEnum {
  APPROVE = 'approve',
  CHAT = 'chat',
}

export const TournamentDashboardHeader: React.FC<TournamentDashboardHeaderProp> = ({tournamentId}) => {
  const styles = useLayout()
  const [modalStyle] = useState<React.CSSProperties>(userModalStyle)
  const [open, setOpen] = useState<boolean>(false)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [modalType, setModalType] = useState<ModalTypeEnum>(ModalTypeEnum.APPROVE)
  const {data, loading, error, refetch} = useTournamentBracketForHeaderQuery({
    variables: {
      id: tournamentId,
    },
    fetchPolicy: 'network-only',
  })

  if (loading) {
    return <LoadingPart/>
  }

  if (error) {
    console.log(error)
  }

  const bracket = data?.tournament.bracket as SingleEliminationTournamentBracket|null
  const nodes = bracket?.inReviewNodes
  const joinChatReaquests = bracket?.joinChatRequests

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget.dataset.modalType !== undefined) {
      if (event.currentTarget.dataset.modalType === ModalTypeEnum.APPROVE) {
        setModalType(ModalTypeEnum.APPROVE)
      } else {
        setModalType(ModalTypeEnum.CHAT)
      }
    }
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleRefetch = () => {
    setIsUpdating(true)
    refetch()
    setTimeout(() => setIsUpdating(false), 500)
  }

  if (!nodes) {
    return (
      <Typography variant="h1" color="textPrimary" className={styles.title}>トーナメント表</Typography>
    )
  }

  const modalBody = (
    <div style={modalStyle}>
      <h2 className={styles.modalHeader}>
        {modalType === ModalTypeEnum.APPROVE ?
          <span>結果承認待ち中</span> :
          <span>チャット招待中</span>
        }
        <button type={'button'} className={styles.modalCloseBtn} onClick={handleClose}><img src='/images/close.svg' /></button>
      </h2>
      <div>
        {modalType === ModalTypeEnum.APPROVE ?
          <TournamentDashboardNodelist nodes={nodes} tournamentId={tournamentId} />:
          <JoinChatRequestList tournamentId={tournamentId} joinChatReaquests={joinChatReaquests}/>
        }
      </div>
    </div>
  )

  const isDisabled = nodes.length === 0
  const isChatExist = joinChatReaquests?.length === 0

  return (
    <>
      <Box className={styles.dashboardHead}>
        <Typography variant="body2" color="textPrimary">
            大会が進行中です！各試合の対戦結果を確認・承認して、大会を進行していきましょう{'\u{1F64C}'}
        </Typography>
        <div className={styles.buttonsWrap}>
          <Button onClick={handleOpen} size='large' className={nodes.length ? styles.acceptBtns + ' ' + styles.acceptBtnsActive : styles.acceptBtns} disabled={isDisabled} data-modal-type={ModalTypeEnum.APPROVE}>
            <span className={styles.acceptTxt}>承認結果待ち</span>
            <span className={styles.acceptNum}>{nodes.length}</span>
          </Button>
          <Button onClick={handleOpen} size='large' className={joinChatReaquests?.length ? styles.acceptBtns + ' ' + styles.acceptBtnsActive : styles.acceptBtns} data-modal-type={ModalTypeEnum.CHAT} disabled={isChatExist}>
            <span className={styles.acceptTxt}>チャット招待</span>
            <span className={styles.acceptNum}>{joinChatReaquests?.length}</span>
          </Button>
          <Button onClick={() => handleRefetch()} size='large' className={styles.reloadBtn} disabled={isUpdating}>
            {isUpdating ?
              <CircularProgress size='15px' className={styles.progress} /> :
              <AutorenewIcon className={styles.reloadIcon}/>
            }
            <span className={styles.acceptTxt}>更新</span>
          </Button>
        </div>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>
    </>
  )
}
