import {GameExpFragment, UserEntryFragment} from '@/graphql/client'
import React from 'react'
import {GridList, GridListProps, GridListTile, useTheme} from '@material-ui/core'
import {UserListItem} from '@/components'

type UserListItemGridProps = GridListProps & {
  // FIXME: UserEntryFragmentを引数にとるのやめたい
  users: UserEntryFragment[]
  gameId: string
  handleClick?: (user: UserEntryFragment) => void
  outlined?: boolean
}

export const UserListItemGrid: React.FC<UserListItemGridProps> = ({outlined = false, users, gameId, handleClick, style, ...props}) => {
  const {spacing} = useTheme()

  const dispatchHandleClick = (id: string) => {
    const user = users.find(it => it.id === id)
    if (user) {
      handleClick?.(user)
    }
  }

  const getGameExp = (user: UserEntryFragment): GameExpFragment => user.gameExps.find(it => it.game.id === gameId)!

  return (
    <GridList
      cellHeight={88}
      cols={1}
      spacing={spacing(2)}
      style={{marginTop: 0, ...style}}
      {...props}
    >
      {users.map((user, index) => {
        const gameExp = getGameExp(user)
        return (
          <GridListTile key={index}>
            <UserListItem
              id={user.id}
              name={user.profile.displayName}
              thumbnailUrl={user.profile.thumbnailUrl}
              level={user.level.level}
              gameExp={gameExp}
              handleClick={dispatchHandleClick}
              outlined={outlined}
            />
          </GridListTile>
        )
      })}
    </GridList>
  )
}
