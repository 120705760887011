import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Button as MUIButton,
  Modal, Paper, makeStyles,
} from '@material-ui/core'
import {Box, Divider, Typography} from '@material-ui/core/'
import {TournamentStatus, TournamentTeamFragment, UserEntryFragment} from '@/graphql/client'
import {Button, Card, SquareAvatar, UserListItemGrid} from '@/components'
import CloseIcon from '@material-ui/icons/Close'
import {useRouter} from 'next/router'
import {serverConfig} from '@/env'
import {BottomSheet} from '@/components/atoms/bottom-sheet'
import {useSwitchUser} from '@/hooks'

const useTeamEntryDetailModalStyles = makeStyles(({spacing, palette}) => ({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    width: 'calc(100vw - 20px)',
    maxWidth: 600,
  },
  container: {
    margin: spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: 'calc(100vh * 0.8)',
  },
  subtitle: {
    alignSelf: 'flex-start',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContents: 'center',
    alignItems: 'center',
  },
  closeIcon: {
    color: 'rgba(255, 255, 255, 0.38)',
    alignSelf: 'flex-end',
  },
  divider: {
    marginTop: spacing(6),
    marginBottom: spacing(6),
    width: '200%', // FIXME: これでいい?
  },
  clanName: {
    fontSize: 28,
  },
  clanIcon: {
    width: 100,
    height: 100,
    marginBottom: spacing(4),
  },
  dangerText: {
    color: palette.status.error,
  },
}))

type TeamEntryDetailModalProps = {
  open: boolean
  gameId: string
  team: TournamentTeamFragment
  status: TournamentStatus
  onClose: () => void
  canForceExit: boolean
  canEdit: boolean
  onEdit: (team: TournamentTeamFragment) => void
  onForceExit: (team: TournamentTeamFragment) => void
}

export const TeamEntryDetailModal: React.FC<TeamEntryDetailModalProps> = (props) => {
  const styles = useTeamEntryDetailModalStyles()
  const router = useRouter()
  const {switchUser} = useSwitchUser()

  const {open, gameId, team, status, onClose, canForceExit, canEdit, onEdit, onForceExit} = props

  const [openDialog, setOpenDialog] = React.useState<boolean>(false)
  const handleOpenDialog = () => setOpenDialog(true)
  const handleCloseDialog = () => setOpenDialog(false)

  const [openSheet, setOpenSheet] = React.useState<boolean>(false)
  const handleOpenSheet = () => setOpenSheet(true)
  const handleCloseSheet = () => setOpenSheet(false)

  const [selectedUserId, setSelectedUserId] = React.useState<string|null>(null)
  const handleGoProfile = async (userId: string) => {
    await router.push(`/users/${userId}/profile`)
    handleCloseSheet()
  }

  const handleClanDetail = async () => await router.push(`/clan/${team.clan.id}/detail`)
  const handleEditTeam = (team: TournamentTeamFragment) => onEdit(team)

  const handleForceExit = () => {
    setOpenDialog(false)
    onForceExit(team)
    onClose()
  }

  const handleUserClick = async (user: UserEntryFragment) => {
    setSelectedUserId(user.id)
    serverConfig.isPrd ? await handleGoProfile(user.id) : handleOpenSheet()
  }

  const handleSwitchUser = () => {
    switchUser(selectedUserId!)
    handleCloseSheet()
  }

  return (
    <Modal
      open={open}
      className={styles.modal}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
    >
      <>
        <Paper>
          <Card className={styles.root}>
            <Box className={styles.container}>
              <CloseIcon className={styles.closeIcon} onClick={onClose}/>

              <Box className={styles.header}>
                <SquareAvatar className={styles.clanIcon} src={team.clan.logoUrl}/>
                <Typography variant={'h2'}>{team.clan.name}</Typography>
                {(canEdit && status === TournamentStatus.Public) && (
                  <MUIButton variant={'text'} color={'primary'}
                    onClick={() => handleEditTeam(team)}
                  >エントリー情報の変更
                  </MUIButton>
                )}
                {(canForceExit && status === TournamentStatus.Public) && (
                  <MUIButton className={styles.dangerText} variant={'text'}
                    onClick={handleOpenDialog}
                  >強制エントリー解除
                  </MUIButton>
                )}
              </Box>

              <Divider className={styles.divider}/>

              <Typography className={styles.subtitle} variant={'subtitle2'}>参加メンバー</Typography>
              <UserListItemGrid style={{width: '100%'}} outlined users={team.users} gameId={gameId} handleClick={handleUserClick}/>

              <Box mt={10} mb={10}>
                <Button outlined onClick={handleClanDetail}>クランの詳細を見る</Button>
              </Box>
            </Box>
          </Card>
        </Paper>

        {/* 強制エントリー解除ダイアログ */}
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>強制エントリー解除</DialogTitle>
          <DialogContent>
            <DialogContentText>{`${team.clan.name}のエントリーを解除してもよろしいですか？`}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <MUIButton variant={'text'} onClick={handleCloseDialog}>キャンセル</MUIButton>
            <MUIButton className={styles.dangerText} variant={'text'} onClick={handleForceExit}>解除</MUIButton>
          </DialogActions>
        </Dialog>

        {/* 開発用ユーザー切り替えダイアログ */}
        {!serverConfig.isPrd &&
          <BottomSheet open={openSheet} onClose={handleCloseSheet}>
            <List component="nav" aria-label="secondary mailbox folders">
              <ListItem button onClick={handleSwitchUser}>
                <ListItemText primary="このユーザーに切り替え"/>
              </ListItem>
              <ListItem button onClick={() => handleGoProfile(selectedUserId!)}>
                <ListItemText primary="プロフィールを見る"/>
              </ListItem>
            </List>
          </BottomSheet>
        }
      </>
    </Modal>
  )
}
