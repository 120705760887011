import {GameExpFragment, ProfileFragment, UserFragment} from '@/graphql/client'
import {
  Box,
  LinearProgress,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import TwitterIcon from '@material-ui/icons/Twitter'
import React, {useState} from 'react'
import VerifiedBadge from '@/components/atoms/verified-badge'
import VerifiedAvatar from '@/components/molecules/verified-avatar'
import {INT32_MAX} from '@/consts/graphql'
import {GiftModal} from '@/components/organisms/users/gift-modal'
import {Button} from '@/components/atoms/button'

const useUserProfileStyle = makeStyles(() =>
  createStyles({
    userProfileWrap: {
      justifyContent: 'space-between',
      marginBottom: '36px',
    },
    userthumb: {
      width: '100px',
    },
    userthumbIcon: {
      width: '100px',
      height: '100px',
    },
    usercontent: {
      width: 'calc(100% - 116px)',
    },
    twicon: {
      marginLeft: '16px',
    },
    usertitle: {
      fontSize: '32px',
      lineHeight: '47px',
      marginBottom: '8px',
      '& > a': {
        marginLeft: '16px',
      },
    },
    userlv: {
      alignItems: 'center',
      marginBottom: '12px',
    },
    userLevelTxt: {
      fontWeight: 'bold',
      fontSize: '15px',
      lineHeight: '22px',
      marginRight: '16px',
    },
    userlvbarwrap: {
      flexGrow: 1,
      maxWidth: '250px',
      marginRight: '16px',
    },
    userprogress: {
      borderRadius: '4px',
      backgroundColor: 'rgba(255, 255, 255, 0.12)',
    },
    userExps: {
      fontSize: '15px',
      color: 'rgba(255, 255, 255, 0.6)',
    },
    userinfo: {
      marginBottom: '16px',
      alignItems: 'center',
    },
    userinfoicon: {
      marginRight: '8px',
      width: 24,
      height: 24,
      borderRadius: 12,
    },
    userinfoExp: {
      marginRight: '8px',
      color: 'rgba(255, 255, 255, 0.6)',
      fontSize: '12px',
      lineHeight: '18px',
    },
    userinfoLabel: {
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '18px',
    },
    userdescription: {
      color: 'rgba(255, 255, 255, 0.6)',
      fontSize: '14px',
      lineHeight: '160.1%',
      wordBreak: 'break-word',
    },
    userName: {
      wordBreak: 'break-word',
    },
  })
)

const useUserProfileSpStyle = makeStyles(() =>
  createStyles({
    userProfileWrap: {
      justifyContent: 'space-between',
      marginBottom: '40px',
    },
    userthumb: {
      marginBottom: '24px',
    },
    userthumbIcon: {
      width: '100px',
      height: '100px',
    },
    twicon: {
      marginLeft: '16px',
      verticalAlign: 'middle',
    },
    usertitle: {
      fontSize: '28px',
      lineHeight: '41px',
      marginBottom: '28px',
      justifyContent: 'space-between',
      '& > a': {
        marginLeft: '8px',
        display: 'block',
        width: '38px',
        height: '38px',
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
        borderRadius: '50%',
        textAlign: 'center',
      },
    },
    userlv: {
      alignItems: 'center',
      marginBottom: '12px',
    },
    userLevelTxt: {
      fontWeight: 'bold',
      fontSize: '15px',
      lineHeight: '22px',
      marginRight: '8px',
    },
    userlvbarwrap: {
      flexGrow: 1,
      marginRight: '8px',
    },
    userprogress: {
      borderRadius: '4px',
      backgroundColor: 'rgba(255, 255, 255, 0.12)',
    },
    userExps: {
      fontSize: '15px',
      color: 'rgba(255, 255, 255, 0.6)',
    },
    userinfo: {
      marginBottom: '16px',
      alignItems: 'center',
    },
    userinfoicon: {
      marginRight: '8px',
      width: 24,
      height: 24,
      borderRadius: 12,
    },
    userinfoExp: {
      marginRight: '8px',
      color: 'rgba(255, 255, 255, 0.6)',
      fontSize: '12px',
      lineHeight: '18px',
    },
    userinfoLabel: {
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '18px',
    },
    userdescription: {
      color: 'rgba(255, 255, 255, 0.6)',
      fontSize: '14px',
      lineHeight: '160.1%',
      wordBreak: 'break-word',
    },
    userName: {
      maxWidth:'calc(100% - 46px)',
      wordBreak:'break-word',
    },
  })
)

const getLvPercent = (user: UserFragment) => {
  return (user.totalExp - user.level.minExp) / (user.level.maxExp - user.level.minExp) * 100
}

const twitterIcon = (profile: ProfileFragment) => {
  if (profile.twitterId == null) return null

  return (
    <a href={`https://twitter.com/${profile.twitterId}`} target='_blank' rel='noopener noreferrer'>
      <TwitterIcon style={{color:'rgba(255, 255, 255, 0.38)'}}/>
    </a>
  )
}


type UserProfileProp = {
  user: UserFragment
  onGiftUpdate: () => void
}

export const UserProfilePc: React.FC<UserProfileProp> = ({user, onGiftUpdate}) => {
  const styles = useUserProfileStyle()

  const gameExps = user.gameExps.filter(
    (exp) => exp.game.enabled && exp.exp > 0
  )
  const verifiedBadge = user.verifiedOrganizerLevel > 1 ? <VerifiedBadge size={21} /> : null
  const maxExp = user.level.maxExp === INT32_MAX ? '--' : user.level.maxExp

  return (
    <Box display="flex" className={styles.userProfileWrap}>
      <Box display="flex" className={styles.userthumb}>
        <VerifiedAvatar src={user.profile.thumbnailUrl} className={styles.userthumbIcon} verifiedOrganizerLevel={user.verifiedOrganizerLevel} />
      </Box>
      <Box className={styles.usercontent}>
        <Box display="flex" className={styles.usertitle}>
          <Typography variant='h1' className={styles.userName}>{user.profile.displayName} {verifiedBadge} {twitterIcon(user.profile)}</Typography>
          <SendGift user={user} onGiftUpdate={onGiftUpdate} />
        </Box>
        <Box display="flex" className={styles.userlv}>
          <Typography className={styles.userLevelTxt}>Lv.{user.level.level}</Typography>
          <Box className={styles.userlvbarwrap}>
            <LinearProgress className={styles.userprogress} variant="determinate" value={getLvPercent(user)} />
          </Box>
          <Box className={styles.userExps}>
            {user.totalExp}/{maxExp} XP
          </Box>
        </Box>
        {gameExps.map((gameExp: GameExpFragment, idx: number) => {
          return (
            <Box key={idx} display="flex" className={styles.userinfo}>
              <img src={gameExp.game.iconUrl} className={styles.userinfoicon} />
              <Typography className={styles.userinfoExp}>
                {gameExp.exp}XP
              </Typography>
              <Typography className={styles.userinfoLabel}>
                {gameExp.title}
              </Typography>
            </Box>
          )
        })}
        <Box className={styles.userdescription}>
          {user.profile.body}
        </Box>
      </Box>
    </Box>
  )
}

export const UserProfileSp: React.FC<UserProfileProp> = ({user, onGiftUpdate}) => {
  const styles = useUserProfileSpStyle()

  const gameExps = user.gameExps.filter(
    (exp) => exp.game.enabled && exp.exp > 0
  )
  const verifiedBadge = user.verifiedOrganizerLevel > 1 ? <VerifiedBadge size={21} /> : null
  const maxExp = user.level.maxExp === INT32_MAX ? '--' : user.level.maxExp

  return (
    <Box className={styles.userProfileWrap}>
      <Box className={styles.userthumb} display="flex">
        <VerifiedAvatar src={user.profile.thumbnailUrl} className={styles.userthumbIcon} verifiedOrganizerLevel={user.verifiedOrganizerLevel} />
        <SendGift user={user} onGiftUpdate={onGiftUpdate} />
      </Box>
      <Box display="flex" className={styles.usertitle}>
        <Typography variant='h1' className={styles.userName}>{user.profile.displayName} {verifiedBadge} {twitterIcon(user.profile)}</Typography>
      </Box>
      <Box display="flex" className={styles.userlv}>
        <Typography className={styles.userLevelTxt}>Lv.{user.level.level}</Typography>
        <Box className={styles.userlvbarwrap}>
          <LinearProgress className={styles.userprogress} variant="determinate" value={getLvPercent(user)} />
        </Box>
        <Box className={styles.userExps}>
          {user.totalExp}/{maxExp} XP
        </Box>
      </Box>
      {gameExps.map((gameExp: GameExpFragment, idx: number) => {
        return (
          <Box key={idx} display="flex" className={styles.userinfo}>
            <img src={gameExp.game.iconUrl} className={styles.userinfoicon} />
            <Typography className={styles.userinfoExp}>
              {gameExp.exp}XP
            </Typography>
            <Typography className={styles.userinfoLabel}>
              {gameExp.title}
            </Typography>
          </Box>
        )
      })}
      <Box className={styles.userdescription}>
        {user.profile.body}
      </Box>
    </Box>
  )
}

const SendGift = ({user, onGiftUpdate}: {user: UserFragment, onGiftUpdate: () => void}) => {
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
    onGiftUpdate()
  }

  if (user.isDeleted) return null

  return (
    <Box display="flex" alignItems="center" ml="auto" pl={4} minWidth="max-content">
      <Button onClick={() => setOpen(true)} variant="outlined">ギフトを送る</Button>
      <GiftModal open={open} user={user} onClose={handleClose} />
    </Box>
  )
}
