import React from 'react'

import {
  Box,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'

import {
  Button,
} from '../../atoms'

const useTournamentChatComponetStyle = makeStyles(() =>
  createStyles({
    wrap: {
      display: 'block',
      background: '#1E1E1E',
      borderRadius: '4px',
      marginBottom: '24px',
      padding: '17px 24px',
    },
    chatDescription: {
      fontSize: '14px',
      marginBottom: '18px',
    },
    openChatButtonWrap: {
      display: 'flex',
      justifyContent: 'center',
    },
    openChatButton: {
      border: '1px solid #55C341',
      background: 'none',
      color: '#55C341',
      fontSize: '14px',
      fontWeight: 'bold',
    },
  })
)

type TournamentChatComponetProps = {
  toggleChatState: () => void
}

export const TournamentChatComponet: React.FC<TournamentChatComponetProps> = ({...props}) => {
  const styles = useTournamentChatComponetStyle()

  return (
    <>
      <Box className={styles.wrap}>
        <Box className={styles.chatDescription}>
          <Typography>対戦相手と連絡を取り合って、バトルを開始しましょう！</Typography>
        </Box>
        <Box className={styles.openChatButtonWrap}>
          <Button className={styles.openChatButtonWrap} onClick={props.toggleChatState}>チャットを開く</Button>
        </Box>
      </Box>
    </>
  )
}
