import {
  Card as MUICard,
  CardProps as MUICardProps,
  createStyles,
  makeStyles,
} from '@material-ui/core'

import {
  composeClassNames,
} from '@/utils'

export type CardProps = MUICardProps

const useCardStyles = makeStyles(({palette}) => createStyles({
  root: {
    backgroundColor: palette.contentBackground,
  },
}))

export const Card: React.FC<CardProps> = ({className, elevation=0, ...props}) => {

  const styles = useCardStyles()

  return (
    <MUICard className={composeClassNames(styles.root, className)} elevation={elevation} {...props} />
  )

}
