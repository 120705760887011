import React from 'react'

import {Grid, GridProps} from '@material-ui/core'

import {
  TournamentCard,
} from './tournament-card'

import MessageEmptyState from '@/components/atoms/message-empty-state'
import {Maybe, TournamentCardFragment, TournamentEdge} from '@/graphql/client'
import {useIsLg} from '@/hooks'

type TournamentCardGridProps = GridProps & {
  data?: Array<Maybe<TournamentEdge>> | null
}

export const TournamentCardGrid: React.FC<TournamentCardGridProps> = ({
  data = [],
  style,
  ...props
}) => {
  const isLg = useIsLg()

  if (!data || data.length == 0) {
    return (
      <MessageEmptyState message='該当する大会はありません'/>
    )
  }

  const items = data.map((x) => x?.node).filter(x => x) as TournamentCardFragment[]

  return (
    <Grid
      container
      style={style}
      spacing={6}
      justify={isLg ? 'flex-start' : 'center'}
      {...props}
    >
      {items.map((item) => (
        <Grid item key={item.id}>
          <TournamentCard
            id={item.id}
            title={item.title}
            status={item.status}
            unitType={item.unitType}
            time={item.willStartAt}
            coverPreviewUrl={item.coverPreviewUrl}
            participants={{max: item.maxEntry, current: item.entryNum}}
            openTournamentType={item.openTournamentType}
            verifiedOrganizerLevel={item.organizer?.verifiedOrganizerLevel}
            sponsorImage={item.sponsorCampaign?.sponsor?.iconUrl}
            sponsorLandingUrl={item.sponsorCampaign?.landingUrl}
          />
        </Grid>
      ))}
    </Grid>
  )
}
