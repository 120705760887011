import React from 'react'

import {
  Box,
  BoxProps,
  Typography,
  TypographyVariant,
  createStyles,
  makeStyles,
} from '@material-ui/core'

import {
  composeClassNames,
} from '@/utils'

import {TournamentStatus} from '@/graphql/client'
import {format, isToday, parseISO} from 'date-fns'

type TournamentTitleProps = BoxProps & {
  time: string
  status: TournamentStatus
  variant?: TypographyVariant
}

// type UseTournamentTitleStylesProps = {}

//const useTournamentTitleStyles = makeStyles<Theme, UseTournamentTitleStylesProps>(({palette, spacing}) => createStyles({
const useTournamentTitleStyles = makeStyles(({spacing}) => createStyles({
  root: {},
  startTimeText: {
    fontWeight: 'bold',
    marginBottom: spacing(1),
  },
}))

export const TournamentTitle: React.FC<TournamentTitleProps> = ({className, status, time, variant='subtitle2', children, ...props}) => {

  const styles = useTournamentTitleStyles()
  // className={composeClassNames(className, styles.root)}
  const getStartTime = ()=>{
    switch (status) {
      case TournamentStatus.Public:
        if (isToday(new Date(time))){
          return '今日  ' + format(parseISO(time), 'HH:mm~')
        }
        return format(parseISO(time), 'yyyy/MM/dd HH:mm~')
      case TournamentStatus.InProgress:
        return '現在進行中'
      case TournamentStatus.Finish:
        return '結果発表'
      case TournamentStatus.Cancel:
        return '開催中止'
      default:
        return ('')
    }
  }

  const startTime= getStartTime()

  return (
    <Box className={composeClassNames(className, styles.root)} {...props}>
      {status === TournamentStatus.InProgress ?
        <Typography color='primary' className={styles.startTimeText}>{startTime}</Typography>:
        <Typography color='textSecondary' className={styles.startTimeText}>{startTime}</Typography>
      }
      <Typography variant={variant}>{children}</Typography>
    </Box>
  )

}
