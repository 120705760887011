import React from 'react'

type VerifiedBadgeProp = {
  size?: number
}

const VerifiedBadge = (props: VerifiedBadgeProp) => {
  const size = props.size || 14
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>認定主催者</title>
      <path
        d="M15.3333 6.99994L13.7067 5.14661L13.9333 2.69328L11.5267 2.14661L10.2667 0.0266113L7.99999 0.999945L5.73332 0.0266113L4.47332 2.14661L2.06666 2.68661L2.29332 5.13995L0.666656 6.99994L2.29332 8.85328L2.06666 11.3133L4.47332 11.8599L5.73332 13.9799L7.99999 12.9999L10.2667 13.9733L11.5267 11.8533L13.9333 11.3066L13.7067 8.85328L15.3333 6.99994ZM6.66666 10.3333L3.99999 7.66661L4.93999 6.72661L6.66666 8.44661L11.06 4.05328L12 4.99994L6.66666 10.3333Z"
        fill="#55C341"
      />
    </svg>
  )
}

export default VerifiedBadge
