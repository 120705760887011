import React from 'react'

import {Box, CardActionArea, Typography} from '@material-ui/core/'

import {Theme, createStyles, makeStyles} from '@material-ui/core/styles'

import {Avatar, Card, CardProps, Medal, MedalBackgroundColor} from '@/components/atoms'
import {RoleFragment, UserFragment} from '@/graphql/client'

const useMemberListItem = makeStyles<Theme, {isOwner: boolean}>(({spacing}) => createStyles({
  root: {
    position: 'relative',
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: spacing(4),
    height: 80,
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    marginRight: spacing(3),
  },
}))

type MemberListItemProps = {
  user: UserFragment
  role: RoleFragment|undefined
  isOwner: boolean
  cardProps?: CardProps
  handleClick?: (userId: string) => void
}

export const MemberListItem: React.FC<MemberListItemProps> = ({cardProps, ...props}) => {

  const {user, role, isOwner} = props

  const styles = useMemberListItem({isOwner})

  const getMedalLabel = (): string => {
    if (isOwner) {
      return 'CLAN MASTER'
    }

    switch (role?.name) {
      case 'clan:manager':
        return 'MANAGER'
      default:
        return ''
    }
  }

  return (
    <Card {...cardProps} className={styles.root} onClick={() => props.handleClick?.(user.id)}>
      <CardActionArea className={styles.container}>
        <Box className={styles.nameContainer}>
          <Avatar src={user.profile.thumbnailUrl} className={styles.avatar} />
          <Box>
            <Typography variant='subtitle1'>{user.profile.displayName}</Typography>
            <Typography variant='overline'>Lv.{user.level.level}</Typography>
          </Box>
        </Box>
      </CardActionArea>
      {(role || isOwner) && (
        <Medal background={isOwner ? MedalBackgroundColor.GREEN : MedalBackgroundColor.SILVER} text={getMedalLabel()}/>
      )}
    </Card>
  )
}
