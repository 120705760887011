import React from 'react'
import {makeStyles} from '@material-ui/core'
import {Box, Typography} from '@material-ui/core/'
import {UserFragment} from '@/graphql/client'
import {Button} from '@/components'
import {SelectableUserList} from '@/components/organisms/tournament-team/selectable-user-list'


const useChoseManagerContentStyles = makeStyles(({spacing}) => ({
  root: {
  },
  subtitle: {
    marginBottom: spacing(1),
  },
  description: {
    marginBottom: spacing(4),
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginTop: spacing(10),
    marginBottom: spacing(12),
    width: 73,
    height: 44,
  },
}))

type ChoseManagerContentProps = {
  initialSelectedIds?: string[]
  memberIds: string[]
  managers: UserFragment[]
  gameId: string
  onNext: (managerIds: string[]) => void
  onBack: () => void
}

export const ChoseManagerContent: React.FC<ChoseManagerContentProps> = (props) => {
  const styles = useChoseManagerContentStyles()
  const {initialSelectedIds, memberIds, managers, gameId, onNext, onBack} = props

  const [selectedManagerIds, setSelectedManagerIds] = React.useState<string[]>(initialSelectedIds ?? [])

  const handleManagerSelected = (userIds: string[]) => setSelectedManagerIds(userIds)
  const managersExceptPlayer = managers.filter(it => !memberIds.includes(it.id))

  return (
    <Box className={styles.root}>
      <Typography className={styles.description} variant={'body2'}>
          参加するメンバーを選択してください
      </Typography>

      <Typography className={styles.subtitle} variant={'body2'}>マネージャー</Typography>
      <Typography className={styles.description} variant={'body2'}>最大2名まで選択できます</Typography>
      <SelectableUserList
        initialSelectedIds={initialSelectedIds}
        users={managersExceptPlayer}
        gameId={gameId}
        handleChange={handleManagerSelected}
        maxSelectNum={2}
        outlined
      />

      <Box className={styles.buttons}>
        <Button outlined className={styles.button} onClick={onBack}>前へ</Button>
        <Box width={16}/>
        <Button
          className={styles.button}
          onClick={() => onNext(selectedManagerIds)}
        >
            次へ
        </Button>
      </Box>
    </Box>
  )
}
