import React, {useState} from 'react'
import {useRouter} from 'next/router'
import {format, parseISO} from 'date-fns'
import ja from 'date-fns/locale/ja'

import {Box, Button, Card, CardContent, Grid, Modal, Typography, createStyles, makeStyles} from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import {useAuth0} from '@auth0/auth0-react'

import {
  Tournament,
  TournamentDetailWithSponsorQuery,
  TournamentStatus,
  TournamentTeamFragment,
  TournamentUnitType,
  UserEntryFragment,
  UserOrganizerFragment,
  useMeQuery,
  useTournamentDetailQuery,
  useTournamentEntryAddMutation,
  useTournamentEntryRemoveMutation,
  useTournamentEntryStatusQuery,
  useTournamentOpenEntryRemoveMutation,
  useTournamentTeamEntryRemoveMutation,
} from '@/graphql/client'

// TODO: コンポーネントの呼び出しについて総整理する必要あり
import {Avatar, Link} from '@/components'
import VerifiedBadge from '@/components/atoms/verified-badge'
import VerifiedAvatar from '@/components/molecules/verified-avatar'
import {EntryButton} from '@/components/molecules/tournament-detail/entry-button'
import {DashboardButton} from '@/components/molecules/tournament-detail/dashboard-button'
import {TournamentResult} from '@/components/organisms/tournament-detail/tournament-result'
import {TeamEntryModal} from '@/components/organisms/tournament-team'
import MutationLoading from '@/components/templates/layouts/mutation-loading'

import {useSnackbar} from '@/hooks/acknowledge'
import {useClientStateContext} from '@/hooks/apollo'
import {useErrorReport} from '@/hooks/error'
import {useLocalStorage} from '@/hooks/localstorage'
import {NOT_LOGIN_MODAL_IDS} from '@/consts'
import {getMemberCountByUnit, truncateFullWidth, unitTypeSuffix} from '@/utils'
import {useIsLg} from '@/hooks'
import {OpenEntryModal} from '../tournament-open/open-entry-modal'
import {OpenCheckinModal} from '../tournament-open/open-checkin-modal'

// こういう罪深いことは本来しない方が良い
export type tournamentDetailHeaderType = Pick<Tournament,
'id' | 'title' | 'willStartAt' | 'maxEntry' | 'entryNum' | 'checkinNum' | 'coverUrl' | 'status'| 'unitType' | 'contactDiscord'
| 'openTournamentType' | 'openEntryStartsAt' | 'openEntryEndsAt' | 'openCheckinStartsAt' | 'openCheckinEndsAt'
> & {
  organizer: UserOrganizerFragment
  entries: UserEntryFragment[]
  entryTeams: TournamentTeamFragment[]
  gameId: string
  sponsorCampaign?: TournamentDetailWithSponsorQuery['tournament']['sponsorCampaign']
  isAfterCheckinStartsAt: boolean
  isAfterCheckinEndsAt: boolean
}

type TournamentDetailHeaderProp = {
  tournamentHeader: tournamentDetailHeaderType
}

const useModalStyle = makeStyles(() =>
  createStyles({
    root: {
      borderRadius: '4px',
      position: 'absolute',
      width: 'calc(100vw - 20px)',
      maxWidth: '400px',
      top: '50%',
      left: '50%',
      background: '#232323',
      transform: 'translate(-50%, -50%)',
      outline: 0,
    },
  })
)


const useLayout = makeStyles(({breakpoints, spacing, palette}) =>
  createStyles({
    gameImage: {
      width: '100%',
      maxWidth: 800,
    },
    button: {
      padding: '12px 0',
      width: '100%',
      [breakpoints.up('lg')]: {
        padding: '11.5px 22px',
        width: 185,
      },
    },
    cardTop: {
      width: '100%',
      height: 72,
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 8,
    },
    cardBottom: {
      width: '100%',
      height: 72,
      display: 'flex',
      marginBottom: 8,
    },
    leftCard: {
      width: '100%',
      height: 72,
      display: 'flex',
      justifyContent: 'center',
    },
    cardGrid: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cardText: {
      fontSize: '0.75rem',
      letterSpacing: '0.005rem',
    },
    cardUserName: {
      fontSize: '15px',
      fontWeight: 'bold',
    },
    entry: {
      padding: '6px 16px',
      height: 90,
      backgroundColor: palette.contentBackground,
    },
    organizerBox: {
      width: '100%',
      [breakpoints.up('lg')]: {
        height: 72,
        display: 'flex',
        marginLeft: spacing(4.5),
        '&> *': {
          width: '100%',
        },
      },
    },
    winnersBox: {
      width: '100%',
    },
    modalHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '25px 17px 0',
      margin: 0,
      justifyContent: 'flex-end',
    },
    modalIcon: {
      marginTop: '-20px',
    },
    modalBody: {
      textAlign: 'center',
      paddingBottom: '48px',
    },
    modalCloseBtn: {
      border: '0 none',
      margin: '0',
      padding: '0',
      background: 'none',
      outline: 'none',
      cursor: 'pointer',
    },
    modalTitle: {
      marginBottom: '8px',
    },
    modalText: {
      marginBottom: '39px',
    },
    loginButton: {
      display: 'block',
      margin: '0 auto 24px',
      padding: '12px 21px',
      border: '1px solid #55C341',
      background: 'none',
      color: '#55C341',
      fontSize: '14px',
      fontWeight: 'bold',
      width: '101px',
    },
    help: {
      position: 'relative',
      height: '16px',
      top: '1.33px',
      color: palette.text.secondary,
    },
    tournamentHeaderWrapper: {
      width: 'calc(100% + 32px)',
      margin: '-40px -16px 0 -16px',
      [breakpoints.up('lg')]: {
        width: '100%',
      },
    },
    tournamentContentWrapper: {
      padding: '0 16px',
      width: '100%',
    },
    tournamentContentMargin: {
      marginTop: spacing(4),
      marginBottom: spacing(4),
      [breakpoints.up('lg')]: {
        marginTop: spacing(4.5),
        marginBottom: 0,
      },
    },
  })
)


export const TournamentDetailHeader = (props: TournamentDetailHeaderProp) => {
  const styles = useLayout()
  const modalStyle = useModalStyle()

  const {isLoading, loginWithRedirect} = useAuth0()
  const router = useRouter()
  const {snackbar} = useSnackbar()
  const {errorReport} = useErrorReport()
  const [open, setOpen] = useState<boolean>(false)
  const [tournamentId, setTournamentId] = useLocalStorage<string[]>(NOT_LOGIN_MODAL_IDS, [])
  const [teamEntryOpen, setTeamEntryOpen] = React.useState<boolean>(false)
  const [openEntryOpen, setOpenEntryOpen] = React.useState<boolean>(false)
  const [openCheckinOpen, setOpenCheckinOpen] = React.useState<boolean>(false)
  const isLg = useIsLg()

  const {tournamentHeader} = props
  const isEnd = props?.tournamentHeader.status === TournamentStatus.Finish

  const {authState} = useClientStateContext()
  const {data: meData, loading: QueryMeLoading} = useMeQuery({
    skip: authState !== 'Authenticated',
  })
  const {refetch: refetchEntryStatus, data: entryStatusData, loading: entryStatusLoading} = useTournamentEntryStatusQuery({
    skip: authState !== 'Authenticated',
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {tournamentId: props.tournamentHeader.id},
  })
  const {refetch: fetchTournament, loading: QueryTournamentLoading} = useTournamentDetailQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {id: props.tournamentHeader.id},
  })
  const [tournamentEntryAdd, {loading: mutationEntryAddLoading}] = useTournamentEntryAddMutation()
  const [tournamentEntryRemove, {loading: mutationEntryRemoveLoading}] = useTournamentEntryRemoveMutation()

  const [tournamentTeamEntryRemove] = useTournamentTeamEntryRemoveMutation()
  const [tournamentOpenEntryRemove] = useTournamentOpenEntryRemoveMutation()

  const entryLoading = isLoading || QueryMeLoading || QueryTournamentLoading || entryStatusLoading || mutationEntryAddLoading || mutationEntryRemoveLoading

  // ログインしているユーザーのID
  const myId = meData ? meData.me.id : '-1'
  const entryStatus = entryStatusData?.tournamentEntryStatus
  const checkinDue = props.tournamentHeader.openCheckinEndsAt ? new Date(props.tournamentHeader.openCheckinEndsAt) : undefined

  const isAfterCheckinStartsAt = props.tournamentHeader.isAfterCheckinStartsAt
  const isAfterCheckinEndsAt = props.tournamentHeader.isAfterCheckinEndsAt
  const openCheckinGrid = tournamentHeader.openTournamentType && isAfterCheckinStartsAt ? 4 : 6

  const getMyEntryTeam = (): TournamentTeamFragment => {
    return tournamentHeader.entryTeams.find((it) => {
      return it.users.find(u => u.id === myId) || it.managers.find(m => m.id === myId)
    })!
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    tournamentId.push(props.tournamentHeader.id)
    setTournamentId(tournamentId)
    setOpen(false)
  }

  const handleLogin = async () => {
    setTournamentId([])
    await loginWithRedirect({appState: {returnTo: window.location.pathname}})
  }
  const handleEntry = async () => {
    if (!meData) {
      await loginWithRedirect({appState: {returnTo: window.location.pathname}})
      return
    }

    if (props.tournamentHeader.openTournamentType != null) {
      setOpenEntryOpen(true)
    } else if (props.tournamentHeader.unitType !== TournamentUnitType.Single) {
      setTeamEntryOpen(true)
    } else {
      try {
        await tournamentEntryAdd({variables: {input: {tournamentId: tournamentHeader.id}}})
        await fetchTournament()
        await refetchEntryStatus()

        snackbar.success(`${props.tournamentHeader.title}へのエントリーをしました`)
      } catch (e) {
        snackbar.failed(`${props.tournamentHeader.title}へのエントリーに失敗しました`, e)
        errorReport.captureException(e)
      }
    }
  }

  const handleEntryExit = async () => {
    if (props.tournamentHeader.openTournamentType) {
      try {
        await tournamentOpenEntryRemove({variables: {input: {tournamentId: tournamentHeader.id}}})
        snackbar.success(`${props.tournamentHeader.title}へのエントリーを取り消しました`)
      } catch (e) {
        snackbar.failed(`${props.tournamentHeader.title}へのエントリー取り消しに失敗しました`, e)
      }
    } else if (props.tournamentHeader.unitType === TournamentUnitType.Single) {
      try {
        await tournamentEntryRemove({variables: {input: {tournamentId: tournamentHeader.id}}})
        snackbar.success(`${props.tournamentHeader.title}へのエントリーを取り消しました`)
      } catch (e) {
        snackbar.failed(`${props.tournamentHeader.title}へのエントリー取り消しに失敗しました`, e)
        errorReport.captureException(e)
      }
    } else {
      const team = getMyEntryTeam()
      try {
        await tournamentTeamEntryRemove({variables: {input: {tournamentTeamId: team.id}}})
        snackbar.success(`${props.tournamentHeader.title}へのエントリーを取り消しました`)
      } catch (e) {
        snackbar.failed(`${props.tournamentHeader.title}へのエントリー取り消しに失敗しました`, e)
      }
    }

    await fetchTournament()
    await refetchEntryStatus()
  }

  const handleCheckin = () => setOpenCheckinOpen(true)

  React.useEffect(() => {
    if (!meData && !tournamentId.includes(props.tournamentHeader.id)) {
      handleOpen()
    }
  }, [meData, tournamentId, props.tournamentHeader.id])

  const ActionButton = () => {
    if (props.tournamentHeader.status !== TournamentStatus.Public) {
      return (<></>)
    }
    if (myId === props.tournamentHeader.organizer.id || !!meData?.me.isTournamentModerator) {
      return isLg ? (
        <Box
          minWidth='180px'
        >
          <DashboardButton
            className={styles.button}
            handleClick={() => router.push('/tournaments/history')}
            disabled={entryLoading}
          />
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-evenly"
          className={styles.entry}
        >
          <DashboardButton
            className={styles.button}
            handleClick={() => router.push('/tournaments/history')}
            disabled={entryLoading}
          />
        </Box>
      )
    }
    return isLg ? (
      <Box
        minWidth='180px'
      >
        <EntryButton
          className={styles.button}
          entryStatus={entryStatus}
          isLoading={entryLoading}
          handleEntry={handleEntry}
          handleEntryExit={handleEntryExit}
          handleCheckin={handleCheckin}
          isAuthenticated={authState === 'Authenticated'}
          userId={myId}
          tournamentId={tournamentHeader.id}
          checkinDue={checkinDue}
          isAfterCheckinEndsAt={isAfterCheckinEndsAt}
        />
      </Box>
    ) : (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-evenly"
        className={styles.entry}
      >
        <EntryButton
          className={styles.button}
          entryStatus={entryStatus}
          isLoading={entryLoading}
          handleEntry={handleEntry}
          handleEntryExit={handleEntryExit}
          handleCheckin={handleCheckin}
          isAuthenticated={authState === 'Authenticated'}
          userId={myId}
          tournamentId={tournamentHeader.id}
          checkinDue={checkinDue}
          isAfterCheckinEndsAt={isAfterCheckinEndsAt}
        />
      </Box>
    )
  }

  const StartDate = () => {
    if (tournamentHeader.status === TournamentStatus.Finish) {
      return (
        <Typography variant="body2" color="textSecondary">
          この大会は終了しました
        </Typography>
      )
    }
    if (tournamentHeader.status === TournamentStatus.Cancel) {
      return (
        <Typography variant="body2" color="textSecondary">
          この大会は中止しました
        </Typography>
      )
    }
    return (
      <Typography variant="body2" color="textSecondary">
        {format(parseISO(tournamentHeader.willStartAt), 'yyyy/MM/dd(eeeee) HH:mm~', {locale: ja})}
      </Typography>
    )
  }

  const modalBody = (
    <div className={modalStyle.root}>
      <div className={styles.modalHeader}>
        <button type={'button'} className={styles.modalCloseBtn} onClick={handleClose}><img src='/images/close.svg' /></button>
      </div>
      <div className={styles.modalBody}>
        <img src='/images/flag.svg' className={styles.modalIcon} />
        <Typography color='textPrimary' variant='h3' className={styles.modalTitle}>
          さぁバトルを始めましょう
        </Typography>
        <Typography color='textPrimary' variant='body2' className={styles.modalText}>
          大会開始時刻になりました！<br />トーナメント表から自分の試合画面を<br />開いてバトルを進めていきましょう！
        </Typography>
        <Button className={styles.loginButton} onClick={handleLogin}>ログイン</Button>
        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center'>
          <Link href="https://app.adictor.jp/help/participate_totheday" target='_blank' rel='noopener noreferrer'>
            <Typography color='textSecondary' variant='caption'>
              大会進行の流れ
            </Typography>
            <HelpOutlineIcon className={styles.help}/>
          </Link>
        </Box>
      </div>
    </div>
  )

  const verifiedBadge = tournamentHeader.organizer.verifiedOrganizerLevel > 1 ? <VerifiedBadge /> : null

  return (
    <Box className={styles.tournamentHeaderWrapper}>
      {mutationEntryAddLoading || mutationEntryRemoveLoading && <MutationLoading/>}
      <Box display={isLg ? 'flex': 'block'} justifyContent={isLg ? 'center': 'flex-start'} width="100%" maxWidth={800} maxHeight={400} mx="auto">
        <img
          className={styles.gameImage}
          src={tournamentHeader.coverUrl}
          alt="Game Image"
        />
      </Box>
      <Box className={styles.tournamentContentWrapper}>
        <Box
          display="flex"
          flexDirection="column"
          mx="auto"
          maxWidth={800}
          className={styles.tournamentContentMargin}
        >
          <StartDate/>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="auto"
          >
            <Typography variant="h1" color="textPrimary" style={{fontSize: isLg ? 32 : 22}}>
              {tournamentHeader.title}
            </Typography>
            {
              isLg ?
                <Box
                  minWidth="180px"
                >
                  {/*
                <Typography variant="caption" color="textSecondary">
                  締切まで 07:13:29
                </Typography>
                */}
                  <ActionButton/>
                </Box> : <></>
            }
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent={isLg ? 'space-between': 'flex-start'}
          flexDirection={isLg ? 'row': 'column'}
          alignItems={isLg ? 'flex-start' : 'center'}
          mx="auto"
          mt={isLg ? 8 : 0}
          maxWidth={isLg ? 800 : 400}
        >
          <Card className={styles.cardTop} variant="outlined">
            <CardContent>
              <Box display="flex" justifyContent="center" width={'100%'}>
                <Grid container spacing={1}>
                  {(tournamentHeader.openTournamentType && isAfterCheckinStartsAt) &&
                    <Grid item xs={openCheckinGrid} className={styles.cardGrid}>
                      <Typography
                        className={styles.cardText}
                        color="textSecondary"
                      >
                      チェックイン数
                      </Typography>
                    </Grid>
                  }
                  <Grid item xs={openCheckinGrid} className={styles.cardGrid}>
                    <Typography
                      className={styles.cardText}
                      color="textSecondary"
                    >
                      現在の参加数
                    </Typography>
                  </Grid>
                  <Grid item xs={openCheckinGrid} className={styles.cardGrid}>
                    <Typography
                      className={styles.cardText}
                      color="textSecondary"
                    >
                      参加上限数
                    </Typography>
                  </Grid>
                  {
                    (tournamentHeader.openTournamentType && isAfterCheckinStartsAt) &&
                    <Grid item xs={openCheckinGrid} className={styles.cardGrid}>
                      <Typography variant="h4" color="textPrimary">
                        {`${tournamentHeader.checkinNum}人`}
                      </Typography>
                    </Grid>
                  }
                  <Grid item xs={openCheckinGrid} className={styles.cardGrid}>
                    <Typography variant="h4" color="textPrimary">
                      {tournamentHeader.openTournamentType ? `${tournamentHeader.entryNum}人`:`${tournamentHeader.entryNum}${unitTypeSuffix(tournamentHeader.unitType)}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={openCheckinGrid} className={styles.cardGrid}>
                    <Typography variant="h4" color="textPrimary">
                      {tournamentHeader.openTournamentType ? `${tournamentHeader.maxEntry * getMemberCountByUnit(tournamentHeader.unitType)}人` : `${tournamentHeader.maxEntry}${unitTypeSuffix(tournamentHeader.unitType)}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
          <Link className={styles.organizerBox} href='/users/[id]/profile' as={`/users/${tournamentHeader.organizer.id}/profile`}>
            <Card className={styles.cardBottom} variant="outlined">
              <CardContent>
                <Box display="flex" alignItems="center">
                  <VerifiedAvatar
                    alt={tournamentHeader.organizer.profile.displayName}
                    src={tournamentHeader.organizer.profile.thumbnailUrl}
                    verifiedOrganizerLevel={tournamentHeader.organizer.verifiedOrganizerLevel}
                  />
                  <Box display="flex" flexDirection="column" ml={2}>
                    <Typography
                      className={styles.cardUserName}
                      color="textPrimary"
                    >
                      {isLg?truncateFullWidth(
                        tournamentHeader.organizer.profile.displayName,
                        18 - (verifiedBadge ? 1 : 0) - (tournamentHeader.sponsorCampaign ? 9 : 0)
                      ):truncateFullWidth(tournamentHeader.organizer.profile.displayName, verifiedBadge ? 14 : 16)} {verifiedBadge}
                    </Typography>
                    <Typography className={styles.cardText} color="textPrimary">
                      @{tournamentHeader.organizer.profile.twitterId}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Link>
          {tournamentHeader.sponsorCampaign &&
          <Link
            href={tournamentHeader.sponsorCampaign.landingUrl}
            rel='noopener'
            target='_blank'
            className={styles.organizerBox}
          >
            <Card className={styles.cardBottom} variant="outlined">
              <CardContent style={{lineHeight: 1, width: '100%'}}>
                <Avatar
                  src={tournamentHeader.sponsorCampaign.sponsor.iconUrl}
                  alt={tournamentHeader.sponsorCampaign.sponsor.name}
                  style={{float: 'right'}}
                />
                <Typography variant='caption' color='textSecondary' style={{fontWeight: 'normal'}}>
                Sponsored by
                </Typography>
                <Typography variant='subtitle1'>{tournamentHeader.sponsorCampaign.sponsor.name}</Typography>
              </CardContent>
            </Card>
          </Link>
          }
        </Box>
        {isLg ?
          <></>:
          <ActionButton />
        }
        {/*<Typography variant="caption" color="textSecondary">*/}
        {/*  締切まで 07:13:29*/}
        {/*</Typography>*/}
        {tournamentHeader.status === TournamentStatus.InProgress &&
          <OperationDiscordLink/>
        }
        {isEnd &&
          <Box className={styles.winnersBox}>
            <Box mt={10} maxWidth={800} mx="auto">
              <TournamentResult tournamentHeader={tournamentHeader}/>
            </Box>
          </Box>
        }
      </Box>
      {!QueryMeLoading && !meData && tournamentHeader.status === TournamentStatus.InProgress &&
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {modalBody}
        </Modal>
      }
      <TeamEntryModal
        open={teamEntryOpen}
        mode={'new'}
        onClose={() => setTeamEntryOpen(false)}
        tournamentId={tournamentHeader.id}
        gameId={tournamentHeader.gameId}
        unitType={tournamentHeader.unitType}
      />
      <OpenEntryModal
        open={openEntryOpen}
        onClose={() => setOpenEntryOpen(false)}
        meData={meData}
        tournament={tournamentHeader}
      />
      <OpenCheckinModal
        open={openCheckinOpen}
        onClose={() => setOpenCheckinOpen(false)}
        tournament={tournamentHeader}
      />
    </Box>
  )
}

const useDiscordLinkStyle = makeStyles(({breakpoints}) =>
  createStyles({
    wrapper: {
      [breakpoints.up('lg')]: {
        padding: 'initial',
      },
    },
    discordLink: {
      display: 'block',
      maxWidth: '800px',
      background: '#1E1E1E',
      borderRadius: '4px',
      padding: '17px 24px',
      justifyContent: 'space-between',
      alignItems: 'center',
      [breakpoints.up('lg')]: {
        margin: '32px auto 6px',
        display: 'flex',
      },
    },
    discordLinkDescription: {
      fontSize: '14px',
      marginBottom: '16px',
      [breakpoints.up('lg')]: {
        margin: 'initial',
      },
    },
    link: {
      textDecoration: 'none',
    },
    discordLinkButtonJoin: {
      border: '1px solid #55C341',
      background: 'none',
      color: '#55C341',
      fontSize: '14px',
      fontWeight: 'bold',
      width: '100%',
      [breakpoints.up('lg')]: {
        width: '155px',
      },
    },
  })
)

const OperationDiscordLink: React.FC = () => {
  const styles = useDiscordLinkStyle()

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.discordLink}>
        <Box className={styles.discordLinkDescription}>
          <Typography>大会中に困ったことが起きたらAdictor運営に相談してみましょう！</Typography>
        </Box>
        <a href='https://discord.gg/CeEJzqh' target='_blank' rel='noreferrer' className={styles.link}>
          <Button className={styles.discordLinkButtonJoin}>運営Discordに参加</Button>
        </a>
      </Box>
    </Box>
  )
}
