import React from 'react'

import {
  Box,
  BoxProps,
} from '@material-ui/core'

type CenterProps = BoxProps & {
  vertical?: boolean
  horizontal?: boolean
}

export const Center: React.FC<CenterProps> = ({vertical=false, horizontal=true, ...props}) => {

  return (
    <Box style={{display: 'flex', justifyContent: horizontal ? 'center' : 'flex-start', alignItems: vertical ? 'center' : 'flex-start'}} {...props} />
  )

}
