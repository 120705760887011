import {Box, IconButton, Link, Typography, makeStyles} from '@material-ui/core'
import {ChevronLeft, ChevronRight} from '@material-ui/icons'
import {composeClassNames} from '@/utils'
import {useEffect, useState} from 'react'

type ImageData = {
  url: string
  value: any
}

export type ImageSelectProps = {
  name: string
  inputRef: any
  defaultValue?: any
  imageData: ImageData[]
}

const ImageSelectStyle = makeStyles(({palette}) => ({
  link: {
    color: palette.text.secondary,
    textDecoration: 'underline',
  },
  cover: {
    width: '100%',
    maxHeight: 200,
    maxWidth: 400,
    display: 'block',
  },
  hidden: {
    display: 'none',
  },
  button: {
    height: 40,
    width: 40,
    borderColor: palette.divider,
    borderWidth: 1,
    borderStyle: 'solid',
  },
  wrapper: {
    paddingTop: 15,
  },
  selector: {
    margin: 'auto',
    textAlign: 'center',
    '& button:hover': {
      backgroundColor: '#2C2C2C',
    },
    '& :not(:first-child)': {
      marginLeft: 50,
    },
  },
}))

const ImageSelect = ({name, inputRef, defaultValue, imageData}: ImageSelectProps) => {
  const styles = ImageSelectStyle()
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  useEffect(() => {
    if (defaultValue){
      setSelectedIndex(Math.max(imageData.findIndex(elem => Number(elem.value) === defaultValue), 0))
    }
  }, [defaultValue, imageData])

  if (imageData.length === 0) {
    return <></>
  }

  if (imageData.length <= selectedIndex) {
    setSelectedIndex(0)
    return <></>
  }

  return (
    <>
      <input type="hidden" name={name} ref={inputRef} value={imageData[selectedIndex].value} />
      <Typography color="textSecondary">
        オリジナルの大会サムネイル設定は
        <Link href='https://special.adictor.jp/Authorized_organizer' className={styles.link} target='_blank' rel='noopener'>認定主催者</Link>
        のみ可能です。<br />
        非認定主催者の方は、テンプレート画像の中から大会サムネイルを設定してください。
      </Typography>
      <Box className={styles.wrapper}>
        <Box width='100%' maxWidth={400} className={styles.wrapper}>
          <Box width='100%' maxWidth={400} maxHeight={200}>
            {
              // イメージのpre-renderのため、
              // 全部イメージを入れて、display:noneで調整する
              imageData.map(
                ({value, url}, index) => {
                  return (
                    <img
                      src={url}
                      key={value}
                      className={index === selectedIndex ? styles.cover : styles.hidden}
                    />
                  )
                }
              )
            }
          </Box>
          <Box width={150} className={composeClassNames(styles.selector, styles.wrapper)}>
            <IconButton
              disabled={selectedIndex === 0}
              className={styles.button}
              onClick={() => setSelectedIndex(i => --i)}
            >
              <ChevronLeft />
            </IconButton>
            <IconButton
              disabled={selectedIndex === imageData.length - 1}
              className={styles.button}
              onClick={() => setSelectedIndex(i => ++i)}
            >
              <ChevronRight />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default ImageSelect
