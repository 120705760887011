// 全角N文字分で文字列切り取り
export const truncateFullWidth = (str: string, len: number): string => {
  let fullWidthLength = 0
  let newString = ''

  for (let i = 0; i < str.length; i++) {
    if (fullWidthLength > len - 0.5) {
      newString += '...'
      break
    }

    newString += str[i]

    if (str[i].match(/[ -~]/)) {
      fullWidthLength += 0.5
    } else {
      fullWidthLength += 1
    }
  }

  return newString
}
