import React from 'react'
import ReactLinkify from 'react-linkify'
import {Link} from '@/components'
import {createStyles, makeStyles} from '@material-ui/core'

const useLinkifyStyles = makeStyles(({palette}) => createStyles({
  root: {
    color: palette.primary.main,
  },
}))


export const Linkify: React.FC = ({children}) => {
  const styles = useLinkifyStyles()

  const componentDecorator = (decoratedHref: string, decoratedText: string) => {
    return (
      <Link className={styles.root} href={decoratedHref} target='_blank'>{decoratedText}</Link>
    )
  }

  return (
    <ReactLinkify
      componentDecorator={componentDecorator}
    >
      {children}
    </ReactLinkify>
  )
}
