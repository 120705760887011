import React from 'react'
import {Card, CardContent, Typography, createStyles, makeStyles} from '@material-ui/core'

const useEmptyMessageStyles = makeStyles(() => createStyles({
  content: {
    padding: '24px',
    '&:last-child': {
      paddingBottom: '24px',
    },
  },
}))

const MessageEmptyState = ({...props}) => {
  const styles = useEmptyMessageStyles()
  const breakedText =props.message.split('¥n').map((line:string,
    key:number) => <span key={key}>{line}<br /></span>)
  return (
    <Card>
      <CardContent className={styles.content}>
        <Typography>{breakedText}</Typography>
      </CardContent>
    </Card>
  )
}

export default MessageEmptyState
