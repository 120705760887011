import {Box, Button, Paper, createStyles, makeStyles} from '@material-ui/core'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import {UnitJudgeListItem} from '@/components/molecules'
import {TournamentAwardType, TournamentCustomUpdateUnitInput} from '@/graphql/client'
import {useIsLg} from '@/hooks'
import {Typography} from '@material-ui/core/'
import {CustomTournamentUnitFragment} from '@/types/tournament'
import {getTournamentUnitUnitId} from '@/utils'


const useConfirmStyle = makeStyles(() =>
  createStyles({
    modal: {
      maxWidth: 444,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#1e1e1e',
      borderRadius: 4,
    },
    modalWrap: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'start',
      marginTop: 40,
    },
    spModalWrap: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'start',
      marginTop: 24,
      padding: 16,
    },
    modalTitle: {
      height: 64,
      padding: 16,
      fontSize: 18,
      opacity: 0.9,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: '1px solid rgba(255, 255, 255, 0.12)',
    },
    modalContent: {
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'scroll',
      maxHeight: '80vh',
    },
    caption: {
      marginTop: 4,
      marginBottom: 24,
    },
    userJudgeListItem: {
      marginBottom: 8,
    },
    buttonWrap: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 30,
    },
    head: {
      fontWeight: 'bold',
      opacity: 0.6,
      marginBottom: 8,
    },
    modalCloseBtn: {
      border: '0 none',
      margin: '0',
      padding: '0',
      background: 'none',
      outline: 'none',
      cursor: 'pointer',
      color: '#fff',
    },
    content: {
      padding: '24px',
      '&:last-child': {
        paddingBottom: '24px',
      },
    },
    confirmationHead: {
      fontSize: 18,
      fontWeight: 700,
      marginBottom: 12,
    },
    button: {
      marginTop: 20,
    },
  })
)

type CustomTournamentConfirmProps = {
  units: CustomTournamentUnitFragment[]
  updates: TournamentCustomUpdateUnitInput[]
  onSubmit: () => void
  onCancel: () => void
}

export const CustomTournamentConfirm: React.FC<CustomTournamentConfirmProps> = ({
  units,
  updates,
  onSubmit,
  onCancel,
}) => {
  const isLg = useIsLg()
  const styles = useConfirmStyle()

  const getUnitsForAwardType = (awardType: TournamentAwardType): CustomTournamentUnitFragment[] => {
    const unitIds = updates.filter(it => it.awardType === awardType).map(it => it.unitId)
    return units.filter(it => unitIds.includes(getTournamentUnitUnitId(it)))
  }

  const listItemsForAwardType = (award: TournamentAwardType) =>
    getUnitsForAwardType(award).map((it, idx) =>
      (
        <UnitJudgeListItem
          key={idx}
          unit={it}
          awardType={award}
          mode={'show'}
        />
      )
    )

  return (
    <div className={isLg ? styles.modalWrap : styles.spModalWrap}>
      <div className={styles.modal}>
        <Box className={styles.modalTitle}>大会結果の確定
          <button type={'button'} className={styles.modalCloseBtn} onClick={onCancel}><CloseIcon/></button>
        </Box>
        <Box className={styles.modalContent}>
          <Typography className={styles.caption} variant='body1'>
            下記の戦績で大会結果を確定します。
            よろしければ確定ボタンを押してください。
          </Typography>

          <Paper>
            <Box className={styles.head}>上位入賞者</Box>
            {listItemsForAwardType(TournamentAwardType.First)}
            {listItemsForAwardType(TournamentAwardType.Second)}
            {listItemsForAwardType(TournamentAwardType.Third)}

            <Box className={styles.head}>入賞者</Box>
            {[TournamentAwardType.Fourth, TournamentAwardType.Fifth, TournamentAwardType.Sixth, TournamentAwardType.Seventh,
              TournamentAwardType.Eighth, TournamentAwardType.Ninth, TournamentAwardType.Tenth].map(it => {
              return listItemsForAwardType(it)
            })}

            {getUnitsForAwardType(TournamentAwardType.Eliminated)[0] &&
              <>
                <Box mt={4} className={styles.head}>失格者</Box>
                {listItemsForAwardType(TournamentAwardType.Eliminated)}
              </>
            }
          </Paper>

          <Box className={styles.buttonWrap}>
            <Box mr={4}>
              <Button variant="outlined" color="primary" onClick={onCancel}>キャンセル</Button>
            </Box>
            <Button variant="contained" color="primary" onClick={onSubmit}>確定する</Button>
          </Box>
        </Box>
      </div>
    </div>
  )
}
