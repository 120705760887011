import React from 'react'
import {Box, CardActionArea, FormControl, MenuItem, Select, Typography} from '@material-ui/core/'
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles'
import {Avatar, Card, SquareAvatar} from '@/components/atoms'
import {TournamentAwardType} from '@/graphql/client'
import {CustomTournamentUnitFragment, isCustomTournamentPlayerFragment} from '@/types/tournament'
import {
  getTournamentAwardTypeLabel,
  getTournamentNodeUnitThumbnailUrl,
  getTournamentUnitDisplayName,
  getTournamentUnitUnitId,
} from '@/utils'

const useUnitJudgeListItemStyle = makeStyles<Theme>(({spacing, breakpoints}) => createStyles({
  root: {
    position: 'relative',
    overflow: 'hidden',
    marginBottom: spacing(2),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: spacing(4),
    height: 80,
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    width: '48px',
    height: '48px',
    marginRight: spacing(3),
  },
  switchButton: {
    marginLeft: spacing(2),
    padding: spacing(1),
    border: 'solid 1px',
    [breakpoints.up('lg')]: {
      padding: spacing(2),
    },
  },
  userSwitchContainer: {
    position: 'absolute',
    top: 50,
    left: 100,
    [breakpoints.up('lg')]: {
      position: 'initial',
    },
  },
  roundButton: {
    height: 44,
    maxWidth: 44,
    minWidth: 44,
    borderRadius: 22,
    padding: 0,
    marginLeft: 8,
    border: '1px solid #fff',
    opacity: '0.38',
    '&:hover': {
      border: '1px solid #55C341',
      color: '#55C341',
      opacity: 1,
    },
  },
  primaryRoundButton: {
    height: 44,
    maxWidth: 44,
    minWidth: 44,
    borderRadius: 22,
    padding: 0,
    marginLeft: 8,
    border: '1px solid #55C341',
    color: '#55C341',
  },
  eliminationButton: {
    height: 44,
    maxWidth: 44,
    minWidth: 44,
    borderRadius: 22,
    padding: 0,
    marginLeft: 8,
    border: '1px solid #fff',
    opacity: '0.38',
    '&:hover': {
      border: '1px solid #F44336',
      color: '#F44336',
      opacity: 1,
    },
  },
  eliminationPrimaryButton: {
    height: 44,
    maxWidth: 44,
    minWidth: 44,
    borderRadius: 22,
    padding: 0,
    marginLeft: 8,
    border: '1px solid #F44336',
    color: '#F44336',
  },
  rankSelectForm: {
    width: 88,
    opacity: 0.6,
  },
}))

const useSelectBoxStyle = makeStyles(() => ({
  root: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 14px',
  },
  icon: {
    fill: 'rgba(255, 255, 255, 0.9)',
  },
}))

export const shortAwardTypes = [
  TournamentAwardType.First, TournamentAwardType.Second, TournamentAwardType.Third, TournamentAwardType.Eliminated,
]

const fullAwardTypes = [
  TournamentAwardType.First, TournamentAwardType.Second, TournamentAwardType.Third,
  TournamentAwardType.Fourth, TournamentAwardType.Fifth, TournamentAwardType.Sixth,
  TournamentAwardType.Seventh, TournamentAwardType.Eighth, TournamentAwardType.Ninth,
  TournamentAwardType.Tenth, TournamentAwardType.Eliminated,
]

type UnitJudgeListItemProps = {
  unit: CustomTournamentUnitFragment
  awardType: TournamentAwardType
  mode: 'edit' | 'show'
  rankRange?: 'short' | 'full'
  onClick?: (id: string, awardType: TournamentAwardType) => void
}

export const UnitJudgeListItem: React.FC<UnitJudgeListItemProps> = ({rankRange='long', ...props}) => {
  const styles = useUnitJudgeListItemStyle()
  const selectStyles = useSelectBoxStyle()

  const {unit, awardType, mode, onClick} = props

  const handleClick = (newAwardType: TournamentAwardType) => {
    if (newAwardType === awardType) {
      return
    }
    onClick?.(getTournamentUnitUnitId(unit), newAwardType)
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    handleClick(event.target.value as TournamentAwardType)
  }

  const getThumbnailUrl = getTournamentNodeUnitThumbnailUrl(unit)
  const displayName = getTournamentUnitDisplayName(unit)

  return (
    <Card className={styles.root}>
      <CardActionArea className={styles.container}>
        <Box className={styles.nameContainer}>
          {isCustomTournamentPlayerFragment(unit) ? (
            <Avatar className={styles.avatar} src={getThumbnailUrl}/>
          ) : (
            <SquareAvatar className={styles.avatar} src={getThumbnailUrl}/>
          )}
          <Box>
            <Typography variant='subtitle1'>{displayName}</Typography>
            {isCustomTournamentPlayerFragment(unit) && (
              <Typography variant='overline'>Lv.{unit.user.level.level}</Typography>
            )}
          </Box>
        </Box>
        <Box className={styles.judgeArea}>
          <FormControl className={styles.rankSelectForm} variant="outlined">
            <Select
              displayEmpty
              value={awardType}
              defaultValue=""
              inputProps={{classes: {root: selectStyles.root, icon: selectStyles.icon}}}
              disabled={mode === 'show'}
              onChange={handleChange}
            >
              <MenuItem value={TournamentAwardType.Participation}>順位</MenuItem>
              {
                (rankRange === 'short' ? shortAwardTypes : fullAwardTypes).map((it, index) => {
                  return <MenuItem key={index} selected={it === awardType} value={it}>{getTournamentAwardTypeLabel(it)}</MenuItem>
                })
              }
            </Select>
          </FormControl>
        </Box>
      </CardActionArea>
    </Card>
  )
}
