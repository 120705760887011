import {apiConfig, serverConfig} from '@/env'
import {useDevContext} from '@/hooks'
import {useClientStateContext} from '@/hooks/apollo/context'
import {setContext} from '@apollo/client/link/context'
import {useAuth0} from '@auth0/auth0-react'
import {formatError} from 'graphql'
import {useEffect, useMemo, useState} from 'react'
import {ApolloClient, ApolloLink, InMemoryCache, createHttpLink} from '@apollo/client'
import {relayStylePagination} from '@apollo/client/utilities'
import merge from 'deepmerge'
import fetch from 'node-fetch'
import {onError} from '@apollo/client/link/error'
import * as Sentry from '@sentry/node'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

function createInMemoryCache() {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          featuredTournaments: relayStylePagination(['gameId']),
          organizedTournaments: relayStylePagination(),
          enteredTournaments: relayStylePagination(),
          userRanking: relayStylePagination(['gameId']),
          chatMessage: relayStylePagination(['nodeId']),
          myCoinExchangeRequests: relayStylePagination(),
          tournamentsByTerm: relayStylePagination(['gameId']),
          tournamentResults: relayStylePagination(['userId']),
          compensations: relayStylePagination(['userId']),
          gifts: relayStylePagination(['userId']),
        },
      },
      User: {
        fields: {
          tournamentResults: relayStylePagination(['id']),
        },
      },
    },
  })
}

export function useApollo(pageProps: any): ApolloClient<unknown> {
  const state = pageProps ? pageProps[APOLLO_STATE_PROP_NAME] : {}

  const {isAuthenticated, getAccessTokenSilently, user} = useAuth0()
  const ctx = useDevContext()

  // setAuthStateをuseMemo内で直接呼べないため counter を利用する
  const [counter, setCounter] = useState(0)
  const {setAuthState} = useClientStateContext()

  useEffect(() => {
    setAuthState(isAuthenticated ? 'Authenticated' : 'Unauthenticated')
  }, [counter])

  const sentryLink = onError(({graphQLErrors, operation}) => {
    if (graphQLErrors) {
      graphQLErrors.map((err) => {
        const {message, path, extensions} = formatError(err)

        Sentry.withScope(scope => {
          scope.setTag('kind', operation.operationName)

          scope.setExtra('path', path)
          scope.setExtra('variables', operation.variables)
          scope.setExtra('code', extensions?.code)
          scope.setExtra('username', user?.name)

          Sentry.captureMessage(`[GraphQL] ${operation.operationName} - ${message}`)
        })
      })
    }
  })

  const httpLink = createHttpLink({
    uri: `${apiConfig.apiEndpoint}/graphql`,
  })

  const authLink = setContext(async (_, {headers}) => {
    const token = await getAccessTokenSilently()
    const context = {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }

    // NOTE: ユーザー切り替え機能
    if (!serverConfig.isPrd) {
      context.headers['X-USER-ID'] = ctx.userId
    }

    return context
  })

  const client = useMemo(() => {
    const links = []
    links.push(sentryLink)
    isAuthenticated && links.push(authLink)
    links.push(httpLink)

    // ClientStateを更新するためcounterをインクリメントする
    setCounter(counter + 1)

    const _client = new ApolloClient({
      link: ApolloLink.from(links),
      cache: createInMemoryCache(),
      ssrMode: typeof window === 'undefined',
    })

    if (state) {
      restoreCache(_client, state)
    }

    return _client
  }, [isAuthenticated, ctx.userId])

  return client
}

function restoreCache(client: ApolloClient<unknown>, initialState: any) {
  // Get existing cache, loaded during client side data fetching
  const existingCache = client.extract()

  // Merge the existing cache into data passed from getStaticProps/getServerSideProps
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const data = merge(initialState, existingCache)

  // Restore the cache with the merged data
  client.cache.restore(data)
}

export function addApolloState(client: ApolloClient<unknown>, pageProps: any) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export function createApolloClientForServerSide() {
  const httpLink = createHttpLink({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fetch: fetch,
    uri: `${apiConfig.apiEndpoint}/graphql`,
  })

  return new ApolloClient({
    link: httpLink,
    cache: createInMemoryCache(),
    ssrMode: true,
  })
}
