import React from 'react'

import {
  Link as MUILink,
} from '@material-ui/core'

type MuiLinkProps = React.ComponentProps<typeof MUILink>

export const MuiLink: React.FC<MuiLinkProps> = React.forwardRef(({...props}, ref) => {
  return (
    <MUILink ref={ref} color='textPrimary' underline='none' {...props} />
  )
})
