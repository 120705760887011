import * as React from 'react'
import {createContext, useContext} from 'react'
import {useLocalStorage} from '@/hooks/localstorage'

export type DevContextState = {
  userId: string
  setUserId: (isDark: string) => void
}

const initialState: DevContextState = {
  userId: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUserId: () => {},
}

export const DevContext = createContext<DevContextState>(initialState)

export const DevContextProvider: React.FC = ({children}) => {
  const [userId, setUserId] = useLocalStorage<string>('ADICTOR_DEBUG_USER_ID', '')

  return (
    <DevContext.Provider value={{userId, setUserId}}>{children}</DevContext.Provider>
  )
}

export const useDevContext = () => useContext<DevContextState>(DevContext)
