import React from 'react'
import {Box, Card, CardActionArea, Typography, createStyles, makeStyles} from '@material-ui/core'
import {ClanFragment} from '@/graphql/client'

const useClanListItemStyle = makeStyles(({palette}) =>
  createStyles({
    container: {
      display: 'flex',
      padding: '16px',
      width: '100%',
      height: '80px',
      background: '#232323',
    },
    clanLogo: {
      width: '48px',
      height: '48px',
      objectFit: 'cover',
      borderRadius: '4px',
    },
    nameContainer: {
      marginLeft: '12px',
      display: 'flex',
    },
    clanName: {
      fontSize: '15px',
    },
    level: {
      fontSize: '14px',
    },
    memberContainer: {
      width: 70,
      display: 'flex',
      flexDirection: 'column',
    },
    member: {
      color: palette.text.secondary,
      fontSize: '10px',
      alignSelf: 'flex-end',
    },
    number: {
      fontSize: '12px',
    },
    border: {
      border: '1px solid rgba(255, 255, 255, 0.12)',
      borderRadius: '4px',
    },
  })
)

type ClanListItemProps = {
  clan: ClanFragment
  outline?: boolean
}

export const ClanListItem: React.FC<ClanListItemProps> = ({clan, outline=false}) => {
  const styles = useClanListItemStyle()

  return (
    <Card className={outline ? styles.border : ''}>
      <CardActionArea>
        <Box className={styles.container}>
          <img src={clan.logoUrl} alt="clan logo" className={styles.clanLogo}/>
          <Box className={styles.nameContainer} flexGrow={1}>
            <Typography variant="subtitle2" className={styles.clanName}>{clan.name}</Typography>
          </Box>
          <Box className={styles.memberContainer} flexShrink={0} alignSelf={'flex-end'}>
            <Typography variant="caption" className={styles.member}>
              <span className={styles.number}>{clan.memberNum}</span>人が所属
            </Typography>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  )
}
