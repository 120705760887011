import React from 'react'
import {Checkbox, makeStyles} from '@material-ui/core'
import {Box, Typography} from '@material-ui/core/'
import {Button, ClanListItem, UserListItemGrid} from '@/components'
import {ClanDetailFragment, ClanFragment, UserEntryFragment} from '@/graphql/client'

const useConfirmContentStyles = makeStyles(({spacing}) => ({
  root: {
    width: '100%',
  },
  description: {
    marginBottom: spacing(7),
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    marginTop: spacing(10),
    marginBottom: spacing(12),
    width: 73,
    height: 44,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

type ConfirmContentProps = {
  gameId: string
  clan: ClanDetailFragment
  memberIds: string[]
  managerIds: string[]
  onNext: () => void
  onBack: () => void
}

export const ConfirmContent: React.FC<ConfirmContentProps> = (props) => {
  const styles = useConfirmContentStyles()

  const {gameId, clan, memberIds, managerIds, onNext, onBack} = props
  const [isAgreeTournament, setIsAgreeTournament] = React.useState<boolean>(false)
  const [isAgreeTerms, setIsAgreeTerms] = React.useState<boolean>(false)

  const clanMembers = [...clan.members, clan.owner]
  const clanManagers = clan.clanUserRoles.filter(it => it.role.name === 'clan:manager').map(it => it.user)

  const teamMembers = clanMembers.filter(it => memberIds.includes(it.id))
  const teamManagers = clanManagers.filter(it => managerIds.includes(it.id))

  return (
    <Box className={styles.root}>
      <Typography className={styles.description} variant={'body2'}>
          入力内容を確認した上でエントリーを確定してください
      </Typography>

      {/* クラン */}
      <Typography>クラン</Typography>
      <Box mb={6}>
        <ClanListItem outline clan={clan as ClanFragment}/>
      </Box>

      {/* メンバー */}
      {teamMembers && (
        <Box mb={6}>
          <Typography>チームメンバー</Typography>
          <UserListItemGrid outlined users={teamMembers} gameId={gameId}/>
        </Box>
      )}

      {/* マネージャー */}
      {teamManagers[0] && (
        <Box mb={8}>
          <Typography>マネージャー</Typography>
          <UserListItemGrid outlined users={teamManagers as UserEntryFragment[]} gameId={gameId}/>
        </Box>
      )}

      {/* フォーム */}
      <Box className={styles.form} onClick={() => setIsAgreeTournament(!isAgreeTournament)}>
        <Checkbox checked={isAgreeTournament}/>
        <Typography>大会詳細に同意する</Typography>
      </Box>
      <Box className={styles.form} onClick={() => setIsAgreeTerms(!isAgreeTerms)}>
        <Checkbox checked={isAgreeTerms}/>
        <Typography>規約に同意する</Typography>
      </Box>

      {/* ボタン */}
      <Box className={styles.buttons}>
        <Button outlined className={styles.button} onClick={onBack}>前へ</Button>
        <Box width={16}/>
        <Button
          className={styles.button}
          onClick={onNext}
          disabled={!(isAgreeTournament && isAgreeTerms)}
        >
          次へ
        </Button>
      </Box>
    </Box>
  )
}
