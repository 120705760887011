import React from 'react'

import {
  AppBar as MuiAppBar,
  makeStyles,
} from '@material-ui/core'

import {
  composeClassNames,
} from '@/utils'

export const appBarHeight = 70

const useAppBarStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.contentBackground,
    height: appBarHeight,
  },
}))

type AppBarProps = React.ComponentProps<typeof MuiAppBar>

export const AppBar: React.FC<AppBarProps> = ({className, ...props}) => {

  const styles = useAppBarStyles()

  return (
    <MuiAppBar className={composeClassNames(styles.root, className)} {...props} />
  )

}
