import React from 'react'

import {
  Container as MuiContainer,
  ContainerProps as MuiContainerProps,
} from '@material-ui/core'

import {
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles'

import {
  composeClassNames,
} from '@/utils'

type ContainerProps = MuiContainerProps

const Container: React.FC<ContainerProps> = ({...props}) => {
  return (
    <MuiContainer maxWidth={false} {...props} />
  )
}

const usePaddingContainerStyles = makeStyles<Theme, {padding: number}>(() => createStyles({
  root: ({padding}) => ({
    paddingLeft: padding,
    paddingRight: padding,
  }),
}))

export type PaddingContainerProps = ContainerProps & {
  padding?: number
}

export const PaddingContainer: React.FC<PaddingContainerProps> = ({className, padding=54, ...props}) => {
  const styles = usePaddingContainerStyles({padding})
  return (
    <Container className={composeClassNames(styles.root, className)} {...props} />
  )
}

export type MaxWidthPaddingContainerProps = Omit<PaddingContainerProps, 'maxWidth'> & {
  maxWidth?: number
}

const useMaxWidthPaddingContainerStyles = makeStyles<Theme, {maxWidth: number}>(()=> createStyles({
  root: ({maxWidth}) => ({
    maxWidth,
  }),
}))

export const MaxWidthPaddingContainer: React.FC<MaxWidthPaddingContainerProps> = ({className, maxWidth=1080+16*2, ...props}) => {
  const styles = useMaxWidthPaddingContainerStyles({maxWidth})
  return (
    <PaddingContainer className={composeClassNames(styles.root, className)} {...props} />
  )
}
