import React, {useCallback} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Title} from '@/components'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import {Controller, useForm} from 'react-hook-form'
import {composeClassNames} from '@/utils'
import {DropEvent, FileRejection, useDropzone} from 'react-dropzone'
import useAxios from 'axios-hooks'
import {
  ClanAddInput,
  ClanUpdateInput,
  useClanAddMutation,
  useClanUpdateMutation,
} from '@/graphql/client'
import {useRouter} from 'next/router'
import {useSnackbar} from '@/hooks/acknowledge'
import {useDevContext} from '@/hooks'

const useSelectBoxStyle = makeStyles(() => ({
  root: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 14px',
  },
  icon: {
    fill: 'rgba(255, 255, 255, 0.9)',
  },
}))

const useLayout = makeStyles(theme => ({
  root: {
    margin: '0 -16px 0 -16px',
    [theme.breakpoints.up('lg')]: {
      margin: 0,
    },
  },
  formRoot: {
    padding: '24px 16px',
    [theme.breakpoints.up('lg')]: {
      padding: '40px 32px',
      width: '800px',
    },
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
  },
  head: {
    marginBottom: 8,
  },
  inputContent: {
    marginBottom: 32,
  },
  uploadBtn: {
    display: 'none',
  },
  imageUploadWrapper: {
    display: 'inline-block',
    height: '100%',
  },
  sponserImageUploadWrapper: {
    display: 'inline-block',
    width: '300px',
    height: '100%',
  },
  dropzone: {
    width: '100%',
    height: '100%',
    border: 'none',
    background: 'none',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems:'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  iconDropzone: {
    width: '100%',
    height: '100px',
    border: 'none',
    background: 'none',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems:'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dropzoneWrap: {
    width: '100%',
    height: '100%',
    maxWidth: '400px',
  },
  iconDropzoneWrap: {
    width: '100%',
    height: '100px',
    maxWidth: '100px',
  },
  isActive: {
    opacity: 0.2,
  },
  uploadNotice: {
    marginLeft: '14px',
    marginTop: '4px',
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: '0.857rem',
    fontWeight: 'bold',
    lineHeight: 1.5,
  },
  notice: {
    marginRight: '10px',
  },
  camera: {
    position: 'absolute',
  },
  updateable: {
    opacity: 0.5,
  },
  tournamentImagePick: {
    maxWidth: 400,
    width: '100%',
    height: 200,
    objectFit: 'cover',
    borderRadius: '4px',
  },
  iconImagePick: {
    maxWidth: 100,
    width: '100%',
    height: 100,
    objectFit: 'cover',
    borderRadius: '4px',
  },
  iconCamera: {
    width: '100px',
  },
  round: {
    borderRadius: '50%',
  },
  select: {
    width: '140px',
  },
  sponserWrap: {
    position: 'relative',
    width: '300px',
    height: '100px',
    background: '#232323',
    textAlign: 'center',
    borderRadius: '4px',
  },
  sponserContents: {
    position: 'absolute',
    top: '20px',
    width: '100%',
  },
  sponserCameraIcon: {
    display: 'block',
    margin: '0 auto',
    width: '32px',
  },
  sponserText: {
    marginTop: '8px',
    fontSize: '15px',
    color: 'rgba(255, 255, 255, 0.38)',
  },
  textInput: {
    width: '100%',
    height: '40px',
  },
}))

type ClanLogoUploadResult = {
  url: string
}

type ClanCoverUploadResult = {
  url: string
}

type ClanFormLayoutProps = {
  mode: 'new' | 'edit'
  editData?: ClanEditData
}

export type ClanEditData = {
  id: string
  name: string
  description: string
  maxMember: number
  logoUrl: string
  coverUrl: string
}

export const ClanFormLayout: React.FC<ClanFormLayoutProps> = ({mode, editData}) => {
  const styles = useLayout()
  const selectStyles = useSelectBoxStyle()
  const {snackbar} = useSnackbar()
  const router = useRouter()
  const {userId: devUserId} = useDevContext()

  const {register, handleSubmit,  errors, control, formState} = useForm<ClanAddInput>({mode: 'onChange'})
  const [imageType, setImageType] = React.useState<string | undefined>('')
  const [coverUrl, setCoverUrl] = React.useState('')
  const [logoUrl, setLogoUrl] = React.useState('')
  const [isCoverImageDragActive, setIsCoverImageDragActive] = React.useState(false)
  const [isIconDragActive, setIsIconDragActive] = React.useState(false)
  const [submitDisabled, setIsSubmitDisabled] = React.useState<boolean>(false)

  const [clanAdd] = useClanAddMutation()
  const [clanUpdate] = useClanUpdateMutation()

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
    if (fileRejections[0]) {
      const code = fileRejections[0].errors[0].code
      if (code === 'file-too-large') {
        snackbar.failed('アップロードできる画像容量は最大2MBです')
      }
      if (code === 'file-invalid-type') {
        snackbar.failed('アップロードできるファイル形式はpng / jpgです')
      }
    }
    const uploadImage = acceptedFiles[0]
    if (uploadImage) {
      const type = (event.target as HTMLInputElement)?.dataset.type
      setImageType(type)
      const formData = new FormData()
      formData.append('image', uploadImage)
      try {
        if (type === 'coverImage') {
          executeCoverPost({
            data: formData,
          })
        } else if (type === 'icon') {
          executeThumnailPost({
            data: formData,
          })
        }
      } catch (e) {
        snackbar.failed('画像アップロードに失敗しました', e)
        // errorReport.captureException(e)
      }
    }
  }, [])

  const onDragOver = useCallback((event: React.DragEvent<HTMLElement>) => {
    const type = (event.target as HTMLInputElement)?.dataset.type
    if (type === 'coverImage') {
      setIsCoverImageDragActive(true)
    }
    if (type === 'icon') {
      setIsIconDragActive(true)
    }
  }, [])

  const onDragLeave = useCallback((event: React.DragEvent<HTMLElement>) => {
    const type = (event.target as HTMLInputElement)?.dataset.type
    if (type === 'coverImage') {
      setIsCoverImageDragActive(false)
    }
    if (type === 'icon') {
      setIsIconDragActive(false)
    }
  }, [])

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    maxSize: 2097152,
    onDragOver,
    onDragLeave,
  })

  const [
    {data: postCoverData, loading: postCoverLoading},
    executeCoverPost,
  ] = useAxios<ClanCoverUploadResult>(
    {
      url: '/assets/clan_cover',
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-USER-ID': devUserId,
      },
    },
    {manual: true}
  )

  const [
    {data: postLogoData, loading: postLogoLoading},
    executeThumnailPost,
  ] = useAxios<ClanLogoUploadResult>(
    {
      url: '/assets/clan_logo',
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-USER-ID': devUserId,
      },
    },
    {manual: true}
  )

  const onSubmit = async (value: any) => {
    setIsSubmitDisabled(true)
    if (mode === 'new') {
      await onCreate(value)
    } else {
      await onUpdate(value)
    }
    setIsSubmitDisabled(false)
  }

  const onCreate = async (value: any) => {
    const input = {
      ...value,
    } as ClanAddInput

    if (logoUrl !== '') {
      input.logoTmpUrl = logoUrl
    }

    if (coverUrl !== '') {
      input.coverTmpUrl = coverUrl
    }

    try {
      const res = await clanAdd({variables: {input}})
      await router.push(`/clan/${res.data!.clanAdd!.id}/detail`)
      snackbar.success('クランの作成に成功しました')
    } catch (e) {
      snackbar.failed('クランの作成に失敗しました', e)
    }
  }

  const onUpdate = async (value: any) => {
    const input = {
      clanId: editData?.id,
      ...value,
    } as ClanUpdateInput

    if (logoUrl !== '') {
      input.logoTmpUrl = logoUrl
    }

    if (coverUrl !== '') {
      input.coverTmpUrl = coverUrl
    }

    try {
      await clanUpdate({variables: {input}})
      await router.push(`/clan/${editData!.id}/detail`)
      snackbar.success('クランの更新に成功しました')
    } catch (e) {
      snackbar.failed('クランの更新に失敗しました', e)
    }
  }

  React.useEffect(() => {
    if (imageType === 'coverImage' && postCoverData?.url) {
      setCoverUrl(postCoverData.url)
    }
    if (imageType === 'icon' && postLogoData?.url) {
      setLogoUrl(postLogoData.url)
    }
  }, [postCoverData?.url, postLogoData?.url])

  return (
    <>
      {mode === 'new' ?
        <Title variant="h1">新規クランの作成</Title>:
        <Title variant="h1">クラン情報の編集</Title>
      }
      <Box className={styles.root}>
        <Card className={styles.formRoot}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Typography className={styles.head} variant="subtitle1" color="textPrimary">クラン名</Typography>
              <TextField
                className={styles.inputContent}
                inputRef={register({required: true, minLength: 1, maxLength: 100})}
                error={Boolean(errors.name)}
                name="name"
                placeholder="クラン名を入力"
                helperText="最大100文字です"
                fullWidth
                inputProps={{style: {height: '40px', padding: '0 14px'}}}
                defaultValue={editData?.name}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              className={styles.inputContent}
            >
              <Typography className={styles.head} variant="subtitle1" color="textPrimary">カバー画像</Typography>
              <Box>
                <FormControl variant="outlined" className={styles.dropzoneWrap}>
                  <label className={styles.imageUploadWrapper}>
                    <Controller
                      name="coverTmpUrl"
                      control={control}
                      render={() =>
                        <Paper className={styles.dropzone} {...getRootProps()} onClick={(e) => {e.stopPropagation()}}>
                          <input
                            {...getInputProps({className: 'dropzone'})}
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            className={styles.uploadBtn}
                            disabled={postCoverLoading || postLogoLoading}
                            data-type="coverImage"
                          />
                          {(() => {
                            if (postCoverLoading && imageType === 'coverImage') {
                              return <CircularProgress />
                            } else if (coverUrl || editData?.coverUrl) {
                              return (
                                <>
                                  <img
                                    src={coverUrl || editData?.coverUrl}
                                    className={isCoverImageDragActive ? composeClassNames(styles.tournamentImagePick, styles.isActive) : composeClassNames(styles.tournamentImagePick, styles.updateable)}
                                    alt='カバー画像'
                                    data-type="coverImage"
                                  />
                                  <img src="/images/camera.png" alt="camera" className={styles.camera} />
                                </>
                              )
                            } else {
                              return <img src="/images/upload-game-image.png" className={isCoverImageDragActive ? composeClassNames(styles.tournamentImagePick, styles.isActive) : styles.tournamentImagePick} alt='カバー画像' data-type="coverImage" />
                            }
                          })()}
                        </Paper>
                      }
                    />
                  </label>
                </FormControl>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Typography className={styles.head} variant="subtitle1" color="textPrimary">アイコン画像</Typography>
              <Box>
                <FormControl variant="outlined" className={styles.iconDropzoneWrap}>
                  <label className={styles.imageUploadWrapper}>
                    <Controller
                      name="logoTmpUrl"
                      control={control}
                      render={() =>
                        <Paper className={styles.iconDropzone} {...getRootProps()} onClick={(e) => {e.stopPropagation()}}>
                          <input
                            {...getInputProps()}
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            className={styles.uploadBtn}
                            disabled={postCoverLoading || postLogoLoading}
                            data-type="icon"
                          />
                          {(() => {
                            if (postLogoLoading && imageType === 'icon') {
                              return <CircularProgress />
                            } else if (logoUrl || editData?.logoUrl) {
                              return (
                                <>
                                  <img
                                    src={logoUrl || editData?.logoUrl}
                                    className={isIconDragActive ? composeClassNames(styles.iconImagePick, styles.isActive) : composeClassNames(styles.iconImagePick, styles.updateable)}
                                    alt='アイコン画像'
                                    data-type="icon"
                                  />
                                  <img src="/images/camera.png" alt="camera" className={styles.camera} />
                                </>
                              )
                            } else {
                              return <img src="/images/icon_camera.png" className={isIconDragActive ? composeClassNames(styles.iconImagePick, styles.isActive) : styles.iconCamera} alt='アイコン画像' data-type="icon"/>
                            }
                          })()}
                        </Paper>
                      }
                    />
                  </label>
                </FormControl>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              mt={8}
            >
              <Typography className={styles.head} variant="subtitle1" color="textPrimary">クランのプロフィール</Typography>
              <TextField
                name="description"
                placeholder="プロフィール文を入力"
                type="text"
                multiline rows={4}
                InputLabelProps={{shrink: false}}
                inputRef={register({required: true, minLength: 1, maxLength: 1_000})}
                error={Boolean(errors.description)}
                inputProps={{classes: {root: selectStyles.root, icon: selectStyles.icon}}}
                helperText="最大1,000文字です"
                defaultValue={editData?.description}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              mt={8}
            >
              <Typography className={styles.head} variant="subtitle1" color="textPrimary">最大可能人数</Typography>
              <FormControl variant="outlined" className={styles.select}>
                <Controller
                  as={
                    <Select
                      inputProps={{classes: {root: selectStyles.root, icon: selectStyles.icon}}}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                    >
                      <MenuItem value='' disabled>人数を選択</MenuItem>
                      { [16, 32, 64, 128].map((it, idx) => {
                        return <MenuItem value={it} key={idx}>{it}</MenuItem>
                      }) }
                    </Select>
                  }
                  inputRef={register}
                  name='maxMember'
                  rules={{required: true}}
                  control={control}
                  defaultValue={editData?.maxMember}
                />
              </FormControl>
            </Box>

            <Box display='flex' justifyContent='flex-end' mt={15}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{width: '101px', height: '44px'}}
                disabled={!formState.isValid || submitDisabled}
              >
                { mode === 'new' ? '作成する' : '更新する' }
              </Button>
            </Box>
          </form>
        </Card>
      </Box>
    </>
  )
}
