import {useApollo} from '@/hooks/apollo/client'
import {ApolloProvider} from '@apollo/client'
import React from 'react'

export type MyApolloProviderProps = {
  pageProps: any
  children: any
}

export const MyApolloProvider: React.FC<MyApolloProviderProps> = ({pageProps, children}) => {
  const apolloClient = useApollo(pageProps)

  return (
    <ApolloProvider client={apolloClient}>
      {children}
    </ApolloProvider>
  )
}
