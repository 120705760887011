import {TournamentAwardType, TournamentClanAwardType} from '@/graphql/client'

export const getTournamentAwardTypeLabel = (awardType: TournamentAwardType) => {
  switch (awardType) {
    case TournamentAwardType.First:
      return '1位'
    case TournamentAwardType.Second:
      return '2位'
    case TournamentAwardType.Third:
      return '3位'
    case TournamentAwardType.Fourth:
      return '4位'
    case TournamentAwardType.Fifth:
      return '5位'
    case TournamentAwardType.Sixth:
      return '6位'
    case TournamentAwardType.Seventh:
      return '7位'
    case TournamentAwardType.Eighth:
      return '8位'
    case TournamentAwardType.Ninth:
      return '9位'
    case TournamentAwardType.Tenth:
      return '10位'
    case TournamentAwardType.Participation:
      return '参加'
    case TournamentAwardType.Organizer:
      return '主催者'
    case TournamentAwardType.Eliminated:
      return '失格'
    default:
      return '-'
  }
}

export const getTournamentClanAwardTypeLabel = (awardType: TournamentClanAwardType) => {
  switch (awardType) {
    case TournamentClanAwardType.First:
      return '1位'
    case TournamentClanAwardType.Second:
      return '2位'
    case TournamentClanAwardType.Third:
      return '3位'
    case TournamentClanAwardType.Fourth:
      return '4位'
    case TournamentClanAwardType.Fifth:
      return '5位'
    case TournamentClanAwardType.Sixth:
      return '6位'
    case TournamentClanAwardType.Seventh:
      return '7位'
    case TournamentClanAwardType.Eighth:
      return '8位'
    case TournamentClanAwardType.Ninth:
      return '9位'
    case TournamentClanAwardType.Tenth:
      return '10位'
    case TournamentClanAwardType.Participation:
      return '参加'
    case TournamentClanAwardType.Eliminated:
      return '失格'
    default:
      return '-'
  }
}
