import React from 'react'
import {
  Box,
  Button,
  Card,
  GridList,
  GridListTile,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'

const ClanApplicationListLayout = makeStyles(() =>
  createStyles({
    wrapper: {
      marginBottom: '45px',
      maxWidth: '800px',
    },
    root: {
      padding: '16px',
      marginBottom: '8px',
      background: '#232323',
      borderRadius: '4px',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    userIcon: {
      display: 'block',
      marginRight: '12px',
      width: '48px',
      height: '48px',
      objectFit: 'cover',
      borderRadius: '50%',
    },
    userName: {
      fontSize: '15px',
    },
    level: {
      fontSize: '14px',
    },
    circleBtn: {
      padding: 0,
      marginLeft: '8px',
      height: '44px',
      maxWidth: '44px',
      minWidth: '44px',
      borderRadius: '50%',
      border: '1px solid #fff',
      opacity: '0.38',
      '&:hover': {
        border: '1px solid #55C341',
        color :'#55C341',
        opacity: 1,
      },
    },
  })
)

export const ClanApplicationList: React.FC = () => {
  const styles = ClanApplicationListLayout()

  return (
    <div className={styles.wrapper}>
      <GridList
        cellHeight={80}
        cols={1}
      >
        <GridListTile key={1}>
          <Card className={styles.root}>
            <Box className={styles.container}>
              <img src="https://asset.adictor.jp/default/icon.png" alt="clan icon" className={styles.userIcon} />
              <Box flexGrow={1}>
                <Typography variant='subtitle1' className={styles.userName}>Ralph Edwards</Typography>
                <Typography variant='overline' className={styles.level}>Lv.25</Typography>
              </Box>
              <Box>
                <Button className={styles.circleBtn}>却下</Button>
                <Button className={styles.circleBtn}>承諾</Button>
              </Box>
            </Box>
          </Card>
        </GridListTile>
        <GridListTile key={2}>
          <Card className={styles.root}>
            <Box className={styles.container}>
              <img src="https://asset.adictor.jp/default/icon.png" alt="clan icon" className={styles.userIcon} />
              <Box flexGrow={1}>
                <Typography variant='subtitle1' className={styles.userName}>Albert Flores</Typography>
                <Typography variant='overline' className={styles.level}>Lv.25</Typography>
              </Box>
              <Box>
                <Button className={styles.circleBtn}>却下</Button>
                <Button className={styles.circleBtn}>承諾</Button>
              </Box>
            </Box>
          </Card>
        </GridListTile>
      </GridList>
    </div>
  )
}
