import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, createStyles, makeStyles,
} from '@material-ui/core'
import {ClanUserRoleFragment, UserFragment} from '@/graphql/client'
import {ManageMemberOwnerMenu} from '@/components/molecules/clan/manage-member-owner-menu'

const useClanMemberLayout = makeStyles(({breakpoints, palette}) =>
  createStyles({
    container: {
      margin: '0 -16px',
      width: 'calc(var(--vw, 1vw) * 100)',
      [breakpoints.up('lg')]: {
        margin: 'initial',
        width: '800px',
      },
    },
    table: {
      overflowX: 'scroll',
      whiteSpace: 'nowrap',
      [breakpoints.up('lg')]: {
        width: '800px',
      },
    },
    tablehead: {
      color: palette.text.secondary,
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    },
    tablecontent: {
      paddingTop: '8px',
      paddingBottom: '8px',
      verticalAlign: 'middle',
      color: palette.text.secondary,
      borderBottom: 'none',
    },
    border: {
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    userIcon: {
      display: 'block',
      width: '36px',
      height: '36px',
      borderRadius: '50%',
    },
    iconCell: {
      width: '80px',
    },
    levelCell: {
      width: '100px',
    },
    rankCell: {
      width: '120px',
    },
    authorityCell: {
      width: '110px',
    },
    settingCell: {
      width: '75px',
    },
  })
)

type ClanMemberProps = {
  owner: UserFragment
  members: UserFragment[]
  userRoles: ClanUserRoleFragment[]
  handleTransferOwner: (user: UserFragment) => void
  handleMemberRemove: (user: UserFragment) => void
  handleMakeManager: (user: UserFragment) => void
  handleMakeMember: (user: UserFragment) => void
}

export const ClanManageMemberList: React.FC<ClanMemberProps> = ({
  owner,
  members,
  userRoles,
  handleTransferOwner,
  handleMemberRemove,
  handleMakeManager,
  handleMakeMember,
}) => {
  const styles = useClanMemberLayout()

  const getIsManager = (user: UserFragment) => {
    return userRoles.find(it => it.user.id === user.id) !== undefined
  }

  const tableRow = (_: number, user: UserFragment) => {
    const isOwner = owner.id === user.id
    const isManager = getIsManager(user)
    const label = isOwner ? 'クランマスター' : isManager ? 'マネージャー' : 'メンバー'

    return (
      <TableRow key={1} className={styles.border}>
        <TableCell className={styles.tablecontent + ' ' + styles.iconCell}>
          <img className={styles.userIcon} src={user.profile.thumbnailUrl} alt="icon"/>
        </TableCell>
        <TableCell className={styles.tablecontent} component="th" scope="row">{user.profile.displayName}</TableCell>
        <TableCell className={styles.tablecontent + ' ' + styles.levelCell}>{user.level.level}</TableCell>
        <TableCell className={styles.tablecontent + ' ' + styles.authorityCell}>{label}</TableCell>
        <TableCell className={styles.tablecontent + ' ' + styles.settingCell}>
          {!isOwner &&
            <ManageMemberOwnerMenu
              isManager={isManager}
              onTransferOwner={() => handleTransferOwner(user)}
              onMakeManager={() => handleMakeManager(user)}
              onMakeMember={() => handleMakeMember(user)}
              onRemoveMember={() => handleMemberRemove(user)}
            />
          }
        </TableCell>
      </TableRow>
    )
  }

  return (
    <TableContainer component={Paper} className={styles.container}>
      <Table className={styles.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={styles.tablehead}/>
            <TableCell className={styles.tablehead}>プレイヤー名</TableCell>
            <TableCell className={styles.tablehead}>レベル</TableCell>
            <TableCell className={styles.tablehead}>権限</TableCell>
            <TableCell className={styles.tablehead}>操作</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRow(1, owner)}
          {members.map((user, idx) => tableRow(idx + 1, user))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
