import {useApolloClient} from '@apollo/client'
import {useDevContext} from '@/hooks'
import {serverConfig} from '@/env'

export function useSwitchUser() {
  const ctx = useDevContext()
  const client = useApolloClient()

  const switchUser = (id: string) => {
    // NOTE: 開発環境のみで利用
    if (serverConfig.isPrd) {
      return
    }
    client.clearStore().then(() => ctx.setUserId(id))
  }

  const resetUser = () => {
    // NOTE: 開発環境のみで利用
    if (serverConfig.isPrd) {
      return
    }
    client.clearStore().then(() => ctx.setUserId(''))
  }

  return {switchUser, resetUser}
}
