import React, {useState} from 'react'
import {Checkbox, CircularProgress, Divider, Fade, IconButton, InputAdornment, LinearProgress, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Modal, TextField, makeStyles} from '@material-ui/core'
import {Box, Typography} from '@material-ui/core/'
import CloseIcon from '@material-ui/icons/Close'
import {Stepper} from '@/components/atoms/stepper'
import {Avatar, Button, Link} from '@/components/atoms'
import {MeQuery, OpenTournamentType, SearchUserFragment, TournamentOpenEntryAddInput, UserFragment, useCheckUserForOpenTournamentQuery, useSearchUsersQuery, useTournamentEntryStatusLazyQuery, useTournamentOpenEntryAddMutation, useTournamentSettingQuery} from '@/graphql/client'
import {useAuth0} from '@auth0/auth0-react'
import {CheckCircle, CheckCircleOutline, PersonAdd, PersonAddDisabled, Search} from '@material-ui/icons'
import {tournamentDetailHeaderType} from '../tournament-detail'
import {getOpenTournamentTeammateLimit, missingRequirementsLabel} from '@/utils/open-tournaments/open-tournament-type'
import {composeClassNames} from '@/utils/style'
import {debounce} from '@/utils'
import {useSnackbar} from '@/hooks/acknowledge'

const useStyles = makeStyles(({spacing, palette}) => ({
  modal: {
    justifyContent:'center',
    alignItems: 'center',
    display: 'flex',
    margin: spacing(4),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 16px',
    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
  },
  content: {
    '& .MuiStepper-root': {
      width: 'fit-content',
      backgroundColor: palette.contentBackground,
      margin: '0 auto',
      paddingTop: 0,
      paddingBottom: 16,
    },
  },
}))

type OpenEntryModalProps = {
  open: boolean
  onClose: () => void
  meData?: MeQuery
  tournament?: tournamentDetailHeaderType
}

export const OpenEntryModal: React.FC<OpenEntryModalProps> = ({...props}) => {
  const styles = useStyles()
  const {open, onClose, meData, tournament} = props
  const [activeStep, setActiveStep] = useState<1 | 2 | 3>(1)
  const {isLoading, loginWithRedirect} = useAuth0()
  const [teammates, setTeammates] = useState<SearchUserFragment[]>([])

  const {snackbar} = useSnackbar()
  const [addEntry] = useTournamentOpenEntryAddMutation()
  const [getEntryStatus] = useTournamentEntryStatusLazyQuery({
    fetchPolicy: 'network-only',
    variables: {tournamentId: tournament?.id as string},
  })

  const handleClose = () => {
    setTeammates([])
    setActiveStep(1)
    onClose()
  }
  const handleSubmit = async () => {
    const input: TournamentOpenEntryAddInput = {
      tournamentId: tournament?.id as string,
      userIds: teammates.map(u => u.id),
    }
    try {
      await addEntry({variables: {input}})
      handleClose()
      snackbar.success('エントリーしました')
      await getEntryStatus()
    } catch (e) {
      snackbar.failed('エントリーに失敗しました', e)
    }
  }

  if (isLoading || !tournament || !tournament.openTournamentType) return null
  if (!meData) {
    if (open) loginWithRedirect({appState: {returnTo: window.location.pathname}})
    return null
  }

  return (
    <Modal className={styles.modal} open={open} onClose={handleClose}>
      <Fade in={open}>
        <Box
          width="100%"
          maxWidth={444}
          display="flex"
          flexDirection="column"
          borderRadius={4}
          bgcolor="#232323"
        >
          <Box className={styles.header}>
            <Typography variant="h4">オープン大会エントリー</Typography>
            <IconButton size="small" onClick={handleClose}><CloseIcon /></IconButton>
          </Box>
          <Box className={styles.content} padding={4} minHeight="30vh">
            <Stepper activeStep={activeStep - 1} steps={3} />
            {activeStep === 1 && (
              <Step1
                proceed={() => setActiveStep(2)}
                meData={meData}
                tournamentType={tournament.openTournamentType}
              />
            )}
            {activeStep === 2 && (
              <Step2
                goBack={() => setActiveStep(1)}
                proceed={() => setActiveStep(3)}
                meData={meData}
                tournamentType={tournament.openTournamentType}
                teammates={teammates}
                setTeammates={setTeammates}
              />
            )}
            {activeStep === 3 && (
              <Step3
                goBack={() => setActiveStep(2)}
                proceed={handleSubmit}
                meData={meData}
                teammates={teammates}
              />
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}

type StepProps = {
  proceed: () => void
  goBack: () => void
  meData: MeQuery
  tournamentType: OpenTournamentType
  teammates: SearchUserFragment[]
  setTeammates: (teammates: SearchUserFragment[]) => void
}

const useStep1Styles = makeStyles(({spacing}) => ({
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: spacing(6),
    marginBottom: spacing(6),
    gap: '8px',

    '& > .MuiBox-root': {
      borderRadius: '4px',
      border: '1px solid rgba(255, 255, 255, 0.12)',

      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      gap: `${spacing(4)}px`,
      padding: `${spacing(4)}px ${spacing(6)}px`,

      '& .icon-box': {
        display: 'flex',
        width: spacing(12),
        height: spacing(12),
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(88, 101, 242, 0.12)',
        borderRadius: spacing(1),
      },
    },
  },
}))
type Step1Props = Pick<StepProps, 'proceed' | 'meData' | 'tournamentType'>
const Step1 = ({proceed, meData, tournamentType}: Step1Props) => {
  const styles = useStep1Styles()
  const [check1, setCheck1] = useState(false)
  const [check2, setCheck2] = useState(false)
  const {data, loading} = useTournamentSettingQuery({
    variables: {tournamentType},
    fetchPolicy: 'network-only',
  })

  const config = !!data?.tournamentSetting?.config['roles']
  const riotId = meData.me.riotId
  const discord = meData.me.discordUsername
  const canProceed = config && riotId && discord && check1 && check2

  return (
    <>
      <Typography align="center" variant="body2">
        エントリー前に設定内容をご確認ください
      </Typography>

      <Box className={styles.gridContainer}>
        <Box>
          <Box>
            <Typography variant="subtitle1">大会設定</Typography>
            {loading ? (
              <Box paddingY="8.5px"><LinearProgress /></Box>
            ) : config ? (
              <Typography variant="subtitle2" color="textSecondary">設定済み</Typography>
            ) : (
              <Link href="/mypage"><Typography color="primary">大会設定画面へ</Typography></Link>
            )}
          </Box>
          <Box flexGrow={1}/>
          {loading ? (
            <CircularProgress size={24} />
          ) : config ? (
            <CheckCircle color="primary" />
          ) : (
            <CheckCircleOutline color="disabled" />
          )}
        </Box>
        <Box>
          <Box>
            <Typography variant="subtitle1">Riot ID 連携</Typography>
            {riotId ? (
              <Typography variant="subtitle2" color="textSecondary">{riotId}</Typography>
            ) : (
              <Link href="/mypage"><Typography color="primary">大会設定画面へ</Typography></Link>
            )}
          </Box>
          <Box flexGrow={1}/>
          {riotId ? (
            <CheckCircle color="primary" />
          ) : (
            <CheckCircleOutline color="disabled" />
          )}
        </Box>
        <Box>
          <Box>
            <Typography variant="subtitle1">Discord連携</Typography>
            {discord ? (
              <Typography variant="subtitle2" color="textSecondary">{discord}</Typography>
            ) : (
              <Link href="/mypage"><Typography color="primary">大会設定画面へ</Typography></Link>
            )}
          </Box>
          <Box flexGrow={1}/>
          {discord ? (
            <CheckCircle color="primary" />
          ) : (
            <CheckCircleOutline color="disabled" />
          )}
        </Box>
      </Box>

      <Box>
        <label>
          <Checkbox color="primary" checked={check1} onChange={e => setCheck1(e.target.checked)}/>
          大会詳細に同意する
        </label>
      </Box>
      <Box>
        <label>
          <Checkbox color="primary" checked={check2} onChange={e => setCheck2(e.target.checked)}/>
          <Link href="/terms" target="_blank">規約</Link>に同意する
        </label>
      </Box>

      <Box display="flex" justifyContent="center" marginY={6}>
        <Button onClick={proceed} size="large" disabled={!canProceed}>
          <Typography variant="subtitle2">次へ</Typography>
        </Button>
      </Box>
    </>
  )
}

type Step2Props = Pick<StepProps, 'proceed' | 'goBack' | 'meData' | 'tournamentType' | 'teammates' | 'setTeammates'>
const Step2 = ({proceed, goBack, meData, tournamentType, teammates, setTeammates}: Step2Props) => {
  const canProceed = true
  const limit = getOpenTournamentTeammateLimit(tournamentType)

  return (
    <>
      <Typography align="center" variant="body2">
        一緒に参加するメンバーを{limit}名まで追加できます
      </Typography>

      <Box marginY={6}>
        <UserBox user={meData.me}/>
        {teammates.map(u => {
          const onDismiss = () => setTeammates(teammates.filter((t) => t.id !== u.id))
          return <UserBox user={u} onDismiss={onDismiss} key={u.id}/>
        })}
      </Box>

      <UserSearch
        tournamentType={tournamentType}
        meId={meData.me.id}
        teammates={teammates}
        setTeammates={setTeammates}
      />

      <Box display="flex" justifyContent="center" marginY={6} gridGap={16}>
        <Button onClick={goBack} size="large" variant="outlined">
          <Typography variant="subtitle2" color="primary">前へ</Typography>
        </Button>
        <Button onClick={proceed} size="large" disabled={!canProceed}>
          <Typography variant="subtitle2">確認</Typography>
        </Button>
      </Box>
    </>
  )
}

type UserBoxProps = {
  user: UserFragment | MeQuery['me'] | SearchUserFragment
  onDismiss?: () => void
}
const useUserBoxStyles = makeStyles(({spacing}) => ({
  root: {
    borderRadius: '4px',
    border: '1px solid rgba(255, 255, 255, 0.12)',
    marginBottom: spacing(2),

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    gap: `${spacing(3)}px`,
    padding: `${spacing(4)}px ${spacing(6)}px`,
  },
  rootHighlighted: {
    border: '1px solid rgba(255, 255, 255, 0.38)',
  },
}))

const UserBox = ({user, onDismiss}: UserBoxProps) => {
  const styles = useUserBoxStyles()

  return (
    <Box className={composeClassNames(styles.root, styles.rootHighlighted)}>
      <Avatar src={user.profile.thumbnailUrl} />
      <Box>
        <Typography variant="subtitle1">{user.profile.displayName}</Typography>
        <Typography variant="body2" color="textSecondary">@{user.profile.twitterId}</Typography>
      </Box>
      <Box flexGrow={1}/>
      {onDismiss && <IconButton edge="end" onClick={onDismiss}><PersonAddDisabled /></IconButton>}
    </Box>
  )
}

type UserSearchProps = {
  tournamentType: OpenTournamentType
  meId: string
  teammates: SearchUserFragment[]
  setTeammates: (teammates: SearchUserFragment[]) => void
}
const useUserSearchStyle = makeStyles(({spacing}) => ({
  header: {
    marginBottom: spacing(2),
  },
  result: {
    marginTop: spacing(2),
    height: '210px',
    overflow: 'scroll',
    border: '1px solid #545454',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
const UserSearch = ({tournamentType, meId, teammates, setTeammates}: UserSearchProps) => {
  const styles = useUserSearchStyle()
  const {snackbar} = useSnackbar()
  const limit = getOpenTournamentTeammateLimit(tournamentType)

  const [newTeammate, setNewTeammate] = useState<SearchUserFragment>()
  const [query, setQuery] = useState<string>('')
  const {data, loading} = useSearchUsersQuery({
    variables: {query},
    skip: !query,
  })
  const {data: userData, loading: userLoading} = useCheckUserForOpenTournamentQuery({
    variables: {userId: newTeammate?.id as string, openTournamentType: tournamentType},
    skip: !newTeammate,
  })

  const teammateIds = teammates.map((t) => t.id)
  const users = data?.usersByQuery.filter((u) => (u.id != meId && !teammateIds.includes(u.id)))

  const debounceQuery = debounce(setQuery, 500)

  if (newTeammate && !userLoading && userData) {
    if (userData.userEligibilityForOpenTournament.isEligible) {
      if (!teammates.find((t) => t.id === newTeammate.id)) {
        setTeammates([...teammates, newTeammate])
      }
    } else {
      const missing = userData.userEligibilityForOpenTournament.missingRequirements || []
      snackbar.failed(
        `追加できません。\
        ${missing.map((it) => missingRequirementsLabel[it]).join('・')}\
        の設定が完了していないユーザーです。`
      )
    }
    setNewTeammate(undefined)
  }

  return (
    <>
      <Typography variant="subtitle2" className={styles.header}>メンバー検索</Typography>
      <TextField
        variant="outlined"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start"><Search color="disabled" /></InputAdornment>
          ),
        }}
        fullWidth
        defaultValue={query}
        onChange={(e) => debounceQuery(e.target.value)}
        placeholder="Adictorユーザーネーム"
      />
      {loading ? (
        <Box className={styles.result} display="flex"><CircularProgress /></Box>
      ) : users ? (
        users.length === 0 ? (
          <Box className={styles.result} display="flex">
            <Typography variant="body2" color="textSecondary">ユーザーが見つかりません</Typography>
          </Box>
        ) : (
          <List className={styles.result}>
            {/* 自分と追加済みユーザーは除外する */}
            {users.map((u, i) => (
              <React.Fragment key={u.id}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar src={u.profile.thumbnailUrl} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={u.profile.displayName}
                    primaryTypographyProps={{variant: 'subtitle1'}}
                    secondary={`@${u.profile.twitterId}`}
                    secondaryTypographyProps={{variant: 'body2'}}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="追加"
                      onClick={() => setNewTeammate(u)}
                      disabled={!!newTeammate} // 他のユーザーの追加処理中は disabled
                    >
                      {newTeammate?.id === u.id ? (
                        <CircularProgress size={24} />
                      ) : (
                        <PersonAdd />
                      )}
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                {i + 1 != users.length && <Divider component="li" />}
              </React.Fragment>
            ))}
          </List>
        )
      ): (
        <Typography variant="caption" color="textSecondary">
          メンバーは{limit}名まで追加できます
        </Typography>
      )}
    </>
  )
}

type Step3Props = Pick<StepProps, 'goBack' | 'meData' | 'proceed' | 'teammates'>
const Step3 = ({goBack, meData, proceed, teammates}: Step3Props) => {

  return (
    <>
      <Typography align="center" variant="body2">
      このメンバーでエントリーします
      </Typography>

      <Box marginY={6}>
        <UserBox user={meData.me}/>
        {teammates.map(u => <UserBox user={u} key={u.id} />)}
      </Box>

      <Box display="flex" justifyContent="center" marginY={6} gridGap={16}>
        <Button onClick={goBack} size="large" variant="outlined">
          <Typography variant="subtitle2" color="primary">前へ</Typography>
        </Button>
        <Button onClick={proceed} size="large">
          <Typography variant="subtitle2">エントリー</Typography>
        </Button>
      </Box>
    </>
  )
}
