import {useSignInRequiredLink} from '@/hooks/auth'
import React from 'react'

import {Tab, Tabs, makeStyles} from '@material-ui/core'
import {useRouter} from 'next/router'

const useTabMenuStyles = makeStyles(({breakpoints}) => ({
  tabs: {
    width: '100%',
    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    [breakpoints.up('lg')]: {
      // @todo padding整理時に修正が必要
      // width: 960,
    },
  },
  activeTab: {
    height: 48,
    fontSize: '14px',
  },
  inActiveTab:{
    height: 48,
    fontSize: '14px',
  },
  [breakpoints.up('lg')]: {
    width: 172,
  },
}))

export type TabMenuType = {
  value: number
  label: string
  path: string
  isActive: boolean
  isSignInRequired: boolean
}

type TabMenuProps = {
  tabs: TabMenuType[]
  tabWidth?: number | string
}

export const TabMenu: React.FC<TabMenuProps> = (props) => {
  const {tabs, tabWidth = 172} = props
  const styles = useTabMenuStyles()
  const router = useRouter()
  const {redirect} = useSignInRequiredLink()

  // @todo 同一ページでタブを回すようなことが現状無さそうなのでこの対応
  //       同一ページでタブを回したい場合にはhandleChangeを再実装

  // 少し雑ではあるが、isActive = trueは1つであるはずなので「activeTab[0].value」でアクティブなタブが取れるはず
  const activeTab = tabs.filter((tab) => tab.isActive)

  const handleChange = (e: any, value: number) => {
    if (tabs[value].isSignInRequired) {
      redirect(tabs[value].path)
    } else {
      router.push(tabs[value].path)
    }
  }

  return (
    <Tabs
      value={activeTab[0].value}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleChange}
      aria-label="disabled tabs example"
      classes={{root: styles.tabs}}
    >
      {tabs.map((tab, index) => {
        return (
          <Tab
            style={{width: tabWidth}}
            classes={{root: styles.activeTab}}
            value={tab.value}
            label={tab.label}
            key={index}
          />
        )
      })}
    </Tabs>
  )
}
