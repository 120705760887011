import React from 'react'

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core/'

import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles'

import {
  Link,
  LinkProps,
} from '../../atoms'

const useDrawerMenuListSubItemStyles = makeStyles(({palette}) => createStyles({
  menuListItem: {
    color: palette.text.disabled,
  },
  menuListItemIcon: {
    color: palette.text.disabled,
    minWidth: 26,
  },
  activeMenuListItem: {
    borderLeftColor: palette.primary.main,
  },
}))

type DrawerMenuListSubItemProps = LinkProps & {
  title: string
  active?: boolean
}

export const DrawerMenuListSubItem: React.FC<DrawerMenuListSubItemProps> = ({children, title, active, ...props}) => {

  const styles = useDrawerMenuListSubItemStyles()
  const statusClassName = active ? styles.activeMenuListItem : styles.menuListItem

  return (
    <Link {...props}>
      <ListItem button className={statusClassName}>
        <ListItemIcon className={styles.menuListItemIcon}>{children}</ListItemIcon>
        <ListItemText disableTypography>
          <Typography variant='body2'>{title}</Typography>
        </ListItemText>
      </ListItem>
    </Link>
  )
}
