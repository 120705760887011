import React from 'react'
import {makeStyles} from '@material-ui/core'
import {Box, Typography} from '@material-ui/core/'
import {ClanFragment, useMyClansQuery} from '@/graphql/client'
import {SelectableClanList} from '@/components/organisms/tournament-team/selectable-clan-list'
import {Button} from '@/components'
import {LoadingPart} from '../../atoms/loading-part'


const useChoseClanContentStyles = makeStyles(({spacing}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  description: {
    marginBottom: spacing(7),
  },
  button: {
    marginTop: spacing(10),
    marginBottom: spacing(12),
    width: 73,
    height: 44,
  },
}))

type ChoseClanContentProps = {
  handleChose: (clan: ClanFragment) => void
}

export const ChoseClanContent: React.FC<ChoseClanContentProps> = ({handleChose}) => {

  const styles = useChoseClanContentStyles()
  const [selectedClan, setSelectedClan] = React.useState<ClanFragment | null>(null)
  const {data, loading} = useMyClansQuery()

  const handleSelect = (clan: ClanFragment) => setSelectedClan(clan)
  const handleSubmit = () => handleChose(selectedClan!)

  if (loading) {
    return <LoadingPart/>
  }

  return (
    <Box className={styles.root}>
      <Typography className={styles.description} variant={'body2'}>
          この大会にエントリーするクランを選択してください。
      </Typography>
      <SelectableClanList clans={data!.myClans!} handleChose={handleSelect}/>
      <Button
        className={styles.button}
        onClick={handleSubmit}
        disabled={selectedClan === null}
      >
          次へ
      </Button>
    </Box>
  )
}
