import React from 'react'
import {Box, CardActionArea, Typography} from '@material-ui/core/'
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles'
import {Avatar, Card, CardProps, Medal, MedalBackgroundColor} from '../atoms'
import {GameExpFragment} from '@/graphql/client'

const useUserListItem = makeStyles<Theme>(({spacing}) => createStyles({
  root: {
    position: 'relative',
    overflow: 'hidden',
  },
  border: {
    border: '1px solid rgba(255, 255, 255, 0.12)',
    borderRadius: '4px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: spacing(4),
    height: 80,
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    marginRight: spacing(3),
  },
  xpTextContainer: {
    paddingBottom: 22,
  },
}))

type UserListItemProps = {
  id: string
  name: string
  level: number
  thumbnailUrl: string
  gameExp: GameExpFragment
  cardProps?: CardProps
  handleClick?: (userId: string) => void
  outlined?: boolean
}

export const UserListItem: React.FC<UserListItemProps> = ({
  outlined = false,
  ...props}
) => {

  const styles = useUserListItem()
  const {id, name, level, thumbnailUrl, gameExp, cardProps} = props

  const getMedalBackground = (): MedalBackgroundColor => {
    switch (gameExp.rank) {
      case 1:
        return MedalBackgroundColor.SILVER
      case 2:
        return MedalBackgroundColor.GRAD_GOLD
      case 3:
        return MedalBackgroundColor.GRAD_GREEN
      case 4:
        return MedalBackgroundColor.GRAD_PURPLE
      default:
        return MedalBackgroundColor.GRAD_RED
    }
  }

  return (
    <Box className={outlined ? styles.border : ''}>
      <Card {...cardProps} className={styles.root} onClick={() => props.handleClick?.(id)}>
        <CardActionArea className={styles.container}>
          <Box className={styles.nameContainer}>
            <Avatar src={thumbnailUrl} className={styles.avatar}/>
            <Box>
              <Typography variant='subtitle1'>{name}</Typography>
              <Typography variant='overline'>Lv.{level}</Typography>
            </Box>
          </Box>
          <Box className={styles.xpTextContainer}>
            <Typography variant='overline' color='textSecondary'>{gameExp.exp}XP</Typography>
          </Box>
        </CardActionArea>
        <Medal background={getMedalBackground()} text={gameExp.title}/>
      </Card>
    </Box>
  )
}
