import {BracketType, TournamentUnitType} from '@/graphql/client'

export const getMemberCountByUnit = (unitType: TournamentUnitType) => {
  switch (unitType) {
    case TournamentUnitType.Single: return 1
    case TournamentUnitType.Team_2Vs2: return 2
    case TournamentUnitType.Team_3Vs3: return 3
    case TournamentUnitType.Team_4Vs4: return 4
    case TournamentUnitType.Team_5Vs5: return 5
  }
}

export const unitTypeSuffix = (unitType?: TournamentUnitType): string => {
  switch (unitType) {
    case undefined:
    case TournamentUnitType.Single:
      return '人'
    case TournamentUnitType.Team_2Vs2:
    case TournamentUnitType.Team_3Vs3:
    case TournamentUnitType.Team_4Vs4:
    case TournamentUnitType.Team_5Vs5:
      return 'チーム'
  }
}

export const getUnitTypeLabel = (unitType?: TournamentUnitType, bracketType?: BracketType): string => {
  switch (bracketType) {
    case undefined:
    case BracketType.SingleElimination:
      switch (unitType) {
        case undefined:
        case TournamentUnitType.Single:
          return '1vs1'
        case TournamentUnitType.Team_2Vs2:
          return '2vs2'
        case TournamentUnitType.Team_3Vs3:
          return '3vs3'
        case TournamentUnitType.Team_4Vs4:
          return '4vs4'
        case TournamentUnitType.Team_5Vs5:
          return '5vs5'
      }
      break
    case BracketType.Custom:
      switch (unitType) {
        case undefined:
        case TournamentUnitType.Single:
          return 'シングル (1人)'
        case TournamentUnitType.Team_2Vs2:
          return 'デュオ (2人組)'
        case TournamentUnitType.Team_3Vs3:
          return 'トリオ (3人組)'
        case TournamentUnitType.Team_4Vs4:
          return 'カルテット (4人組)'
        case TournamentUnitType.Team_5Vs5:
          return 'クインテット (5人組)'
      }
      break
  }
}
