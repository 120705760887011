import React from 'react'
import {DrawerLayout} from '.'
import {LoadingPart} from '@/components'

// @todo とりあえず雑にローディングコンポーネント作ってます
const Loading = () => {
  return (
    <DrawerLayout><LoadingPart height="100vh" /></DrawerLayout>
  )
}

export default Loading
