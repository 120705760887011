import React from 'react'

import {
  Drawer,
} from '@material-ui/core'

type BottomSheetProps = {
  open: boolean
  onClose?: () => void
}

export const BottomSheet: React.FC<BottomSheetProps> = ({open, children, onClose}) => {

  return (
    <Drawer
      open={open}
      anchor={'bottom'}
      onClose={onClose}
    >
      {children}
    </Drawer>
  )

}
