import React from 'react'

import {
  HelpContentsAPIResponse,
} from '@/types'

import {
  Card,
  Link,
  Title,
} from '@/components/atoms'

import {
  SingleColumnLayout,
} from '@/components/templates/layouts'

import {
  useIsLg,
} from '@/hooks'

import {
  Box,
  GridList,
  GridListTile,
  List,
  ListItem,
  makeStyles,
  useTheme,
} from '@material-ui/core'

const useHelpContentsListTemplateStyles = makeStyles(({palette, spacing}) => ({
  card: {
    display: 'flex',
    backgroundColor: palette.background.paper,
    flexDirection: 'column',
    padding: spacing(5),
    paddingLeft: spacing(10),
    paddingRight: spacing(10),
    minHeight: '400px',
  },
  title: {
    paddingTop: spacing(15),
    marginBottom: spacing(15),
    textAlign: 'center',
  },
  headline: {
    paddingTop: spacing(2),
    marginBottom: spacing(3),
    color: palette.text.primary,
  },
  listItem: {
    paddingTop: 0,
    paddingLeft: 0,
    marginLeft: spacing(8),
    marginBottom: spacing(5),
    listStyleType: 'disc',
    display: 'list-item',
    fontSize: '1.2em',
    color: palette.text.secondary,
  },
  listText: {
    color: 'inherit',
  },
  gridList: {
    marginBottom: spacing(30),
  },
}))

type HelpContentsListTemplateProps = HelpContentsAPIResponse

export const HelpContentsListTemplate: React.FC<HelpContentsListTemplateProps> = ({contents}) => {

  const styles = useHelpContentsListTemplateStyles()
  const lg = useIsLg()
  const {spacing} = useTheme()

  const [
    IntroductonHelpContents,
    OrganizeHelpContents,
    JoinHelpContents,
    MoreEnjoyHelpContents,
  ] = [
    'はじめに',
    '開催する',
    '参加する',
    'もっと楽しむ',
  ].map((category) => {
    return {
      category: category,
      contents: contents.filter(content => content.category[0] === category),
    }
  })

  const HelpContents = [
    IntroductonHelpContents,
    OrganizeHelpContents,
    JoinHelpContents,
    MoreEnjoyHelpContents,
  ]

  return (
    <SingleColumnLayout>
      <Title className={styles.title} variant='h1'>&quot;ADICTOR&quot; HELP PAGE</Title>
      <Box className={styles.gridList}>
        <GridList
          cellHeight='auto'
          cols={lg ? 2 : 1}
          spacing={spacing(10)}
        >
          {
            HelpContents.map((contents) => (
              <GridListTile key={contents.category} >
                <Card className={styles.card}>
                  <Title className={styles.headline} variant='h2'>
                    {contents.category}
                  </Title>
                  <List>
                    {
                      contents.contents.map((content) => (
                        <ListItem
                          className={styles.listItem}
                          key={content.id}
                        >
                          <Link
                            href={`/help/${encodeURIComponent(content.page_name)}`}
                            className={styles.listText}
                          >
                            {content.title}
                          </Link>
                        </ListItem>
                      ))
                    }
                  </List>
                </Card>
              </GridListTile>
            ))
          }
        </GridList>
      </Box>
    </SingleColumnLayout>
  )

}
