import {makeStyles} from '@material-ui/core'
import {CalendarContents} from '@/components'
import React, {useState} from 'react'
import {Title} from '../../atoms'
import {useTournamentCountsByTermQuery} from '@/graphql/client'
import {endOfMonth, startOfMonth} from 'date-fns'
import {useGameContext} from '@/hooks/game'

const useTournamentCalendarLayout = makeStyles(theme => ({
  title: {
    marginTop: '60px',
    fontSize: '18px',
    [theme.breakpoints.up('lg')]: {
      fontSize: '22px',
    },
  },
}))

export const TournamentCalendar: React.FC = () => {
  const styles = useTournamentCalendarLayout()
  const {gameId} = useGameContext()
  const [startDate, setStartDate] = useState<Date>(startOfMonth(new Date()))

  const {data, fetchMore, loading} = useTournamentCountsByTermQuery({
    variables: {
      gameId: gameId,
      from: startOfMonth(startDate),
      to: endOfMonth(startDate),
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const handleStartDateChange = async (startDate: Date) => {
    setStartDate(startDate)
    await fetchMore({
      variables: {
        gameId: gameId,
        from: startOfMonth(startDate),
        to: endOfMonth(startDate),
      },
    })
  }

  return (
    <>
      <Title variant='h3' className={styles.title}>開催カレンダー</Title>
      <CalendarContents
        data={data}
        loading={loading}
        fetchMore={fetchMore}
        startDate={startDate}
        onStartDateChange={handleStartDateChange}
        gameId={gameId}
      />
    </>
  )
}
