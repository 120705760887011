import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A valid Discord URL, transported as a string */
  DiscordId: any;
  /** A valid Discord URL, transported as a string */
  DiscordUrl: any;
  /** A valid Email, transported as a string */
  Email: any;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
  /** A valid TwitterID, transported as a string */
  TwitterId: any;
  /** A valid URL, transported as a string */
  Url: any;
};

export enum BracketType {
  SingleElimination = 'SINGLE_ELIMINATION',
  Custom = 'CUSTOM'
}

export type ChatMessage = {
  __typename?: 'ChatMessage';
  body: Scalars['String'];
  id: Scalars['ID'];
  messageType: ChatMessageType;
  sentAt: Scalars['ISO8601DateTime'];
  user: User;
};

/** Autogenerated input type of ChatMessageAdd */
export type ChatMessageAddInput = {
  tournamentId: Scalars['ID'];
  nodeId: Scalars['ID'];
  body: Scalars['String'];
  messageType: ChatMessageType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ChatMessageAdd */
export type ChatMessageAddPayload = {
  __typename?: 'ChatMessageAddPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: ChatMessage;
};

/** The connection type for ChatMessage. */
export type ChatMessageConnection = {
  __typename?: 'ChatMessageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ChatMessageEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ChatMessage>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ChatMessageEdge = {
  __typename?: 'ChatMessageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ChatMessage>;
};

export enum ChatMessageType {
  Text = 'TEXT',
  Image = 'IMAGE'
}

export type Clan = {
  __typename?: 'Clan';
  clanUserRoles: Array<ClanUserRole>;
  coverUrl: Scalars['Url'];
  description: Scalars['String'];
  id: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  logoUrl: Scalars['Url'];
  maxMember: Scalars['Int'];
  memberNum: Scalars['Int'];
  members: Array<User>;
  name: Scalars['String'];
  owner: User;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of ClanAdd */
export type ClanAddInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  maxMember: Scalars['Int'];
  logoTmpUrl?: Maybe<Scalars['Url']>;
  coverTmpUrl?: Maybe<Scalars['Url']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ClanArchive */
export type ClanArchiveInput = {
  clanId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ClanExit */
export type ClanExitInput = {
  clanId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ClanInviteLink = {
  __typename?: 'ClanInviteLink';
  clan: Clan;
  currentNum: Scalars['Int'];
  expiresOn?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  isExpired: Scalars['Boolean'];
  maxNum?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
  uuid: Scalars['String'];
};

/** Autogenerated input type of ClanInviteLinkAdd */
export type ClanInviteLinkAddInput = {
  clanId: Scalars['ID'];
  maxNum?: Maybe<Scalars['Int']>;
  expireInDays?: Maybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The connection type for ClanInviteLink. */
export type ClanInviteLinkConnection = {
  __typename?: 'ClanInviteLinkConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ClanInviteLinkEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ClanInviteLink>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ClanInviteLinkEdge = {
  __typename?: 'ClanInviteLinkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ClanInviteLink>;
};

/** Autogenerated input type of ClanInviteLinkExecute */
export type ClanInviteLinkExecuteInput = {
  uuid: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ClanInviteLinkRemove */
export type ClanInviteLinkRemoveInput = {
  inviteLinkId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ClanInviteLinkUpdate */
export type ClanInviteLinkUpdateInput = {
  inviteLinkId: Scalars['ID'];
  maxNum?: Maybe<Scalars['Int']>;
  expireInDays?: Maybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ClanMemberRemove */
export type ClanMemberRemoveInput = {
  clanId: Scalars['ID'];
  userId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ClanMemberRoleChange */
export type ClanMemberRoleChangeInput = {
  clanId: Scalars['ID'];
  userId: Scalars['ID'];
  role: ClanMemberRoles;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum ClanMemberRoles {
  Manager = 'MANAGER',
  Member = 'MEMBER'
}

/** Autogenerated input type of ClanTransferOwner */
export type ClanTransferOwnerInput = {
  clanId: Scalars['ID'];
  newOwnerId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ClanUpdate */
export type ClanUpdateInput = {
  clanId: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  maxMember: Scalars['Int'];
  logoTmpUrl?: Maybe<Scalars['Url']>;
  coverTmpUrl?: Maybe<Scalars['Url']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ClanUserRole = {
  __typename?: 'ClanUserRole';
  id: Scalars['ID'];
  role: Role;
  user: User;
};

export type CoinExchangeRequest = {
  __typename?: 'CoinExchangeRequest';
  coin: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  emailAddress: Scalars['Email'];
  exchangeType: CoinExchangeType;
  id: Scalars['ID'];
  status: CoinExchangeStatus;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for CoinExchangeRequest. */
export type CoinExchangeRequestConnection = {
  __typename?: 'CoinExchangeRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CoinExchangeRequestEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CoinExchangeRequest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CoinExchangeRequestEdge = {
  __typename?: 'CoinExchangeRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CoinExchangeRequest>;
};

/** Autogenerated input type of CoinExchangeRequest */
export type CoinExchangeRequestInput = {
  jpy: Scalars['Int'];
  emailAddress: Scalars['String'];
  exchangeType: CoinExchangeType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum CoinExchangeStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED'
}

export enum CoinExchangeType {
  Amazon = 'AMAZON',
  Itunes = 'ITUNES',
  GooglePlay = 'GOOGLE_PLAY',
  AppleGift = 'APPLE_GIFT',
  LoglyAmazon = 'LOGLY_AMAZON'
}

export type CoinIssuance = {
  __typename?: 'CoinIssuance';
  coin: Scalars['Int'];
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Compensation = {
  __typename?: 'Compensation';
  coinIssuance?: Maybe<CoinIssuance>;
  createdAt: Scalars['ISO8601DateTime'];
  exp: Scalars['Int'];
  id: Scalars['ID'];
  memo: Scalars['String'];
};

/** The connection type for Compensation. */
export type CompensationConnection = {
  __typename?: 'CompensationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CompensationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Compensation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CompensationEdge = {
  __typename?: 'CompensationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Compensation>;
};

export type CustomTournamentBracket = {
  __typename?: 'CustomTournamentBracket';
  completed: Scalars['Boolean'];
  players: Array<CustomTournamentPlayer>;
};

export type CustomTournamentPlayer = {
  __typename?: 'CustomTournamentPlayer';
  awardType: TournamentAwardType;
  user: User;
};

export type DateCount = {
  __typename?: 'DateCount';
  count: Scalars['Int'];
  date: Scalars['ISO8601Date'];
};




export type Game = {
  __typename?: 'Game';
  enabled: Scalars['Boolean'];
  genre: Genre;
  iconUrl: Scalars['Url'];
  id: Scalars['ID'];
  title: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for Game. */
export type GameConnection = {
  __typename?: 'GameConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GameEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Game>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type GameDefaultCover = {
  __typename?: 'GameDefaultCover';
  coverUrl: Scalars['Url'];
  game: Game;
  id: Scalars['ID'];
};

/** The connection type for GameDefaultCover. */
export type GameDefaultCoverConnection = {
  __typename?: 'GameDefaultCoverConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GameDefaultCoverEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<GameDefaultCover>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type GameDefaultCoverEdge = {
  __typename?: 'GameDefaultCoverEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<GameDefaultCover>;
};

/** An edge in a connection. */
export type GameEdge = {
  __typename?: 'GameEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Game>;
};

export type GameExp = {
  __typename?: 'GameExp';
  exp: Scalars['Int'];
  game: Game;
  id: Scalars['ID'];
  rank: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Genre = {
  __typename?: 'Genre';
  games: Array<Game>;
  genreRanks: Array<GenreRank>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** The connection type for Genre. */
export type GenreConnection = {
  __typename?: 'GenreConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GenreEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Genre>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type GenreEdge = {
  __typename?: 'GenreEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Genre>;
};

export type GenreRank = {
  __typename?: 'GenreRank';
  id: Scalars['ID'];
  maxExp: Scalars['Int'];
  minExp: Scalars['Int'];
  rank: Scalars['Int'];
  title: Scalars['String'];
};

export type Gift = {
  __typename?: 'Gift';
  amount: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  from: User;
  giftType: GiftType;
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  to: User;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of GiftAddCoin */
export type GiftAddCoinInput = {
  toId: Scalars['ID'];
  amount: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The connection type for Gift. */
export type GiftConnection = {
  __typename?: 'GiftConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GiftEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Gift>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type GiftEdge = {
  __typename?: 'GiftEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Gift>;
};

export enum GiftType {
  Coin = 'COIN',
  Ticket = 'TICKET'
}




export type JoinChatRequest = {
  __typename?: 'JoinChatRequest';
  node: TournamentNode;
  requestedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of JoinChatRequestAdd */
export type JoinChatRequestAddInput = {
  tournamentId: Scalars['ID'];
  nodeId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of JoinChatRequestAdd */
export type JoinChatRequestAddPayload = {
  __typename?: 'JoinChatRequestAddPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  node: TournamentNode;
};

export type Level = {
  __typename?: 'Level';
  id: Scalars['ID'];
  level: Scalars['Int'];
  maxExp: Scalars['Int'];
  minExp: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** チャットメッセージ送信 */
  chatMessageAdd?: Maybe<ChatMessageAddPayload>;
  /** クラン作成 */
  clanAdd?: Maybe<Clan>;
  /** クラン削除 */
  clanArchive?: Maybe<Clan>;
  /** クラン脱退 */
  clanExit?: Maybe<Clan>;
  /** クラン招待リンク発行 */
  clanInviteLinkAdd?: Maybe<ClanInviteLink>;
  /** クラン招待リンク利用 */
  clanInviteLinkExecute?: Maybe<ClanInviteLink>;
  /** クラン招待リンク削除 */
  clanInviteLinkRemove?: Maybe<ClanInviteLink>;
  /** クラン招待リンク更新 */
  clanInviteLinkUpdate?: Maybe<ClanInviteLink>;
  /** クランメンバー除籍 */
  clanMemberRemove?: Maybe<Clan>;
  /** クランメンバー権限付与 */
  clanMemberRoleChange?: Maybe<Clan>;
  /** クランマスター委譲 */
  clanTransferOwner?: Maybe<Clan>;
  /** クラン更新 */
  clanUpdate?: Maybe<Clan>;
  /** AC交換リクエスト */
  coinExchangeRequest?: Maybe<CoinExchangeRequest>;
  /** コインギフト作成 */
  giftAddCoin?: Maybe<Gift>;
  /** チャット参加リクエスト作成 */
  joinChatRequestAdd?: Maybe<JoinChatRequestAddPayload>;
  /** プロフィール更新 */
  profileUpdate?: Maybe<Profile>;
  /** トーナメント作成 */
  tournamentAdd?: Maybe<Tournament>;
  /** 大会アナウンス作成 */
  tournamentAnnouncementAdd?: Maybe<TournamentAnnouncement>;
  /** 大会アナウンス削除 */
  tournamentAnnouncementRemove?: Maybe<TournamentAnnouncement>;
  /** トーナメント中止 */
  tournamentCancel?: Maybe<Tournament>;
  /** カスタム方式大会更新(カスタム方式のみ) */
  tournamentCustomUpdate?: Maybe<Tournament>;
  /** 個人戦トーナメント参加 */
  tournamentEntryAdd?: Maybe<Tournament>;
  /** 個人戦トーナメント参加取消 */
  tournamentEntryRemove?: Maybe<Tournament>;
  /** 強制エントリー解除 */
  tournamentForceEntryRemove?: Maybe<Tournament>;
  /** 強制エントリー解除（オープン大会） */
  tournamentForceOpenEntryRemove?: Maybe<Tournament>;
  /** 強制エントリー解除 */
  tournamentForceTeamEntryRemove?: Maybe<Tournament>;
  /** 対戦結果承認 */
  tournamentNodeApprove?: Maybe<TournamentNodeApprovePayload>;
  /** 対戦結果確認通知 */
  tournamentNodeConfirmRequest?: Maybe<TournamentNodeConfirmRequestPayload>;
  /** 対戦失格 */
  tournamentNodeElimination?: Maybe<TournamentNodeEliminationPayload>;
  /** 対戦結果却下 */
  tournamentNodeReject?: Maybe<TournamentNodeRejectPayload>;
  /** 対戦結果取り消し */
  tournamentNodeRollback?: Maybe<TournamentNodeRollbackPayload>;
  /** オープン大会チェックイン */
  tournamentOpenCheckin?: Maybe<OpenTournamentUser>;
  /** オープン大会エントリー */
  tournamentOpenEntryAdd?: Maybe<OpenTournamentUser>;
  /** オープン大会エントリー取消 */
  tournamentOpenEntryRemove?: Maybe<OpenTournamentUser>;
  /** 大会設定の変更 */
  tournamentSettingSave?: Maybe<TournamentSetting>;
  /** トーナメント開始宣言 */
  tournamentStart?: Maybe<Tournament>;
  /** チーム戦トーナメント参加 */
  tournamentTeamEntryAdd?: Maybe<TournamentTeam>;
  /** チーム戦トーナメント参加取消 */
  tournamentTeamEntryRemove?: Maybe<TournamentTeam>;
  /** チーム戦トーナメント参加情報更新 */
  tournamentTeamEntryUpdate?: Maybe<TournamentTeam>;
  /** トーナメント更新 */
  tournamentUpdate?: Maybe<Tournament>;
  /** ユーザのDiscordアカウントの連携 */
  userLinkDiscord?: Maybe<User>;
  /** ユーザのRiot IDの連携 */
  userLinkRiot?: Maybe<User>;
  /** サインアップ */
  userSignUp?: Maybe<User>;
  /** 退会 */
  userWithdraw?: Maybe<User>;
};


export type MutationChatMessageAddArgs = {
  input: ChatMessageAddInput;
};


export type MutationClanAddArgs = {
  input: ClanAddInput;
};


export type MutationClanArchiveArgs = {
  input: ClanArchiveInput;
};


export type MutationClanExitArgs = {
  input: ClanExitInput;
};


export type MutationClanInviteLinkAddArgs = {
  input: ClanInviteLinkAddInput;
};


export type MutationClanInviteLinkExecuteArgs = {
  input: ClanInviteLinkExecuteInput;
};


export type MutationClanInviteLinkRemoveArgs = {
  input: ClanInviteLinkRemoveInput;
};


export type MutationClanInviteLinkUpdateArgs = {
  input: ClanInviteLinkUpdateInput;
};


export type MutationClanMemberRemoveArgs = {
  input: ClanMemberRemoveInput;
};


export type MutationClanMemberRoleChangeArgs = {
  input: ClanMemberRoleChangeInput;
};


export type MutationClanTransferOwnerArgs = {
  input: ClanTransferOwnerInput;
};


export type MutationClanUpdateArgs = {
  input: ClanUpdateInput;
};


export type MutationCoinExchangeRequestArgs = {
  input: CoinExchangeRequestInput;
};


export type MutationGiftAddCoinArgs = {
  input: GiftAddCoinInput;
};


export type MutationJoinChatRequestAddArgs = {
  input: JoinChatRequestAddInput;
};


export type MutationProfileUpdateArgs = {
  input: ProfileUpdateInput;
};


export type MutationTournamentAddArgs = {
  input: TournamentAddInput;
};


export type MutationTournamentAnnouncementAddArgs = {
  input: TournamentAnnouncementAddInput;
};


export type MutationTournamentAnnouncementRemoveArgs = {
  input: TournamentAnnouncementRemoveInput;
};


export type MutationTournamentCancelArgs = {
  input: TournamentCancelInput;
};


export type MutationTournamentCustomUpdateArgs = {
  input: TournamentCustomUpdateInput;
};


export type MutationTournamentEntryAddArgs = {
  input: TournamentEntryAddInput;
};


export type MutationTournamentEntryRemoveArgs = {
  input: TournamentEntryRemoveInput;
};


export type MutationTournamentForceEntryRemoveArgs = {
  input: TournamentForceEntryRemoveInput;
};


export type MutationTournamentForceOpenEntryRemoveArgs = {
  input: TournamentForceOpenEntryRemoveInput;
};


export type MutationTournamentForceTeamEntryRemoveArgs = {
  input: TournamentForceTeamEntryRemoveInput;
};


export type MutationTournamentNodeApproveArgs = {
  input: TournamentNodeApproveInput;
};


export type MutationTournamentNodeConfirmRequestArgs = {
  input: TournamentNodeConfirmRequestInput;
};


export type MutationTournamentNodeEliminationArgs = {
  input: TournamentNodeEliminationInput;
};


export type MutationTournamentNodeRejectArgs = {
  input: TournamentNodeRejectInput;
};


export type MutationTournamentNodeRollbackArgs = {
  input: TournamentNodeRollbackInput;
};


export type MutationTournamentOpenCheckinArgs = {
  input: TournamentOpenCheckinInput;
};


export type MutationTournamentOpenEntryAddArgs = {
  input: TournamentOpenEntryAddInput;
};


export type MutationTournamentOpenEntryRemoveArgs = {
  input: TournamentOpenEntryRemoveInput;
};


export type MutationTournamentSettingSaveArgs = {
  input: TournamentSettingSaveInput;
};


export type MutationTournamentStartArgs = {
  input: TournamentStartInput;
};


export type MutationTournamentTeamEntryAddArgs = {
  input: TournamentTeamEntryAddInput;
};


export type MutationTournamentTeamEntryRemoveArgs = {
  input: TournamentTeamEntryRemoveInput;
};


export type MutationTournamentTeamEntryUpdateArgs = {
  input: TournamentTeamEntryUpdateInput;
};


export type MutationTournamentUpdateArgs = {
  input: TournamentUpdateInput;
};


export type MutationUserLinkDiscordArgs = {
  input: UserLinkDiscordInput;
};


export type MutationUserLinkRiotArgs = {
  input: UserLinkRiotInput;
};


export type MutationUserSignUpArgs = {
  input: UserSignUpInput;
};


export type MutationUserWithdrawArgs = {
  input: UserWithdrawInput;
};

export type OpenCustomTournamentBracket = {
  __typename?: 'OpenCustomTournamentBracket';
  completed: Scalars['Boolean'];
  teams: Array<OpenCustomTournamentTeam>;
};

export type OpenCustomTournamentTeam = {
  __typename?: 'OpenCustomTournamentTeam';
  awardType: TournamentAwardType;
  team: OpenTournamentTeam;
};

export type OpenSingleEliminationTournamentBracket = {
  __typename?: 'OpenSingleEliminationTournamentBracket';
  inReviewNodes: Array<OpenSingleEliminationTournamentNode>;
  joinChatRequests: Array<JoinChatRequest>;
  maxRound: Scalars['Int'];
  node?: Maybe<OpenSingleEliminationTournamentNode>;
  nodes: Array<OpenSingleEliminationTournamentNode>;
};


export type OpenSingleEliminationTournamentBracketNodeArgs = {
  nodeId: Scalars['ID'];
};

export type OpenSingleEliminationTournamentNode = {
  __typename?: 'OpenSingleEliminationTournamentNode';
  matchIndex: Scalars['Int'];
  nodeId: Scalars['ID'];
  parentNodeId?: Maybe<Scalars['ID']>;
  round: Scalars['Int'];
  status: TournamentNodeStatus;
  statusUpdatedAt: Scalars['ISO8601DateTime'];
  teams: Array<OpenSingleEliminationTournamentTeam>;
};

export type OpenSingleEliminationTournamentTeam = {
  __typename?: 'OpenSingleEliminationTournamentTeam';
  battleResult: TournamentBattleResult;
  index: Scalars['Int'];
  score?: Maybe<Scalars['Int']>;
  team: OpenTournamentTeam;
};

export enum OpenTournamentStatus {
  Entered = 'ENTERED',
  CheckedIn = 'CHECKED_IN',
  Assigned = 'ASSIGNED',
  Unseated = 'UNSEATED',
  NoShow = 'NO_SHOW'
}

export type OpenTournamentTeam = {
  __typename?: 'OpenTournamentTeam';
  teamName: Scalars['String'];
  users: Array<OpenTournamentUser>;
};

export enum OpenTournamentType {
  Hanakin = 'hanakin'
}

export type OpenTournamentUser = {
  __typename?: 'OpenTournamentUser';
  id: Scalars['ID'];
  role?: Maybe<Scalars['String']>;
  status?: Maybe<OpenTournamentStatus>;
  teamName?: Maybe<Scalars['String']>;
  teammates?: Maybe<Array<User>>;
  tournament: Tournament;
  user: User;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Profile = {
  __typename?: 'Profile';
  body?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  id: Scalars['ID'];
  thumbnailUrl?: Maybe<Scalars['Url']>;
  twitterId?: Maybe<Scalars['TwitterId']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of ProfileUpdate */
export type ProfileUpdateInput = {
  displayName?: Maybe<Scalars['String']>;
  twitterId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  thumbnailTmpUrl?: Maybe<Scalars['Url']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /** チャットメッセージ取得 */
  chatMessage: ChatMessageConnection;
  /** クラン単体取得 */
  clan?: Maybe<Clan>;
  /** クラン招待リンク取得 */
  clanInviteLink?: Maybe<ClanInviteLink>;
  /** クラン全招待リンク取得 */
  clanInviteLinks?: Maybe<ClanInviteLinkConnection>;
  /** 補填履歴 */
  compensations: CompensationConnection;
  /** 参加トーナメント */
  enteredTournaments: TournamentConnection;
  /** 注目のトーナメント */
  featuredTournaments: TournamentConnection;
  /** ゲーム大会画像一覧 */
  gameDefaultCovers: GameDefaultCoverConnection;
  /** ゲーム一覧 */
  games: GameConnection;
  /** ジャンル一覧 */
  genres: GenreConnection;
  /** ギフト履歴 */
  gifts: GiftConnection;
  /** ログイン中のユーザーの取得 */
  me: User;
  /** 主催・所属クラン取得 */
  myClans?: Maybe<Array<Clan>>;
  /** コイン交換要求履歴 */
  myCoinExchangeRequests: CoinExchangeRequestConnection;
  /** ウォレット情報取得 */
  myWallet: Wallet;
  /** オープントーナメント参加者 */
  openTournamentUsers: Array<OpenTournamentUser>;
  /** 主催トーナメント */
  organizedTournaments: TournamentConnection;
  /** 登録確認 */
  signUpCheck: Scalars['Boolean'];
  /** トーナメント単体 */
  tournament: Tournament;
  /** 大会アナウンス */
  tournamentAnnouncement: TournamentAnnouncementConnection;
  /** チーム戦大会の戦績 */
  tournamentClanRanking: TournamentClanResultConnection;
  /** 期間中のトーナメント数 */
  tournamentCountsByTerm: Array<DateCount>;
  /** エントリー状況 */
  tournamentEntryStatus: TournamentEntryStatusResponse;
  /** オープン大会の戦績 */
  tournamentOpenRanking: TournamentOpenResultConnection;
  /** 個人戦大会結果 */
  tournamentResults: TournamentResultConnection;
  /** 大会設定 */
  tournamentSetting?: Maybe<TournamentSetting>;
  /** 個人戦大会の戦績 */
  tournamentUserRanking: TournamentResultConnection;
  /** 期間中のトーナメント */
  tournamentsByTerm: TournamentConnection;
  /** ユーザー単体取得 */
  user: User;
  /** オープントーナメント参加資格 */
  userEligibilityForOpenTournament: UserEligibilityForOpenTournament;
  /** ゲーム別ユーザーランキング */
  userRanking: UserRankingResponse;
  /** ユーザー検索 */
  usersByQuery: Array<User>;
};


export type QueryChatMessageArgs = {
  tournamentId: Scalars['ID'];
  nodeId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryClanArgs = {
  id: Scalars['ID'];
};


export type QueryClanInviteLinkArgs = {
  uuid: Scalars['String'];
};


export type QueryClanInviteLinksArgs = {
  clanId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCompensationsArgs = {
  userId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryEnteredTournamentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryFeaturedTournamentsArgs = {
  gameId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGameDefaultCoversArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGamesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGenresArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGiftsArgs = {
  userId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryMyCoinExchangeRequestsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOpenTournamentUsersArgs = {
  tournamentId: Scalars['ID'];
};


export type QueryOrganizedTournamentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryTournamentArgs = {
  id: Scalars['ID'];
};


export type QueryTournamentAnnouncementArgs = {
  tournamentId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryTournamentClanRankingArgs = {
  tournamentId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryTournamentCountsByTermArgs = {
  gameId: Scalars['ID'];
  from: Scalars['ISO8601Date'];
  to: Scalars['ISO8601Date'];
};


export type QueryTournamentEntryStatusArgs = {
  tournamentId: Scalars['ID'];
};


export type QueryTournamentOpenRankingArgs = {
  tournamentId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryTournamentResultsArgs = {
  userId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryTournamentSettingArgs = {
  tournamentType: Scalars['String'];
};


export type QueryTournamentUserRankingArgs = {
  tournamentId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryTournamentsByTermArgs = {
  gameId: Scalars['ID'];
  from: Scalars['ISO8601DateTime'];
  to: Scalars['ISO8601DateTime'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserEligibilityForOpenTournamentArgs = {
  userId: Scalars['ID'];
  openTournamentType: OpenTournamentType;
};


export type QueryUserRankingArgs = {
  gameId: Scalars['ID'];
};


export type QueryUsersByQueryArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SingleEliminationTournamentBracket = {
  __typename?: 'SingleEliminationTournamentBracket';
  inReviewNodes: Array<SingleEliminationTournamentNode>;
  joinChatRequests: Array<JoinChatRequest>;
  maxRound: Scalars['Int'];
  node?: Maybe<SingleEliminationTournamentNode>;
  nodes: Array<SingleEliminationTournamentNode>;
};


export type SingleEliminationTournamentBracketNodeArgs = {
  nodeId: Scalars['ID'];
};

export type SingleEliminationTournamentNode = {
  __typename?: 'SingleEliminationTournamentNode';
  matchIndex: Scalars['Int'];
  nodeId: Scalars['ID'];
  parentNodeId?: Maybe<Scalars['ID']>;
  players: Array<SingleEliminationTournamentPlayer>;
  round: Scalars['Int'];
  status: TournamentNodeStatus;
  statusUpdatedAt: Scalars['ISO8601DateTime'];
};

export type SingleEliminationTournamentPlayer = {
  __typename?: 'SingleEliminationTournamentPlayer';
  battleResult: TournamentBattleResult;
  index: Scalars['Int'];
  score?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
};

export type Sponsor = {
  __typename?: 'Sponsor';
  bannerUrl: Scalars['Url'];
  createdAt: Scalars['ISO8601DateTime'];
  iconUrl: Scalars['Url'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type SponsorCampaign = {
  __typename?: 'SponsorCampaign';
  coverUrl: Scalars['Url'];
  createdAt: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  gameIds: Array<Scalars['ID']>;
  id: Scalars['ID'];
  landingUrl: Scalars['Url'];
  sponsor: Sponsor;
  title: Scalars['String'];
  tournamentIds: Array<Scalars['ID']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type TeamCustomTournamentBracket = {
  __typename?: 'TeamCustomTournamentBracket';
  completed: Scalars['Boolean'];
  teams: Array<TeamCustomTournamentTeam>;
};

export type TeamCustomTournamentTeam = {
  __typename?: 'TeamCustomTournamentTeam';
  awardType: TournamentAwardType;
  team: TournamentTeam;
};

export type TeamSingleEliminationTournamentBracket = {
  __typename?: 'TeamSingleEliminationTournamentBracket';
  inReviewNodes: Array<TeamSingleEliminationTournamentNode>;
  joinChatRequests: Array<JoinChatRequest>;
  maxRound: Scalars['Int'];
  node?: Maybe<TeamSingleEliminationTournamentNode>;
  nodes: Array<TeamSingleEliminationTournamentNode>;
};


export type TeamSingleEliminationTournamentBracketNodeArgs = {
  nodeId: Scalars['ID'];
};

export type TeamSingleEliminationTournamentNode = {
  __typename?: 'TeamSingleEliminationTournamentNode';
  matchIndex: Scalars['Int'];
  nodeId: Scalars['ID'];
  parentNodeId?: Maybe<Scalars['ID']>;
  round: Scalars['Int'];
  status: TournamentNodeStatus;
  statusUpdatedAt: Scalars['ISO8601DateTime'];
  teams: Array<TeamSingleEliminationTournamentTeam>;
};

export type TeamSingleEliminationTournamentTeam = {
  __typename?: 'TeamSingleEliminationTournamentTeam';
  battleResult: TournamentBattleResult;
  index: Scalars['Int'];
  score?: Maybe<Scalars['Int']>;
  team: TournamentTeam;
};

export type Tournament = {
  __typename?: 'Tournament';
  bracket?: Maybe<TournamentBracket>;
  bracketType: BracketType;
  checkinNum?: Maybe<Scalars['Int']>;
  contactDiscord?: Maybe<Scalars['DiscordUrl']>;
  contactEmail: Scalars['Email'];
  contactTwitter?: Maybe<Scalars['TwitterId']>;
  coverPreviewUrl: Scalars['Url'];
  coverUrl: Scalars['Url'];
  entries: Array<User>;
  entryNum: Scalars['Int'];
  entryTeams: Array<TournamentTeam>;
  game: Game;
  gameDefaultCover?: Maybe<GameDefaultCover>;
  id: Scalars['ID'];
  isPrivate?: Maybe<Scalars['Boolean']>;
  maxEntry: Scalars['Int'];
  openCheckinEndsAt?: Maybe<Scalars['ISO8601DateTime']>;
  openCheckinStartsAt?: Maybe<Scalars['ISO8601DateTime']>;
  openEntryEndsAt?: Maybe<Scalars['ISO8601DateTime']>;
  openEntryStartsAt?: Maybe<Scalars['ISO8601DateTime']>;
  openTournamentType?: Maybe<OpenTournamentType>;
  organizer: User;
  rewardNote: Scalars['String'];
  rules: Scalars['String'];
  scoreToWin: Scalars['Int'];
  sponsorCampaign?: Maybe<SponsorCampaign>;
  status: TournamentStatus;
  title: Scalars['String'];
  unitType: TournamentUnitType;
  updatedAt: Scalars['ISO8601DateTime'];
  willStartAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of TournamentAdd */
export type TournamentAddInput = {
  title: Scalars['String'];
  gameId: Scalars['ID'];
  bracketType: BracketType;
  unitType: TournamentUnitType;
  willStartAt: Scalars['ISO8601DateTime'];
  maxEntry: Scalars['Int'];
  scoreToWin: Scalars['Int'];
  rules: Scalars['String'];
  rewardNote?: Maybe<Scalars['String']>;
  contactEmail: Scalars['Email'];
  contactTwitter?: Maybe<Scalars['TwitterId']>;
  contactDiscord?: Maybe<Scalars['DiscordUrl']>;
  coverTmpUrl?: Maybe<Scalars['String']>;
  gameDefaultCoverId?: Maybe<Scalars['ID']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  openTournamentType?: Maybe<OpenTournamentType>;
  openEntryStartsAt?: Maybe<Scalars['ISO8601DateTime']>;
  openEntryEndsAt?: Maybe<Scalars['ISO8601DateTime']>;
  openCheckinStartsAt?: Maybe<Scalars['ISO8601DateTime']>;
  openCheckinEndsAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type TournamentAnnouncement = {
  __typename?: 'TournamentAnnouncement';
  body: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  tournament: Tournament;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

/** Autogenerated input type of TournamentAnnouncementAdd */
export type TournamentAnnouncementAddInput = {
  body: Scalars['String'];
  tournamentId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The connection type for TournamentAnnouncement. */
export type TournamentAnnouncementConnection = {
  __typename?: 'TournamentAnnouncementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TournamentAnnouncementEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TournamentAnnouncement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TournamentAnnouncementEdge = {
  __typename?: 'TournamentAnnouncementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TournamentAnnouncement>;
};

/** Autogenerated input type of TournamentAnnouncementRemove */
export type TournamentAnnouncementRemoveInput = {
  id: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum TournamentAwardType {
  First = 'FIRST',
  Second = 'SECOND',
  Third = 'THIRD',
  Fourth = 'FOURTH',
  Fifth = 'FIFTH',
  Sixth = 'SIXTH',
  Seventh = 'SEVENTH',
  Eighth = 'EIGHTH',
  Ninth = 'NINTH',
  Tenth = 'TENTH',
  Participation = 'PARTICIPATION',
  Eliminated = 'ELIMINATED',
  Organizer = 'ORGANIZER'
}

export enum TournamentBattleResult {
  Winner = 'WINNER',
  Loser = 'LOSER',
  Eliminated = 'ELIMINATED',
  NotDetermined = 'NOT_DETERMINED'
}

/** トーナメント情報 */
export type TournamentBracket = CustomTournamentBracket | OpenCustomTournamentBracket | OpenSingleEliminationTournamentBracket | SingleEliminationTournamentBracket | TeamCustomTournamentBracket | TeamSingleEliminationTournamentBracket;

/** Autogenerated input type of TournamentCancel */
export type TournamentCancelInput = {
  tournamentId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum TournamentClanAwardType {
  First = 'FIRST',
  Second = 'SECOND',
  Third = 'THIRD',
  Fourth = 'FOURTH',
  Fifth = 'FIFTH',
  Sixth = 'SIXTH',
  Seventh = 'SEVENTH',
  Eighth = 'EIGHTH',
  Ninth = 'NINTH',
  Tenth = 'TENTH',
  Participation = 'PARTICIPATION',
  Eliminated = 'ELIMINATED'
}

export type TournamentClanResult = {
  __typename?: 'TournamentClanResult';
  awardType: TournamentClanAwardType;
  clan: Clan;
  id: Scalars['ID'];
  tournament: Tournament;
  tournamentTeam: TournamentTeam;
};

/** The connection type for TournamentClanResult. */
export type TournamentClanResultConnection = {
  __typename?: 'TournamentClanResultConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TournamentClanResultEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TournamentClanResult>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TournamentClanResultEdge = {
  __typename?: 'TournamentClanResultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TournamentClanResult>;
};

/** The connection type for Tournament. */
export type TournamentConnection = {
  __typename?: 'TournamentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TournamentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Tournament>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated input type of TournamentCustomUpdate */
export type TournamentCustomUpdateInput = {
  tournamentId: Scalars['ID'];
  updates: Array<TournamentCustomUpdateUnitInput>;
  completed: Scalars['Boolean'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** ユニット情報。オープン大会以外は unit_id が、オープン大会は team_name が必須 */
export type TournamentCustomUpdateUnitInput = {
  unitId?: Maybe<Scalars['ID']>;
  teamName?: Maybe<Scalars['String']>;
  awardType: TournamentAwardType;
};

/** An edge in a connection. */
export type TournamentEdge = {
  __typename?: 'TournamentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Tournament>;
};

/** Autogenerated input type of TournamentEntryAdd */
export type TournamentEntryAddInput = {
  tournamentId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of TournamentEntryRemove */
export type TournamentEntryRemoveInput = {
  tournamentId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum TournamentEntryStatusReason {
  NotBelongToClan = 'NOT_BELONG_TO_CLAN',
  ReachEntryMax = 'REACH_ENTRY_MAX',
  AllYourClansEntered = 'ALL_YOUR_CLANS_ENTERED',
  NotEntryPeriod = 'NOT_ENTRY_PERIOD'
}

export type TournamentEntryStatusResponse = {
  __typename?: 'TournamentEntryStatusResponse';
  /** オープン大会でのみ使用 */
  checkedIn?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<TournamentEntryStatusReason>;
  status: TournamentEntryStatusStatus;
};

export enum TournamentEntryStatusStatus {
  Entered = 'ENTERED',
  CanEnter = 'CAN_ENTER',
  CannotEnter = 'CANNOT_ENTER'
}

/** Autogenerated input type of TournamentForceEntryRemove */
export type TournamentForceEntryRemoveInput = {
  tournamentId: Scalars['ID'];
  userId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of TournamentForceOpenEntryRemove */
export type TournamentForceOpenEntryRemoveInput = {
  tournamentId: Scalars['ID'];
  userId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of TournamentForceTeamEntryRemove */
export type TournamentForceTeamEntryRemoveInput = {
  tournamentId: Scalars['ID'];
  tournamentTeamId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** トーナメント対戦情報 */
export type TournamentNode = OpenSingleEliminationTournamentNode | SingleEliminationTournamentNode | TeamSingleEliminationTournamentNode;

/** Autogenerated input type of TournamentNodeApprove */
export type TournamentNodeApproveInput = {
  tournamentId: Scalars['ID'];
  nodeId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of TournamentNodeApprove */
export type TournamentNodeApprovePayload = {
  __typename?: 'TournamentNodeApprovePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  node: TournamentNode;
};

/** Autogenerated input type of TournamentNodeConfirmRequest */
export type TournamentNodeConfirmRequestInput = {
  tournamentId: Scalars['ID'];
  nodeId: Scalars['ID'];
  units: Array<TournamentNodeConfirmRequestUnitInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of TournamentNodeConfirmRequest */
export type TournamentNodeConfirmRequestPayload = {
  __typename?: 'TournamentNodeConfirmRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  node: TournamentNode;
};

/** 対戦者別結果。オープン大会以外は unit_id が、オープン大会は team_name が必須 */
export type TournamentNodeConfirmRequestUnitInput = {
  unitId?: Maybe<Scalars['ID']>;
  teamName?: Maybe<Scalars['String']>;
  score: Scalars['Int'];
};

/** Autogenerated input type of TournamentNodeElimination */
export type TournamentNodeEliminationInput = {
  tournamentId: Scalars['ID'];
  nodeId: Scalars['ID'];
  userIds?: Maybe<Array<Scalars['ID']>>;
  teamNames?: Maybe<Array<Scalars['String']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of TournamentNodeElimination */
export type TournamentNodeEliminationPayload = {
  __typename?: 'TournamentNodeEliminationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  node: TournamentNode;
};

/** Autogenerated input type of TournamentNodeReject */
export type TournamentNodeRejectInput = {
  tournamentId: Scalars['ID'];
  nodeId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of TournamentNodeReject */
export type TournamentNodeRejectPayload = {
  __typename?: 'TournamentNodeRejectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  node: TournamentNode;
};

/** Autogenerated input type of TournamentNodeRollback */
export type TournamentNodeRollbackInput = {
  tournamentId: Scalars['ID'];
  nodeId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of TournamentNodeRollback */
export type TournamentNodeRollbackPayload = {
  __typename?: 'TournamentNodeRollbackPayload';
  bracket: TournamentBracket;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum TournamentNodeStatus {
  Pending = 'PENDING',
  InBattle = 'IN_BATTLE',
  InReview = 'IN_REVIEW',
  Confirm = 'CONFIRM'
}

/** Autogenerated input type of TournamentOpenCheckin */
export type TournamentOpenCheckinInput = {
  tournamentId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of TournamentOpenEntryAdd */
export type TournamentOpenEntryAddInput = {
  tournamentId: Scalars['ID'];
  /** current_user 以外に同時にエントリーするユーザーのID */
  userIds?: Maybe<Array<Scalars['ID']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of TournamentOpenEntryRemove */
export type TournamentOpenEntryRemoveInput = {
  tournamentId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type TournamentOpenResult = {
  __typename?: 'TournamentOpenResult';
  awardType: TournamentClanAwardType;
  id: Scalars['ID'];
  teamName: Scalars['String'];
  tournament: Tournament;
  users: Array<User>;
};

/** The connection type for TournamentOpenResult. */
export type TournamentOpenResultConnection = {
  __typename?: 'TournamentOpenResultConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TournamentOpenResultEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TournamentOpenResult>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TournamentOpenResultEdge = {
  __typename?: 'TournamentOpenResultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TournamentOpenResult>;
};

export type TournamentResult = {
  __typename?: 'TournamentResult';
  awardType: TournamentAwardType;
  coinIssuance?: Maybe<CoinIssuance>;
  earnedExp: Scalars['Int'];
  game: Game;
  id: Scalars['ID'];
  tournament: Tournament;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

/** The connection type for TournamentResult. */
export type TournamentResultConnection = {
  __typename?: 'TournamentResultConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TournamentResultEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TournamentResult>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TournamentResultEdge = {
  __typename?: 'TournamentResultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TournamentResult>;
};

export type TournamentSetting = {
  __typename?: 'TournamentSetting';
  config?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  tournamentType: Scalars['String'];
};

/** Autogenerated input type of TournamentSettingSave */
export type TournamentSettingSaveInput = {
  tournamentType: Scalars['String'];
  config: Scalars['JSON'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of TournamentStart */
export type TournamentStartInput = {
  tournamentId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum TournamentStatus {
  Public = 'PUBLIC',
  InProgress = 'IN_PROGRESS',
  Finish = 'FINISH',
  Cancel = 'CANCEL'
}

export type TournamentTeam = {
  __typename?: 'TournamentTeam';
  clan: Clan;
  id: Scalars['ID'];
  managers: Array<User>;
  users: Array<User>;
};

/** Autogenerated input type of TournamentTeamEntryAdd */
export type TournamentTeamEntryAddInput = {
  tournamentId: Scalars['ID'];
  clanId: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
  managerIds: Array<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of TournamentTeamEntryRemove */
export type TournamentTeamEntryRemoveInput = {
  tournamentTeamId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of TournamentTeamEntryUpdate */
export type TournamentTeamEntryUpdateInput = {
  tournamentTeamId: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
  managerIds: Array<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum TournamentUnitType {
  Single = 'SINGLE',
  Team_2Vs2 = 'TEAM_2VS2',
  Team_3Vs3 = 'TEAM_3VS3',
  Team_4Vs4 = 'TEAM_4VS4',
  Team_5Vs5 = 'TEAM_5VS5'
}

/** Autogenerated input type of TournamentUpdate */
export type TournamentUpdateInput = {
  tournamentId: Scalars['ID'];
  bracketType?: Maybe<BracketType>;
  title?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  rewardNote?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['Email']>;
  contactTwitter?: Maybe<Scalars['TwitterId']>;
  contactDiscord?: Maybe<Scalars['DiscordUrl']>;
  coverTmpUrl?: Maybe<Scalars['String']>;
  gameDefaultCoverId?: Maybe<Scalars['String']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  openEntryStartsAt?: Maybe<Scalars['ISO8601DateTime']>;
  openEntryEndsAt?: Maybe<Scalars['ISO8601DateTime']>;
  openCheckinStartsAt?: Maybe<Scalars['ISO8601DateTime']>;
  openCheckinEndsAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};



export type User = {
  __typename?: 'User';
  discordId?: Maybe<Scalars['DiscordId']>;
  discordUsername?: Maybe<Scalars['String']>;
  editableOpenTournamentTypes?: Maybe<Array<OpenTournamentType>>;
  gameExp?: Maybe<GameExp>;
  gameExps: Array<GameExp>;
  id: Scalars['ID'];
  isAnnouncementAuditor: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isTournamentModerator: Scalars['Boolean'];
  level: Level;
  monthlyTicketCount: Scalars['Int'];
  profile: Profile;
  riotId?: Maybe<Scalars['String']>;
  totalExp: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
  verifiedOrganizerLevel: Scalars['Int'];
};


export type UserGameExpArgs = {
  gameId: Scalars['ID'];
};

export type UserEligibilityForOpenTournament = {
  __typename?: 'UserEligibilityForOpenTournament';
  isEligible: Scalars['Boolean'];
  missingRequirements?: Maybe<Array<Scalars['String']>>;
  openTournamentType: OpenTournamentType;
  userId: Scalars['Int'];
};

/** Autogenerated input type of UserLinkDiscord */
export type UserLinkDiscordInput = {
  code: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UserLinkRiot */
export type UserLinkRiotInput = {
  code: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserRankingResponse = {
  __typename?: 'UserRankingResponse';
  targetNum: Scalars['Int'];
  users: Array<User>;
};

/** Autogenerated input type of UserSignUp */
export type UserSignUpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UserWithdraw */
export type UserWithdrawInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Wallet = {
  __typename?: 'Wallet';
  availableCoin: Scalars['Int'];
  coin: Scalars['Int'];
  id: Scalars['ID'];
  requestedCoin: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ClanFragment = (
  { __typename?: 'Clan' }
  & Pick<Clan, 'id' | 'name' | 'description' | 'logoUrl' | 'coverUrl' | 'memberNum' | 'maxMember' | 'isArchived' | 'updatedAt'>
);

export type ClanDetailFragment = (
  { __typename?: 'Clan' }
  & Pick<Clan, 'id' | 'name' | 'description' | 'logoUrl' | 'coverUrl' | 'memberNum' | 'maxMember' | 'updatedAt'>
  & { owner: (
    { __typename?: 'User' }
    & UserFragment
  ), members: Array<(
    { __typename?: 'User' }
    & UserFragment
  )>, clanUserRoles: Array<(
    { __typename?: 'ClanUserRole' }
    & ClanUserRoleFragment
  )> }
);

export type ClanInviteLinkFragment = (
  { __typename?: 'ClanInviteLink' }
  & Pick<ClanInviteLink, 'id' | 'uuid' | 'maxNum' | 'currentNum' | 'expiresOn' | 'isExpired' | 'updatedAt'>
  & { clan: (
    { __typename?: 'Clan' }
    & ClanFragment
  ) }
);

export type ClanUserRoleFragment = (
  { __typename?: 'ClanUserRole' }
  & { user: (
    { __typename?: 'User' }
    & UserFragment
  ), role: (
    { __typename?: 'Role' }
    & RoleFragment
  ) }
);

export type CoverImageSelectFragment = (
  { __typename?: 'GameDefaultCover' }
  & Pick<GameDefaultCover, 'id' | 'coverUrl'>
  & { game: (
    { __typename?: 'Game' }
    & Pick<Game, 'id'>
  ) }
);

export type GameFragment = (
  { __typename?: 'Game' }
  & Pick<Game, 'id' | 'title' | 'iconUrl' | 'enabled' | 'updatedAt'>
  & { genre: (
    { __typename?: 'Genre' }
    & GenreFragment
  ) }
);

export type GameExpFragment = (
  { __typename?: 'GameExp' }
  & Pick<GameExp, 'exp' | 'id' | 'rank' | 'title' | 'updatedAt'>
  & { game: (
    { __typename?: 'Game' }
    & GameFragment
  ) }
);

export type GenreFragment = (
  { __typename?: 'Genre' }
  & Pick<Genre, 'id' | 'name'>
);

export type GenreWithGameFragment = (
  { __typename?: 'Genre' }
  & Pick<Genre, 'id' | 'name'>
  & { games: Array<(
    { __typename?: 'Game' }
    & GameFragment
  )> }
);

export type GiftFragment = (
  { __typename?: 'Gift' }
  & Pick<Gift, 'id' | 'giftType' | 'amount' | 'message' | 'createdAt' | 'updatedAt'>
  & { from: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'displayName' | 'thumbnailUrl'>
    ) }
  ), to: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'displayName' | 'thumbnailUrl'>
    ) }
  ) }
);

export type RoleFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'name'>
);

export type TournamentJoinChatRequestFragment = (
  { __typename?: 'JoinChatRequest' }
  & Pick<JoinChatRequest, 'requestedAt'>
  & { node: (
    { __typename?: 'OpenSingleEliminationTournamentNode' }
    & Pick<OpenSingleEliminationTournamentNode, 'nodeId' | 'status'>
    & { teams: Array<(
      { __typename?: 'OpenSingleEliminationTournamentTeam' }
      & OpenSingleEliminationTournamentTeamFragment
    )> }
  ) | (
    { __typename?: 'SingleEliminationTournamentNode' }
    & Pick<SingleEliminationTournamentNode, 'nodeId' | 'status'>
    & { players: Array<(
      { __typename?: 'SingleEliminationTournamentPlayer' }
      & TournamentPlayerFragment
    )> }
  ) | (
    { __typename?: 'TeamSingleEliminationTournamentNode' }
    & Pick<TeamSingleEliminationTournamentNode, 'nodeId' | 'status'>
    & { teams: Array<(
      { __typename?: 'TeamSingleEliminationTournamentTeam' }
      & TeamTournamentTeamFragment
    )> }
  ) }
);

export type TournamentNodeFragment = (
  { __typename?: 'SingleEliminationTournamentNode' }
  & Pick<SingleEliminationTournamentNode, 'nodeId' | 'parentNodeId' | 'round' | 'status' | 'matchIndex'>
  & { players: Array<(
    { __typename?: 'SingleEliminationTournamentPlayer' }
    & TournamentPlayerFragment
  )> }
);

export type TournamentPlayerFragment = (
  { __typename?: 'SingleEliminationTournamentPlayer' }
  & Pick<SingleEliminationTournamentPlayer, 'index' | 'score' | 'battleResult'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserFragment
  )> }
);

export type CustomTournamentPlaterFragment = (
  { __typename?: 'CustomTournamentPlayer' }
  & Pick<CustomTournamentPlayer, 'awardType'>
  & { user: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type TeamTournamentNodeFragment = (
  { __typename?: 'TeamSingleEliminationTournamentNode' }
  & Pick<TeamSingleEliminationTournamentNode, 'nodeId' | 'parentNodeId' | 'matchIndex' | 'round' | 'status' | 'statusUpdatedAt'>
  & { teams: Array<(
    { __typename?: 'TeamSingleEliminationTournamentTeam' }
    & TeamTournamentTeamFragment
  )> }
);

export type TeamTournamentTeamFragment = (
  { __typename?: 'TeamSingleEliminationTournamentTeam' }
  & Pick<TeamSingleEliminationTournamentTeam, 'index' | 'score' | 'battleResult'>
  & { team: (
    { __typename?: 'TournamentTeam' }
    & TournamentTeamFragment
  ) }
);

export type TeamCustomTournamentTeamFragment = (
  { __typename?: 'TeamCustomTournamentTeam' }
  & Pick<TeamCustomTournamentTeam, 'awardType'>
  & { team: (
    { __typename?: 'TournamentTeam' }
    & TournamentTeamFragment
  ) }
);

export type OpenTournamentNodeFragment = (
  { __typename?: 'OpenSingleEliminationTournamentNode' }
  & Pick<OpenSingleEliminationTournamentNode, 'nodeId' | 'parentNodeId' | 'round' | 'status' | 'matchIndex'>
  & { teams: Array<(
    { __typename?: 'OpenSingleEliminationTournamentTeam' }
    & OpenSingleEliminationTournamentTeamFragment
  )> }
);

export type OpenSingleEliminationTournamentTeamFragment = (
  { __typename?: 'OpenSingleEliminationTournamentTeam' }
  & Pick<OpenSingleEliminationTournamentTeam, 'index' | 'score' | 'battleResult'>
  & { team: (
    { __typename?: 'OpenTournamentTeam' }
    & OpenTournamentTeamFragment
  ) }
);

export type OpenCustomTournamentTeamFragment = (
  { __typename?: 'OpenCustomTournamentTeam' }
  & Pick<OpenCustomTournamentTeam, 'awardType'>
  & { team: (
    { __typename?: 'OpenTournamentTeam' }
    & OpenTournamentTeamFragment
  ) }
);

export type TournamentFragment = (
  { __typename?: 'Tournament' }
  & Pick<Tournament, 'id' | 'title' | 'willStartAt' | 'maxEntry' | 'entryNum' | 'checkinNum' | 'coverUrl' | 'status' | 'bracketType' | 'unitType' | 'isPrivate'>
  & { game: (
    { __typename?: 'Game' }
    & GameFragment
  ) }
);

export type TournamentCardFragment = (
  { __typename?: 'Tournament' }
  & Pick<Tournament, 'id' | 'title' | 'willStartAt' | 'maxEntry' | 'entryNum' | 'coverPreviewUrl' | 'status' | 'unitType' | 'openTournamentType'>
  & { organizer: (
    { __typename?: 'User' }
    & Pick<User, 'verifiedOrganizerLevel'>
  ), sponsorCampaign?: Maybe<(
    { __typename?: 'SponsorCampaign' }
    & Pick<SponsorCampaign, 'landingUrl'>
    & { sponsor: (
      { __typename?: 'Sponsor' }
      & Pick<Sponsor, 'name' | 'iconUrl'>
    ) }
  )> }
);

export type TournamentAnnouncementFragment = (
  { __typename?: 'TournamentAnnouncement' }
  & Pick<TournamentAnnouncement, 'id' | 'body' | 'isDeleted' | 'createdAt' | 'updatedAt'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'displayName' | 'thumbnailUrl'>
    ) }
  ) }
);

export type TournamentTeamFragment = (
  { __typename?: 'TournamentTeam' }
  & Pick<TournamentTeam, 'id'>
  & { clan: (
    { __typename?: 'Clan' }
    & ClanFragment
  ), users: Array<(
    { __typename?: 'User' }
    & UserFragment
  )>, managers: Array<(
    { __typename?: 'User' }
    & UserFragment
  )> }
);

export type TournamentResultFragment = (
  { __typename?: 'TournamentResult' }
  & Pick<TournamentResult, 'awardType' | 'earnedExp' | 'id' | 'updatedAt'>
  & { coinIssuance?: Maybe<(
    { __typename?: 'CoinIssuance' }
    & CoinIssuanceFragment
  )>, game: (
    { __typename?: 'Game' }
    & GameFragment
  ), tournament: (
    { __typename?: 'Tournament' }
    & TournamentFragment
  ) }
);

export type ChatMessageFragment = (
  { __typename?: 'ChatMessage' }
  & Pick<ChatMessage, 'id' | 'sentAt' | 'messageType' | 'body'>
  & { user: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type OpenTournamentUserFragment = (
  { __typename?: 'OpenTournamentUser' }
  & Pick<OpenTournamentUser, 'id' | 'teamName' | 'status' | 'role'>
  & { user: (
    { __typename?: 'User' }
    & UserFragment
  ), tournament: (
    { __typename?: 'Tournament' }
    & Pick<Tournament, 'openTournamentType'>
  ) }
);

export type OpenTournamentTeamFragment = (
  { __typename?: 'OpenTournamentTeam' }
  & Pick<OpenTournamentTeam, 'teamName'>
  & { users: Array<(
    { __typename?: 'OpenTournamentUser' }
    & OpenTournamentUserFragment
  )> }
);

export type UserOrganizerFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'verifiedOrganizerLevel'>
  & { profile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'displayName' | 'thumbnailUrl' | 'twitterId'>
  ) }
);

export type UserEntryFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
  & { gameExps: Array<(
    { __typename?: 'GameExp' }
    & GameExpFragment
  )>, level: (
    { __typename?: 'Level' }
    & Pick<Level, 'level'>
  ), profile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'displayName' | 'thumbnailUrl' | 'twitterId'>
  ) }
);

export type UserRankingFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
  & { profile: (
    { __typename?: 'Profile' }
    & ProfileFragment
  ), gameExp?: Maybe<(
    { __typename?: 'GameExp' }
    & GameExpFragment
  )>, level: (
    { __typename?: 'Level' }
    & LevelFragment
  ) }
);

export type ProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'body' | 'displayName' | 'id' | 'thumbnailUrl' | 'twitterId' | 'updatedAt'>
);

export type LevelFragment = (
  { __typename?: 'Level' }
  & Pick<Level, 'level' | 'maxExp' | 'minExp'>
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'totalExp' | 'id' | 'verifiedOrganizerLevel' | 'updatedAt' | 'monthlyTicketCount' | 'isDeleted' | 'editableOpenTournamentTypes'>
  & { gameExps: Array<(
    { __typename?: 'GameExp' }
    & GameExpFragment
  )>, level: (
    { __typename?: 'Level' }
    & LevelFragment
  ), profile: (
    { __typename?: 'Profile' }
    & ProfileFragment
  ) }
);

export type DrawerMeFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'verifiedOrganizerLevel' | 'monthlyTicketCount'>
  & { profile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'displayName' | 'thumbnailUrl'>
  ) }
);

export type MyVerifiedOrganizerLevelFragment = (
  { __typename?: 'User' }
  & Pick<User, 'verifiedOrganizerLevel'>
);

export type CoinIssuanceFragment = (
  { __typename?: 'CoinIssuance' }
  & Pick<CoinIssuance, 'coin' | 'id' | 'updatedAt'>
);

export type CompensationFragment = (
  { __typename?: 'Compensation' }
  & Pick<Compensation, 'id' | 'exp' | 'memo' | 'createdAt'>
  & { coinIssuance?: Maybe<(
    { __typename?: 'CoinIssuance' }
    & CoinIssuanceFragment
  )> }
);

export type SearchUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
  & { profile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'displayName' | 'twitterId' | 'thumbnailUrl'>
  ) }
);

export type TournamentSettingFragment = (
  { __typename?: 'TournamentSetting' }
  & Pick<TournamentSetting, 'tournamentType' | 'config'>
);

export type CoinExchangeRequestFragment = (
  { __typename?: 'CoinExchangeRequest' }
  & Pick<CoinExchangeRequest, 'id' | 'coin' | 'createdAt' | 'exchangeType' | 'status'>
);

export type ClanInviteLinkAddMutationVariables = Exact<{
  input: ClanInviteLinkAddInput;
}>;


export type ClanInviteLinkAddMutation = (
  { __typename?: 'Mutation' }
  & { clanInviteLinkAdd?: Maybe<(
    { __typename?: 'ClanInviteLink' }
    & ClanInviteLinkFragment
  )> }
);

export type ClanInviteLinkUpdateMutationVariables = Exact<{
  input: ClanInviteLinkUpdateInput;
}>;


export type ClanInviteLinkUpdateMutation = (
  { __typename?: 'Mutation' }
  & { clanInviteLinkUpdate?: Maybe<(
    { __typename?: 'ClanInviteLink' }
    & ClanInviteLinkFragment
  )> }
);

export type ClanInviteLinkRemoveMutationVariables = Exact<{
  input: ClanInviteLinkRemoveInput;
}>;


export type ClanInviteLinkRemoveMutation = (
  { __typename?: 'Mutation' }
  & { deleted?: Maybe<(
    { __typename?: 'ClanInviteLink' }
    & Pick<ClanInviteLink, 'id'>
  )> }
);

export type ClanInviteLinkExecuteMutationVariables = Exact<{
  input: ClanInviteLinkExecuteInput;
}>;


export type ClanInviteLinkExecuteMutation = (
  { __typename?: 'Mutation' }
  & { clanInviteLinkExecute?: Maybe<(
    { __typename?: 'ClanInviteLink' }
    & ClanInviteLinkFragment
  )> }
);

export type ClanAddMutationVariables = Exact<{
  input: ClanAddInput;
}>;


export type ClanAddMutation = (
  { __typename?: 'Mutation' }
  & { clanAdd?: Maybe<(
    { __typename?: 'Clan' }
    & ClanFragment
  )> }
);

export type ClanUpdateMutationVariables = Exact<{
  input: ClanUpdateInput;
}>;


export type ClanUpdateMutation = (
  { __typename?: 'Mutation' }
  & { clanUpdate?: Maybe<(
    { __typename?: 'Clan' }
    & ClanFragment
  )> }
);

export type ClanArchiveMutationVariables = Exact<{
  input: ClanArchiveInput;
}>;


export type ClanArchiveMutation = (
  { __typename?: 'Mutation' }
  & { clanArchive?: Maybe<(
    { __typename?: 'Clan' }
    & ClanFragment
  )> }
);

export type ClanExitMutationVariables = Exact<{
  input: ClanExitInput;
}>;


export type ClanExitMutation = (
  { __typename?: 'Mutation' }
  & { clanExit?: Maybe<(
    { __typename?: 'Clan' }
    & ClanDetailFragment
  )> }
);

export type ClanTransferOwnerMutationVariables = Exact<{
  input: ClanTransferOwnerInput;
}>;


export type ClanTransferOwnerMutation = (
  { __typename?: 'Mutation' }
  & { clanTransferOwner?: Maybe<(
    { __typename?: 'Clan' }
    & ClanDetailFragment
  )> }
);

export type ClanMemberRemoveMutationVariables = Exact<{
  input: ClanMemberRemoveInput;
}>;


export type ClanMemberRemoveMutation = (
  { __typename?: 'Mutation' }
  & { clanMemberRemove?: Maybe<(
    { __typename?: 'Clan' }
    & ClanDetailFragment
  )> }
);

export type ClanMemberRolechangeMutationVariables = Exact<{
  input: ClanMemberRoleChangeInput;
}>;


export type ClanMemberRolechangeMutation = (
  { __typename?: 'Mutation' }
  & { clanMemberRoleChange?: Maybe<(
    { __typename?: 'Clan' }
    & ClanDetailFragment
  )> }
);

export type GiftAddCoinMutationVariables = Exact<{
  input: GiftAddCoinInput;
}>;


export type GiftAddCoinMutation = (
  { __typename?: 'Mutation' }
  & { giftAddCoin?: Maybe<(
    { __typename?: 'Gift' }
    & Pick<Gift, 'id'>
  )> }
);

export type TournamentTeamEntryAddMutationVariables = Exact<{
  input: TournamentTeamEntryAddInput;
}>;


export type TournamentTeamEntryAddMutation = (
  { __typename?: 'Mutation' }
  & { tournamentTeamEntryAdd?: Maybe<(
    { __typename?: 'TournamentTeam' }
    & Pick<TournamentTeam, 'id'>
  )> }
);

export type TournamentTeamEntryUpdateMutationVariables = Exact<{
  input: TournamentTeamEntryUpdateInput;
}>;


export type TournamentTeamEntryUpdateMutation = (
  { __typename?: 'Mutation' }
  & { tournamentTeamEntryUpdate?: Maybe<(
    { __typename?: 'TournamentTeam' }
    & Pick<TournamentTeam, 'id'>
  )> }
);

export type TournamentTeamEntryRemoveMutationVariables = Exact<{
  input: TournamentTeamEntryRemoveInput;
}>;


export type TournamentTeamEntryRemoveMutation = (
  { __typename?: 'Mutation' }
  & { tournamentTeamEntryRemove?: Maybe<(
    { __typename?: 'TournamentTeam' }
    & Pick<TournamentTeam, 'id'>
  )> }
);

export type TournamentTeamForceEntryRemoveMutationVariables = Exact<{
  input: TournamentForceTeamEntryRemoveInput;
}>;


export type TournamentTeamForceEntryRemoveMutation = (
  { __typename?: 'Mutation' }
  & { tournamentForceTeamEntryRemove?: Maybe<(
    { __typename?: 'Tournament' }
    & Pick<Tournament, 'id'>
  )> }
);

export type TournamentAddMutationVariables = Exact<{
  input: TournamentAddInput;
}>;


export type TournamentAddMutation = (
  { __typename?: 'Mutation' }
  & { tournamentAdd?: Maybe<(
    { __typename?: 'Tournament' }
    & Pick<Tournament, 'id'>
  )> }
);

export type TournamentEntryAddMutationVariables = Exact<{
  input: TournamentEntryAddInput;
}>;


export type TournamentEntryAddMutation = (
  { __typename?: 'Mutation' }
  & { tournamentEntryAdd?: Maybe<(
    { __typename?: 'Tournament' }
    & Pick<Tournament, 'id'>
  )> }
);

export type TournamentEntryRemoveMutationVariables = Exact<{
  input: TournamentEntryRemoveInput;
}>;


export type TournamentEntryRemoveMutation = (
  { __typename?: 'Mutation' }
  & { tournamentEntryRemove?: Maybe<(
    { __typename?: 'Tournament' }
    & Pick<Tournament, 'id'>
  )> }
);

export type TournamentStartMutationVariables = Exact<{
  input: TournamentStartInput;
}>;


export type TournamentStartMutation = (
  { __typename?: 'Mutation' }
  & { tournamentStart?: Maybe<(
    { __typename?: 'Tournament' }
    & TournamentFragment
  )> }
);

export type TournamentCancelMutationVariables = Exact<{
  input: TournamentCancelInput;
}>;


export type TournamentCancelMutation = (
  { __typename?: 'Mutation' }
  & { tournamentCancel?: Maybe<(
    { __typename?: 'Tournament' }
    & TournamentFragment
  )> }
);

export type TournamentNodeEliminationMutationVariables = Exact<{
  input: TournamentNodeEliminationInput;
}>;


export type TournamentNodeEliminationMutation = (
  { __typename?: 'Mutation' }
  & { tournamentNodeElimination?: Maybe<(
    { __typename?: 'TournamentNodeEliminationPayload' }
    & { node: { __typename?: 'OpenSingleEliminationTournamentNode' } | (
      { __typename?: 'SingleEliminationTournamentNode' }
      & TournamentNodeFragment
    ) | (
      { __typename?: 'TeamSingleEliminationTournamentNode' }
      & TeamTournamentNodeFragment
    ) }
  )> }
);

export type TournamentNodeConfirmRequestMutationVariables = Exact<{
  input: TournamentNodeConfirmRequestInput;
}>;


export type TournamentNodeConfirmRequestMutation = (
  { __typename?: 'Mutation' }
  & { tournamentNodeConfirmRequest?: Maybe<(
    { __typename?: 'TournamentNodeConfirmRequestPayload' }
    & { node: { __typename?: 'OpenSingleEliminationTournamentNode' } | (
      { __typename?: 'SingleEliminationTournamentNode' }
      & TournamentNodeFragment
    ) | (
      { __typename?: 'TeamSingleEliminationTournamentNode' }
      & TeamTournamentNodeFragment
    ) }
  )> }
);

export type TournamentNodeApproveMutationVariables = Exact<{
  input: TournamentNodeApproveInput;
}>;


export type TournamentNodeApproveMutation = (
  { __typename?: 'Mutation' }
  & { tournamentNodeApprove?: Maybe<(
    { __typename?: 'TournamentNodeApprovePayload' }
    & { node: { __typename?: 'OpenSingleEliminationTournamentNode' } | (
      { __typename?: 'SingleEliminationTournamentNode' }
      & TournamentNodeFragment
    ) | (
      { __typename?: 'TeamSingleEliminationTournamentNode' }
      & TeamTournamentNodeFragment
    ) }
  )> }
);

export type TournamentNodeRejectMutationVariables = Exact<{
  input: TournamentNodeRejectInput;
}>;


export type TournamentNodeRejectMutation = (
  { __typename?: 'Mutation' }
  & { tournamentNodeReject?: Maybe<(
    { __typename?: 'TournamentNodeRejectPayload' }
    & { node: { __typename?: 'OpenSingleEliminationTournamentNode' } | (
      { __typename?: 'SingleEliminationTournamentNode' }
      & TournamentNodeFragment
    ) | (
      { __typename?: 'TeamSingleEliminationTournamentNode' }
      & TeamTournamentNodeFragment
    ) }
  )> }
);

export type TournamentNodeRollbackMutationVariables = Exact<{
  input: TournamentNodeRollbackInput;
}>;


export type TournamentNodeRollbackMutation = (
  { __typename?: 'Mutation' }
  & { tournamentNodeRollback?: Maybe<(
    { __typename?: 'TournamentNodeRollbackPayload' }
    & { bracket: { __typename?: 'CustomTournamentBracket' } | { __typename?: 'OpenCustomTournamentBracket' } | { __typename?: 'OpenSingleEliminationTournamentBracket' } | (
      { __typename?: 'SingleEliminationTournamentBracket' }
      & { nodes: Array<(
        { __typename?: 'SingleEliminationTournamentNode' }
        & TournamentNodeFragment
      )> }
    ) | { __typename?: 'TeamCustomTournamentBracket' } | (
      { __typename?: 'TeamSingleEliminationTournamentBracket' }
      & { nodes: Array<(
        { __typename?: 'TeamSingleEliminationTournamentNode' }
        & TeamTournamentNodeFragment
      )> }
    ) }
  )> }
);

export type TournamentUpdateMutationVariables = Exact<{
  input: TournamentUpdateInput;
}>;


export type TournamentUpdateMutation = (
  { __typename?: 'Mutation' }
  & { tournamentUpdate?: Maybe<(
    { __typename?: 'Tournament' }
    & TournamentFragment
  )> }
);

export type TournamentCustomUpdateMutationVariables = Exact<{
  input: TournamentCustomUpdateInput;
}>;


export type TournamentCustomUpdateMutation = (
  { __typename?: 'Mutation' }
  & { tournamentCustomUpdate?: Maybe<(
    { __typename?: 'Tournament' }
    & TournamentFragment
  )> }
);

export type ChatMessageAddMutationVariables = Exact<{
  input: ChatMessageAddInput;
}>;


export type ChatMessageAddMutation = (
  { __typename?: 'Mutation' }
  & { chatMessageAdd?: Maybe<(
    { __typename?: 'ChatMessageAddPayload' }
    & { message: (
      { __typename?: 'ChatMessage' }
      & ChatMessageFragment
    ) }
  )> }
);

export type JoinChatRequestAddMutationVariables = Exact<{
  input: JoinChatRequestAddInput;
}>;


export type JoinChatRequestAddMutation = (
  { __typename?: 'Mutation' }
  & { joinChatRequestAdd?: Maybe<(
    { __typename?: 'JoinChatRequestAddPayload' }
    & { node: { __typename?: 'OpenSingleEliminationTournamentNode' } | (
      { __typename?: 'SingleEliminationTournamentNode' }
      & TournamentNodeFragment
    ) | (
      { __typename?: 'TeamSingleEliminationTournamentNode' }
      & TeamTournamentNodeFragment
    ) }
  )> }
);

export type TournamentForceEntryRemoveMutationVariables = Exact<{
  input: TournamentForceEntryRemoveInput;
}>;


export type TournamentForceEntryRemoveMutation = (
  { __typename?: 'Mutation' }
  & { tournamentForceEntryRemove?: Maybe<(
    { __typename?: 'Tournament' }
    & TournamentFragment
  )> }
);

export type TournamentAnnouncementAddMutationVariables = Exact<{
  input: TournamentAnnouncementAddInput;
}>;


export type TournamentAnnouncementAddMutation = (
  { __typename?: 'Mutation' }
  & { tournamentAnnouncementAdd?: Maybe<(
    { __typename?: 'TournamentAnnouncement' }
    & Pick<TournamentAnnouncement, 'id'>
  )> }
);

export type TournamentAnnouncementRemoveMutationVariables = Exact<{
  input: TournamentAnnouncementRemoveInput;
}>;


export type TournamentAnnouncementRemoveMutation = (
  { __typename?: 'Mutation' }
  & { tournamentAnnouncementRemove?: Maybe<(
    { __typename?: 'TournamentAnnouncement' }
    & Pick<TournamentAnnouncement, 'id'>
  )> }
);

export type TournamentOpenEntryAddMutationVariables = Exact<{
  input: TournamentOpenEntryAddInput;
}>;


export type TournamentOpenEntryAddMutation = (
  { __typename?: 'Mutation' }
  & { tournamentOpenEntryAdd?: Maybe<(
    { __typename?: 'OpenTournamentUser' }
    & Pick<OpenTournamentUser, 'id'>
  )> }
);

export type TournamentOpenEntryRemoveMutationVariables = Exact<{
  input: TournamentOpenEntryRemoveInput;
}>;


export type TournamentOpenEntryRemoveMutation = (
  { __typename?: 'Mutation' }
  & { tournamentOpenEntryRemove?: Maybe<(
    { __typename?: 'OpenTournamentUser' }
    & Pick<OpenTournamentUser, 'id'>
  )> }
);

export type TournamentForceOpenEntryRemoveMutationVariables = Exact<{
  input: TournamentForceOpenEntryRemoveInput;
}>;


export type TournamentForceOpenEntryRemoveMutation = (
  { __typename?: 'Mutation' }
  & { tournamentForceOpenEntryRemove?: Maybe<(
    { __typename?: 'Tournament' }
    & Pick<Tournament, 'id'>
  )> }
);

export type TournamentOpenCheckinMutationVariables = Exact<{
  input: TournamentOpenCheckinInput;
}>;


export type TournamentOpenCheckinMutation = (
  { __typename?: 'Mutation' }
  & { tournamentOpenCheckin?: Maybe<(
    { __typename?: 'OpenTournamentUser' }
    & Pick<OpenTournamentUser, 'id'>
  )> }
);

export type ProfileUpdateMutationVariables = Exact<{
  input: ProfileUpdateInput;
}>;


export type ProfileUpdateMutation = (
  { __typename?: 'Mutation' }
  & { profileUpdate?: Maybe<(
    { __typename?: 'Profile' }
    & ProfileFragment
  )> }
);

export type UserWithdrawMutationVariables = Exact<{
  input: UserWithdrawInput;
}>;


export type UserWithdrawMutation = (
  { __typename?: 'Mutation' }
  & { userWithdraw?: Maybe<(
    { __typename?: 'User' }
    & UserFragment
  )> }
);

export type TournamentSettingSaveMutationVariables = Exact<{
  input: TournamentSettingSaveInput;
}>;


export type TournamentSettingSaveMutation = (
  { __typename?: 'Mutation' }
  & { tournamentSettingSave?: Maybe<(
    { __typename?: 'TournamentSetting' }
    & TournamentSettingFragment
  )> }
);

export type UserLinkDiscordMutationVariables = Exact<{
  input: UserLinkDiscordInput;
}>;


export type UserLinkDiscordMutation = (
  { __typename?: 'Mutation' }
  & { userLinkDiscord?: Maybe<(
    { __typename?: 'User' }
    & UserFragment
  )> }
);

export type UserLinkRiotMutationVariables = Exact<{
  input: UserLinkRiotInput;
}>;


export type UserLinkRiotMutation = (
  { __typename?: 'Mutation' }
  & { userLinkRiot?: Maybe<(
    { __typename?: 'User' }
    & UserFragment
  )> }
);

export type CoinExchangeRequestMutationVariables = Exact<{
  input: CoinExchangeRequestInput;
}>;


export type CoinExchangeRequestMutation = (
  { __typename?: 'Mutation' }
  & { coinExchangeRequest?: Maybe<(
    { __typename?: 'CoinExchangeRequest' }
    & Pick<CoinExchangeRequest, 'id' | 'coin'>
  )> }
);

export type UserSignUpMutationVariables = Exact<{
  input: UserSignUpInput;
}>;


export type UserSignUpMutation = (
  { __typename?: 'Mutation' }
  & { userSignUp?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type SignUpCheckQueryVariables = Exact<{ [key: string]: never; }>;


export type SignUpCheckQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'signUpCheck'>
);

export type ClanInviteLinkQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type ClanInviteLinkQuery = (
  { __typename?: 'Query' }
  & { clanInviteLink?: Maybe<(
    { __typename?: 'ClanInviteLink' }
    & ClanInviteLinkFragment
  )> }
);

export type ClanInviteLinksQueryVariables = Exact<{
  clanId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type ClanInviteLinksQuery = (
  { __typename?: 'Query' }
  & { clanInviteLinks?: Maybe<(
    { __typename?: 'ClanInviteLinkConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ClanInviteLinkEdge' }
      & Pick<ClanInviteLinkEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'ClanInviteLink' }
        & ClanInviteLinkFragment
      )> }
    )>>> }
  )> }
);

export type ClanQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ClanQuery = (
  { __typename?: 'Query' }
  & { clan?: Maybe<(
    { __typename?: 'Clan' }
    & ClanDetailFragment
  )> }
);

export type MyClansQueryVariables = Exact<{ [key: string]: never; }>;


export type MyClansQuery = (
  { __typename?: 'Query' }
  & { myClans?: Maybe<Array<(
    { __typename?: 'Clan' }
    & ClanFragment
  )>> }
);

export type CoverImageSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type CoverImageSelectQuery = (
  { __typename?: 'Query' }
  & { gameDefaultCovers: (
    { __typename?: 'GameDefaultCoverConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'GameDefaultCover' }
      & CoverImageSelectFragment
    )>>> }
  ) }
);

export type GamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GamesQuery = (
  { __typename?: 'Query' }
  & { games: (
    { __typename?: 'GameConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Game' }
      & GameFragment
    )>>> }
  ) }
);

export type GenresQueryVariables = Exact<{ [key: string]: never; }>;


export type GenresQuery = (
  { __typename?: 'Query' }
  & { genres: (
    { __typename?: 'GenreConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Genre' }
      & GenreWithGameFragment
    )>>> }
  ) }
);

export type GiftsQueryVariables = Exact<{
  userId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type GiftsQuery = (
  { __typename?: 'Query' }
  & { gifts: (
    { __typename?: 'GiftConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'GiftEdge' }
      & Pick<GiftEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Gift' }
        & GiftFragment
      )> }
    )>>> }
  ) }
);

export type TournamentBracketQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TournamentBracketQuery = (
  { __typename?: 'Query' }
  & { tournament: (
    { __typename?: 'Tournament' }
    & { bracket?: Maybe<(
      { __typename?: 'CustomTournamentBracket' }
      & Pick<CustomTournamentBracket, 'completed'>
      & { players: Array<(
        { __typename?: 'CustomTournamentPlayer' }
        & CustomTournamentPlaterFragment
      )> }
    ) | (
      { __typename?: 'OpenCustomTournamentBracket' }
      & Pick<OpenCustomTournamentBracket, 'completed'>
      & { teams: Array<(
        { __typename?: 'OpenCustomTournamentTeam' }
        & OpenCustomTournamentTeamFragment
      )> }
    ) | (
      { __typename?: 'OpenSingleEliminationTournamentBracket' }
      & Pick<OpenSingleEliminationTournamentBracket, 'maxRound'>
      & { nodes: Array<(
        { __typename?: 'OpenSingleEliminationTournamentNode' }
        & OpenTournamentNodeFragment
      )> }
    ) | (
      { __typename?: 'SingleEliminationTournamentBracket' }
      & Pick<SingleEliminationTournamentBracket, 'maxRound'>
      & { nodes: Array<(
        { __typename?: 'SingleEliminationTournamentNode' }
        & TournamentNodeFragment
      )> }
    ) | (
      { __typename?: 'TeamCustomTournamentBracket' }
      & Pick<TeamCustomTournamentBracket, 'completed'>
      & { teams: Array<(
        { __typename?: 'TeamCustomTournamentTeam' }
        & TeamCustomTournamentTeamFragment
      )> }
    ) | (
      { __typename?: 'TeamSingleEliminationTournamentBracket' }
      & Pick<TeamSingleEliminationTournamentBracket, 'maxRound'>
      & { nodes: Array<(
        { __typename?: 'TeamSingleEliminationTournamentNode' }
        & TeamTournamentNodeFragment
      )> }
    )> }
  ) }
);

export type TournamentBracketForHeaderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TournamentBracketForHeaderQuery = (
  { __typename?: 'Query' }
  & { tournament: (
    { __typename?: 'Tournament' }
    & { bracket?: Maybe<(
      { __typename?: 'CustomTournamentBracket' }
      & Pick<CustomTournamentBracket, 'completed'>
    ) | (
      { __typename?: 'OpenCustomTournamentBracket' }
      & Pick<OpenCustomTournamentBracket, 'completed'>
    ) | (
      { __typename?: 'OpenSingleEliminationTournamentBracket' }
      & { inReviewNodes: Array<(
        { __typename?: 'OpenSingleEliminationTournamentNode' }
        & OpenTournamentNodeFragment
      )>, joinChatRequests: Array<(
        { __typename?: 'JoinChatRequest' }
        & TournamentJoinChatRequestFragment
      )> }
    ) | (
      { __typename?: 'SingleEliminationTournamentBracket' }
      & { inReviewNodes: Array<(
        { __typename?: 'SingleEliminationTournamentNode' }
        & TournamentNodeFragment
      )>, joinChatRequests: Array<(
        { __typename?: 'JoinChatRequest' }
        & TournamentJoinChatRequestFragment
      )> }
    ) | (
      { __typename?: 'TeamCustomTournamentBracket' }
      & Pick<TeamCustomTournamentBracket, 'completed'>
    ) | (
      { __typename?: 'TeamSingleEliminationTournamentBracket' }
      & { inReviewNodes: Array<(
        { __typename?: 'TeamSingleEliminationTournamentNode' }
        & TeamTournamentNodeFragment
      )>, joinChatRequests: Array<(
        { __typename?: 'JoinChatRequest' }
        & TournamentJoinChatRequestFragment
      )> }
    )> }
  ) }
);

export type TournamentUserRankingQueryVariables = Exact<{
  tournamentId: Scalars['ID'];
  first: Scalars['Int'];
}>;


export type TournamentUserRankingQuery = (
  { __typename?: 'Query' }
  & { tournamentUserRanking: (
    { __typename?: 'TournamentResultConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'TournamentResult' }
      & Pick<TournamentResult, 'id' | 'awardType'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { profile: (
          { __typename?: 'Profile' }
          & Pick<Profile, 'thumbnailUrl' | 'displayName'>
        ) }
      ) }
    )>>> }
  ) }
);

export type TournamentClanRankingQueryVariables = Exact<{
  tournamentId: Scalars['ID'];
  first: Scalars['Int'];
}>;


export type TournamentClanRankingQuery = (
  { __typename?: 'Query' }
  & { tournamentClanRanking: (
    { __typename?: 'TournamentClanResultConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'TournamentClanResult' }
      & Pick<TournamentClanResult, 'id' | 'awardType'>
      & { clan: (
        { __typename?: 'Clan' }
        & Pick<Clan, 'id' | 'name' | 'logoUrl'>
      ) }
    )>>> }
  ) }
);

export type TournamentOpenRankingQueryVariables = Exact<{
  tournamentId: Scalars['ID'];
  first: Scalars['Int'];
}>;


export type TournamentOpenRankingQuery = (
  { __typename?: 'Query' }
  & { tournamentOpenRanking: (
    { __typename?: 'TournamentOpenResultConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'TournamentOpenResult' }
      & Pick<TournamentOpenResult, 'id' | 'awardType' | 'teamName'>
      & { tournament: (
        { __typename?: 'Tournament' }
        & Pick<Tournament, 'openTournamentType'>
      ) }
    )>>> }
  ) }
);

export type TournamentsByTermQueryVariables = Exact<{
  gameId: Scalars['ID'];
  from: Scalars['ISO8601DateTime'];
  to: Scalars['ISO8601DateTime'];
}>;


export type TournamentsByTermQuery = (
  { __typename?: 'Query' }
  & { tournamentsByTerm: (
    { __typename?: 'TournamentConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Tournament' }
      & TournamentCardFragment
    )>>> }
  ) }
);

export type TournamentCountsByTermQueryVariables = Exact<{
  gameId: Scalars['ID'];
  from: Scalars['ISO8601Date'];
  to: Scalars['ISO8601Date'];
}>;


export type TournamentCountsByTermQuery = (
  { __typename?: 'Query' }
  & { tournamentCountsByTerm: Array<(
    { __typename?: 'DateCount' }
    & Pick<DateCount, 'date' | 'count'>
  )> }
);

export type TournamentDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TournamentDetailQuery = (
  { __typename?: 'Query' }
  & { tournament: (
    { __typename?: 'Tournament' }
    & Pick<Tournament, 'id' | 'title' | 'status' | 'willStartAt' | 'scoreToWin' | 'maxEntry' | 'entryNum' | 'rules' | 'rewardNote' | 'contactEmail' | 'contactTwitter' | 'contactDiscord' | 'coverUrl' | 'isPrivate' | 'bracketType' | 'unitType' | 'openTournamentType' | 'openEntryStartsAt' | 'openEntryEndsAt' | 'openCheckinStartsAt' | 'openCheckinEndsAt'>
    & { gameDefaultCover?: Maybe<(
      { __typename?: 'GameDefaultCover' }
      & Pick<GameDefaultCover, 'id'>
    )>, game: (
      { __typename?: 'Game' }
      & Pick<Game, 'id' | 'title'>
    ), organizer: (
      { __typename?: 'User' }
      & UserOrganizerFragment
    ), entries: Array<(
      { __typename?: 'User' }
      & UserEntryFragment
    )>, entryTeams: Array<(
      { __typename?: 'TournamentTeam' }
      & TournamentTeamFragment
    )> }
  ) }
);

export type TournamentDetailWithSponsorQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TournamentDetailWithSponsorQuery = (
  { __typename?: 'Query' }
  & { tournament: (
    { __typename?: 'Tournament' }
    & Pick<Tournament, 'id' | 'title' | 'status' | 'willStartAt' | 'scoreToWin' | 'maxEntry' | 'entryNum' | 'checkinNum' | 'rules' | 'rewardNote' | 'contactEmail' | 'contactTwitter' | 'contactDiscord' | 'coverUrl' | 'bracketType' | 'unitType' | 'isPrivate' | 'openTournamentType' | 'openEntryStartsAt' | 'openEntryEndsAt' | 'openCheckinStartsAt' | 'openCheckinEndsAt'>
    & { game: (
      { __typename?: 'Game' }
      & Pick<Game, 'id' | 'title'>
    ), organizer: (
      { __typename?: 'User' }
      & UserOrganizerFragment
    ), entries: Array<(
      { __typename?: 'User' }
      & UserEntryFragment
    )>, entryTeams: Array<(
      { __typename?: 'TournamentTeam' }
      & TournamentTeamFragment
    )>, sponsorCampaign?: Maybe<(
      { __typename?: 'SponsorCampaign' }
      & Pick<SponsorCampaign, 'title' | 'description' | 'landingUrl' | 'coverUrl'>
      & { sponsor: (
        { __typename?: 'Sponsor' }
        & Pick<Sponsor, 'name' | 'iconUrl' | 'bannerUrl'>
      ) }
    )> }
  ) }
);

export type FeaturedTournamentsQueryVariables = Exact<{
  gameId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type FeaturedTournamentsQuery = (
  { __typename?: 'Query' }
  & { featuredTournaments: (
    { __typename?: 'TournamentConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'TournamentEdge' }
      & Pick<TournamentEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Tournament' }
        & TournamentCardFragment
      )> }
    )>>> }
  ) }
);

export type EnteredTournamentsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type EnteredTournamentsQuery = (
  { __typename?: 'Query' }
  & { enteredTournaments: (
    { __typename?: 'TournamentConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'TournamentEdge' }
      & Pick<TournamentEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Tournament' }
        & TournamentCardFragment
      )> }
    )>>> }
  ) }
);

export type OrganizedTournamentsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type OrganizedTournamentsQuery = (
  { __typename?: 'Query' }
  & { organizedTournaments: (
    { __typename?: 'TournamentConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'TournamentEdge' }
      & Pick<TournamentEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Tournament' }
        & TournamentFragment
      )> }
    )>>> }
  ) }
);

export type ChatMessagesQueryVariables = Exact<{
  tournamentId: Scalars['ID'];
  nodeId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type ChatMessagesQuery = (
  { __typename?: 'Query' }
  & { chatMessage: (
    { __typename?: 'ChatMessageConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ChatMessageEdge' }
      & Pick<ChatMessageEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'ChatMessage' }
        & ChatMessageFragment
      )> }
    )>>> }
  ) }
);

export type TournamentEntryStatusQueryVariables = Exact<{
  tournamentId: Scalars['ID'];
}>;


export type TournamentEntryStatusQuery = (
  { __typename?: 'Query' }
  & { tournamentEntryStatus: (
    { __typename?: 'TournamentEntryStatusResponse' }
    & Pick<TournamentEntryStatusResponse, 'status' | 'reason' | 'checkedIn'>
  ) }
);

export type TournamentAnnouncementsQueryVariables = Exact<{
  tournamentId: Scalars['ID'];
}>;


export type TournamentAnnouncementsQuery = (
  { __typename?: 'Query' }
  & { tournamentAnnouncement: (
    { __typename?: 'TournamentAnnouncementConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'TournamentAnnouncement' }
      & TournamentAnnouncementFragment
    )>>> }
  ) }
);

export type OpenTournamentUsersQueryVariables = Exact<{
  tournamentId: Scalars['ID'];
}>;


export type OpenTournamentUsersQuery = (
  { __typename?: 'Query' }
  & { openTournamentUsers: Array<(
    { __typename?: 'OpenTournamentUser' }
    & OpenTournamentUserFragment
  )> }
);

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'isTournamentModerator' | 'monthlyTicketCount' | 'editableOpenTournamentTypes' | 'discordUsername' | 'riotId'>
    & { profile: (
      { __typename?: 'Profile' }
      & ProfileFragment
    ) }
  ) }
);

export type MyDiscordIdQueryVariables = Exact<{ [key: string]: never; }>;


export type MyDiscordIdQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'discordId' | 'discordUsername'>
  ) }
);

export type MyRiotIdQueryVariables = Exact<{ [key: string]: never; }>;


export type MyRiotIdQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'riotId'>
  ) }
);

export type DrawerMeQueryVariables = Exact<{ [key: string]: never; }>;


export type DrawerMeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & DrawerMeFragment
  ) }
);

export type MyVerifiedOrganizerLevelQueryVariables = Exact<{ [key: string]: never; }>;


export type MyVerifiedOrganizerLevelQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & MyVerifiedOrganizerLevelFragment
  ) }
);

export type UserRankingQueryVariables = Exact<{
  gameId: Scalars['ID'];
}>;


export type UserRankingQuery = (
  { __typename?: 'Query' }
  & { userRanking: (
    { __typename?: 'UserRankingResponse' }
    & Pick<UserRankingResponse, 'targetNum'>
    & { users: Array<(
      { __typename?: 'User' }
      & UserRankingFragment
    )> }
  ) }
);

export type CompensationsQueryVariables = Exact<{
  userId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type CompensationsQuery = (
  { __typename?: 'Query' }
  & { compensations: (
    { __typename?: 'CompensationConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'CompensationEdge' }
      & Pick<CompensationEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Compensation' }
        & CompensationFragment
      )> }
    )>>> }
  ) }
);

export type TournamentResultsQueryVariables = Exact<{
  userId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type TournamentResultsQuery = (
  { __typename?: 'Query' }
  & { tournamentResults: (
    { __typename?: 'TournamentResultConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'TournamentResultEdge' }
      & Pick<TournamentResultEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'TournamentResult' }
        & TournamentResultFragment
      )> }
    )>>> }
  ) }
);

export type TournamentSettingQueryVariables = Exact<{
  tournamentType: Scalars['String'];
}>;


export type TournamentSettingQuery = (
  { __typename?: 'Query' }
  & { tournamentSetting?: Maybe<(
    { __typename?: 'TournamentSetting' }
    & TournamentSettingFragment
  )> }
);

export type SearchUsersQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type SearchUsersQuery = (
  { __typename?: 'Query' }
  & { usersByQuery: Array<(
    { __typename?: 'User' }
    & SearchUserFragment
  )> }
);

export type CheckUserForOpenTournamentQueryVariables = Exact<{
  userId: Scalars['ID'];
  openTournamentType: OpenTournamentType;
}>;


export type CheckUserForOpenTournamentQuery = (
  { __typename?: 'Query' }
  & { userEligibilityForOpenTournament: (
    { __typename?: 'UserEligibilityForOpenTournament' }
    & Pick<UserEligibilityForOpenTournament, 'isEligible' | 'missingRequirements' | 'openTournamentType' | 'userId'>
  ) }
);

export type MyWalletQueryVariables = Exact<{ [key: string]: never; }>;


export type MyWalletQuery = (
  { __typename?: 'Query' }
  & { myWallet: (
    { __typename?: 'Wallet' }
    & Pick<Wallet, 'id' | 'coin' | 'availableCoin'>
  ) }
);

export type MyCoinExchangeRequestsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type MyCoinExchangeRequestsQuery = (
  { __typename?: 'Query' }
  & { myCoinExchangeRequests: (
    { __typename?: 'CoinExchangeRequestConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'CoinExchangeRequestEdge' }
      & Pick<CoinExchangeRequestEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'CoinExchangeRequest' }
        & CoinExchangeRequestFragment
      )> }
    )>>> }
  ) }
);

export const GenreFragmentDoc = gql`
    fragment genre on Genre {
  id
  name
}
    `;
export const GameFragmentDoc = gql`
    fragment game on Game {
  id
  title
  iconUrl
  enabled
  updatedAt
  genre {
    ...genre
  }
}
    ${GenreFragmentDoc}`;
export const GameExpFragmentDoc = gql`
    fragment gameExp on GameExp {
  exp
  game {
    ...game
  }
  id
  rank
  title
  updatedAt
}
    ${GameFragmentDoc}`;
export const LevelFragmentDoc = gql`
    fragment level on Level {
  level
  maxExp
  minExp
}
    `;
export const ProfileFragmentDoc = gql`
    fragment profile on Profile {
  body
  displayName
  id
  thumbnailUrl
  twitterId
  updatedAt
}
    `;
export const UserFragmentDoc = gql`
    fragment user on User {
  totalExp
  gameExps {
    ...gameExp
  }
  id
  level {
    ...level
  }
  profile {
    ...profile
  }
  verifiedOrganizerLevel
  updatedAt
  monthlyTicketCount
  isDeleted
  editableOpenTournamentTypes
}
    ${GameExpFragmentDoc}
${LevelFragmentDoc}
${ProfileFragmentDoc}`;
export const RoleFragmentDoc = gql`
    fragment role on Role {
  id
  name
}
    `;
export const ClanUserRoleFragmentDoc = gql`
    fragment clanUserRole on ClanUserRole {
  user {
    ...user
  }
  role {
    ...role
  }
}
    ${UserFragmentDoc}
${RoleFragmentDoc}`;
export const ClanDetailFragmentDoc = gql`
    fragment clanDetail on Clan {
  id
  name
  description
  owner {
    ...user
  }
  members {
    ...user
  }
  clanUserRoles {
    ...clanUserRole
  }
  logoUrl
  coverUrl
  memberNum
  maxMember
  updatedAt
}
    ${UserFragmentDoc}
${ClanUserRoleFragmentDoc}`;
export const ClanFragmentDoc = gql`
    fragment clan on Clan {
  id
  name
  description
  logoUrl
  coverUrl
  memberNum
  maxMember
  isArchived
  updatedAt
}
    `;
export const ClanInviteLinkFragmentDoc = gql`
    fragment clanInviteLink on ClanInviteLink {
  id
  uuid
  maxNum
  currentNum
  expiresOn
  isExpired
  updatedAt
  clan {
    ...clan
  }
}
    ${ClanFragmentDoc}`;
export const CoverImageSelectFragmentDoc = gql`
    fragment coverImageSelect on GameDefaultCover {
  id
  game {
    id
  }
  coverUrl
}
    `;
export const GenreWithGameFragmentDoc = gql`
    fragment genreWithGame on Genre {
  id
  name
  games {
    ...game
  }
}
    ${GameFragmentDoc}`;
export const GiftFragmentDoc = gql`
    fragment gift on Gift {
  id
  from {
    id
    profile {
      displayName
      thumbnailUrl
    }
  }
  to {
    id
    profile {
      displayName
      thumbnailUrl
    }
  }
  giftType
  amount
  message
  createdAt
  updatedAt
}
    `;
export const TournamentPlayerFragmentDoc = gql`
    fragment tournamentPlayer on SingleEliminationTournamentPlayer {
  index
  score
  battleResult
  user {
    ...user
  }
}
    ${UserFragmentDoc}`;
export const TournamentTeamFragmentDoc = gql`
    fragment tournamentTeam on TournamentTeam {
  id
  clan {
    ...clan
  }
  users {
    ...user
  }
  managers {
    ...user
  }
}
    ${ClanFragmentDoc}
${UserFragmentDoc}`;
export const TeamTournamentTeamFragmentDoc = gql`
    fragment teamTournamentTeam on TeamSingleEliminationTournamentTeam {
  team {
    ...tournamentTeam
  }
  index
  score
  battleResult
}
    ${TournamentTeamFragmentDoc}`;
export const OpenTournamentUserFragmentDoc = gql`
    fragment openTournamentUser on OpenTournamentUser {
  id
  user {
    ...user
  }
  teamName
  status
  role
  tournament {
    openTournamentType
  }
}
    ${UserFragmentDoc}`;
export const OpenTournamentTeamFragmentDoc = gql`
    fragment openTournamentTeam on OpenTournamentTeam {
  users {
    ...openTournamentUser
  }
  teamName
}
    ${OpenTournamentUserFragmentDoc}`;
export const OpenSingleEliminationTournamentTeamFragmentDoc = gql`
    fragment openSingleEliminationTournamentTeam on OpenSingleEliminationTournamentTeam {
  index
  score
  battleResult
  team {
    ...openTournamentTeam
  }
}
    ${OpenTournamentTeamFragmentDoc}`;
export const TournamentJoinChatRequestFragmentDoc = gql`
    fragment tournamentJoinChatRequest on JoinChatRequest {
  node {
    ... on SingleEliminationTournamentNode {
      nodeId
      status
      players {
        ...tournamentPlayer
      }
    }
    ... on TeamSingleEliminationTournamentNode {
      nodeId
      status
      teams {
        ...teamTournamentTeam
      }
    }
    ... on OpenSingleEliminationTournamentNode {
      nodeId
      status
      teams {
        ...openSingleEliminationTournamentTeam
      }
    }
  }
  requestedAt
}
    ${TournamentPlayerFragmentDoc}
${TeamTournamentTeamFragmentDoc}
${OpenSingleEliminationTournamentTeamFragmentDoc}`;
export const TournamentNodeFragmentDoc = gql`
    fragment tournamentNode on SingleEliminationTournamentNode {
  nodeId
  parentNodeId
  round
  status
  matchIndex
  players {
    ...tournamentPlayer
  }
}
    ${TournamentPlayerFragmentDoc}`;
export const CustomTournamentPlaterFragmentDoc = gql`
    fragment customTournamentPlater on CustomTournamentPlayer {
  user {
    ...user
  }
  awardType
}
    ${UserFragmentDoc}`;
export const TeamTournamentNodeFragmentDoc = gql`
    fragment teamTournamentNode on TeamSingleEliminationTournamentNode {
  nodeId
  parentNodeId
  matchIndex
  round
  teams {
    ...teamTournamentTeam
  }
  status
  statusUpdatedAt
}
    ${TeamTournamentTeamFragmentDoc}`;
export const TeamCustomTournamentTeamFragmentDoc = gql`
    fragment teamCustomTournamentTeam on TeamCustomTournamentTeam {
  team {
    ...tournamentTeam
  }
  awardType
}
    ${TournamentTeamFragmentDoc}`;
export const OpenTournamentNodeFragmentDoc = gql`
    fragment openTournamentNode on OpenSingleEliminationTournamentNode {
  nodeId
  parentNodeId
  round
  status
  matchIndex
  teams {
    ...openSingleEliminationTournamentTeam
  }
}
    ${OpenSingleEliminationTournamentTeamFragmentDoc}`;
export const OpenCustomTournamentTeamFragmentDoc = gql`
    fragment openCustomTournamentTeam on OpenCustomTournamentTeam {
  team {
    ...openTournamentTeam
  }
  awardType
}
    ${OpenTournamentTeamFragmentDoc}`;
export const TournamentCardFragmentDoc = gql`
    fragment tournamentCard on Tournament {
  id
  title
  willStartAt
  maxEntry
  entryNum
  coverPreviewUrl
  status
  unitType
  openTournamentType
  organizer {
    verifiedOrganizerLevel
  }
  sponsorCampaign {
    sponsor {
      name
      iconUrl
    }
    landingUrl
  }
}
    `;
export const TournamentAnnouncementFragmentDoc = gql`
    fragment tournamentAnnouncement on TournamentAnnouncement {
  id
  body
  user {
    id
    profile {
      displayName
      thumbnailUrl
    }
  }
  isDeleted
  createdAt
  updatedAt
}
    `;
export const CoinIssuanceFragmentDoc = gql`
    fragment coinIssuance on CoinIssuance {
  coin
  id
  updatedAt
}
    `;
export const TournamentFragmentDoc = gql`
    fragment tournament on Tournament {
  game {
    ...game
  }
  id
  title
  willStartAt
  maxEntry
  entryNum
  checkinNum
  coverUrl
  status
  bracketType
  unitType
  isPrivate
}
    ${GameFragmentDoc}`;
export const TournamentResultFragmentDoc = gql`
    fragment tournamentResult on TournamentResult {
  awardType
  coinIssuance {
    ...coinIssuance
  }
  earnedExp
  game {
    ...game
  }
  id
  tournament {
    ...tournament
  }
  updatedAt
}
    ${CoinIssuanceFragmentDoc}
${GameFragmentDoc}
${TournamentFragmentDoc}`;
export const ChatMessageFragmentDoc = gql`
    fragment chatMessage on ChatMessage {
  id
  sentAt
  user {
    ...user
  }
  messageType
  body
}
    ${UserFragmentDoc}`;
export const UserOrganizerFragmentDoc = gql`
    fragment userOrganizer on User {
  id
  profile {
    displayName
    thumbnailUrl
    twitterId
  }
  verifiedOrganizerLevel
}
    `;
export const UserEntryFragmentDoc = gql`
    fragment userEntry on User {
  id
  gameExps {
    ...gameExp
  }
  level {
    level
  }
  profile {
    displayName
    thumbnailUrl
    twitterId
  }
}
    ${GameExpFragmentDoc}`;
export const UserRankingFragmentDoc = gql`
    fragment userRanking on User {
  id
  profile {
    ...profile
  }
  gameExp(gameId: $gameId) {
    ...gameExp
  }
  level {
    ...level
  }
}
    ${ProfileFragmentDoc}
${GameExpFragmentDoc}
${LevelFragmentDoc}`;
export const DrawerMeFragmentDoc = gql`
    fragment drawerMe on User {
  id
  profile {
    displayName
    thumbnailUrl
  }
  verifiedOrganizerLevel
  monthlyTicketCount
}
    `;
export const MyVerifiedOrganizerLevelFragmentDoc = gql`
    fragment myVerifiedOrganizerLevel on User {
  verifiedOrganizerLevel
}
    `;
export const CompensationFragmentDoc = gql`
    fragment compensation on Compensation {
  id
  exp
  memo
  coinIssuance {
    ...coinIssuance
  }
  createdAt
}
    ${CoinIssuanceFragmentDoc}`;
export const SearchUserFragmentDoc = gql`
    fragment searchUser on User {
  id
  profile {
    displayName
    twitterId
    thumbnailUrl
  }
}
    `;
export const TournamentSettingFragmentDoc = gql`
    fragment tournamentSetting on TournamentSetting {
  tournamentType
  config
}
    `;
export const CoinExchangeRequestFragmentDoc = gql`
    fragment coinExchangeRequest on CoinExchangeRequest {
  id
  coin
  createdAt
  exchangeType
  status
}
    `;
export const ClanInviteLinkAddDocument = gql`
    mutation clanInviteLinkAdd($input: ClanInviteLinkAddInput!) {
  clanInviteLinkAdd(input: $input) {
    ...clanInviteLink
  }
}
    ${ClanInviteLinkFragmentDoc}`;
export type ClanInviteLinkAddMutationFn = Apollo.MutationFunction<ClanInviteLinkAddMutation, ClanInviteLinkAddMutationVariables>;

/**
 * __useClanInviteLinkAddMutation__
 *
 * To run a mutation, you first call `useClanInviteLinkAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClanInviteLinkAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clanInviteLinkAddMutation, { data, loading, error }] = useClanInviteLinkAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClanInviteLinkAddMutation(baseOptions?: Apollo.MutationHookOptions<ClanInviteLinkAddMutation, ClanInviteLinkAddMutationVariables>) {
        return Apollo.useMutation<ClanInviteLinkAddMutation, ClanInviteLinkAddMutationVariables>(ClanInviteLinkAddDocument, baseOptions);
      }
export type ClanInviteLinkAddMutationHookResult = ReturnType<typeof useClanInviteLinkAddMutation>;
export type ClanInviteLinkAddMutationResult = Apollo.MutationResult<ClanInviteLinkAddMutation>;
export type ClanInviteLinkAddMutationOptions = Apollo.BaseMutationOptions<ClanInviteLinkAddMutation, ClanInviteLinkAddMutationVariables>;
export const ClanInviteLinkUpdateDocument = gql`
    mutation clanInviteLinkUpdate($input: ClanInviteLinkUpdateInput!) {
  clanInviteLinkUpdate(input: $input) {
    ...clanInviteLink
  }
}
    ${ClanInviteLinkFragmentDoc}`;
export type ClanInviteLinkUpdateMutationFn = Apollo.MutationFunction<ClanInviteLinkUpdateMutation, ClanInviteLinkUpdateMutationVariables>;

/**
 * __useClanInviteLinkUpdateMutation__
 *
 * To run a mutation, you first call `useClanInviteLinkUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClanInviteLinkUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clanInviteLinkUpdateMutation, { data, loading, error }] = useClanInviteLinkUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClanInviteLinkUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ClanInviteLinkUpdateMutation, ClanInviteLinkUpdateMutationVariables>) {
        return Apollo.useMutation<ClanInviteLinkUpdateMutation, ClanInviteLinkUpdateMutationVariables>(ClanInviteLinkUpdateDocument, baseOptions);
      }
export type ClanInviteLinkUpdateMutationHookResult = ReturnType<typeof useClanInviteLinkUpdateMutation>;
export type ClanInviteLinkUpdateMutationResult = Apollo.MutationResult<ClanInviteLinkUpdateMutation>;
export type ClanInviteLinkUpdateMutationOptions = Apollo.BaseMutationOptions<ClanInviteLinkUpdateMutation, ClanInviteLinkUpdateMutationVariables>;
export const ClanInviteLinkRemoveDocument = gql`
    mutation clanInviteLinkRemove($input: ClanInviteLinkRemoveInput!) {
  deleted: clanInviteLinkRemove(input: $input) {
    id
  }
}
    `;
export type ClanInviteLinkRemoveMutationFn = Apollo.MutationFunction<ClanInviteLinkRemoveMutation, ClanInviteLinkRemoveMutationVariables>;

/**
 * __useClanInviteLinkRemoveMutation__
 *
 * To run a mutation, you first call `useClanInviteLinkRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClanInviteLinkRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clanInviteLinkRemoveMutation, { data, loading, error }] = useClanInviteLinkRemoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClanInviteLinkRemoveMutation(baseOptions?: Apollo.MutationHookOptions<ClanInviteLinkRemoveMutation, ClanInviteLinkRemoveMutationVariables>) {
        return Apollo.useMutation<ClanInviteLinkRemoveMutation, ClanInviteLinkRemoveMutationVariables>(ClanInviteLinkRemoveDocument, baseOptions);
      }
export type ClanInviteLinkRemoveMutationHookResult = ReturnType<typeof useClanInviteLinkRemoveMutation>;
export type ClanInviteLinkRemoveMutationResult = Apollo.MutationResult<ClanInviteLinkRemoveMutation>;
export type ClanInviteLinkRemoveMutationOptions = Apollo.BaseMutationOptions<ClanInviteLinkRemoveMutation, ClanInviteLinkRemoveMutationVariables>;
export const ClanInviteLinkExecuteDocument = gql`
    mutation clanInviteLinkExecute($input: ClanInviteLinkExecuteInput!) {
  clanInviteLinkExecute(input: $input) {
    ...clanInviteLink
  }
}
    ${ClanInviteLinkFragmentDoc}`;
export type ClanInviteLinkExecuteMutationFn = Apollo.MutationFunction<ClanInviteLinkExecuteMutation, ClanInviteLinkExecuteMutationVariables>;

/**
 * __useClanInviteLinkExecuteMutation__
 *
 * To run a mutation, you first call `useClanInviteLinkExecuteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClanInviteLinkExecuteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clanInviteLinkExecuteMutation, { data, loading, error }] = useClanInviteLinkExecuteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClanInviteLinkExecuteMutation(baseOptions?: Apollo.MutationHookOptions<ClanInviteLinkExecuteMutation, ClanInviteLinkExecuteMutationVariables>) {
        return Apollo.useMutation<ClanInviteLinkExecuteMutation, ClanInviteLinkExecuteMutationVariables>(ClanInviteLinkExecuteDocument, baseOptions);
      }
export type ClanInviteLinkExecuteMutationHookResult = ReturnType<typeof useClanInviteLinkExecuteMutation>;
export type ClanInviteLinkExecuteMutationResult = Apollo.MutationResult<ClanInviteLinkExecuteMutation>;
export type ClanInviteLinkExecuteMutationOptions = Apollo.BaseMutationOptions<ClanInviteLinkExecuteMutation, ClanInviteLinkExecuteMutationVariables>;
export const ClanAddDocument = gql`
    mutation clanAdd($input: ClanAddInput!) {
  clanAdd(input: $input) {
    ...clan
  }
}
    ${ClanFragmentDoc}`;
export type ClanAddMutationFn = Apollo.MutationFunction<ClanAddMutation, ClanAddMutationVariables>;

/**
 * __useClanAddMutation__
 *
 * To run a mutation, you first call `useClanAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClanAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clanAddMutation, { data, loading, error }] = useClanAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClanAddMutation(baseOptions?: Apollo.MutationHookOptions<ClanAddMutation, ClanAddMutationVariables>) {
        return Apollo.useMutation<ClanAddMutation, ClanAddMutationVariables>(ClanAddDocument, baseOptions);
      }
export type ClanAddMutationHookResult = ReturnType<typeof useClanAddMutation>;
export type ClanAddMutationResult = Apollo.MutationResult<ClanAddMutation>;
export type ClanAddMutationOptions = Apollo.BaseMutationOptions<ClanAddMutation, ClanAddMutationVariables>;
export const ClanUpdateDocument = gql`
    mutation clanUpdate($input: ClanUpdateInput!) {
  clanUpdate(input: $input) {
    ...clan
  }
}
    ${ClanFragmentDoc}`;
export type ClanUpdateMutationFn = Apollo.MutationFunction<ClanUpdateMutation, ClanUpdateMutationVariables>;

/**
 * __useClanUpdateMutation__
 *
 * To run a mutation, you first call `useClanUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClanUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clanUpdateMutation, { data, loading, error }] = useClanUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClanUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ClanUpdateMutation, ClanUpdateMutationVariables>) {
        return Apollo.useMutation<ClanUpdateMutation, ClanUpdateMutationVariables>(ClanUpdateDocument, baseOptions);
      }
export type ClanUpdateMutationHookResult = ReturnType<typeof useClanUpdateMutation>;
export type ClanUpdateMutationResult = Apollo.MutationResult<ClanUpdateMutation>;
export type ClanUpdateMutationOptions = Apollo.BaseMutationOptions<ClanUpdateMutation, ClanUpdateMutationVariables>;
export const ClanArchiveDocument = gql`
    mutation clanArchive($input: ClanArchiveInput!) {
  clanArchive(input: $input) {
    ...clan
  }
}
    ${ClanFragmentDoc}`;
export type ClanArchiveMutationFn = Apollo.MutationFunction<ClanArchiveMutation, ClanArchiveMutationVariables>;

/**
 * __useClanArchiveMutation__
 *
 * To run a mutation, you first call `useClanArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClanArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clanArchiveMutation, { data, loading, error }] = useClanArchiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClanArchiveMutation(baseOptions?: Apollo.MutationHookOptions<ClanArchiveMutation, ClanArchiveMutationVariables>) {
        return Apollo.useMutation<ClanArchiveMutation, ClanArchiveMutationVariables>(ClanArchiveDocument, baseOptions);
      }
export type ClanArchiveMutationHookResult = ReturnType<typeof useClanArchiveMutation>;
export type ClanArchiveMutationResult = Apollo.MutationResult<ClanArchiveMutation>;
export type ClanArchiveMutationOptions = Apollo.BaseMutationOptions<ClanArchiveMutation, ClanArchiveMutationVariables>;
export const ClanExitDocument = gql`
    mutation clanExit($input: ClanExitInput!) {
  clanExit(input: $input) {
    ...clanDetail
  }
}
    ${ClanDetailFragmentDoc}`;
export type ClanExitMutationFn = Apollo.MutationFunction<ClanExitMutation, ClanExitMutationVariables>;

/**
 * __useClanExitMutation__
 *
 * To run a mutation, you first call `useClanExitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClanExitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clanExitMutation, { data, loading, error }] = useClanExitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClanExitMutation(baseOptions?: Apollo.MutationHookOptions<ClanExitMutation, ClanExitMutationVariables>) {
        return Apollo.useMutation<ClanExitMutation, ClanExitMutationVariables>(ClanExitDocument, baseOptions);
      }
export type ClanExitMutationHookResult = ReturnType<typeof useClanExitMutation>;
export type ClanExitMutationResult = Apollo.MutationResult<ClanExitMutation>;
export type ClanExitMutationOptions = Apollo.BaseMutationOptions<ClanExitMutation, ClanExitMutationVariables>;
export const ClanTransferOwnerDocument = gql`
    mutation clanTransferOwner($input: ClanTransferOwnerInput!) {
  clanTransferOwner(input: $input) {
    ...clanDetail
  }
}
    ${ClanDetailFragmentDoc}`;
export type ClanTransferOwnerMutationFn = Apollo.MutationFunction<ClanTransferOwnerMutation, ClanTransferOwnerMutationVariables>;

/**
 * __useClanTransferOwnerMutation__
 *
 * To run a mutation, you first call `useClanTransferOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClanTransferOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clanTransferOwnerMutation, { data, loading, error }] = useClanTransferOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClanTransferOwnerMutation(baseOptions?: Apollo.MutationHookOptions<ClanTransferOwnerMutation, ClanTransferOwnerMutationVariables>) {
        return Apollo.useMutation<ClanTransferOwnerMutation, ClanTransferOwnerMutationVariables>(ClanTransferOwnerDocument, baseOptions);
      }
export type ClanTransferOwnerMutationHookResult = ReturnType<typeof useClanTransferOwnerMutation>;
export type ClanTransferOwnerMutationResult = Apollo.MutationResult<ClanTransferOwnerMutation>;
export type ClanTransferOwnerMutationOptions = Apollo.BaseMutationOptions<ClanTransferOwnerMutation, ClanTransferOwnerMutationVariables>;
export const ClanMemberRemoveDocument = gql`
    mutation clanMemberRemove($input: ClanMemberRemoveInput!) {
  clanMemberRemove(input: $input) {
    ...clanDetail
  }
}
    ${ClanDetailFragmentDoc}`;
export type ClanMemberRemoveMutationFn = Apollo.MutationFunction<ClanMemberRemoveMutation, ClanMemberRemoveMutationVariables>;

/**
 * __useClanMemberRemoveMutation__
 *
 * To run a mutation, you first call `useClanMemberRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClanMemberRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clanMemberRemoveMutation, { data, loading, error }] = useClanMemberRemoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClanMemberRemoveMutation(baseOptions?: Apollo.MutationHookOptions<ClanMemberRemoveMutation, ClanMemberRemoveMutationVariables>) {
        return Apollo.useMutation<ClanMemberRemoveMutation, ClanMemberRemoveMutationVariables>(ClanMemberRemoveDocument, baseOptions);
      }
export type ClanMemberRemoveMutationHookResult = ReturnType<typeof useClanMemberRemoveMutation>;
export type ClanMemberRemoveMutationResult = Apollo.MutationResult<ClanMemberRemoveMutation>;
export type ClanMemberRemoveMutationOptions = Apollo.BaseMutationOptions<ClanMemberRemoveMutation, ClanMemberRemoveMutationVariables>;
export const ClanMemberRolechangeDocument = gql`
    mutation clanMemberRolechange($input: ClanMemberRoleChangeInput!) {
  clanMemberRoleChange(input: $input) {
    ...clanDetail
  }
}
    ${ClanDetailFragmentDoc}`;
export type ClanMemberRolechangeMutationFn = Apollo.MutationFunction<ClanMemberRolechangeMutation, ClanMemberRolechangeMutationVariables>;

/**
 * __useClanMemberRolechangeMutation__
 *
 * To run a mutation, you first call `useClanMemberRolechangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClanMemberRolechangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clanMemberRolechangeMutation, { data, loading, error }] = useClanMemberRolechangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClanMemberRolechangeMutation(baseOptions?: Apollo.MutationHookOptions<ClanMemberRolechangeMutation, ClanMemberRolechangeMutationVariables>) {
        return Apollo.useMutation<ClanMemberRolechangeMutation, ClanMemberRolechangeMutationVariables>(ClanMemberRolechangeDocument, baseOptions);
      }
export type ClanMemberRolechangeMutationHookResult = ReturnType<typeof useClanMemberRolechangeMutation>;
export type ClanMemberRolechangeMutationResult = Apollo.MutationResult<ClanMemberRolechangeMutation>;
export type ClanMemberRolechangeMutationOptions = Apollo.BaseMutationOptions<ClanMemberRolechangeMutation, ClanMemberRolechangeMutationVariables>;
export const GiftAddCoinDocument = gql`
    mutation giftAddCoin($input: GiftAddCoinInput!) {
  giftAddCoin(input: $input) {
    id
  }
}
    `;
export type GiftAddCoinMutationFn = Apollo.MutationFunction<GiftAddCoinMutation, GiftAddCoinMutationVariables>;

/**
 * __useGiftAddCoinMutation__
 *
 * To run a mutation, you first call `useGiftAddCoinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiftAddCoinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giftAddCoinMutation, { data, loading, error }] = useGiftAddCoinMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGiftAddCoinMutation(baseOptions?: Apollo.MutationHookOptions<GiftAddCoinMutation, GiftAddCoinMutationVariables>) {
        return Apollo.useMutation<GiftAddCoinMutation, GiftAddCoinMutationVariables>(GiftAddCoinDocument, baseOptions);
      }
export type GiftAddCoinMutationHookResult = ReturnType<typeof useGiftAddCoinMutation>;
export type GiftAddCoinMutationResult = Apollo.MutationResult<GiftAddCoinMutation>;
export type GiftAddCoinMutationOptions = Apollo.BaseMutationOptions<GiftAddCoinMutation, GiftAddCoinMutationVariables>;
export const TournamentTeamEntryAddDocument = gql`
    mutation tournamentTeamEntryAdd($input: TournamentTeamEntryAddInput!) {
  tournamentTeamEntryAdd(input: $input) {
    id
  }
}
    `;
export type TournamentTeamEntryAddMutationFn = Apollo.MutationFunction<TournamentTeamEntryAddMutation, TournamentTeamEntryAddMutationVariables>;

/**
 * __useTournamentTeamEntryAddMutation__
 *
 * To run a mutation, you first call `useTournamentTeamEntryAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentTeamEntryAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentTeamEntryAddMutation, { data, loading, error }] = useTournamentTeamEntryAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentTeamEntryAddMutation(baseOptions?: Apollo.MutationHookOptions<TournamentTeamEntryAddMutation, TournamentTeamEntryAddMutationVariables>) {
        return Apollo.useMutation<TournamentTeamEntryAddMutation, TournamentTeamEntryAddMutationVariables>(TournamentTeamEntryAddDocument, baseOptions);
      }
export type TournamentTeamEntryAddMutationHookResult = ReturnType<typeof useTournamentTeamEntryAddMutation>;
export type TournamentTeamEntryAddMutationResult = Apollo.MutationResult<TournamentTeamEntryAddMutation>;
export type TournamentTeamEntryAddMutationOptions = Apollo.BaseMutationOptions<TournamentTeamEntryAddMutation, TournamentTeamEntryAddMutationVariables>;
export const TournamentTeamEntryUpdateDocument = gql`
    mutation tournamentTeamEntryUpdate($input: TournamentTeamEntryUpdateInput!) {
  tournamentTeamEntryUpdate(input: $input) {
    id
  }
}
    `;
export type TournamentTeamEntryUpdateMutationFn = Apollo.MutationFunction<TournamentTeamEntryUpdateMutation, TournamentTeamEntryUpdateMutationVariables>;

/**
 * __useTournamentTeamEntryUpdateMutation__
 *
 * To run a mutation, you first call `useTournamentTeamEntryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentTeamEntryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentTeamEntryUpdateMutation, { data, loading, error }] = useTournamentTeamEntryUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentTeamEntryUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TournamentTeamEntryUpdateMutation, TournamentTeamEntryUpdateMutationVariables>) {
        return Apollo.useMutation<TournamentTeamEntryUpdateMutation, TournamentTeamEntryUpdateMutationVariables>(TournamentTeamEntryUpdateDocument, baseOptions);
      }
export type TournamentTeamEntryUpdateMutationHookResult = ReturnType<typeof useTournamentTeamEntryUpdateMutation>;
export type TournamentTeamEntryUpdateMutationResult = Apollo.MutationResult<TournamentTeamEntryUpdateMutation>;
export type TournamentTeamEntryUpdateMutationOptions = Apollo.BaseMutationOptions<TournamentTeamEntryUpdateMutation, TournamentTeamEntryUpdateMutationVariables>;
export const TournamentTeamEntryRemoveDocument = gql`
    mutation tournamentTeamEntryRemove($input: TournamentTeamEntryRemoveInput!) {
  tournamentTeamEntryRemove(input: $input) {
    id
  }
}
    `;
export type TournamentTeamEntryRemoveMutationFn = Apollo.MutationFunction<TournamentTeamEntryRemoveMutation, TournamentTeamEntryRemoveMutationVariables>;

/**
 * __useTournamentTeamEntryRemoveMutation__
 *
 * To run a mutation, you first call `useTournamentTeamEntryRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentTeamEntryRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentTeamEntryRemoveMutation, { data, loading, error }] = useTournamentTeamEntryRemoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentTeamEntryRemoveMutation(baseOptions?: Apollo.MutationHookOptions<TournamentTeamEntryRemoveMutation, TournamentTeamEntryRemoveMutationVariables>) {
        return Apollo.useMutation<TournamentTeamEntryRemoveMutation, TournamentTeamEntryRemoveMutationVariables>(TournamentTeamEntryRemoveDocument, baseOptions);
      }
export type TournamentTeamEntryRemoveMutationHookResult = ReturnType<typeof useTournamentTeamEntryRemoveMutation>;
export type TournamentTeamEntryRemoveMutationResult = Apollo.MutationResult<TournamentTeamEntryRemoveMutation>;
export type TournamentTeamEntryRemoveMutationOptions = Apollo.BaseMutationOptions<TournamentTeamEntryRemoveMutation, TournamentTeamEntryRemoveMutationVariables>;
export const TournamentTeamForceEntryRemoveDocument = gql`
    mutation tournamentTeamForceEntryRemove($input: TournamentForceTeamEntryRemoveInput!) {
  tournamentForceTeamEntryRemove(input: $input) {
    id
  }
}
    `;
export type TournamentTeamForceEntryRemoveMutationFn = Apollo.MutationFunction<TournamentTeamForceEntryRemoveMutation, TournamentTeamForceEntryRemoveMutationVariables>;

/**
 * __useTournamentTeamForceEntryRemoveMutation__
 *
 * To run a mutation, you first call `useTournamentTeamForceEntryRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentTeamForceEntryRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentTeamForceEntryRemoveMutation, { data, loading, error }] = useTournamentTeamForceEntryRemoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentTeamForceEntryRemoveMutation(baseOptions?: Apollo.MutationHookOptions<TournamentTeamForceEntryRemoveMutation, TournamentTeamForceEntryRemoveMutationVariables>) {
        return Apollo.useMutation<TournamentTeamForceEntryRemoveMutation, TournamentTeamForceEntryRemoveMutationVariables>(TournamentTeamForceEntryRemoveDocument, baseOptions);
      }
export type TournamentTeamForceEntryRemoveMutationHookResult = ReturnType<typeof useTournamentTeamForceEntryRemoveMutation>;
export type TournamentTeamForceEntryRemoveMutationResult = Apollo.MutationResult<TournamentTeamForceEntryRemoveMutation>;
export type TournamentTeamForceEntryRemoveMutationOptions = Apollo.BaseMutationOptions<TournamentTeamForceEntryRemoveMutation, TournamentTeamForceEntryRemoveMutationVariables>;
export const TournamentAddDocument = gql`
    mutation tournamentAdd($input: TournamentAddInput!) {
  tournamentAdd(input: $input) {
    id
  }
}
    `;
export type TournamentAddMutationFn = Apollo.MutationFunction<TournamentAddMutation, TournamentAddMutationVariables>;

/**
 * __useTournamentAddMutation__
 *
 * To run a mutation, you first call `useTournamentAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentAddMutation, { data, loading, error }] = useTournamentAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentAddMutation(baseOptions?: Apollo.MutationHookOptions<TournamentAddMutation, TournamentAddMutationVariables>) {
        return Apollo.useMutation<TournamentAddMutation, TournamentAddMutationVariables>(TournamentAddDocument, baseOptions);
      }
export type TournamentAddMutationHookResult = ReturnType<typeof useTournamentAddMutation>;
export type TournamentAddMutationResult = Apollo.MutationResult<TournamentAddMutation>;
export type TournamentAddMutationOptions = Apollo.BaseMutationOptions<TournamentAddMutation, TournamentAddMutationVariables>;
export const TournamentEntryAddDocument = gql`
    mutation tournamentEntryAdd($input: TournamentEntryAddInput!) {
  tournamentEntryAdd(input: $input) {
    id
  }
}
    `;
export type TournamentEntryAddMutationFn = Apollo.MutationFunction<TournamentEntryAddMutation, TournamentEntryAddMutationVariables>;

/**
 * __useTournamentEntryAddMutation__
 *
 * To run a mutation, you first call `useTournamentEntryAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentEntryAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentEntryAddMutation, { data, loading, error }] = useTournamentEntryAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentEntryAddMutation(baseOptions?: Apollo.MutationHookOptions<TournamentEntryAddMutation, TournamentEntryAddMutationVariables>) {
        return Apollo.useMutation<TournamentEntryAddMutation, TournamentEntryAddMutationVariables>(TournamentEntryAddDocument, baseOptions);
      }
export type TournamentEntryAddMutationHookResult = ReturnType<typeof useTournamentEntryAddMutation>;
export type TournamentEntryAddMutationResult = Apollo.MutationResult<TournamentEntryAddMutation>;
export type TournamentEntryAddMutationOptions = Apollo.BaseMutationOptions<TournamentEntryAddMutation, TournamentEntryAddMutationVariables>;
export const TournamentEntryRemoveDocument = gql`
    mutation tournamentEntryRemove($input: TournamentEntryRemoveInput!) {
  tournamentEntryRemove(input: $input) {
    id
  }
}
    `;
export type TournamentEntryRemoveMutationFn = Apollo.MutationFunction<TournamentEntryRemoveMutation, TournamentEntryRemoveMutationVariables>;

/**
 * __useTournamentEntryRemoveMutation__
 *
 * To run a mutation, you first call `useTournamentEntryRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentEntryRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentEntryRemoveMutation, { data, loading, error }] = useTournamentEntryRemoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentEntryRemoveMutation(baseOptions?: Apollo.MutationHookOptions<TournamentEntryRemoveMutation, TournamentEntryRemoveMutationVariables>) {
        return Apollo.useMutation<TournamentEntryRemoveMutation, TournamentEntryRemoveMutationVariables>(TournamentEntryRemoveDocument, baseOptions);
      }
export type TournamentEntryRemoveMutationHookResult = ReturnType<typeof useTournamentEntryRemoveMutation>;
export type TournamentEntryRemoveMutationResult = Apollo.MutationResult<TournamentEntryRemoveMutation>;
export type TournamentEntryRemoveMutationOptions = Apollo.BaseMutationOptions<TournamentEntryRemoveMutation, TournamentEntryRemoveMutationVariables>;
export const TournamentStartDocument = gql`
    mutation tournamentStart($input: TournamentStartInput!) {
  tournamentStart(input: $input) {
    ...tournament
  }
}
    ${TournamentFragmentDoc}`;
export type TournamentStartMutationFn = Apollo.MutationFunction<TournamentStartMutation, TournamentStartMutationVariables>;

/**
 * __useTournamentStartMutation__
 *
 * To run a mutation, you first call `useTournamentStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentStartMutation, { data, loading, error }] = useTournamentStartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentStartMutation(baseOptions?: Apollo.MutationHookOptions<TournamentStartMutation, TournamentStartMutationVariables>) {
        return Apollo.useMutation<TournamentStartMutation, TournamentStartMutationVariables>(TournamentStartDocument, baseOptions);
      }
export type TournamentStartMutationHookResult = ReturnType<typeof useTournamentStartMutation>;
export type TournamentStartMutationResult = Apollo.MutationResult<TournamentStartMutation>;
export type TournamentStartMutationOptions = Apollo.BaseMutationOptions<TournamentStartMutation, TournamentStartMutationVariables>;
export const TournamentCancelDocument = gql`
    mutation tournamentCancel($input: TournamentCancelInput!) {
  tournamentCancel(input: $input) {
    ...tournament
  }
}
    ${TournamentFragmentDoc}`;
export type TournamentCancelMutationFn = Apollo.MutationFunction<TournamentCancelMutation, TournamentCancelMutationVariables>;

/**
 * __useTournamentCancelMutation__
 *
 * To run a mutation, you first call `useTournamentCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentCancelMutation, { data, loading, error }] = useTournamentCancelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentCancelMutation(baseOptions?: Apollo.MutationHookOptions<TournamentCancelMutation, TournamentCancelMutationVariables>) {
        return Apollo.useMutation<TournamentCancelMutation, TournamentCancelMutationVariables>(TournamentCancelDocument, baseOptions);
      }
export type TournamentCancelMutationHookResult = ReturnType<typeof useTournamentCancelMutation>;
export type TournamentCancelMutationResult = Apollo.MutationResult<TournamentCancelMutation>;
export type TournamentCancelMutationOptions = Apollo.BaseMutationOptions<TournamentCancelMutation, TournamentCancelMutationVariables>;
export const TournamentNodeEliminationDocument = gql`
    mutation tournamentNodeElimination($input: TournamentNodeEliminationInput!) {
  tournamentNodeElimination(input: $input) {
    node {
      ... on SingleEliminationTournamentNode {
        ...tournamentNode
      }
      ... on TeamSingleEliminationTournamentNode {
        ...teamTournamentNode
      }
    }
  }
}
    ${TournamentNodeFragmentDoc}
${TeamTournamentNodeFragmentDoc}`;
export type TournamentNodeEliminationMutationFn = Apollo.MutationFunction<TournamentNodeEliminationMutation, TournamentNodeEliminationMutationVariables>;

/**
 * __useTournamentNodeEliminationMutation__
 *
 * To run a mutation, you first call `useTournamentNodeEliminationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentNodeEliminationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentNodeEliminationMutation, { data, loading, error }] = useTournamentNodeEliminationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentNodeEliminationMutation(baseOptions?: Apollo.MutationHookOptions<TournamentNodeEliminationMutation, TournamentNodeEliminationMutationVariables>) {
        return Apollo.useMutation<TournamentNodeEliminationMutation, TournamentNodeEliminationMutationVariables>(TournamentNodeEliminationDocument, baseOptions);
      }
export type TournamentNodeEliminationMutationHookResult = ReturnType<typeof useTournamentNodeEliminationMutation>;
export type TournamentNodeEliminationMutationResult = Apollo.MutationResult<TournamentNodeEliminationMutation>;
export type TournamentNodeEliminationMutationOptions = Apollo.BaseMutationOptions<TournamentNodeEliminationMutation, TournamentNodeEliminationMutationVariables>;
export const TournamentNodeConfirmRequestDocument = gql`
    mutation tournamentNodeConfirmRequest($input: TournamentNodeConfirmRequestInput!) {
  tournamentNodeConfirmRequest(input: $input) {
    node {
      ... on SingleEliminationTournamentNode {
        ...tournamentNode
      }
      ... on TeamSingleEliminationTournamentNode {
        ...teamTournamentNode
      }
    }
  }
}
    ${TournamentNodeFragmentDoc}
${TeamTournamentNodeFragmentDoc}`;
export type TournamentNodeConfirmRequestMutationFn = Apollo.MutationFunction<TournamentNodeConfirmRequestMutation, TournamentNodeConfirmRequestMutationVariables>;

/**
 * __useTournamentNodeConfirmRequestMutation__
 *
 * To run a mutation, you first call `useTournamentNodeConfirmRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentNodeConfirmRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentNodeConfirmRequestMutation, { data, loading, error }] = useTournamentNodeConfirmRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentNodeConfirmRequestMutation(baseOptions?: Apollo.MutationHookOptions<TournamentNodeConfirmRequestMutation, TournamentNodeConfirmRequestMutationVariables>) {
        return Apollo.useMutation<TournamentNodeConfirmRequestMutation, TournamentNodeConfirmRequestMutationVariables>(TournamentNodeConfirmRequestDocument, baseOptions);
      }
export type TournamentNodeConfirmRequestMutationHookResult = ReturnType<typeof useTournamentNodeConfirmRequestMutation>;
export type TournamentNodeConfirmRequestMutationResult = Apollo.MutationResult<TournamentNodeConfirmRequestMutation>;
export type TournamentNodeConfirmRequestMutationOptions = Apollo.BaseMutationOptions<TournamentNodeConfirmRequestMutation, TournamentNodeConfirmRequestMutationVariables>;
export const TournamentNodeApproveDocument = gql`
    mutation tournamentNodeApprove($input: TournamentNodeApproveInput!) {
  tournamentNodeApprove(input: $input) {
    node {
      ... on SingleEliminationTournamentNode {
        ...tournamentNode
      }
      ... on TeamSingleEliminationTournamentNode {
        ...teamTournamentNode
      }
    }
  }
}
    ${TournamentNodeFragmentDoc}
${TeamTournamentNodeFragmentDoc}`;
export type TournamentNodeApproveMutationFn = Apollo.MutationFunction<TournamentNodeApproveMutation, TournamentNodeApproveMutationVariables>;

/**
 * __useTournamentNodeApproveMutation__
 *
 * To run a mutation, you first call `useTournamentNodeApproveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentNodeApproveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentNodeApproveMutation, { data, loading, error }] = useTournamentNodeApproveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentNodeApproveMutation(baseOptions?: Apollo.MutationHookOptions<TournamentNodeApproveMutation, TournamentNodeApproveMutationVariables>) {
        return Apollo.useMutation<TournamentNodeApproveMutation, TournamentNodeApproveMutationVariables>(TournamentNodeApproveDocument, baseOptions);
      }
export type TournamentNodeApproveMutationHookResult = ReturnType<typeof useTournamentNodeApproveMutation>;
export type TournamentNodeApproveMutationResult = Apollo.MutationResult<TournamentNodeApproveMutation>;
export type TournamentNodeApproveMutationOptions = Apollo.BaseMutationOptions<TournamentNodeApproveMutation, TournamentNodeApproveMutationVariables>;
export const TournamentNodeRejectDocument = gql`
    mutation tournamentNodeReject($input: TournamentNodeRejectInput!) {
  tournamentNodeReject(input: $input) {
    node {
      ... on SingleEliminationTournamentNode {
        ...tournamentNode
      }
      ... on TeamSingleEliminationTournamentNode {
        ...teamTournamentNode
      }
    }
  }
}
    ${TournamentNodeFragmentDoc}
${TeamTournamentNodeFragmentDoc}`;
export type TournamentNodeRejectMutationFn = Apollo.MutationFunction<TournamentNodeRejectMutation, TournamentNodeRejectMutationVariables>;

/**
 * __useTournamentNodeRejectMutation__
 *
 * To run a mutation, you first call `useTournamentNodeRejectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentNodeRejectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentNodeRejectMutation, { data, loading, error }] = useTournamentNodeRejectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentNodeRejectMutation(baseOptions?: Apollo.MutationHookOptions<TournamentNodeRejectMutation, TournamentNodeRejectMutationVariables>) {
        return Apollo.useMutation<TournamentNodeRejectMutation, TournamentNodeRejectMutationVariables>(TournamentNodeRejectDocument, baseOptions);
      }
export type TournamentNodeRejectMutationHookResult = ReturnType<typeof useTournamentNodeRejectMutation>;
export type TournamentNodeRejectMutationResult = Apollo.MutationResult<TournamentNodeRejectMutation>;
export type TournamentNodeRejectMutationOptions = Apollo.BaseMutationOptions<TournamentNodeRejectMutation, TournamentNodeRejectMutationVariables>;
export const TournamentNodeRollbackDocument = gql`
    mutation tournamentNodeRollback($input: TournamentNodeRollbackInput!) {
  tournamentNodeRollback(input: $input) {
    bracket {
      ... on SingleEliminationTournamentBracket {
        nodes {
          ...tournamentNode
        }
      }
      ... on TeamSingleEliminationTournamentBracket {
        nodes {
          ...teamTournamentNode
        }
      }
    }
  }
}
    ${TournamentNodeFragmentDoc}
${TeamTournamentNodeFragmentDoc}`;
export type TournamentNodeRollbackMutationFn = Apollo.MutationFunction<TournamentNodeRollbackMutation, TournamentNodeRollbackMutationVariables>;

/**
 * __useTournamentNodeRollbackMutation__
 *
 * To run a mutation, you first call `useTournamentNodeRollbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentNodeRollbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentNodeRollbackMutation, { data, loading, error }] = useTournamentNodeRollbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentNodeRollbackMutation(baseOptions?: Apollo.MutationHookOptions<TournamentNodeRollbackMutation, TournamentNodeRollbackMutationVariables>) {
        return Apollo.useMutation<TournamentNodeRollbackMutation, TournamentNodeRollbackMutationVariables>(TournamentNodeRollbackDocument, baseOptions);
      }
export type TournamentNodeRollbackMutationHookResult = ReturnType<typeof useTournamentNodeRollbackMutation>;
export type TournamentNodeRollbackMutationResult = Apollo.MutationResult<TournamentNodeRollbackMutation>;
export type TournamentNodeRollbackMutationOptions = Apollo.BaseMutationOptions<TournamentNodeRollbackMutation, TournamentNodeRollbackMutationVariables>;
export const TournamentUpdateDocument = gql`
    mutation tournamentUpdate($input: TournamentUpdateInput!) {
  tournamentUpdate(input: $input) {
    ...tournament
  }
}
    ${TournamentFragmentDoc}`;
export type TournamentUpdateMutationFn = Apollo.MutationFunction<TournamentUpdateMutation, TournamentUpdateMutationVariables>;

/**
 * __useTournamentUpdateMutation__
 *
 * To run a mutation, you first call `useTournamentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentUpdateMutation, { data, loading, error }] = useTournamentUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TournamentUpdateMutation, TournamentUpdateMutationVariables>) {
        return Apollo.useMutation<TournamentUpdateMutation, TournamentUpdateMutationVariables>(TournamentUpdateDocument, baseOptions);
      }
export type TournamentUpdateMutationHookResult = ReturnType<typeof useTournamentUpdateMutation>;
export type TournamentUpdateMutationResult = Apollo.MutationResult<TournamentUpdateMutation>;
export type TournamentUpdateMutationOptions = Apollo.BaseMutationOptions<TournamentUpdateMutation, TournamentUpdateMutationVariables>;
export const TournamentCustomUpdateDocument = gql`
    mutation tournamentCustomUpdate($input: TournamentCustomUpdateInput!) {
  tournamentCustomUpdate(input: $input) {
    ...tournament
  }
}
    ${TournamentFragmentDoc}`;
export type TournamentCustomUpdateMutationFn = Apollo.MutationFunction<TournamentCustomUpdateMutation, TournamentCustomUpdateMutationVariables>;

/**
 * __useTournamentCustomUpdateMutation__
 *
 * To run a mutation, you first call `useTournamentCustomUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentCustomUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentCustomUpdateMutation, { data, loading, error }] = useTournamentCustomUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentCustomUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TournamentCustomUpdateMutation, TournamentCustomUpdateMutationVariables>) {
        return Apollo.useMutation<TournamentCustomUpdateMutation, TournamentCustomUpdateMutationVariables>(TournamentCustomUpdateDocument, baseOptions);
      }
export type TournamentCustomUpdateMutationHookResult = ReturnType<typeof useTournamentCustomUpdateMutation>;
export type TournamentCustomUpdateMutationResult = Apollo.MutationResult<TournamentCustomUpdateMutation>;
export type TournamentCustomUpdateMutationOptions = Apollo.BaseMutationOptions<TournamentCustomUpdateMutation, TournamentCustomUpdateMutationVariables>;
export const ChatMessageAddDocument = gql`
    mutation chatMessageAdd($input: ChatMessageAddInput!) {
  chatMessageAdd(input: $input) {
    message {
      ...chatMessage
    }
  }
}
    ${ChatMessageFragmentDoc}`;
export type ChatMessageAddMutationFn = Apollo.MutationFunction<ChatMessageAddMutation, ChatMessageAddMutationVariables>;

/**
 * __useChatMessageAddMutation__
 *
 * To run a mutation, you first call `useChatMessageAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatMessageAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatMessageAddMutation, { data, loading, error }] = useChatMessageAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatMessageAddMutation(baseOptions?: Apollo.MutationHookOptions<ChatMessageAddMutation, ChatMessageAddMutationVariables>) {
        return Apollo.useMutation<ChatMessageAddMutation, ChatMessageAddMutationVariables>(ChatMessageAddDocument, baseOptions);
      }
export type ChatMessageAddMutationHookResult = ReturnType<typeof useChatMessageAddMutation>;
export type ChatMessageAddMutationResult = Apollo.MutationResult<ChatMessageAddMutation>;
export type ChatMessageAddMutationOptions = Apollo.BaseMutationOptions<ChatMessageAddMutation, ChatMessageAddMutationVariables>;
export const JoinChatRequestAddDocument = gql`
    mutation joinChatRequestAdd($input: JoinChatRequestAddInput!) {
  joinChatRequestAdd(input: $input) {
    node {
      ... on SingleEliminationTournamentNode {
        ...tournamentNode
      }
      ... on TeamSingleEliminationTournamentNode {
        ...teamTournamentNode
      }
    }
  }
}
    ${TournamentNodeFragmentDoc}
${TeamTournamentNodeFragmentDoc}`;
export type JoinChatRequestAddMutationFn = Apollo.MutationFunction<JoinChatRequestAddMutation, JoinChatRequestAddMutationVariables>;

/**
 * __useJoinChatRequestAddMutation__
 *
 * To run a mutation, you first call `useJoinChatRequestAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinChatRequestAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinChatRequestAddMutation, { data, loading, error }] = useJoinChatRequestAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinChatRequestAddMutation(baseOptions?: Apollo.MutationHookOptions<JoinChatRequestAddMutation, JoinChatRequestAddMutationVariables>) {
        return Apollo.useMutation<JoinChatRequestAddMutation, JoinChatRequestAddMutationVariables>(JoinChatRequestAddDocument, baseOptions);
      }
export type JoinChatRequestAddMutationHookResult = ReturnType<typeof useJoinChatRequestAddMutation>;
export type JoinChatRequestAddMutationResult = Apollo.MutationResult<JoinChatRequestAddMutation>;
export type JoinChatRequestAddMutationOptions = Apollo.BaseMutationOptions<JoinChatRequestAddMutation, JoinChatRequestAddMutationVariables>;
export const TournamentForceEntryRemoveDocument = gql`
    mutation tournamentForceEntryRemove($input: TournamentForceEntryRemoveInput!) {
  tournamentForceEntryRemove(input: $input) {
    ...tournament
  }
}
    ${TournamentFragmentDoc}`;
export type TournamentForceEntryRemoveMutationFn = Apollo.MutationFunction<TournamentForceEntryRemoveMutation, TournamentForceEntryRemoveMutationVariables>;

/**
 * __useTournamentForceEntryRemoveMutation__
 *
 * To run a mutation, you first call `useTournamentForceEntryRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentForceEntryRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentForceEntryRemoveMutation, { data, loading, error }] = useTournamentForceEntryRemoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentForceEntryRemoveMutation(baseOptions?: Apollo.MutationHookOptions<TournamentForceEntryRemoveMutation, TournamentForceEntryRemoveMutationVariables>) {
        return Apollo.useMutation<TournamentForceEntryRemoveMutation, TournamentForceEntryRemoveMutationVariables>(TournamentForceEntryRemoveDocument, baseOptions);
      }
export type TournamentForceEntryRemoveMutationHookResult = ReturnType<typeof useTournamentForceEntryRemoveMutation>;
export type TournamentForceEntryRemoveMutationResult = Apollo.MutationResult<TournamentForceEntryRemoveMutation>;
export type TournamentForceEntryRemoveMutationOptions = Apollo.BaseMutationOptions<TournamentForceEntryRemoveMutation, TournamentForceEntryRemoveMutationVariables>;
export const TournamentAnnouncementAddDocument = gql`
    mutation tournamentAnnouncementAdd($input: TournamentAnnouncementAddInput!) {
  tournamentAnnouncementAdd(input: $input) {
    id
  }
}
    `;
export type TournamentAnnouncementAddMutationFn = Apollo.MutationFunction<TournamentAnnouncementAddMutation, TournamentAnnouncementAddMutationVariables>;

/**
 * __useTournamentAnnouncementAddMutation__
 *
 * To run a mutation, you first call `useTournamentAnnouncementAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentAnnouncementAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentAnnouncementAddMutation, { data, loading, error }] = useTournamentAnnouncementAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentAnnouncementAddMutation(baseOptions?: Apollo.MutationHookOptions<TournamentAnnouncementAddMutation, TournamentAnnouncementAddMutationVariables>) {
        return Apollo.useMutation<TournamentAnnouncementAddMutation, TournamentAnnouncementAddMutationVariables>(TournamentAnnouncementAddDocument, baseOptions);
      }
export type TournamentAnnouncementAddMutationHookResult = ReturnType<typeof useTournamentAnnouncementAddMutation>;
export type TournamentAnnouncementAddMutationResult = Apollo.MutationResult<TournamentAnnouncementAddMutation>;
export type TournamentAnnouncementAddMutationOptions = Apollo.BaseMutationOptions<TournamentAnnouncementAddMutation, TournamentAnnouncementAddMutationVariables>;
export const TournamentAnnouncementRemoveDocument = gql`
    mutation tournamentAnnouncementRemove($input: TournamentAnnouncementRemoveInput!) {
  tournamentAnnouncementRemove(input: $input) {
    id
  }
}
    `;
export type TournamentAnnouncementRemoveMutationFn = Apollo.MutationFunction<TournamentAnnouncementRemoveMutation, TournamentAnnouncementRemoveMutationVariables>;

/**
 * __useTournamentAnnouncementRemoveMutation__
 *
 * To run a mutation, you first call `useTournamentAnnouncementRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentAnnouncementRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentAnnouncementRemoveMutation, { data, loading, error }] = useTournamentAnnouncementRemoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentAnnouncementRemoveMutation(baseOptions?: Apollo.MutationHookOptions<TournamentAnnouncementRemoveMutation, TournamentAnnouncementRemoveMutationVariables>) {
        return Apollo.useMutation<TournamentAnnouncementRemoveMutation, TournamentAnnouncementRemoveMutationVariables>(TournamentAnnouncementRemoveDocument, baseOptions);
      }
export type TournamentAnnouncementRemoveMutationHookResult = ReturnType<typeof useTournamentAnnouncementRemoveMutation>;
export type TournamentAnnouncementRemoveMutationResult = Apollo.MutationResult<TournamentAnnouncementRemoveMutation>;
export type TournamentAnnouncementRemoveMutationOptions = Apollo.BaseMutationOptions<TournamentAnnouncementRemoveMutation, TournamentAnnouncementRemoveMutationVariables>;
export const TournamentOpenEntryAddDocument = gql`
    mutation tournamentOpenEntryAdd($input: TournamentOpenEntryAddInput!) {
  tournamentOpenEntryAdd(input: $input) {
    id
  }
}
    `;
export type TournamentOpenEntryAddMutationFn = Apollo.MutationFunction<TournamentOpenEntryAddMutation, TournamentOpenEntryAddMutationVariables>;

/**
 * __useTournamentOpenEntryAddMutation__
 *
 * To run a mutation, you first call `useTournamentOpenEntryAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentOpenEntryAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentOpenEntryAddMutation, { data, loading, error }] = useTournamentOpenEntryAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentOpenEntryAddMutation(baseOptions?: Apollo.MutationHookOptions<TournamentOpenEntryAddMutation, TournamentOpenEntryAddMutationVariables>) {
        return Apollo.useMutation<TournamentOpenEntryAddMutation, TournamentOpenEntryAddMutationVariables>(TournamentOpenEntryAddDocument, baseOptions);
      }
export type TournamentOpenEntryAddMutationHookResult = ReturnType<typeof useTournamentOpenEntryAddMutation>;
export type TournamentOpenEntryAddMutationResult = Apollo.MutationResult<TournamentOpenEntryAddMutation>;
export type TournamentOpenEntryAddMutationOptions = Apollo.BaseMutationOptions<TournamentOpenEntryAddMutation, TournamentOpenEntryAddMutationVariables>;
export const TournamentOpenEntryRemoveDocument = gql`
    mutation tournamentOpenEntryRemove($input: TournamentOpenEntryRemoveInput!) {
  tournamentOpenEntryRemove(input: $input) {
    id
  }
}
    `;
export type TournamentOpenEntryRemoveMutationFn = Apollo.MutationFunction<TournamentOpenEntryRemoveMutation, TournamentOpenEntryRemoveMutationVariables>;

/**
 * __useTournamentOpenEntryRemoveMutation__
 *
 * To run a mutation, you first call `useTournamentOpenEntryRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentOpenEntryRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentOpenEntryRemoveMutation, { data, loading, error }] = useTournamentOpenEntryRemoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentOpenEntryRemoveMutation(baseOptions?: Apollo.MutationHookOptions<TournamentOpenEntryRemoveMutation, TournamentOpenEntryRemoveMutationVariables>) {
        return Apollo.useMutation<TournamentOpenEntryRemoveMutation, TournamentOpenEntryRemoveMutationVariables>(TournamentOpenEntryRemoveDocument, baseOptions);
      }
export type TournamentOpenEntryRemoveMutationHookResult = ReturnType<typeof useTournamentOpenEntryRemoveMutation>;
export type TournamentOpenEntryRemoveMutationResult = Apollo.MutationResult<TournamentOpenEntryRemoveMutation>;
export type TournamentOpenEntryRemoveMutationOptions = Apollo.BaseMutationOptions<TournamentOpenEntryRemoveMutation, TournamentOpenEntryRemoveMutationVariables>;
export const TournamentForceOpenEntryRemoveDocument = gql`
    mutation tournamentForceOpenEntryRemove($input: TournamentForceOpenEntryRemoveInput!) {
  tournamentForceOpenEntryRemove(input: $input) {
    id
  }
}
    `;
export type TournamentForceOpenEntryRemoveMutationFn = Apollo.MutationFunction<TournamentForceOpenEntryRemoveMutation, TournamentForceOpenEntryRemoveMutationVariables>;

/**
 * __useTournamentForceOpenEntryRemoveMutation__
 *
 * To run a mutation, you first call `useTournamentForceOpenEntryRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentForceOpenEntryRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentForceOpenEntryRemoveMutation, { data, loading, error }] = useTournamentForceOpenEntryRemoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentForceOpenEntryRemoveMutation(baseOptions?: Apollo.MutationHookOptions<TournamentForceOpenEntryRemoveMutation, TournamentForceOpenEntryRemoveMutationVariables>) {
        return Apollo.useMutation<TournamentForceOpenEntryRemoveMutation, TournamentForceOpenEntryRemoveMutationVariables>(TournamentForceOpenEntryRemoveDocument, baseOptions);
      }
export type TournamentForceOpenEntryRemoveMutationHookResult = ReturnType<typeof useTournamentForceOpenEntryRemoveMutation>;
export type TournamentForceOpenEntryRemoveMutationResult = Apollo.MutationResult<TournamentForceOpenEntryRemoveMutation>;
export type TournamentForceOpenEntryRemoveMutationOptions = Apollo.BaseMutationOptions<TournamentForceOpenEntryRemoveMutation, TournamentForceOpenEntryRemoveMutationVariables>;
export const TournamentOpenCheckinDocument = gql`
    mutation tournamentOpenCheckin($input: TournamentOpenCheckinInput!) {
  tournamentOpenCheckin(input: $input) {
    id
  }
}
    `;
export type TournamentOpenCheckinMutationFn = Apollo.MutationFunction<TournamentOpenCheckinMutation, TournamentOpenCheckinMutationVariables>;

/**
 * __useTournamentOpenCheckinMutation__
 *
 * To run a mutation, you first call `useTournamentOpenCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentOpenCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentOpenCheckinMutation, { data, loading, error }] = useTournamentOpenCheckinMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentOpenCheckinMutation(baseOptions?: Apollo.MutationHookOptions<TournamentOpenCheckinMutation, TournamentOpenCheckinMutationVariables>) {
        return Apollo.useMutation<TournamentOpenCheckinMutation, TournamentOpenCheckinMutationVariables>(TournamentOpenCheckinDocument, baseOptions);
      }
export type TournamentOpenCheckinMutationHookResult = ReturnType<typeof useTournamentOpenCheckinMutation>;
export type TournamentOpenCheckinMutationResult = Apollo.MutationResult<TournamentOpenCheckinMutation>;
export type TournamentOpenCheckinMutationOptions = Apollo.BaseMutationOptions<TournamentOpenCheckinMutation, TournamentOpenCheckinMutationVariables>;
export const ProfileUpdateDocument = gql`
    mutation profileUpdate($input: ProfileUpdateInput!) {
  profileUpdate(input: $input) {
    ...profile
  }
}
    ${ProfileFragmentDoc}`;
export type ProfileUpdateMutationFn = Apollo.MutationFunction<ProfileUpdateMutation, ProfileUpdateMutationVariables>;

/**
 * __useProfileUpdateMutation__
 *
 * To run a mutation, you first call `useProfileUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileUpdateMutation, { data, loading, error }] = useProfileUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfileUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ProfileUpdateMutation, ProfileUpdateMutationVariables>) {
        return Apollo.useMutation<ProfileUpdateMutation, ProfileUpdateMutationVariables>(ProfileUpdateDocument, baseOptions);
      }
export type ProfileUpdateMutationHookResult = ReturnType<typeof useProfileUpdateMutation>;
export type ProfileUpdateMutationResult = Apollo.MutationResult<ProfileUpdateMutation>;
export type ProfileUpdateMutationOptions = Apollo.BaseMutationOptions<ProfileUpdateMutation, ProfileUpdateMutationVariables>;
export const UserWithdrawDocument = gql`
    mutation userWithdraw($input: UserWithdrawInput!) {
  userWithdraw(input: $input) {
    ...user
  }
}
    ${UserFragmentDoc}`;
export type UserWithdrawMutationFn = Apollo.MutationFunction<UserWithdrawMutation, UserWithdrawMutationVariables>;

/**
 * __useUserWithdrawMutation__
 *
 * To run a mutation, you first call `useUserWithdrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserWithdrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userWithdrawMutation, { data, loading, error }] = useUserWithdrawMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserWithdrawMutation(baseOptions?: Apollo.MutationHookOptions<UserWithdrawMutation, UserWithdrawMutationVariables>) {
        return Apollo.useMutation<UserWithdrawMutation, UserWithdrawMutationVariables>(UserWithdrawDocument, baseOptions);
      }
export type UserWithdrawMutationHookResult = ReturnType<typeof useUserWithdrawMutation>;
export type UserWithdrawMutationResult = Apollo.MutationResult<UserWithdrawMutation>;
export type UserWithdrawMutationOptions = Apollo.BaseMutationOptions<UserWithdrawMutation, UserWithdrawMutationVariables>;
export const TournamentSettingSaveDocument = gql`
    mutation tournamentSettingSave($input: TournamentSettingSaveInput!) {
  tournamentSettingSave(input: $input) {
    ...tournamentSetting
  }
}
    ${TournamentSettingFragmentDoc}`;
export type TournamentSettingSaveMutationFn = Apollo.MutationFunction<TournamentSettingSaveMutation, TournamentSettingSaveMutationVariables>;

/**
 * __useTournamentSettingSaveMutation__
 *
 * To run a mutation, you first call `useTournamentSettingSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentSettingSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentSettingSaveMutation, { data, loading, error }] = useTournamentSettingSaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTournamentSettingSaveMutation(baseOptions?: Apollo.MutationHookOptions<TournamentSettingSaveMutation, TournamentSettingSaveMutationVariables>) {
        return Apollo.useMutation<TournamentSettingSaveMutation, TournamentSettingSaveMutationVariables>(TournamentSettingSaveDocument, baseOptions);
      }
export type TournamentSettingSaveMutationHookResult = ReturnType<typeof useTournamentSettingSaveMutation>;
export type TournamentSettingSaveMutationResult = Apollo.MutationResult<TournamentSettingSaveMutation>;
export type TournamentSettingSaveMutationOptions = Apollo.BaseMutationOptions<TournamentSettingSaveMutation, TournamentSettingSaveMutationVariables>;
export const UserLinkDiscordDocument = gql`
    mutation userLinkDiscord($input: UserLinkDiscordInput!) {
  userLinkDiscord(input: $input) {
    ...user
  }
}
    ${UserFragmentDoc}`;
export type UserLinkDiscordMutationFn = Apollo.MutationFunction<UserLinkDiscordMutation, UserLinkDiscordMutationVariables>;

/**
 * __useUserLinkDiscordMutation__
 *
 * To run a mutation, you first call `useUserLinkDiscordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserLinkDiscordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userLinkDiscordMutation, { data, loading, error }] = useUserLinkDiscordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserLinkDiscordMutation(baseOptions?: Apollo.MutationHookOptions<UserLinkDiscordMutation, UserLinkDiscordMutationVariables>) {
        return Apollo.useMutation<UserLinkDiscordMutation, UserLinkDiscordMutationVariables>(UserLinkDiscordDocument, baseOptions);
      }
export type UserLinkDiscordMutationHookResult = ReturnType<typeof useUserLinkDiscordMutation>;
export type UserLinkDiscordMutationResult = Apollo.MutationResult<UserLinkDiscordMutation>;
export type UserLinkDiscordMutationOptions = Apollo.BaseMutationOptions<UserLinkDiscordMutation, UserLinkDiscordMutationVariables>;
export const UserLinkRiotDocument = gql`
    mutation userLinkRiot($input: UserLinkRiotInput!) {
  userLinkRiot(input: $input) {
    ...user
  }
}
    ${UserFragmentDoc}`;
export type UserLinkRiotMutationFn = Apollo.MutationFunction<UserLinkRiotMutation, UserLinkRiotMutationVariables>;

/**
 * __useUserLinkRiotMutation__
 *
 * To run a mutation, you first call `useUserLinkRiotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserLinkRiotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userLinkRiotMutation, { data, loading, error }] = useUserLinkRiotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserLinkRiotMutation(baseOptions?: Apollo.MutationHookOptions<UserLinkRiotMutation, UserLinkRiotMutationVariables>) {
        return Apollo.useMutation<UserLinkRiotMutation, UserLinkRiotMutationVariables>(UserLinkRiotDocument, baseOptions);
      }
export type UserLinkRiotMutationHookResult = ReturnType<typeof useUserLinkRiotMutation>;
export type UserLinkRiotMutationResult = Apollo.MutationResult<UserLinkRiotMutation>;
export type UserLinkRiotMutationOptions = Apollo.BaseMutationOptions<UserLinkRiotMutation, UserLinkRiotMutationVariables>;
export const CoinExchangeRequestDocument = gql`
    mutation coinExchangeRequest($input: CoinExchangeRequestInput!) {
  coinExchangeRequest(input: $input) {
    id
    coin
  }
}
    `;
export type CoinExchangeRequestMutationFn = Apollo.MutationFunction<CoinExchangeRequestMutation, CoinExchangeRequestMutationVariables>;

/**
 * __useCoinExchangeRequestMutation__
 *
 * To run a mutation, you first call `useCoinExchangeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoinExchangeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coinExchangeRequestMutation, { data, loading, error }] = useCoinExchangeRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCoinExchangeRequestMutation(baseOptions?: Apollo.MutationHookOptions<CoinExchangeRequestMutation, CoinExchangeRequestMutationVariables>) {
        return Apollo.useMutation<CoinExchangeRequestMutation, CoinExchangeRequestMutationVariables>(CoinExchangeRequestDocument, baseOptions);
      }
export type CoinExchangeRequestMutationHookResult = ReturnType<typeof useCoinExchangeRequestMutation>;
export type CoinExchangeRequestMutationResult = Apollo.MutationResult<CoinExchangeRequestMutation>;
export type CoinExchangeRequestMutationOptions = Apollo.BaseMutationOptions<CoinExchangeRequestMutation, CoinExchangeRequestMutationVariables>;
export const UserSignUpDocument = gql`
    mutation userSignUp($input: UserSignUpInput!) {
  userSignUp(input: $input) {
    id
  }
}
    `;
export type UserSignUpMutationFn = Apollo.MutationFunction<UserSignUpMutation, UserSignUpMutationVariables>;

/**
 * __useUserSignUpMutation__
 *
 * To run a mutation, you first call `useUserSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSignUpMutation, { data, loading, error }] = useUserSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserSignUpMutation(baseOptions?: Apollo.MutationHookOptions<UserSignUpMutation, UserSignUpMutationVariables>) {
        return Apollo.useMutation<UserSignUpMutation, UserSignUpMutationVariables>(UserSignUpDocument, baseOptions);
      }
export type UserSignUpMutationHookResult = ReturnType<typeof useUserSignUpMutation>;
export type UserSignUpMutationResult = Apollo.MutationResult<UserSignUpMutation>;
export type UserSignUpMutationOptions = Apollo.BaseMutationOptions<UserSignUpMutation, UserSignUpMutationVariables>;
export const SignUpCheckDocument = gql`
    query signUpCheck {
  signUpCheck
}
    `;

/**
 * __useSignUpCheckQuery__
 *
 * To run a query within a React component, call `useSignUpCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignUpCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignUpCheckQuery({
 *   variables: {
 *   },
 * });
 */
export function useSignUpCheckQuery(baseOptions?: Apollo.QueryHookOptions<SignUpCheckQuery, SignUpCheckQueryVariables>) {
        return Apollo.useQuery<SignUpCheckQuery, SignUpCheckQueryVariables>(SignUpCheckDocument, baseOptions);
      }
export function useSignUpCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignUpCheckQuery, SignUpCheckQueryVariables>) {
          return Apollo.useLazyQuery<SignUpCheckQuery, SignUpCheckQueryVariables>(SignUpCheckDocument, baseOptions);
        }
export type SignUpCheckQueryHookResult = ReturnType<typeof useSignUpCheckQuery>;
export type SignUpCheckLazyQueryHookResult = ReturnType<typeof useSignUpCheckLazyQuery>;
export type SignUpCheckQueryResult = Apollo.QueryResult<SignUpCheckQuery, SignUpCheckQueryVariables>;
export const ClanInviteLinkDocument = gql`
    query clanInviteLink($uuid: String!) {
  clanInviteLink(uuid: $uuid) {
    ...clanInviteLink
  }
}
    ${ClanInviteLinkFragmentDoc}`;

/**
 * __useClanInviteLinkQuery__
 *
 * To run a query within a React component, call `useClanInviteLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useClanInviteLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClanInviteLinkQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useClanInviteLinkQuery(baseOptions?: Apollo.QueryHookOptions<ClanInviteLinkQuery, ClanInviteLinkQueryVariables>) {
        return Apollo.useQuery<ClanInviteLinkQuery, ClanInviteLinkQueryVariables>(ClanInviteLinkDocument, baseOptions);
      }
export function useClanInviteLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClanInviteLinkQuery, ClanInviteLinkQueryVariables>) {
          return Apollo.useLazyQuery<ClanInviteLinkQuery, ClanInviteLinkQueryVariables>(ClanInviteLinkDocument, baseOptions);
        }
export type ClanInviteLinkQueryHookResult = ReturnType<typeof useClanInviteLinkQuery>;
export type ClanInviteLinkLazyQueryHookResult = ReturnType<typeof useClanInviteLinkLazyQuery>;
export type ClanInviteLinkQueryResult = Apollo.QueryResult<ClanInviteLinkQuery, ClanInviteLinkQueryVariables>;
export const ClanInviteLinksDocument = gql`
    query clanInviteLinks($clanId: ID!, $first: Int, $after: String) {
  clanInviteLinks(clanId: $clanId, first: $first, after: $after) {
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...clanInviteLink
      }
    }
  }
}
    ${ClanInviteLinkFragmentDoc}`;

/**
 * __useClanInviteLinksQuery__
 *
 * To run a query within a React component, call `useClanInviteLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useClanInviteLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClanInviteLinksQuery({
 *   variables: {
 *      clanId: // value for 'clanId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useClanInviteLinksQuery(baseOptions?: Apollo.QueryHookOptions<ClanInviteLinksQuery, ClanInviteLinksQueryVariables>) {
        return Apollo.useQuery<ClanInviteLinksQuery, ClanInviteLinksQueryVariables>(ClanInviteLinksDocument, baseOptions);
      }
export function useClanInviteLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClanInviteLinksQuery, ClanInviteLinksQueryVariables>) {
          return Apollo.useLazyQuery<ClanInviteLinksQuery, ClanInviteLinksQueryVariables>(ClanInviteLinksDocument, baseOptions);
        }
export type ClanInviteLinksQueryHookResult = ReturnType<typeof useClanInviteLinksQuery>;
export type ClanInviteLinksLazyQueryHookResult = ReturnType<typeof useClanInviteLinksLazyQuery>;
export type ClanInviteLinksQueryResult = Apollo.QueryResult<ClanInviteLinksQuery, ClanInviteLinksQueryVariables>;
export const ClanDocument = gql`
    query clan($id: ID!) {
  clan(id: $id) {
    ...clanDetail
  }
}
    ${ClanDetailFragmentDoc}`;

/**
 * __useClanQuery__
 *
 * To run a query within a React component, call `useClanQuery` and pass it any options that fit your needs.
 * When your component renders, `useClanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClanQuery(baseOptions?: Apollo.QueryHookOptions<ClanQuery, ClanQueryVariables>) {
        return Apollo.useQuery<ClanQuery, ClanQueryVariables>(ClanDocument, baseOptions);
      }
export function useClanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClanQuery, ClanQueryVariables>) {
          return Apollo.useLazyQuery<ClanQuery, ClanQueryVariables>(ClanDocument, baseOptions);
        }
export type ClanQueryHookResult = ReturnType<typeof useClanQuery>;
export type ClanLazyQueryHookResult = ReturnType<typeof useClanLazyQuery>;
export type ClanQueryResult = Apollo.QueryResult<ClanQuery, ClanQueryVariables>;
export const MyClansDocument = gql`
    query myClans {
  myClans {
    ...clan
  }
}
    ${ClanFragmentDoc}`;

/**
 * __useMyClansQuery__
 *
 * To run a query within a React component, call `useMyClansQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyClansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyClansQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyClansQuery(baseOptions?: Apollo.QueryHookOptions<MyClansQuery, MyClansQueryVariables>) {
        return Apollo.useQuery<MyClansQuery, MyClansQueryVariables>(MyClansDocument, baseOptions);
      }
export function useMyClansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyClansQuery, MyClansQueryVariables>) {
          return Apollo.useLazyQuery<MyClansQuery, MyClansQueryVariables>(MyClansDocument, baseOptions);
        }
export type MyClansQueryHookResult = ReturnType<typeof useMyClansQuery>;
export type MyClansLazyQueryHookResult = ReturnType<typeof useMyClansLazyQuery>;
export type MyClansQueryResult = Apollo.QueryResult<MyClansQuery, MyClansQueryVariables>;
export const CoverImageSelectDocument = gql`
    query coverImageSelect {
  gameDefaultCovers {
    nodes {
      ...coverImageSelect
    }
  }
}
    ${CoverImageSelectFragmentDoc}`;

/**
 * __useCoverImageSelectQuery__
 *
 * To run a query within a React component, call `useCoverImageSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoverImageSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoverImageSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoverImageSelectQuery(baseOptions?: Apollo.QueryHookOptions<CoverImageSelectQuery, CoverImageSelectQueryVariables>) {
        return Apollo.useQuery<CoverImageSelectQuery, CoverImageSelectQueryVariables>(CoverImageSelectDocument, baseOptions);
      }
export function useCoverImageSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoverImageSelectQuery, CoverImageSelectQueryVariables>) {
          return Apollo.useLazyQuery<CoverImageSelectQuery, CoverImageSelectQueryVariables>(CoverImageSelectDocument, baseOptions);
        }
export type CoverImageSelectQueryHookResult = ReturnType<typeof useCoverImageSelectQuery>;
export type CoverImageSelectLazyQueryHookResult = ReturnType<typeof useCoverImageSelectLazyQuery>;
export type CoverImageSelectQueryResult = Apollo.QueryResult<CoverImageSelectQuery, CoverImageSelectQueryVariables>;
export const GamesDocument = gql`
    query games {
  games {
    nodes {
      ...game
    }
  }
}
    ${GameFragmentDoc}`;

/**
 * __useGamesQuery__
 *
 * To run a query within a React component, call `useGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGamesQuery(baseOptions?: Apollo.QueryHookOptions<GamesQuery, GamesQueryVariables>) {
        return Apollo.useQuery<GamesQuery, GamesQueryVariables>(GamesDocument, baseOptions);
      }
export function useGamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GamesQuery, GamesQueryVariables>) {
          return Apollo.useLazyQuery<GamesQuery, GamesQueryVariables>(GamesDocument, baseOptions);
        }
export type GamesQueryHookResult = ReturnType<typeof useGamesQuery>;
export type GamesLazyQueryHookResult = ReturnType<typeof useGamesLazyQuery>;
export type GamesQueryResult = Apollo.QueryResult<GamesQuery, GamesQueryVariables>;
export const GenresDocument = gql`
    query genres {
  genres {
    nodes {
      ...genreWithGame
    }
  }
}
    ${GenreWithGameFragmentDoc}`;

/**
 * __useGenresQuery__
 *
 * To run a query within a React component, call `useGenresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenresQuery({
 *   variables: {
 *   },
 * });
 */
export function useGenresQuery(baseOptions?: Apollo.QueryHookOptions<GenresQuery, GenresQueryVariables>) {
        return Apollo.useQuery<GenresQuery, GenresQueryVariables>(GenresDocument, baseOptions);
      }
export function useGenresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenresQuery, GenresQueryVariables>) {
          return Apollo.useLazyQuery<GenresQuery, GenresQueryVariables>(GenresDocument, baseOptions);
        }
export type GenresQueryHookResult = ReturnType<typeof useGenresQuery>;
export type GenresLazyQueryHookResult = ReturnType<typeof useGenresLazyQuery>;
export type GenresQueryResult = Apollo.QueryResult<GenresQuery, GenresQueryVariables>;
export const GiftsDocument = gql`
    query gifts($userId: ID!, $first: Int, $after: String) {
  gifts(userId: $userId, first: $first, after: $after) {
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...gift
      }
    }
  }
}
    ${GiftFragmentDoc}`;

/**
 * __useGiftsQuery__
 *
 * To run a query within a React component, call `useGiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGiftsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGiftsQuery(baseOptions?: Apollo.QueryHookOptions<GiftsQuery, GiftsQueryVariables>) {
        return Apollo.useQuery<GiftsQuery, GiftsQueryVariables>(GiftsDocument, baseOptions);
      }
export function useGiftsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GiftsQuery, GiftsQueryVariables>) {
          return Apollo.useLazyQuery<GiftsQuery, GiftsQueryVariables>(GiftsDocument, baseOptions);
        }
export type GiftsQueryHookResult = ReturnType<typeof useGiftsQuery>;
export type GiftsLazyQueryHookResult = ReturnType<typeof useGiftsLazyQuery>;
export type GiftsQueryResult = Apollo.QueryResult<GiftsQuery, GiftsQueryVariables>;
export const TournamentBracketDocument = gql`
    query tournamentBracket($id: ID!) {
  tournament(id: $id) {
    bracket {
      ... on SingleEliminationTournamentBracket {
        nodes {
          ...tournamentNode
        }
        maxRound
      }
      ... on CustomTournamentBracket {
        players {
          ...customTournamentPlater
        }
        completed
      }
      ... on TeamSingleEliminationTournamentBracket {
        nodes {
          ...teamTournamentNode
        }
        maxRound
      }
      ... on TeamCustomTournamentBracket {
        teams {
          ...teamCustomTournamentTeam
        }
        completed
      }
      ... on OpenSingleEliminationTournamentBracket {
        nodes {
          ...openTournamentNode
        }
        maxRound
      }
      ... on OpenCustomTournamentBracket {
        teams {
          ...openCustomTournamentTeam
        }
        completed
      }
    }
  }
}
    ${TournamentNodeFragmentDoc}
${CustomTournamentPlaterFragmentDoc}
${TeamTournamentNodeFragmentDoc}
${TeamCustomTournamentTeamFragmentDoc}
${OpenTournamentNodeFragmentDoc}
${OpenCustomTournamentTeamFragmentDoc}`;

/**
 * __useTournamentBracketQuery__
 *
 * To run a query within a React component, call `useTournamentBracketQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentBracketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentBracketQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentBracketQuery(baseOptions?: Apollo.QueryHookOptions<TournamentBracketQuery, TournamentBracketQueryVariables>) {
        return Apollo.useQuery<TournamentBracketQuery, TournamentBracketQueryVariables>(TournamentBracketDocument, baseOptions);
      }
export function useTournamentBracketLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentBracketQuery, TournamentBracketQueryVariables>) {
          return Apollo.useLazyQuery<TournamentBracketQuery, TournamentBracketQueryVariables>(TournamentBracketDocument, baseOptions);
        }
export type TournamentBracketQueryHookResult = ReturnType<typeof useTournamentBracketQuery>;
export type TournamentBracketLazyQueryHookResult = ReturnType<typeof useTournamentBracketLazyQuery>;
export type TournamentBracketQueryResult = Apollo.QueryResult<TournamentBracketQuery, TournamentBracketQueryVariables>;
export const TournamentBracketForHeaderDocument = gql`
    query tournamentBracketForHeader($id: ID!) {
  tournament(id: $id) {
    bracket {
      ... on SingleEliminationTournamentBracket {
        inReviewNodes {
          ...tournamentNode
        }
        joinChatRequests {
          ...tournamentJoinChatRequest
        }
      }
      ... on CustomTournamentBracket {
        completed
      }
      ... on TeamSingleEliminationTournamentBracket {
        inReviewNodes {
          ...teamTournamentNode
        }
        joinChatRequests {
          ...tournamentJoinChatRequest
        }
      }
      ... on TeamCustomTournamentBracket {
        completed
      }
      ... on OpenSingleEliminationTournamentBracket {
        inReviewNodes {
          ...openTournamentNode
        }
        joinChatRequests {
          ...tournamentJoinChatRequest
        }
      }
      ... on OpenCustomTournamentBracket {
        completed
      }
    }
  }
}
    ${TournamentNodeFragmentDoc}
${TournamentJoinChatRequestFragmentDoc}
${TeamTournamentNodeFragmentDoc}
${OpenTournamentNodeFragmentDoc}`;

/**
 * __useTournamentBracketForHeaderQuery__
 *
 * To run a query within a React component, call `useTournamentBracketForHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentBracketForHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentBracketForHeaderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentBracketForHeaderQuery(baseOptions?: Apollo.QueryHookOptions<TournamentBracketForHeaderQuery, TournamentBracketForHeaderQueryVariables>) {
        return Apollo.useQuery<TournamentBracketForHeaderQuery, TournamentBracketForHeaderQueryVariables>(TournamentBracketForHeaderDocument, baseOptions);
      }
export function useTournamentBracketForHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentBracketForHeaderQuery, TournamentBracketForHeaderQueryVariables>) {
          return Apollo.useLazyQuery<TournamentBracketForHeaderQuery, TournamentBracketForHeaderQueryVariables>(TournamentBracketForHeaderDocument, baseOptions);
        }
export type TournamentBracketForHeaderQueryHookResult = ReturnType<typeof useTournamentBracketForHeaderQuery>;
export type TournamentBracketForHeaderLazyQueryHookResult = ReturnType<typeof useTournamentBracketForHeaderLazyQuery>;
export type TournamentBracketForHeaderQueryResult = Apollo.QueryResult<TournamentBracketForHeaderQuery, TournamentBracketForHeaderQueryVariables>;
export const TournamentUserRankingDocument = gql`
    query tournamentUserRanking($tournamentId: ID!, $first: Int!) {
  tournamentUserRanking(tournamentId: $tournamentId, first: $first) {
    nodes {
      id
      awardType
      user {
        id
        profile {
          thumbnailUrl
          displayName
        }
      }
    }
  }
}
    `;

/**
 * __useTournamentUserRankingQuery__
 *
 * To run a query within a React component, call `useTournamentUserRankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentUserRankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentUserRankingQuery({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useTournamentUserRankingQuery(baseOptions?: Apollo.QueryHookOptions<TournamentUserRankingQuery, TournamentUserRankingQueryVariables>) {
        return Apollo.useQuery<TournamentUserRankingQuery, TournamentUserRankingQueryVariables>(TournamentUserRankingDocument, baseOptions);
      }
export function useTournamentUserRankingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentUserRankingQuery, TournamentUserRankingQueryVariables>) {
          return Apollo.useLazyQuery<TournamentUserRankingQuery, TournamentUserRankingQueryVariables>(TournamentUserRankingDocument, baseOptions);
        }
export type TournamentUserRankingQueryHookResult = ReturnType<typeof useTournamentUserRankingQuery>;
export type TournamentUserRankingLazyQueryHookResult = ReturnType<typeof useTournamentUserRankingLazyQuery>;
export type TournamentUserRankingQueryResult = Apollo.QueryResult<TournamentUserRankingQuery, TournamentUserRankingQueryVariables>;
export const TournamentClanRankingDocument = gql`
    query tournamentClanRanking($tournamentId: ID!, $first: Int!) {
  tournamentClanRanking(tournamentId: $tournamentId, first: $first) {
    nodes {
      id
      awardType
      clan {
        id
        name
        logoUrl
      }
    }
  }
}
    `;

/**
 * __useTournamentClanRankingQuery__
 *
 * To run a query within a React component, call `useTournamentClanRankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentClanRankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentClanRankingQuery({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useTournamentClanRankingQuery(baseOptions?: Apollo.QueryHookOptions<TournamentClanRankingQuery, TournamentClanRankingQueryVariables>) {
        return Apollo.useQuery<TournamentClanRankingQuery, TournamentClanRankingQueryVariables>(TournamentClanRankingDocument, baseOptions);
      }
export function useTournamentClanRankingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentClanRankingQuery, TournamentClanRankingQueryVariables>) {
          return Apollo.useLazyQuery<TournamentClanRankingQuery, TournamentClanRankingQueryVariables>(TournamentClanRankingDocument, baseOptions);
        }
export type TournamentClanRankingQueryHookResult = ReturnType<typeof useTournamentClanRankingQuery>;
export type TournamentClanRankingLazyQueryHookResult = ReturnType<typeof useTournamentClanRankingLazyQuery>;
export type TournamentClanRankingQueryResult = Apollo.QueryResult<TournamentClanRankingQuery, TournamentClanRankingQueryVariables>;
export const TournamentOpenRankingDocument = gql`
    query tournamentOpenRanking($tournamentId: ID!, $first: Int!) {
  tournamentOpenRanking(tournamentId: $tournamentId, first: $first) {
    nodes {
      id
      awardType
      teamName
      tournament {
        openTournamentType
      }
    }
  }
}
    `;

/**
 * __useTournamentOpenRankingQuery__
 *
 * To run a query within a React component, call `useTournamentOpenRankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentOpenRankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentOpenRankingQuery({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useTournamentOpenRankingQuery(baseOptions?: Apollo.QueryHookOptions<TournamentOpenRankingQuery, TournamentOpenRankingQueryVariables>) {
        return Apollo.useQuery<TournamentOpenRankingQuery, TournamentOpenRankingQueryVariables>(TournamentOpenRankingDocument, baseOptions);
      }
export function useTournamentOpenRankingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentOpenRankingQuery, TournamentOpenRankingQueryVariables>) {
          return Apollo.useLazyQuery<TournamentOpenRankingQuery, TournamentOpenRankingQueryVariables>(TournamentOpenRankingDocument, baseOptions);
        }
export type TournamentOpenRankingQueryHookResult = ReturnType<typeof useTournamentOpenRankingQuery>;
export type TournamentOpenRankingLazyQueryHookResult = ReturnType<typeof useTournamentOpenRankingLazyQuery>;
export type TournamentOpenRankingQueryResult = Apollo.QueryResult<TournamentOpenRankingQuery, TournamentOpenRankingQueryVariables>;
export const TournamentsByTermDocument = gql`
    query tournamentsByTerm($gameId: ID!, $from: ISO8601DateTime!, $to: ISO8601DateTime!) {
  tournamentsByTerm(gameId: $gameId, from: $from, to: $to) {
    nodes {
      ...tournamentCard
    }
  }
}
    ${TournamentCardFragmentDoc}`;

/**
 * __useTournamentsByTermQuery__
 *
 * To run a query within a React component, call `useTournamentsByTermQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentsByTermQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentsByTermQuery({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useTournamentsByTermQuery(baseOptions?: Apollo.QueryHookOptions<TournamentsByTermQuery, TournamentsByTermQueryVariables>) {
        return Apollo.useQuery<TournamentsByTermQuery, TournamentsByTermQueryVariables>(TournamentsByTermDocument, baseOptions);
      }
export function useTournamentsByTermLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentsByTermQuery, TournamentsByTermQueryVariables>) {
          return Apollo.useLazyQuery<TournamentsByTermQuery, TournamentsByTermQueryVariables>(TournamentsByTermDocument, baseOptions);
        }
export type TournamentsByTermQueryHookResult = ReturnType<typeof useTournamentsByTermQuery>;
export type TournamentsByTermLazyQueryHookResult = ReturnType<typeof useTournamentsByTermLazyQuery>;
export type TournamentsByTermQueryResult = Apollo.QueryResult<TournamentsByTermQuery, TournamentsByTermQueryVariables>;
export const TournamentCountsByTermDocument = gql`
    query tournamentCountsByTerm($gameId: ID!, $from: ISO8601Date!, $to: ISO8601Date!) {
  tournamentCountsByTerm(gameId: $gameId, from: $from, to: $to) {
    date
    count
  }
}
    `;

/**
 * __useTournamentCountsByTermQuery__
 *
 * To run a query within a React component, call `useTournamentCountsByTermQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentCountsByTermQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentCountsByTermQuery({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useTournamentCountsByTermQuery(baseOptions?: Apollo.QueryHookOptions<TournamentCountsByTermQuery, TournamentCountsByTermQueryVariables>) {
        return Apollo.useQuery<TournamentCountsByTermQuery, TournamentCountsByTermQueryVariables>(TournamentCountsByTermDocument, baseOptions);
      }
export function useTournamentCountsByTermLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentCountsByTermQuery, TournamentCountsByTermQueryVariables>) {
          return Apollo.useLazyQuery<TournamentCountsByTermQuery, TournamentCountsByTermQueryVariables>(TournamentCountsByTermDocument, baseOptions);
        }
export type TournamentCountsByTermQueryHookResult = ReturnType<typeof useTournamentCountsByTermQuery>;
export type TournamentCountsByTermLazyQueryHookResult = ReturnType<typeof useTournamentCountsByTermLazyQuery>;
export type TournamentCountsByTermQueryResult = Apollo.QueryResult<TournamentCountsByTermQuery, TournamentCountsByTermQueryVariables>;
export const TournamentDetailDocument = gql`
    query tournamentDetail($id: ID!) {
  tournament(id: $id) {
    id
    title
    status
    willStartAt
    scoreToWin
    maxEntry
    entryNum
    rules
    rewardNote
    contactEmail
    contactTwitter
    contactDiscord
    coverUrl
    isPrivate
    gameDefaultCover {
      id
    }
    bracketType
    unitType
    game {
      id
      title
    }
    organizer {
      ...userOrganizer
    }
    entries {
      ...userEntry
    }
    entryTeams {
      ...tournamentTeam
    }
    openTournamentType
    openEntryStartsAt
    openEntryEndsAt
    openCheckinStartsAt
    openCheckinEndsAt
  }
}
    ${UserOrganizerFragmentDoc}
${UserEntryFragmentDoc}
${TournamentTeamFragmentDoc}`;

/**
 * __useTournamentDetailQuery__
 *
 * To run a query within a React component, call `useTournamentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentDetailQuery(baseOptions?: Apollo.QueryHookOptions<TournamentDetailQuery, TournamentDetailQueryVariables>) {
        return Apollo.useQuery<TournamentDetailQuery, TournamentDetailQueryVariables>(TournamentDetailDocument, baseOptions);
      }
export function useTournamentDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentDetailQuery, TournamentDetailQueryVariables>) {
          return Apollo.useLazyQuery<TournamentDetailQuery, TournamentDetailQueryVariables>(TournamentDetailDocument, baseOptions);
        }
export type TournamentDetailQueryHookResult = ReturnType<typeof useTournamentDetailQuery>;
export type TournamentDetailLazyQueryHookResult = ReturnType<typeof useTournamentDetailLazyQuery>;
export type TournamentDetailQueryResult = Apollo.QueryResult<TournamentDetailQuery, TournamentDetailQueryVariables>;
export const TournamentDetailWithSponsorDocument = gql`
    query tournamentDetailWithSponsor($id: ID!) {
  tournament(id: $id) {
    id
    title
    status
    willStartAt
    scoreToWin
    maxEntry
    entryNum
    checkinNum
    rules
    rewardNote
    contactEmail
    contactTwitter
    contactDiscord
    coverUrl
    bracketType
    unitType
    isPrivate
    game {
      id
      title
    }
    organizer {
      ...userOrganizer
    }
    entries {
      ...userEntry
    }
    entryTeams {
      ...tournamentTeam
    }
    sponsorCampaign {
      sponsor {
        name
        iconUrl
        bannerUrl
      }
      title
      description
      landingUrl
      coverUrl
    }
    openTournamentType
    openEntryStartsAt
    openEntryEndsAt
    openCheckinStartsAt
    openCheckinEndsAt
  }
}
    ${UserOrganizerFragmentDoc}
${UserEntryFragmentDoc}
${TournamentTeamFragmentDoc}`;

/**
 * __useTournamentDetailWithSponsorQuery__
 *
 * To run a query within a React component, call `useTournamentDetailWithSponsorQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentDetailWithSponsorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentDetailWithSponsorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentDetailWithSponsorQuery(baseOptions?: Apollo.QueryHookOptions<TournamentDetailWithSponsorQuery, TournamentDetailWithSponsorQueryVariables>) {
        return Apollo.useQuery<TournamentDetailWithSponsorQuery, TournamentDetailWithSponsorQueryVariables>(TournamentDetailWithSponsorDocument, baseOptions);
      }
export function useTournamentDetailWithSponsorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentDetailWithSponsorQuery, TournamentDetailWithSponsorQueryVariables>) {
          return Apollo.useLazyQuery<TournamentDetailWithSponsorQuery, TournamentDetailWithSponsorQueryVariables>(TournamentDetailWithSponsorDocument, baseOptions);
        }
export type TournamentDetailWithSponsorQueryHookResult = ReturnType<typeof useTournamentDetailWithSponsorQuery>;
export type TournamentDetailWithSponsorLazyQueryHookResult = ReturnType<typeof useTournamentDetailWithSponsorLazyQuery>;
export type TournamentDetailWithSponsorQueryResult = Apollo.QueryResult<TournamentDetailWithSponsorQuery, TournamentDetailWithSponsorQueryVariables>;
export const FeaturedTournamentsDocument = gql`
    query featuredTournaments($gameId: ID!, $first: Int, $after: String) {
  featuredTournaments(gameId: $gameId, first: $first, after: $after) {
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...tournamentCard
      }
    }
  }
}
    ${TournamentCardFragmentDoc}`;

/**
 * __useFeaturedTournamentsQuery__
 *
 * To run a query within a React component, call `useFeaturedTournamentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedTournamentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedTournamentsQuery({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useFeaturedTournamentsQuery(baseOptions?: Apollo.QueryHookOptions<FeaturedTournamentsQuery, FeaturedTournamentsQueryVariables>) {
        return Apollo.useQuery<FeaturedTournamentsQuery, FeaturedTournamentsQueryVariables>(FeaturedTournamentsDocument, baseOptions);
      }
export function useFeaturedTournamentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeaturedTournamentsQuery, FeaturedTournamentsQueryVariables>) {
          return Apollo.useLazyQuery<FeaturedTournamentsQuery, FeaturedTournamentsQueryVariables>(FeaturedTournamentsDocument, baseOptions);
        }
export type FeaturedTournamentsQueryHookResult = ReturnType<typeof useFeaturedTournamentsQuery>;
export type FeaturedTournamentsLazyQueryHookResult = ReturnType<typeof useFeaturedTournamentsLazyQuery>;
export type FeaturedTournamentsQueryResult = Apollo.QueryResult<FeaturedTournamentsQuery, FeaturedTournamentsQueryVariables>;
export const EnteredTournamentsDocument = gql`
    query enteredTournaments($first: Int, $after: String) {
  enteredTournaments(first: $first, after: $after) {
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...tournamentCard
      }
    }
  }
}
    ${TournamentCardFragmentDoc}`;

/**
 * __useEnteredTournamentsQuery__
 *
 * To run a query within a React component, call `useEnteredTournamentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnteredTournamentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnteredTournamentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useEnteredTournamentsQuery(baseOptions?: Apollo.QueryHookOptions<EnteredTournamentsQuery, EnteredTournamentsQueryVariables>) {
        return Apollo.useQuery<EnteredTournamentsQuery, EnteredTournamentsQueryVariables>(EnteredTournamentsDocument, baseOptions);
      }
export function useEnteredTournamentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnteredTournamentsQuery, EnteredTournamentsQueryVariables>) {
          return Apollo.useLazyQuery<EnteredTournamentsQuery, EnteredTournamentsQueryVariables>(EnteredTournamentsDocument, baseOptions);
        }
export type EnteredTournamentsQueryHookResult = ReturnType<typeof useEnteredTournamentsQuery>;
export type EnteredTournamentsLazyQueryHookResult = ReturnType<typeof useEnteredTournamentsLazyQuery>;
export type EnteredTournamentsQueryResult = Apollo.QueryResult<EnteredTournamentsQuery, EnteredTournamentsQueryVariables>;
export const OrganizedTournamentsDocument = gql`
    query organizedTournaments($first: Int, $after: String) {
  organizedTournaments(first: $first, after: $after) {
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...tournament
      }
    }
  }
}
    ${TournamentFragmentDoc}`;

/**
 * __useOrganizedTournamentsQuery__
 *
 * To run a query within a React component, call `useOrganizedTournamentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizedTournamentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizedTournamentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useOrganizedTournamentsQuery(baseOptions?: Apollo.QueryHookOptions<OrganizedTournamentsQuery, OrganizedTournamentsQueryVariables>) {
        return Apollo.useQuery<OrganizedTournamentsQuery, OrganizedTournamentsQueryVariables>(OrganizedTournamentsDocument, baseOptions);
      }
export function useOrganizedTournamentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizedTournamentsQuery, OrganizedTournamentsQueryVariables>) {
          return Apollo.useLazyQuery<OrganizedTournamentsQuery, OrganizedTournamentsQueryVariables>(OrganizedTournamentsDocument, baseOptions);
        }
export type OrganizedTournamentsQueryHookResult = ReturnType<typeof useOrganizedTournamentsQuery>;
export type OrganizedTournamentsLazyQueryHookResult = ReturnType<typeof useOrganizedTournamentsLazyQuery>;
export type OrganizedTournamentsQueryResult = Apollo.QueryResult<OrganizedTournamentsQuery, OrganizedTournamentsQueryVariables>;
export const ChatMessagesDocument = gql`
    query chatMessages($tournamentId: ID!, $nodeId: ID!, $first: Int, $after: String) {
  chatMessage(tournamentId: $tournamentId, nodeId: $nodeId, first: $first, after: $after) {
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...chatMessage
      }
    }
  }
}
    ${ChatMessageFragmentDoc}`;

/**
 * __useChatMessagesQuery__
 *
 * To run a query within a React component, call `useChatMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatMessagesQuery({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *      nodeId: // value for 'nodeId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useChatMessagesQuery(baseOptions?: Apollo.QueryHookOptions<ChatMessagesQuery, ChatMessagesQueryVariables>) {
        return Apollo.useQuery<ChatMessagesQuery, ChatMessagesQueryVariables>(ChatMessagesDocument, baseOptions);
      }
export function useChatMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatMessagesQuery, ChatMessagesQueryVariables>) {
          return Apollo.useLazyQuery<ChatMessagesQuery, ChatMessagesQueryVariables>(ChatMessagesDocument, baseOptions);
        }
export type ChatMessagesQueryHookResult = ReturnType<typeof useChatMessagesQuery>;
export type ChatMessagesLazyQueryHookResult = ReturnType<typeof useChatMessagesLazyQuery>;
export type ChatMessagesQueryResult = Apollo.QueryResult<ChatMessagesQuery, ChatMessagesQueryVariables>;
export const TournamentEntryStatusDocument = gql`
    query tournamentEntryStatus($tournamentId: ID!) {
  tournamentEntryStatus(tournamentId: $tournamentId) {
    status
    reason
    checkedIn
  }
}
    `;

/**
 * __useTournamentEntryStatusQuery__
 *
 * To run a query within a React component, call `useTournamentEntryStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentEntryStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentEntryStatusQuery({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useTournamentEntryStatusQuery(baseOptions?: Apollo.QueryHookOptions<TournamentEntryStatusQuery, TournamentEntryStatusQueryVariables>) {
        return Apollo.useQuery<TournamentEntryStatusQuery, TournamentEntryStatusQueryVariables>(TournamentEntryStatusDocument, baseOptions);
      }
export function useTournamentEntryStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentEntryStatusQuery, TournamentEntryStatusQueryVariables>) {
          return Apollo.useLazyQuery<TournamentEntryStatusQuery, TournamentEntryStatusQueryVariables>(TournamentEntryStatusDocument, baseOptions);
        }
export type TournamentEntryStatusQueryHookResult = ReturnType<typeof useTournamentEntryStatusQuery>;
export type TournamentEntryStatusLazyQueryHookResult = ReturnType<typeof useTournamentEntryStatusLazyQuery>;
export type TournamentEntryStatusQueryResult = Apollo.QueryResult<TournamentEntryStatusQuery, TournamentEntryStatusQueryVariables>;
export const TournamentAnnouncementsDocument = gql`
    query tournamentAnnouncements($tournamentId: ID!) {
  tournamentAnnouncement(tournamentId: $tournamentId) {
    nodes {
      ...tournamentAnnouncement
    }
  }
}
    ${TournamentAnnouncementFragmentDoc}`;

/**
 * __useTournamentAnnouncementsQuery__
 *
 * To run a query within a React component, call `useTournamentAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentAnnouncementsQuery({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useTournamentAnnouncementsQuery(baseOptions?: Apollo.QueryHookOptions<TournamentAnnouncementsQuery, TournamentAnnouncementsQueryVariables>) {
        return Apollo.useQuery<TournamentAnnouncementsQuery, TournamentAnnouncementsQueryVariables>(TournamentAnnouncementsDocument, baseOptions);
      }
export function useTournamentAnnouncementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentAnnouncementsQuery, TournamentAnnouncementsQueryVariables>) {
          return Apollo.useLazyQuery<TournamentAnnouncementsQuery, TournamentAnnouncementsQueryVariables>(TournamentAnnouncementsDocument, baseOptions);
        }
export type TournamentAnnouncementsQueryHookResult = ReturnType<typeof useTournamentAnnouncementsQuery>;
export type TournamentAnnouncementsLazyQueryHookResult = ReturnType<typeof useTournamentAnnouncementsLazyQuery>;
export type TournamentAnnouncementsQueryResult = Apollo.QueryResult<TournamentAnnouncementsQuery, TournamentAnnouncementsQueryVariables>;
export const OpenTournamentUsersDocument = gql`
    query openTournamentUsers($tournamentId: ID!) {
  openTournamentUsers(tournamentId: $tournamentId) {
    ...openTournamentUser
  }
}
    ${OpenTournamentUserFragmentDoc}`;

/**
 * __useOpenTournamentUsersQuery__
 *
 * To run a query within a React component, call `useOpenTournamentUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenTournamentUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenTournamentUsersQuery({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useOpenTournamentUsersQuery(baseOptions?: Apollo.QueryHookOptions<OpenTournamentUsersQuery, OpenTournamentUsersQueryVariables>) {
        return Apollo.useQuery<OpenTournamentUsersQuery, OpenTournamentUsersQueryVariables>(OpenTournamentUsersDocument, baseOptions);
      }
export function useOpenTournamentUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpenTournamentUsersQuery, OpenTournamentUsersQueryVariables>) {
          return Apollo.useLazyQuery<OpenTournamentUsersQuery, OpenTournamentUsersQueryVariables>(OpenTournamentUsersDocument, baseOptions);
        }
export type OpenTournamentUsersQueryHookResult = ReturnType<typeof useOpenTournamentUsersQuery>;
export type OpenTournamentUsersLazyQueryHookResult = ReturnType<typeof useOpenTournamentUsersLazyQuery>;
export type OpenTournamentUsersQueryResult = Apollo.QueryResult<OpenTournamentUsersQuery, OpenTournamentUsersQueryVariables>;
export const UserDocument = gql`
    query user($id: ID!) {
  user(id: $id) {
    ...user
  }
}
    ${UserFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    profile {
      ...profile
    }
    isTournamentModerator
    monthlyTicketCount
    editableOpenTournamentTypes
    discordUsername
    riotId
  }
}
    ${ProfileFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MyDiscordIdDocument = gql`
    query myDiscordId {
  me {
    discordId
    discordUsername
  }
}
    `;

/**
 * __useMyDiscordIdQuery__
 *
 * To run a query within a React component, call `useMyDiscordIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyDiscordIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyDiscordIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyDiscordIdQuery(baseOptions?: Apollo.QueryHookOptions<MyDiscordIdQuery, MyDiscordIdQueryVariables>) {
        return Apollo.useQuery<MyDiscordIdQuery, MyDiscordIdQueryVariables>(MyDiscordIdDocument, baseOptions);
      }
export function useMyDiscordIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyDiscordIdQuery, MyDiscordIdQueryVariables>) {
          return Apollo.useLazyQuery<MyDiscordIdQuery, MyDiscordIdQueryVariables>(MyDiscordIdDocument, baseOptions);
        }
export type MyDiscordIdQueryHookResult = ReturnType<typeof useMyDiscordIdQuery>;
export type MyDiscordIdLazyQueryHookResult = ReturnType<typeof useMyDiscordIdLazyQuery>;
export type MyDiscordIdQueryResult = Apollo.QueryResult<MyDiscordIdQuery, MyDiscordIdQueryVariables>;
export const MyRiotIdDocument = gql`
    query myRiotId {
  me {
    riotId
  }
}
    `;

/**
 * __useMyRiotIdQuery__
 *
 * To run a query within a React component, call `useMyRiotIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyRiotIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyRiotIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyRiotIdQuery(baseOptions?: Apollo.QueryHookOptions<MyRiotIdQuery, MyRiotIdQueryVariables>) {
        return Apollo.useQuery<MyRiotIdQuery, MyRiotIdQueryVariables>(MyRiotIdDocument, baseOptions);
      }
export function useMyRiotIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyRiotIdQuery, MyRiotIdQueryVariables>) {
          return Apollo.useLazyQuery<MyRiotIdQuery, MyRiotIdQueryVariables>(MyRiotIdDocument, baseOptions);
        }
export type MyRiotIdQueryHookResult = ReturnType<typeof useMyRiotIdQuery>;
export type MyRiotIdLazyQueryHookResult = ReturnType<typeof useMyRiotIdLazyQuery>;
export type MyRiotIdQueryResult = Apollo.QueryResult<MyRiotIdQuery, MyRiotIdQueryVariables>;
export const DrawerMeDocument = gql`
    query drawerMe {
  me {
    ...drawerMe
  }
}
    ${DrawerMeFragmentDoc}`;

/**
 * __useDrawerMeQuery__
 *
 * To run a query within a React component, call `useDrawerMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDrawerMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDrawerMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useDrawerMeQuery(baseOptions?: Apollo.QueryHookOptions<DrawerMeQuery, DrawerMeQueryVariables>) {
        return Apollo.useQuery<DrawerMeQuery, DrawerMeQueryVariables>(DrawerMeDocument, baseOptions);
      }
export function useDrawerMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DrawerMeQuery, DrawerMeQueryVariables>) {
          return Apollo.useLazyQuery<DrawerMeQuery, DrawerMeQueryVariables>(DrawerMeDocument, baseOptions);
        }
export type DrawerMeQueryHookResult = ReturnType<typeof useDrawerMeQuery>;
export type DrawerMeLazyQueryHookResult = ReturnType<typeof useDrawerMeLazyQuery>;
export type DrawerMeQueryResult = Apollo.QueryResult<DrawerMeQuery, DrawerMeQueryVariables>;
export const MyVerifiedOrganizerLevelDocument = gql`
    query myVerifiedOrganizerLevel {
  me {
    ...myVerifiedOrganizerLevel
  }
}
    ${MyVerifiedOrganizerLevelFragmentDoc}`;

/**
 * __useMyVerifiedOrganizerLevelQuery__
 *
 * To run a query within a React component, call `useMyVerifiedOrganizerLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyVerifiedOrganizerLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyVerifiedOrganizerLevelQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyVerifiedOrganizerLevelQuery(baseOptions?: Apollo.QueryHookOptions<MyVerifiedOrganizerLevelQuery, MyVerifiedOrganizerLevelQueryVariables>) {
        return Apollo.useQuery<MyVerifiedOrganizerLevelQuery, MyVerifiedOrganizerLevelQueryVariables>(MyVerifiedOrganizerLevelDocument, baseOptions);
      }
export function useMyVerifiedOrganizerLevelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyVerifiedOrganizerLevelQuery, MyVerifiedOrganizerLevelQueryVariables>) {
          return Apollo.useLazyQuery<MyVerifiedOrganizerLevelQuery, MyVerifiedOrganizerLevelQueryVariables>(MyVerifiedOrganizerLevelDocument, baseOptions);
        }
export type MyVerifiedOrganizerLevelQueryHookResult = ReturnType<typeof useMyVerifiedOrganizerLevelQuery>;
export type MyVerifiedOrganizerLevelLazyQueryHookResult = ReturnType<typeof useMyVerifiedOrganizerLevelLazyQuery>;
export type MyVerifiedOrganizerLevelQueryResult = Apollo.QueryResult<MyVerifiedOrganizerLevelQuery, MyVerifiedOrganizerLevelQueryVariables>;
export const UserRankingDocument = gql`
    query userRanking($gameId: ID!) {
  userRanking(gameId: $gameId) {
    users {
      ...userRanking
    }
    targetNum
  }
}
    ${UserRankingFragmentDoc}`;

/**
 * __useUserRankingQuery__
 *
 * To run a query within a React component, call `useUserRankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRankingQuery({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useUserRankingQuery(baseOptions?: Apollo.QueryHookOptions<UserRankingQuery, UserRankingQueryVariables>) {
        return Apollo.useQuery<UserRankingQuery, UserRankingQueryVariables>(UserRankingDocument, baseOptions);
      }
export function useUserRankingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRankingQuery, UserRankingQueryVariables>) {
          return Apollo.useLazyQuery<UserRankingQuery, UserRankingQueryVariables>(UserRankingDocument, baseOptions);
        }
export type UserRankingQueryHookResult = ReturnType<typeof useUserRankingQuery>;
export type UserRankingLazyQueryHookResult = ReturnType<typeof useUserRankingLazyQuery>;
export type UserRankingQueryResult = Apollo.QueryResult<UserRankingQuery, UserRankingQueryVariables>;
export const CompensationsDocument = gql`
    query compensations($userId: ID!, $first: Int, $after: String) {
  compensations(userId: $userId, first: $first, after: $after) {
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...compensation
      }
    }
  }
}
    ${CompensationFragmentDoc}`;

/**
 * __useCompensationsQuery__
 *
 * To run a query within a React component, call `useCompensationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompensationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompensationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useCompensationsQuery(baseOptions?: Apollo.QueryHookOptions<CompensationsQuery, CompensationsQueryVariables>) {
        return Apollo.useQuery<CompensationsQuery, CompensationsQueryVariables>(CompensationsDocument, baseOptions);
      }
export function useCompensationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompensationsQuery, CompensationsQueryVariables>) {
          return Apollo.useLazyQuery<CompensationsQuery, CompensationsQueryVariables>(CompensationsDocument, baseOptions);
        }
export type CompensationsQueryHookResult = ReturnType<typeof useCompensationsQuery>;
export type CompensationsLazyQueryHookResult = ReturnType<typeof useCompensationsLazyQuery>;
export type CompensationsQueryResult = Apollo.QueryResult<CompensationsQuery, CompensationsQueryVariables>;
export const TournamentResultsDocument = gql`
    query tournamentResults($userId: ID!, $first: Int, $after: String) {
  tournamentResults(userId: $userId, first: $first, after: $after) {
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...tournamentResult
      }
    }
  }
}
    ${TournamentResultFragmentDoc}`;

/**
 * __useTournamentResultsQuery__
 *
 * To run a query within a React component, call `useTournamentResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentResultsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useTournamentResultsQuery(baseOptions?: Apollo.QueryHookOptions<TournamentResultsQuery, TournamentResultsQueryVariables>) {
        return Apollo.useQuery<TournamentResultsQuery, TournamentResultsQueryVariables>(TournamentResultsDocument, baseOptions);
      }
export function useTournamentResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentResultsQuery, TournamentResultsQueryVariables>) {
          return Apollo.useLazyQuery<TournamentResultsQuery, TournamentResultsQueryVariables>(TournamentResultsDocument, baseOptions);
        }
export type TournamentResultsQueryHookResult = ReturnType<typeof useTournamentResultsQuery>;
export type TournamentResultsLazyQueryHookResult = ReturnType<typeof useTournamentResultsLazyQuery>;
export type TournamentResultsQueryResult = Apollo.QueryResult<TournamentResultsQuery, TournamentResultsQueryVariables>;
export const TournamentSettingDocument = gql`
    query tournamentSetting($tournamentType: String!) {
  tournamentSetting(tournamentType: $tournamentType) {
    ...tournamentSetting
  }
}
    ${TournamentSettingFragmentDoc}`;

/**
 * __useTournamentSettingQuery__
 *
 * To run a query within a React component, call `useTournamentSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentSettingQuery({
 *   variables: {
 *      tournamentType: // value for 'tournamentType'
 *   },
 * });
 */
export function useTournamentSettingQuery(baseOptions?: Apollo.QueryHookOptions<TournamentSettingQuery, TournamentSettingQueryVariables>) {
        return Apollo.useQuery<TournamentSettingQuery, TournamentSettingQueryVariables>(TournamentSettingDocument, baseOptions);
      }
export function useTournamentSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentSettingQuery, TournamentSettingQueryVariables>) {
          return Apollo.useLazyQuery<TournamentSettingQuery, TournamentSettingQueryVariables>(TournamentSettingDocument, baseOptions);
        }
export type TournamentSettingQueryHookResult = ReturnType<typeof useTournamentSettingQuery>;
export type TournamentSettingLazyQueryHookResult = ReturnType<typeof useTournamentSettingLazyQuery>;
export type TournamentSettingQueryResult = Apollo.QueryResult<TournamentSettingQuery, TournamentSettingQueryVariables>;
export const SearchUsersDocument = gql`
    query searchUsers($query: String!) {
  usersByQuery(query: $query) {
    ...searchUser
  }
}
    ${SearchUserFragmentDoc}`;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions?: Apollo.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, baseOptions);
      }
export function useSearchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, baseOptions);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const CheckUserForOpenTournamentDocument = gql`
    query checkUserForOpenTournament($userId: ID!, $openTournamentType: OpenTournamentType!) {
  userEligibilityForOpenTournament(userId: $userId, openTournamentType: $openTournamentType) {
    isEligible
    missingRequirements
    openTournamentType
    userId
  }
}
    `;

/**
 * __useCheckUserForOpenTournamentQuery__
 *
 * To run a query within a React component, call `useCheckUserForOpenTournamentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckUserForOpenTournamentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckUserForOpenTournamentQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      openTournamentType: // value for 'openTournamentType'
 *   },
 * });
 */
export function useCheckUserForOpenTournamentQuery(baseOptions?: Apollo.QueryHookOptions<CheckUserForOpenTournamentQuery, CheckUserForOpenTournamentQueryVariables>) {
        return Apollo.useQuery<CheckUserForOpenTournamentQuery, CheckUserForOpenTournamentQueryVariables>(CheckUserForOpenTournamentDocument, baseOptions);
      }
export function useCheckUserForOpenTournamentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckUserForOpenTournamentQuery, CheckUserForOpenTournamentQueryVariables>) {
          return Apollo.useLazyQuery<CheckUserForOpenTournamentQuery, CheckUserForOpenTournamentQueryVariables>(CheckUserForOpenTournamentDocument, baseOptions);
        }
export type CheckUserForOpenTournamentQueryHookResult = ReturnType<typeof useCheckUserForOpenTournamentQuery>;
export type CheckUserForOpenTournamentLazyQueryHookResult = ReturnType<typeof useCheckUserForOpenTournamentLazyQuery>;
export type CheckUserForOpenTournamentQueryResult = Apollo.QueryResult<CheckUserForOpenTournamentQuery, CheckUserForOpenTournamentQueryVariables>;
export const MyWalletDocument = gql`
    query myWallet {
  myWallet {
    id
    coin
    availableCoin
  }
}
    `;

/**
 * __useMyWalletQuery__
 *
 * To run a query within a React component, call `useMyWalletQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyWalletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyWalletQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyWalletQuery(baseOptions?: Apollo.QueryHookOptions<MyWalletQuery, MyWalletQueryVariables>) {
        return Apollo.useQuery<MyWalletQuery, MyWalletQueryVariables>(MyWalletDocument, baseOptions);
      }
export function useMyWalletLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyWalletQuery, MyWalletQueryVariables>) {
          return Apollo.useLazyQuery<MyWalletQuery, MyWalletQueryVariables>(MyWalletDocument, baseOptions);
        }
export type MyWalletQueryHookResult = ReturnType<typeof useMyWalletQuery>;
export type MyWalletLazyQueryHookResult = ReturnType<typeof useMyWalletLazyQuery>;
export type MyWalletQueryResult = Apollo.QueryResult<MyWalletQuery, MyWalletQueryVariables>;
export const MyCoinExchangeRequestsDocument = gql`
    query myCoinExchangeRequests($first: Int, $after: String) {
  myCoinExchangeRequests(first: $first, after: $after) {
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...coinExchangeRequest
      }
    }
  }
}
    ${CoinExchangeRequestFragmentDoc}`;

/**
 * __useMyCoinExchangeRequestsQuery__
 *
 * To run a query within a React component, call `useMyCoinExchangeRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCoinExchangeRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCoinExchangeRequestsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useMyCoinExchangeRequestsQuery(baseOptions?: Apollo.QueryHookOptions<MyCoinExchangeRequestsQuery, MyCoinExchangeRequestsQueryVariables>) {
        return Apollo.useQuery<MyCoinExchangeRequestsQuery, MyCoinExchangeRequestsQueryVariables>(MyCoinExchangeRequestsDocument, baseOptions);
      }
export function useMyCoinExchangeRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyCoinExchangeRequestsQuery, MyCoinExchangeRequestsQueryVariables>) {
          return Apollo.useLazyQuery<MyCoinExchangeRequestsQuery, MyCoinExchangeRequestsQueryVariables>(MyCoinExchangeRequestsDocument, baseOptions);
        }
export type MyCoinExchangeRequestsQueryHookResult = ReturnType<typeof useMyCoinExchangeRequestsQuery>;
export type MyCoinExchangeRequestsLazyQueryHookResult = ReturnType<typeof useMyCoinExchangeRequestsLazyQuery>;
export type MyCoinExchangeRequestsQueryResult = Apollo.QueryResult<MyCoinExchangeRequestsQuery, MyCoinExchangeRequestsQueryVariables>;