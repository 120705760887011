import {
  TournamentDetailQuery,
  TournamentForceEntryRemoveInput,
  TournamentStatus,
  UserEntryFragment,
  useMeQuery,
  useTournamentForceEntryRemoveMutation,
} from '@/graphql/client'
import React, {useState} from 'react'
import {ActionModal, Button, UserListItemGrid} from '@/components'
import MessageEmptyState from '@/components/atoms/message-empty-state'

import {Box, ListItem, ListItemIcon, ListItemText, createStyles, makeStyles} from '@material-ui/core'
import {serverConfig} from '@/env'
import {useSwitchUser} from '@/hooks'
import {BottomSheet} from '@/components/atoms/bottom-sheet'
import {List} from '@material-ui/core/'
import {AccountCircle, Block, Shuffle} from '@material-ui/icons'
import {useRouter} from 'next/router'
import {LoadingPart} from '../../atoms/loading-part'
import {useSnackbar} from '@/hooks/acknowledge'

type TournamentParticipantContent = TournamentDetailQuery

const useStyles = makeStyles(({palette, breakpoints, spacing}) =>
  createStyles({
    root: {
      marginRight: 'auto',
      marginLeft: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: '343px',
      [breakpoints.up('lg')]: {
        marginTop: spacing(6),
        maxWidth: '800px',
      },
    },
    primaryText: {
      color: palette.text.primary,
    },
    dangerText: {
      color: palette.status.error,
    },
    iconPrimary: {
      color: palette.text.primary,
    },
    iconDanger: {
      color: palette.status.error,
    },
  })
)

export const TournamentParticipantContent: React.FC<TournamentParticipantContent> = (props: TournamentParticipantContent) => {
  const tournament = props.tournament

  const styles = useStyles()

  const router = useRouter()
  const {snackbar} = useSnackbar()

  const {switchUser, resetUser} = useSwitchUser()
  const switchOrganizer = () => switchUser(tournament.organizer.id)

  const {data: meData, loading: meLoading} = useMeQuery()
  const [tournamentForceEntryRemove, {
    loading: mutationLoading,
  }] = useTournamentForceEntryRemoveMutation()

  // Modal
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

  // Bottom Sheets
  const [isOpenSheet, setIsOpenSheet] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<UserEntryFragment|null>(null)
  const handleClick = (user: UserEntryFragment) => {
    setSelectedUser(user)
    setIsOpenSheet(true)
  }
  const handleSwitchUser = () => {
    switchUser(selectedUser!.id)
    setIsOpenSheet(false)
  }
  const handleGoProfile = async () => {
    await router.push(`/users/${selectedUser!.id}/profile`)
    setIsOpenSheet(false)
  }
  const showConfirm = () => {
    setIsOpenSheet(false)
    setIsOpenModal(true)
  }

  const handleForceExit = async () => {
    const input: TournamentForceEntryRemoveInput = {
      tournamentId: tournament.id,
      userId: selectedUser!.id,
    }

    try {
      await tournamentForceEntryRemove({
        variables: {
          input: input,
        },
      })
      snackbar.success('エントリー解除に成功しました')
    } catch (e) {
      snackbar.failed('エントリー解除に失敗しました', e)
    }
  }

  // UI
  const WarningNotice = () => {
    if (tournament.entries.length > 0) {
      return (<></>)
    }
    if (tournament.status === TournamentStatus.Cancel) {
      return (<MessageEmptyState message='この大会は中止されました'/>)
    }
    return (<MessageEmptyState message='まだエントリーしている人はいません'/>)
  }

  if (meLoading || mutationLoading) {
    return <LoadingPart/>
  }

  const userId = meData?.me.id
  const isOrganizerOrModerator = meData?.me.id === tournament.organizer.id || !!meData?.me.isTournamentModerator

  return (
    <>
      <Box className={styles.root}  >
        <WarningNotice />
        {!serverConfig.isPrd && (
          <>
            <Button style={{margin: 16}} outlined onClick={switchOrganizer}>
                主催者に切り替え
            </Button>
            <Button style={{margin: 16}} outlined onClick={resetUser}>
                元のユーザに戻る
            </Button>
          </>
        )}

        <UserListItemGrid
          users={tournament.entries}
          gameId={tournament.game.id}
          style={{marginBottom: 40}}
          handleClick={handleClick}
        />
      </Box>

      <BottomSheet open={isOpenSheet} onClose={() => setIsOpenSheet(false)}>
        <List component="nav" aria-label="secondary mailbox folders">
          {!serverConfig.isPrd && (
            <ListItem button onClick={handleSwitchUser}>
              <ListItemIcon>
                <Shuffle
                  className={styles.iconPrimary}
                  fontSize={'large'}
                />
              </ListItemIcon>
              <ListItemText primary="このユーザーに切り替え" />
            </ListItem>
          )}
          <ListItem button onClick={handleGoProfile}>
            <ListItemIcon>
              <AccountCircle
                className={styles.iconPrimary}
                fontSize={'large'}
              />
            </ListItemIcon>
            <ListItemText primary="プロフィールを見る" />
          </ListItem>
          {isOrganizerOrModerator &&
            tournament.status === TournamentStatus.Public && (
            <ListItem button onClick={showConfirm}>
              <ListItemIcon>
                <Block
                  className={styles.iconDanger}
                  fontSize={'large'}
                />
              </ListItemIcon>
              <ListItemText
                className={styles.dangerText}
                primary="エントリー解除"
              />
            </ListItem>
          )}
        </List>
      </BottomSheet>

      {/* エントリー解除モーダル */}
      <ActionModal
        open={isOpenModal}
        title={'エントリーを解除する'}
        contents={`「${selectedUser?.profile.displayName}」のエントリーを解除してもよろしいですか？`}
        buttonText={'解除する'}
        doAction={handleForceExit}
        handleClose={() => setIsOpenModal(false)}
        actionButtonStyle={styles.dangerText}
        userId={userId}
        tournamentId={tournament.id}
      />
    </>
  )
}

