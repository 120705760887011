import {
  Theme,
  useMediaQuery,
} from '@material-ui/core'

export const useIsLg = () => {

  return useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'))

}


export const useIsLgNoSsr = () => {

  return useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'), {noSsr: true})

}
