import * as React from 'react'
import {createContext, useContext, useState} from 'react'

export type GameContextState = {
  gameId: string
  genreId: string
  setGameId: (gameId: string) => void
  setGenreId: (genreId: string) => void
}

const initialState: GameContextState = {
  gameId: '',
  genreId: '',
  setGameId: () => { /* do nothing */ },
  setGenreId: () => { /* do nothing */ },
}

export const GameContext = createContext<GameContextState>(initialState)

export const GameContextProvider: React.FC = ({children}) => {
  const [genreId, setGenreId] = useState<string>('')
  const [gameId, setGameId] = useState<string>('')

  return (
    <GameContext.Provider value={{gameId, setGameId, genreId, setGenreId}}>{children}</GameContext.Provider>
  )
}

export const useGameContext = () => useContext<GameContextState>(GameContext)
