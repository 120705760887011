import React from 'react'
import {makeStyles} from '@material-ui/core'
import {Box} from '@material-ui/core/'
import {TeamEntryFormType} from '@/components'
import {
  ClanFragment,
  TournamentTeamFragment,
  TournamentUnitType,
  UserFragment,
  useClanLazyQuery,
} from '@/graphql/client'
import {useSnackbar} from '@/hooks/acknowledge'
import {ChoseClanContent} from './chose-clan-content'
import {ConfirmContent} from './confirm-content'
import {ChoseMemberContent} from './chose-member-content'
import {ChoseManagerContent} from './chose-manager-content'
import {Stepper} from '@/components/atoms/stepper'

const useTeamEntryContentStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

enum TeamEntryStep {
  CHOSE_CLAN,
  CHOSE_MEMBER,
  CHOSE_MANAGER,
  CONFIRM
}

type TeamEntryContentProps = {
  mode: TeamEntryFormType
  team?: TournamentTeamFragment
  gameId: string
  unitType: TournamentUnitType
  onSubmit: (clanId: string, memberIds: string[], managerIds: string[]) => void
}

export const TeamEntryContent: React.FC<TeamEntryContentProps> = (props) => {
  const styles = useTeamEntryContentStyles()
  const {snackbar} = useSnackbar()

  const {mode, team, gameId, unitType, onSubmit} = props
  const [getClan, {data: clanData, loading: clanLoading}] = useClanLazyQuery()

  // initial values
  const editMode = mode === 'edit'
  const initialClanId = team ? team.clan.id : null
  const initialMemberIds = team ? team.users.map(it => it.id) : []
  const initialManagerIds = team ? team.managers.map(it => it.id) : []

  const [activeStep, setActiveStep] = React.useState<TeamEntryStep>(TeamEntryStep.CHOSE_CLAN)
  const [selectedClanId, setSelectedClanId] = React.useState<string | null>(initialClanId)
  const [selectedMemberIds, setSelectedMemberIds] = React.useState<string[]>(initialMemberIds)
  const [selectedManagerIds, setSelectedManagerIds] = React.useState<string[]>(initialManagerIds)

  const steps = [TeamEntryStep.CHOSE_CLAN, TeamEntryStep.CHOSE_MEMBER, TeamEntryStep.CHOSE_MANAGER, TeamEntryStep.CONFIRM]

  const clan = clanData?.clan
  const clanMembers = (clan ? [...clan.members, clan.owner] : []) as UserFragment[]
  const clanManagers = (clan ? clan.clanUserRoles.filter(it => it.role.name === 'clan:manager').map(it => it.user) : []) as UserFragment[]

  React.useEffect(() => {
    (async () => {
      if (editMode) {
        getClan({variables: {id: team!.clan.id}})
        setActiveStep(TeamEntryStep.CHOSE_MEMBER)
      }
    })()
  }, [editMode])

  const handleClanSelected = async (clan: ClanFragment) => {
    setSelectedClanId(clan.id)
    setActiveStep(TeamEntryStep.CHOSE_MEMBER)
    try {
      getClan({variables: {id: clan.id}})
    } catch (e) {
      snackbar.failed('クランの取得に失敗しました', e)
      setActiveStep(TeamEntryStep.CHOSE_CLAN)
    }
  }

  const handleMemberSelected = (userIds: string[]) => {
    setSelectedMemberIds(userIds)

    if (clanManagers[0]) {
      setActiveStep(TeamEntryStep.CHOSE_MANAGER)
    } else {
      setActiveStep(TeamEntryStep.CONFIRM)
    }
  }

  const handleManagerSelected = (managerIds: string[]) => {
    setSelectedManagerIds(managerIds)
    setActiveStep(TeamEntryStep.CONFIRM)
  }

  const handleConfirm = () => onSubmit(selectedClanId!, selectedMemberIds, selectedManagerIds)

  const loading = clanLoading

  return (
    <Box className={styles.root}>
      {/* Stepper */}
      <Stepper activeStep={activeStep} steps={steps.length}/>

      {/* Stepper Contents */}
      <>
        {loading && (<></>)}

        {/* クラン選択 */}
        {activeStep === TeamEntryStep.CHOSE_CLAN && (
          <ChoseClanContent handleChose={handleClanSelected}/>
        )}

        {/* メンバー選択 */}
        {(!loading && activeStep === TeamEntryStep.CHOSE_MEMBER) && (
          <ChoseMemberContent
            mode={mode}
            initialSelectedIds={initialMemberIds}
            users={clanMembers}
            gameId={gameId}
            unitType={unitType}
            onNext={handleMemberSelected}
            onBack={() => setActiveStep(TeamEntryStep.CHOSE_CLAN)}
          />
        )}

        {/* マネージャー選択 */}
        {(!loading && activeStep === TeamEntryStep.CHOSE_MANAGER) && (
          <ChoseManagerContent
            initialSelectedIds={initialManagerIds}
            memberIds={selectedMemberIds}
            managers={clanManagers}
            gameId={gameId}
            onNext={handleManagerSelected}
            onBack={() => setActiveStep(TeamEntryStep.CHOSE_CLAN)}
          />
        )}

        {/* 確認 */}
        {(!loading && activeStep === TeamEntryStep.CONFIRM) && (
          <ConfirmContent
            gameId={gameId}
            clan={clan!}
            memberIds={selectedMemberIds}
            managerIds={selectedManagerIds}
            onNext={handleConfirm}
            onBack={() => setActiveStep(TeamEntryStep.CHOSE_MEMBER)}
          />
        )}
      </>
    </Box>
  )
}
