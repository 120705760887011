import {TournamentBattleResult, TournamentNodeStatus} from '@/graphql/client'
import React from 'react'

import {Box, ButtonBase, Typography, createStyles, makeStyles} from '@material-ui/core'
import {Avatar, SquareAvatar} from '@/components'
import Link from 'next/link'
import {
  SingleEliminationNodeFragment,
  SingleEliminationUnitFragment,
  isTournamentNodeFragment,
} from '@/types/tournament'
import {getTournamentNodeUnitThumbnailUrl, getTournamentNodeUnits, getTournamentUnitDisplayName} from '@/utils'

const useTournamentBracketStyle = makeStyles(({breakpoints}) =>
  createStyles({
    roundTitle: {
      textAlign: 'center',
      marginBottom: '20px',
      marginRight: '48px',
    },
    roundBoxScroll: {
      paddingTop: '6px',
      overflowX: 'scroll',
      paddingLeft: '17px',
      scrollSnapType: 'x mandatory',
      [breakpoints.up('lg')]: {
        overflowX: 'auto',
        scrollSnapType: 'none',
      },
    },
    scrollPoint: {
      scrollSnapAlign: 'start',
      scrollMarginLeft: '40px',
    },
    roundBoxRow: {
      display: 'flex',
    },
    roundBoxWrap: {
    },
    roundBoxIndex: {
      padding: '30px 0 29px',
      overflow: 'hidden',
      position: 'relative',
    },
    roundBoxIndexRound: {
      '&:nth-of-type(odd)::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        height: '50%',
        width: '16px',
        right: '24px',
        top: '50%',
        borderTop: '1px solid #3D3D3D',
        borderRight: '1px solid #3D3D3D',
        borderRadius: '0 4px 0 0',
      },
      '&:nth-of-type(odd)::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        right: '9px',
        top: 'calc(100% - 1px)',
        width: '15px',
        height: '1px',
        background: '#3D3D3D',
      },
      '&:nth-of-type(even)::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        height: '50%',
        width: '16px',
        right: '24px',
        bottom: '50%',
        borderBottom: '1px solid #3D3D3D',
        borderRight: '1px solid #3D3D3D',
        borderRadius: '0 0 4px 0',
      },
    },
    roundBoxIndexOuter: {
      border: '1px solid rgba(255, 255, 255, 0.12)',
      borderRadius: '4px',
      marginRight: '48px',
      position: 'relative',
      marginBottom: '-1px',
    },
    roundBoxIndexInner: {
      width: '198px',
    },
    roundMatch: {
      background: '#232323',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '4px 8px 4px 7px',
      position: 'relative',
      minHeight: '40px',
      '&:nth-of-type(1)': {
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      },
      '&:nth-of-type(2)': {

      },
    },
    roundMatchBlank: {
      background: '#232323',
    },
    userName: {
      lineHeight: '1',
      textAlign: 'initial',
      maxHeight: 28,
      maxWidth: 130,
      overflow: 'hidden',
      wordBreak: 'break-word',
    },
    score: {
      color: 'rgba(255, 255, 255, 0.6)',
      textAlign: 'right',
      right: '8px',
      top: '11px',
      fontSize: '15px',
      lineHeight: '18px',
      marginLeft: 'auto',
    },
    avatar: {
      width: '32px',
      height: '32px',
      marginRight: '7px',
    },
    roundStatus: {
      fontWeight: 'bold',
      fontSize: '10px',
      lineHeight: '15px',
      position: 'absolute',
      left: '0',
      top: '-20px',
      borderRadius: '10px',
      padding: '0 8px',
    },
    roundStatusCONFIRM: {
      background: '#489FB5',
    },
    roundStatusIN_REVIEW: {
      background: '#477755',
    },
    roundStatusPENDING: {
      background: 'rgba(255, 255, 255, 0.38)',
    },
    roundStatusIN_BATTLE: {
      background: '#F3C000',
    },
    roundStatusHIDE: {
      display: 'none',
    },
    winner: {
      background: '#232323',
    },
    loser: {
      background: 'none',
      '& > *': {
        opacity: '0.6',
      },
    },
    noUserAvatar: {
      width: '32px',
      height: '32px',
      marginRight: '7px',
      borderRadius: '50%',
      border: '2px dashed rgba(255, 255, 255, 0.6)',
      textAlign: 'center',
      paddingTop: '3px',
    },
    itemLink: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
)

type TournamentBracketItemUsersProps = {
  units: SingleEliminationUnitFragment[]
  status: TournamentNodeStatus
}

// 各対戦node内のユーザー or チームの出力
const TournamentBracketItemUnit: React.FC<TournamentBracketItemUsersProps> = ({units, status}) => {
  const styles = useTournamentBracketStyle()

  const players: (SingleEliminationUnitFragment | null)[] = []
  players.push(units.find((x) => x.index == 0) || null)
  players.push(units.find((x) => x.index == 1) || null)

  const getResultStyle = (result: TournamentBattleResult): string => {
    switch (result) {
      case TournamentBattleResult.Winner:
        return styles.winner
      case TournamentBattleResult.Loser:
      case TournamentBattleResult.Eliminated:
        return styles.loser
      case TournamentBattleResult.NotDetermined:
        return styles.roundMatchBlank
    }
  }

  const getNoOpponentStyle = (): string => {
    if (status === TournamentNodeStatus.Confirm) {
      return styles.loser
    }
    return styles.roundMatchBlank
  }

  const userlist = players.map((player, index: number) =>
    <Box key={index} className={styles.roundMatch + ' ' + (player && player.battleResult ? getResultStyle(player.battleResult) : getNoOpponentStyle())}>
      {
        (() => {
          if (player) {
            const thumbnail = getTournamentNodeUnitThumbnailUrl(player)
            const displayName = getTournamentUnitDisplayName(player)

            return (
              <>
                {isTournamentNodeFragment(player) ? (
                  <Avatar src={thumbnail} className={styles.avatar} />
                ) : (
                  <SquareAvatar src={thumbnail} className={styles.avatar} />
                )}
                <Typography variant='subtitle2' className={styles.userName}>{displayName}</Typography>
                <Typography className={styles.score}>{player.score === null ? '-' : player.score}</Typography>
              </>
            )
          }
          if (status === TournamentNodeStatus.Confirm) {
            // playerがnullで、statusがCONFIRMの場合は不戦勝と判断
            return (
              <>
                {isTournamentNodeFragment(player) ? (
                  <Avatar className={styles.avatar} />
                ) : (
                  <SquareAvatar className={styles.avatar} />
                )}
                <Typography variant='subtitle2' className={styles.userName}>対戦者なし</Typography>
                <Typography className={styles.score}>-</Typography>
              </>
            )
          }
        })()
      }
    </Box>
  )

  return (
    <>
      {userlist}
    </>
  )
}

type TournamentBracketItemIndexProps = {
  tournamentId: string
  round: SingleEliminationNodeFragment[]
  roundNum: number
  coltype: boolean
}

// 各対戦nodeの出力
const TournamentBracketItemIndex: React.FC<TournamentBracketItemIndexProps> = ({tournamentId, round, roundNum, coltype}) => {
  const styles = useTournamentBracketStyle()

  const getNodeStatus = (status: TournamentNodeStatus): string => {
    switch (status) {
      case TournamentNodeStatus.Pending:
        return '待機中'
      case TournamentNodeStatus.InBattle:
        return '対戦中'
      case TournamentNodeStatus.InReview:
        return '承認待ち'
      case TournamentNodeStatus.Confirm:
        return '確認済み'
    }
  }

  const getPadding = (): string => {
    const pad = 30
    const baseheight = 82
    const boxheight = 142
    if (roundNum === 0) {
      return pad + 'px 0'
    } else {
      const half = (boxheight * Math.pow(2, roundNum) - baseheight) / 2
      // 各ノードの隙間に1pxの線がある分を調整
      return `${half}px 0 ${half+1}px 0`
    }
  }

  const getRoundStyle = (result: string): string => {
    switch (result) {
      case TournamentNodeStatus.Pending:
        return styles.roundStatusPENDING
      case TournamentNodeStatus.InBattle:
        return styles.roundStatusIN_BATTLE
      case TournamentNodeStatus.InReview:
        return styles.roundStatusIN_REVIEW
      case TournamentNodeStatus.Confirm:
        return styles.roundStatusIN_REVIEW
      default:
        return styles.roundStatus
    }
  }

  const nodelist = round.map((data) => {
    return (
      <Box key={data.nodeId} style={{padding: getPadding()}}
        className={styles.roundBoxIndex + ' ' + (coltype ? styles.roundBoxIndexRound : '')}
      >
        <Box className={styles.roundBoxIndexOuter}>
          <Typography variant='caption'
            className={styles.roundStatus + ' ' + (!data.status ? styles.roundStatusHIDE : getRoundStyle(data.status))}
          >{getNodeStatus(data.status)}
          </Typography>
          <ButtonBase>
            <Link href={`/tournaments/${tournamentId}/nodes/${data.nodeId}/battle`}>
              <Box className={styles.roundBoxIndexInner}>
                <TournamentBracketItemUnit units={getTournamentNodeUnits(data)} status={data.status}/>
              </Box>
            </Link>
          </ButtonBase>
        </Box>
      </Box>)
  })
  return (
    <>
      {nodelist}
    </>
  )
}

type TournamentBracketItemProps = {
  tournamentId: string
  rounds: SingleEliminationNodeFragment[][]
  wrapperWidth: string
}

// 「N回戦」カラムの出力
export const TournamentBracketItem: React.FC<TournamentBracketItemProps> = ({tournamentId, rounds, wrapperWidth, ...props}) => {
  const styles = useTournamentBracketStyle()

  // 「N回戦」の日本語名称を取得
  const getRoundNumber = (index: number, max: number): string => {
    if (index === max) {
      return '決勝'
    } else if (index === max - 1) {
      return '準決勝'
    }
    return index + '回戦'
  }

  const cols = rounds.map((round, index: number) =>
    <Box key={index} width="248px" className={styles.scrollPoint}>
      <Typography variant='h4' className={styles.roundTitle}>{getRoundNumber(index + 1, rounds.length)}</Typography>
      <Box className={styles.roundBoxWrap}>
        <TournamentBracketItemIndex tournamentId={tournamentId} round={round} roundNum={index} coltype={((index + 1 === rounds.length) ? false : true)} />
      </Box>
    </Box>
  )

  return (
    <div {...props}>
      <Box className={styles.roundBoxScroll}>
        <Box width={wrapperWidth} className={styles.roundBoxRow}>
          {cols}
        </Box>
      </Box>
    </div>
  )
}
