import React, {ReactElement}  from 'react'

import {Button, createStyles, makeStyles} from '@material-ui/core'
import {GenreWithGameFragment, useGenresQuery} from '@/graphql/client'
import {useGameContext} from '@/hooks/game'
import {Box} from '@material-ui/core/'
import {LoadingPart} from '../atoms'
import {GameTitle} from '.'

const useGameTitleStyles = makeStyles(() => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  item: {
    fontWeight: 'bold',
    borderRadius: '100px',
    margin: '0.3em',
  },
  inactiveItem: {
    color: 'rgba(255, 255, 255, 0.38)',
    border: '1px solid rgba(255, 255, 255, 0.12)',
  },
  activeItem: {
    color: '#55C341',
    border: '1px solid #55C341',
  },
  gameTitleWrap: {
    marginTop: 16,
  },
}))

export const Genres: React.FC = () => {
  const {data, loading, error} = useGenresQuery()
  if (!data || loading || error) {return <LoadingPart />}

  const genres = (data.genres.nodes || []) as GenreWithGameFragment[]
  const validGenres = genres.filter(it => it.games.filter(it => it.enabled).length > 0)
  if (!validGenres[0]) {return <></>}

  return <GenresUI genres={validGenres} />
}

type GenresUIProps = {
  genres: GenreWithGameFragment[]
}

const GenresUI = ({genres}: GenresUIProps): ReactElement => {
  const styles = useGameTitleStyles()
  const {genreId, setGameId, setGenreId} = useGameContext()

  const setGenreIdAndGameId = (genreId: string) => {
    const genre = genres.find(it => it.id == genreId)
    if (!genre) return
    setGenreId(genre.id)
    setGameId(genre.games[0].id)
  }

  // initialize
  if (!genreId) setGenreIdAndGameId(genres[0].id)

  return (
    <>
      <Box className={styles.container}>
        {genres.map(((genre) => {
          const className = `${styles.item} ${genre.id === genreId ? styles.activeItem : styles.inactiveItem}`
          return (
            <Button
              variant="outlined"
              className={className}
              key={genre.id}
              onClick={() => {setGenreIdAndGameId(genre.id)}}
            >
              {genre.name}
            </Button>
          )
        }))}
      </Box>
      <Box className={styles.gameTitleWrap}>
        <GameTitle />
      </Box>
    </>
  )
}
