import React from 'react'
import {TournamentDetailWithSponsorQuery} from '@/graphql/client'
import {Box, Button, CardContent, Typography, createStyles, makeStyles} from '@material-ui/core'
import MessageEmptyState from '@/components/atoms/message-empty-state'
import {Linkify} from '@/components/atoms/linkify'
import {Card, Link} from '@/components'

const useStyle = makeStyles(({spacing}) => {
  return createStyles({
    cardTitle: {
      marginTop: spacing(8),
      marginBottom: spacing(2),
    },
    card: {
      padding: spacing(6),
    },
    header: {
      marginTop: spacing(1.5),
      marginBottom: spacing(11),
      '&> a': {
        display: 'block',
        margin: `${spacing(2.5)}px auto ${spacing(4)}px auto`,
        width: '240px',
      },
    },
    divider: {
      borderBottom: 'none',
    },
    content: {
      marginTop: spacing(7),
      '&> a > img': {
        width: '100%',
        margin: `${spacing(4)}px 0`,
      },
      '&> p': {
        minHeight: '1em',
      },
      '&> .MuiButton-root': {
        display: 'block',
        width: 'max-content',
        margin: `${spacing(10)}px auto`,
        fontWeight: 'bold',
      },
    },
  })
})

type TournamentSponsorContentProps = {
  tournament: TournamentDetailWithSponsorQuery['tournament']
}

export const TournamentSponsorContent = ({tournament}: TournamentSponsorContentProps) => {
  const styles = useStyle()

  if (!tournament.sponsorCampaign) {
    return (
      <Box mt={5} maxWidth={800} mx="auto">
        <MessageEmptyState message='スポンサー情報はありません。' />
      </Box>
    )
  }

  return (
    <Box maxWidth={800} mx="auto">
      <Typography variant='h4' className={styles.cardTitle}>スポンサー情報</Typography>
      <Card>
        <CardContent className={styles.card}>
          <div className={styles.header}>
            <Typography color='textSecondary' align='center'>Sponsored by</Typography>
            <Link
              href={tournament.sponsorCampaign.landingUrl}
              rel='noopener'
              target='_blank'
            >
              <img
                src={tournament.sponsorCampaign.sponsor.bannerUrl}
                alt={tournament.sponsorCampaign.sponsor.name}
              />
            </Link>
            <Typography variant='body2' align='center'>
              「{tournament.title}」は<br />「
              <Link
                href={tournament.sponsorCampaign.landingUrl}
                rel='noopener'
                target='_blank'
              >
                {tournament.sponsorCampaign.sponsor.name}
              </Link>
              」の提供で開催しています。
            </Typography>
          </div>
          <hr className={styles.divider} />
          <div className={styles.content}>
            <Typography variant='caption' color='textSecondary'>お知らせ</Typography>
            <Typography variant='h4'>
              <Link
                href={tournament.sponsorCampaign.landingUrl}
                rel='noopener'
                target='_blank'
              >
                {tournament.sponsorCampaign.title}
              </Link>
            </Typography>

            <Link
              href={tournament.sponsorCampaign.landingUrl}
              rel='noopener'
              target='_blank'
            >
              <img src={tournament.sponsorCampaign.coverUrl} alt={tournament.sponsorCampaign.title} />
            </Link>

            {tournament.sponsorCampaign.description.split('\n').map((line, i) =>
              <Typography key={i} variant='body2'><Linkify>{line}</Linkify></Typography>
            )}

            <Button
              variant='outlined'
              color='primary'
              size='large'
              href={tournament.sponsorCampaign.landingUrl}
              target='_blank'
              rel='noopener'
            >
              詳細をみてみる
            </Button>
          </div>
        </CardContent>
      </Card>
    </Box>
  )
}
