import React from 'react'
import {IconButton, Menu, MenuItem, createStyles, makeStyles} from '@material-ui/core/'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import {ActionModal} from '@/components'

const useStyles = makeStyles(({palette}) => {
  return createStyles({
    dangerText: {
      color: palette.error.main,
    },
    deleteButton: {
      backgroundColor: palette.status.error,
    },
  })
})

type AnnouncementActionProps = {
  userId: string
  tournamentId: string
  onDelete?: (() => void) | false
}

export const AnnouncementAction: React.FC<AnnouncementActionProps> = ({onDelete, userId, tournamentId}) => {
  const styles = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)

  if (!onDelete) return null

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleDeleteModalOpen = () => {
    setDeleteModalOpen(true)
    setAnchorEl(null)
  }
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false)
  }

  return (
    <div>
      <IconButton aria-label='actions' onClick={handleOpen}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null) }
      >
        <MenuItem className={styles.dangerText} onClick={handleDeleteModalOpen}>削除する</MenuItem>
      </Menu>

      <ActionModal
        open={deleteModalOpen}
        doAction={onDelete}
        handleClose={handleDeleteModalClose}
        title='大会アナウンスを削除'
        contents='この大会アナウンスを削除してもよろしいですか？'
        actionButtonStyle={styles.deleteButton}
        buttonText='削除する'
        userId={userId}
        tournamentId={tournamentId}
      />
    </div>
  )
}
