import React from 'react'
import {Grid, Typography, createStyles, makeStyles} from '@material-ui/core'
import {ClanManageMemberList, LoadingError} from '@/components'
import {
  ClanMemberRemoveInput,
  ClanMemberRoleChangeInput,
  ClanMemberRoles,
  ClanTransferOwnerInput,
  UserFragment,
  useClanMemberRemoveMutation,
  useClanMemberRolechangeMutation,
  useClanQuery,
  useClanTransferOwnerMutation,
  useMeQuery,
} from '@/graphql/client'
import {useRouter} from 'next/router'
import {LoadingPart} from '../../atoms/loading-part'
import {useSnackbar} from '@/hooks/acknowledge'

const useClanMemberManagementStyle = makeStyles(({palette}) =>
  createStyles({
    head: {
      marginBottom: '40px',
    },
    clanIcon: {
      display: 'block',
      width: '32px',
      height: '32px',
      objectFit: 'cover',
      borderRadius: '4px',
    },
    clanName: {
      marginLeft: '8px',
      fontSize: '14px',
    },
    number: {
      color: palette.text.secondary,
    },
    title: {
      marginBottom: '16px',
      fontSize: '18px',
    },
  })
)

export const ClanMemberManagement: React.FC = () => {
  const styles = useClanMemberManagementStyle()

  const router = useRouter()
  const {snackbar} = useSnackbar()
  const [transferOwner] = useClanTransferOwnerMutation()
  const [memberRemove] = useClanMemberRemoveMutation()
  const [memberRoleChange] = useClanMemberRolechangeMutation()

  const clanId = router.query.id as string
  const {data: clanData, loading: clanLoading, error: clanError} = useClanQuery({variables: {id: clanId}})
  const {data: meData, loading: meLoading, error: meError} = useMeQuery()

  const handleTransferOwner = async (user: UserFragment) => {
    const input: ClanTransferOwnerInput = {
      clanId,
      newOwnerId: user.id,
    }

    try {
      await transferOwner({variables: {input: input}})
      await router.push(`/clan/${clanId}/detail`)
      snackbar.success(`${user.profile.displayName}をクランマスターにしました`)
    } catch (e) {
      snackbar.failed('権限の移譲に失敗しました', e)
    }
  }

  const handleMemberRemove = async (user: UserFragment) => {
    const input: ClanMemberRemoveInput = {
      clanId,
      userId: user.id,
    }

    try {
      await memberRemove({variables:{input: input}})
      snackbar.success(`${user.profile.displayName}を除名しました`)
    } catch (e) {
      snackbar.failed(`${user.profile.displayName}の除名に失敗しました`, e)
    }
  }

  const handleChangeMemberRole = async (user: UserFragment) => {
    const isManager = getIsManager(user)
    const input: ClanMemberRoleChangeInput = {
      clanId: clanId,
      userId: user.id,
      role: isManager ? ClanMemberRoles.Member : ClanMemberRoles.Manager,
    }

    try {
      await memberRoleChange({variables:{input: input}})
      snackbar.success(`${user.profile.displayName}を${isManager ? 'メンバー' : 'マネージャー'}に変更しました`)
    } catch (e) {
      snackbar.failed(`${user.profile.displayName}の権限変更に失敗しました`, e)
    }
  }

  if (clanLoading || meLoading) {
    return <LoadingPart/>
  }

  if (clanError || meError) {
    return <LoadingError/>
  }

  const clan = clanData?.clan
  const me = meData?.me
  const isOwner = clan?.owner.id === me?.id

  // アーカイブ済 or マスターじゃない
  if (!clan || !isOwner) {
    router.push('/clan')
    return <></>
  }

  const getIsManager = (user: UserFragment) => {
    return clan.clanUserRoles.find(it => it.user.id === user.id) !== undefined
  }

  return (
    <>
      <Grid container alignItems="center" className={styles.head}>
        <img src={clan.logoUrl} alt="clan icon" className={styles.clanIcon}/>
        <Typography variant="subtitle2" className={styles.clanName}>{clan.name}</Typography>
      </Grid>

      {/*<Typography variant="h4" className={styles.title}>加入申請 <span className={styles.number}>(2)</span></Typography>*/}
      {/*<ClanApplicationList />*/}

      <Typography variant="h4" className={styles.title}>メンバー <span className={styles.number}>({clan.memberNum})</span></Typography>
      <ClanManageMemberList
        owner={clan.owner}
        members={clan.members}
        userRoles={clan.clanUserRoles}
        handleTransferOwner={handleTransferOwner}
        handleMemberRemove={handleMemberRemove}
        handleMakeManager={handleChangeMemberRole}
        handleMakeMember={handleChangeMemberRole}
      />
    </>
  )
}
