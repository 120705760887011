import getConfig from 'next/config'

const {serverRuntimeConfig} = getConfig()

export type SentryConfig = {
  dsn: string|undefined
  distDir: string
}

export const sentryConfig: SentryConfig = {
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  distDir: `${serverRuntimeConfig.ROOT_DIR}/.next`,
}
