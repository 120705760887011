import {DrawerMeFragment, useDrawerMeQuery} from '@/graphql/client'
import {useClientStateContext} from '@/hooks/apollo'
import React from 'react'

import {
  useRouter,
} from 'next/router'

import {
  Divider,
} from '@material-ui/core/'

import SettingsIcon from '@material-ui/icons/Settings'
import SignOutIcon from '@material-ui/icons/ExitToApp'

import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles'

import {
  Button,
  Link,
  Logo, appBarHeight,
} from '../../atoms'

import {
  DrawerMenuList,
  DrawerMenuListAccountItem,
  DrawerMenuListItem,
  DrawerMenuListSubItem,
} from '../../molecules'
import {useAuth0} from '@auth0/auth0-react'
import {auth0Config} from '@/env'

type DrawerContentProps = {
  tournamentId?: string
}

const useDrawerContentStyle = makeStyles(({palette, spacing}) =>
  createStyles({
    drawerContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    logoContainer: {
      height: appBarHeight,
      backgroundColor: palette.contentBackground,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    subMenuList: {
      position: 'absolute',
      bottom: spacing(15),
      width: '100%',
    },
    loginButton: {
      margin: '0 18px',
      marginTop: 'auto',
      marginBottom: spacing(15),
      paddingLeft: spacing(4),
      paddingRight: spacing(4),
    },
    prevLinkWrap: {
      fontSize: '15px',
      textAlign: 'left',
      justifyContent: 'flex-start',
      fontWeight: 'bold',
      '& > a': {
        display: 'block',
        width: '100%',
        boxSizing: 'border-box',
        padding: '27px 34px 27px 24px',
        '& > img': {
          verticalAlign: 'middle',
          marginTop: '-5px',
          marginRight: '5px',
        },
      },
    },
  })
)

const pathnameMap = {
  'top': ['/', '/tournaments/[id]/[tab]'],
  'entries': ['/entries'],
  'new': ['/tournaments/new', '/tournaments/get_started', '/tournaments/history'],
  'mypage': ['/mypage'],
  'clan': ['/clan', '/clan/[id]/detail', '/clan/new', '/clan/[id]/management'],
}

export const DrawerContent: React.FC<DrawerContentProps> = ({tournamentId}) => {

  const styles = useDrawerContentStyle()
  const route = useRouter()
  const {loginWithRedirect, logout} = useAuth0()

  const {authState} = useClientStateContext()
  const {data} = useDrawerMeQuery({
    skip: authState !== 'Authenticated',
  })
  const me: DrawerMeFragment | undefined = data?.me

  const handleLogin = () => loginWithRedirect({appState: {returnTo: window.location.pathname}})

  return (
    <div className={styles.drawerContainer}>
      {tournamentId ?
        (
          <div className={styles.logoContainer + ' ' + styles.prevLinkWrap}>
            <Link href={'/tournaments/' + tournamentId + '/bracket'}>
              <img src="/images/arrow.png" /> 一覧へ戻る
            </Link>
          </div>
        ) : (
          <div className={styles.logoContainer}>
            <Link href="/">
              <Logo />
            </Link>
          </div>
        )
      }
      <Divider />
      <DrawerMenuList>
        <DrawerMenuListItem href='/' title='大会に参加する' active={pathnameMap.top.includes(route.pathname)} />
        { me &&
          <DrawerMenuListItem href='/entries' title='エントリーした大会' active={pathnameMap.entries.includes(route.pathname)} />
        }
        <DrawerMenuListItem href='/tournaments/get_started' title='大会を開催する' active={pathnameMap.new.includes(route.pathname)} />
        <DrawerMenuListItem href='/clan' title='クラン' active={pathnameMap.clan.includes(route.pathname)} />
      </DrawerMenuList>
      { (me && me.profile) ?
        <DrawerMenuList className={styles.subMenuList}>
          <DrawerMenuListAccountItem
            href='/users/[id]/profile'
            as={`/users/${me.id}/profile`}
            src={me.profile.thumbnailUrl}
            name={me.profile.displayName}
            verifiedOrganizerLevel={me.verifiedOrganizerLevel}
            monthlyTicketCount={me.monthlyTicketCount}
          />
          <DrawerMenuListSubItem href='/mypage' title='アカウント設定' active={pathnameMap.mypage.includes(route.pathname)}>
            <SettingsIcon />
          </DrawerMenuListSubItem>
          <DrawerMenuListSubItem href='/' title='ログアウト' onClick={() => {logout({returnTo: auth0Config.logoutUri})}}>
            <SignOutIcon />
          </DrawerMenuListSubItem>
        </DrawerMenuList>
        :
        <Button className={styles.loginButton} outlined onClick={handleLogin}>ログイン</Button>
      }
    </div>
  )
}
