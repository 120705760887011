import React from 'react'

import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
} from '@material-ui/core'

import UserIcon from '@material-ui/icons/Person'

import {
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles'

import {
  composeClassNames,
} from '@/utils'

const useAvatarStyles = makeStyles<Theme, AvatarProps>(({palette}) => createStyles({
  placeholder: {
    color: palette.action.disabledBackground,
    backgroundColor: palette.action.disabled,
  },
  frame: {
    boxShadow: ({frameColor}) => frameColor ? `0 0 0 2px #121212, 0 0 0 4px ${frameColor}`: '',
  },
}))

type AvatarProps = MuiAvatarProps & {
  frameColor?: string
}

export enum AvatarFrameColor {
  GREEN = '#376344',
  PURPLE = '#9B01C6',
  RED = '#E53B2E',
}

export const Avatar: React.FC<AvatarProps> = ({src, className, frameColor, ...props}) => {

  const styles = useAvatarStyles({frameColor})

  return src ?
    (
      <MuiAvatar src={src} className={composeClassNames(className, styles.frame)} {...props} />
    ) : (
      <MuiAvatar className={composeClassNames(styles.placeholder, className, styles.frame)} {...props}>
        <UserIcon />
      </MuiAvatar>
    )

}
