import {useState} from 'react'

export function useLocalStorage<T>(key: string, initialValue: T): [T, ((v: T) => void)] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = (typeof window !== 'undefined') ? window.localStorage.getItem(key) : null
      return item ? JSON.parse(item) : initialValue
    } catch (e) {
      console.log(e)
      return initialValue
    }
  })

  const setValue = (value: T | ((v: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(value))
      }
    } catch (e) {
      console.log(e)
    }
  }

  return [storedValue, setValue]
}
