import {
  Link,
  TournamentBracket,
} from '@/components'
import {TournamentDetailQuery, TournamentStatus} from '@/graphql/client'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'
import {Box, createStyles, makeStyles} from '@material-ui/core'
import React from 'react'
import MessageEmptyState from '@/components/atoms/message-empty-state'
import {format, parseISO} from 'date-fns'

const bracketStyle = makeStyles(({breakpoints, spacing}) =>
  createStyles({
    container: {
      [breakpoints.up('lg')]: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: spacing(10),
        maxWidth: '800px',
      },
    },
    bracketContents: {
      maxWidth: 'calc(100vw - 32px)',
      [breakpoints.up('lg')]: {
        maxWidth: 'initial',
      },
    },
    zoomIcon: {
      marginRight: '8px',
      verticalAlign: 'middle',
      [breakpoints.up('lg')]: {
        marginRight: '14px',
      },
    },
    roundBoxHead: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '9px',
      marginBottom: '16px',
      [breakpoints.up('lg')]: {
        marginTop: '-15px',
      },
    },
    buttonLink: {
      color: '#55C341',
      border: '1px solid rgba(85, 195, 65, 0.5)',
      padding: '5px 15px',
      verticalAlign: 'middle',
      borderRadius: '4px',
    },
  })
)

type TournamentBracketContentProps = TournamentDetailQuery

export const TournamentBracketContent: React.FC<TournamentBracketContentProps> = (props: TournamentBracketContentProps) => {
  const styles = bracketStyle()

  const tournament = props.tournament

  if (tournament.status === TournamentStatus.Cancel) {
    return (
      <Box className={styles.container}>
        <MessageEmptyState message='このトーナメントは中止されました。'/>
      </Box>
    )
  }
  if (tournament.status === TournamentStatus.Public) {
    return (
      <Box className={styles.container}>
        <MessageEmptyState message={`まだトーナメントは開始していません。予定開始時間は${format(parseISO(tournament.willStartAt), 'yyyy/MM/dd HH:mm')}です。`}/>
      </Box>
    )
  }
  return (
    <Box className={styles.container}>
      <Box className={styles.roundBoxHead}>
        <Link href='/tournaments/[id]/dashboard' as={`/tournaments/${tournament.id}/dashboard`} className={styles.buttonLink}>
          <ZoomOutMapIcon className={styles.zoomIcon} />拡大表示
        </Link>
      </Box>
      <Box className={styles.bracketContents} >
        <TournamentBracket pageType={'normal'}/>
      </Box>
    </Box>
  )
}
