import {
  CoinIssuance,
  TournamentAwardType,
  TournamentResultConnection,
  useTournamentResultsQuery,
} from '@/graphql/client'
import React from 'react'
import {
  Box,
  BoxProps,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import {format, parseISO} from 'date-fns'
import MessageEmptyState from '@/components/atoms/message-empty-state'
import {getTournamentAwardTypeLabel} from '@/utils'
import {LoadingPart} from '../../atoms/loading-part'
import {LoadingError} from '@/components'
import {useIsLg} from '@/hooks'

const usePastTournamentsStyle = makeStyles(() =>
  createStyles({
    pastTournamentWrap: {
    },
    pastTournamentHead: {
      fontSize: '18px',
      lineHeight: '27px',
      marginBottom: '16px',
    },
    tableContainer: {
      background: '#1E1E1E',
      borderRadius: '4px',
      marginBottom: '40px',
    },
    table: {
    },
    tablehead: {
      color: 'rgba(255, 255, 255, 0.6)',
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      padding: '8px',
      '&:nth-of-type(1)': {
        width: '80px',
      },
      '&:nth-of-type(2)': {
        maxWidth: '400px',
      },
    },
    tablecontent: {
      color: 'rgba(255, 255, 255, 0.6)',
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      padding: '8px',
    },
    gameicon: {
      width: '36px',
      height: '36px',
      verticalAlign: 'middle',
    },
    viewMoreWrap: {
      justifyContent: 'center',
    },
    viewMore: {
      color: '#55C341',
      padding: '5px 15px',
      border: '1px solid rgba(85, 195, 65, 0.5)',
      borderRadius: '4px',
    },
  })
)

const usePastTournamentsSpStyle = makeStyles(() =>
  createStyles({
    pastTournamentWrap: {
    },
    pastTournamentHead: {
      fontSize: '18px',
      lineHeight: '27px',
      marginBottom: '16px',
    },
    tableContainer: {
      background: '#1E1E1E',
      borderRadius: '4px',
      marginBottom: '40px',
      overflowX: 'auto',
      width: 'calc(100vw - 32px)',
    },
    table: {
      minWidth: 650,
    },
    tablehead: {
      color: 'rgba(255, 255, 255, 0.6)',
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      padding: '8px',
      '&:nth-of-type(1)': {
        width: '80px',
      },
      '&:nth-of-type(2)': {
        maxWidth: '400px',
      },
    },
    tablecontent: {
      color: 'rgba(255, 255, 255, 0.6)',
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      padding: '8px',
    },
    gameicon: {
      width: '36px',
      height: '36px',
      verticalAlign: 'middle',
    },
    viewMoreWrap: {
      justifyContent: 'center',
    },
    viewMore: {
      color: '#55C341',
      padding: '5px 15px',
      border: '1px solid rgba(85, 195, 65, 0.5)',
      borderRadius: '4px',
    },
  })
)

const createData = (icon: string, title: string, willStartAt: string, rank: TournamentAwardType, exp: number, ac: CoinIssuance | null | undefined) => {
  let dataExp = '-'
  if (exp) {
    dataExp = exp + 'XP'
  }

  let dataAc = '-'
  if (ac) {
    dataAc = ac.coin + 'AC'
  }

  const dataRank = getTournamentAwardTypeLabel(rank)
  const dataWillStartAt = (format(parseISO(willStartAt), 'yyyy/MM/dd'))

  return {icon, title, dataWillStartAt, dataRank, dataExp, dataAc}
}

const createTableRow = (tournaments: TournamentResultConnection | undefined) => {
  const rows: any[] = []
  tournaments?.edges?.forEach(tournament => {
    if (tournament?.node) {
      const data = createData( tournament.node.game.iconUrl, tournament.node.tournament.title, tournament.node.tournament.willStartAt, tournament.node.awardType, tournament.node.earnedExp, tournament.node.coinIssuance)
      rows.push(data)
    }
  })
  return rows
}

type PastTournamentsProps = BoxProps & {
  userId: string
}

export const PastTournaments: React.FC<PastTournamentsProps> = (props) => {
  const isLg = useIsLg()

  const spStyles = usePastTournamentsSpStyle()
  const pcStyles = usePastTournamentsStyle()
  const styles = isLg ? pcStyles : spStyles

  const {userId} = props

  const {data, error, loading, fetchMore} = useTournamentResultsQuery({
    variables: {
      userId: userId,
      first: 5,
    },
    notifyOnNetworkStatusChange: true,
  })

  if (loading) {
    return <LoadingPart/>
  }

  if (error || !data) {
    return <LoadingError/>
  }

  const tournamentResults = data.tournamentResults
  const hasMore = tournamentResults.pageInfo.hasNextPage

  const onViewMore = async () => {
    await fetchMore({
      variables: {
        after: tournamentResults.pageInfo.endCursor,
        first: isLg ? 30 : 15,
      },
    })
  }

  if (!tournamentResults || !tournamentResults.edges || tournamentResults.edges.length === 0) {
    return (
      <>
        <Typography className={styles.pastTournamentHead} variant='h4'>過去の参加大会</Typography>
        <MessageEmptyState message='過去に参加した大会はありません。'/>
      </>
    )
  }

  const rows = createTableRow(tournamentResults)

  return (
    <Box className={styles.pastTournamentWrap}>
      <Typography variant='h4' className={styles.pastTournamentHead}>過去の参加大会</Typography>
      <TableContainer className={styles.tableContainer}>
        <Table className={styles.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={styles.tablehead}>ゲーム</TableCell>
              <TableCell className={styles.tablehead}>大会名</TableCell>
              <TableCell className={styles.tablehead}>開催日</TableCell>
              <TableCell className={styles.tablehead}>順位</TableCell>
              <TableCell className={styles.tablehead}>獲得XP</TableCell>
              <TableCell className={styles.tablehead}>獲得AC</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell className={styles.tablecontent}>
                  <img className={styles.gameicon} src={row.icon} alt={'game icon'}/>
                </TableCell>
                <TableCell className={styles.tablecontent}>
                  {row.title}
                </TableCell>
                <TableCell className={styles.tablecontent}>{row.dataWillStartAt}</TableCell>
                <TableCell className={styles.tablecontent}>{row.dataRank}</TableCell>
                <TableCell className={styles.tablecontent}>{row.dataExp}</TableCell>
                <TableCell className={styles.tablecontent}>{row.dataAc}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" className={styles.viewMoreWrap}>
        {hasMore && <Button className={styles.viewMore} onClick={onViewMore}>View more</Button>}
      </Box>
    </Box>
  )
}
