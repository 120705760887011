import React from 'react'
import {GridList, GridListTile, makeStyles, useTheme} from '@material-ui/core'
import {ClanFragment} from '@/graphql/client'
import {SelectableClanListItem} from '@/components/organisms/tournament-team/selectable-clan-list-item'

const useSelectableClanListStyles = makeStyles(({palette}) => ({
  gridList: {
    // height: 88 * 5, // 5クラン分
  },
  container: {
    display: 'flex',
    padding: '16px 16px 16px 0px',
    height: '80px',
    borderRadius: '4px',
    border: '1px solid rgba(255, 255, 255, 0.12)',
  },
  clanLogo: {
    width: '48px',
    height: '48px',
    objectFit: 'cover',
    borderRadius: '4px',
  },
  nameContainer: {
    marginLeft: '12px',
  },
  clanName: {
    fontSize: '15px',
  },
  level: {
    fontSize: '14px',
  },
  member: {
    color: palette.text.secondary,
    fontSize: '10px',
  },
  number: {
    fontSize: '12px',
  },
}))

type SelectableClanListProps = {
  clans: ClanFragment[]
  handleChose: (clan: ClanFragment) => void
}

export const SelectableClanList: React.FC<SelectableClanListProps> = ({clans, handleChose}) => {
  const {spacing} = useTheme()
  const styles = useSelectableClanListStyles()

  const [selectedClanId, setSelectedClanId] = React.useState<string|null>(null)

  const handleSelect = (clan: ClanFragment) => {
    setSelectedClanId(clan.id)
    handleChose(clan)
  }

  return (
    <GridList
      className={styles.gridList}
      cellHeight={80}
      cols={1}
      spacing={spacing(2)}
    >
      {clans.map((clan, idx) => {
        return (
          <GridListTile key={idx}>
            <SelectableClanListItem
              clan={clan}
              checked={clan.id === selectedClanId}
              onClick={() => handleSelect(clan)}
            />
          </GridListTile>
        )
      })}
    </GridList>
  )
}
