import {Button, Link} from '@/components/atoms'
import {Box, Card, Checkbox, Typography, createStyles, makeStyles} from '@material-ui/core'
import {useState} from 'react'
import {useUserWithdrawMutation} from '@/graphql/client'
import {useSnackbar} from '@/hooks/acknowledge'
import {useAuth0} from '@auth0/auth0-react'
import {auth0Config} from '@/env'
import {ActionModal} from '@/components/molecules'

const useStyles = makeStyles(({spacing, breakpoints, palette}) =>
  createStyles({
    withdrawal: {
      marginTop: spacing(7.5),
      marginLeft: spacing(-4),
      marginRight: spacing(-4),
      maxWidth: '800px',
      [breakpoints.up('lg')]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    card: {
      padding: spacing(4),
      paddingBottom: spacing(15),
      width: '100%',
      [breakpoints.up('lg')]: {
        paddingBottom: spacing(20),
      },
    },
    notice: {
      marginTop: spacing(4),
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      alignSelf: 'center',
    },
    link: {
      color: palette.primary.main,
    },
    deleteButton: {
      backgroundColor: palette.status.error,
    },
  })
)

export const Withdrawal: React.FC = () => {
  const styles = useStyles()
  const [confirmation, setConfirmation] = useState(false)
  const [userWithdraw] = useUserWithdrawMutation()
  const {snackbar} = useSnackbar()
  const {logout} = useAuth0()
  const [modalVisible, setModalVisible] = useState(false)

  const requestDeletion = async () => {
    try {
      await userWithdraw({variables: {input: {}}})
      snackbar.success('退会が完了しました')
      logout({returnTo: auth0Config.logoutUri})
    } catch (e) {
      snackbar.failed('退会処理でエラーが発生しました', e)
    }
  }

  return (
    <Box className={styles.withdrawal}>
      <Card className={styles.card}>
        <Typography variant='subtitle1'>退会（アカウント削除）</Typography>
        <form className={styles.notice}>
          <Typography>注意事項</Typography>
          <ul>
            <li>退会すると、アカウントの全てのデータ（プロフィール・参加履歴・レベル・ランキング・AC・XP・参加クランなど）は無効化され、復元できません。</li>
            <li>プロフィールはサイト上に表示されなくなります。</li>
            <li>退会処理は撤回することができません。再登録した場合も、以前のデータを引き継ぐことはできません。</li>
            <li>次に当てはまる場合は退会できません。</li>
            <ul>
              <li>開催前・開催中の大会の主催者である場合（<Link className={styles.link} href="/tournaments/history">大会管理</Link> から中止、または結果確定してください）</li>
              <li>開催前の大会にエントリーしている場合（<Link className={styles.link} href="/entries">エントリーした大会</Link> からエントリーを解除してください）</li>
              <li>クランマスターになっているクランがある場合（<Link className={styles.link} href="/clan">所属クラン</Link> からクランを解散するか、管理権限を譲渡してください）</li>
            </ul>
          </ul>
          <label>
            <Checkbox onChange={e => setConfirmation(e.target.checked)} value={confirmation} />
            上記の注意事項を了承しました。
          </label>
          <Button type="button" className={styles.button} color='secondary' disabled={!confirmation} onClick={() => setModalVisible(true)}>
            退会する
          </Button>
        </form>
      </Card>
      <ActionModal
        open={modalVisible}
        doAction={requestDeletion}
        handleClose={() => setModalVisible(false)}
        title='退会（アカウント削除）'
        contents='本当に実行してもよろしいですか?'
        actionButtonStyle={styles.deleteButton}
        buttonText='実行する'
      />
    </Box>
  )
}
