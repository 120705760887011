import {useCompensationsQuery} from '@/graphql/client'
import React from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  createStyles, makeStyles,
} from '@material-ui/core'
import {format, parseISO} from 'date-fns'
import MessageEmptyState from '@/components/atoms/message-empty-state'
import {LoadingPart} from '../../atoms/loading-part'
import {Button, LoadingError} from '@/components'
import {Theme} from '@material-ui/core/styles'
import {useIsLg} from '@/hooks'

const usePastTournamentsStyle = makeStyles<Theme, {isLg: boolean}>(() =>
  createStyles({
    header: {
      fontSize: '18px',
      lineHeight: '27px',
      marginBottom: '16px',
    },
    tableContainer: {
      background: '#1E1E1E',
      borderRadius: '4px',
      marginBottom: '40px',
      width: ({isLg}) => isLg ? '' : 'calc(100vw - 32px)',
    },
    tablehead: {
      color: 'rgba(255, 255, 255, 0.6)',
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      padding: '16px',
      height: '52px',
    },
    tableRow: {
      height: '52px',
    },
    tablecontent: {
      color: 'rgba(255, 255, 255, 0.6)',
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      padding: '16px',
    },
    viewMoreWrap: {
      justifyContent: 'center',
    },
  })
)

type CompensationsProps = {
  userId: string
}

export const Compensations: React.FC<CompensationsProps> = (props) => {
  const isLg = useIsLg()
  const styles = usePastTournamentsStyle({isLg})

  const {userId} = props

  const {data, error, loading, fetchMore} = useCompensationsQuery({
    variables: {
      userId: userId,
      first: 5,
    },
    notifyOnNetworkStatusChange: true,
  })

  if (loading) {
    return <LoadingPart/>
  }

  if (error) {
    return <LoadingError/>
  }

  const compensationsData = data!.compensations
  const hasMore = compensationsData.pageInfo.hasNextPage

  const onViewMore = async () => {
    await fetchMore({
      variables: {
        after: compensationsData.pageInfo.endCursor,
        first: isLg ? 30 : 15,
      },
    })
  }

  if (compensationsData.edges?.length === 0) {
    return (
      <>
        <Typography className={styles.header} variant='h4'>レイズ獲得履歴</Typography>
        <MessageEmptyState message='獲得履歴はありません。'/>
      </>
    )
  }

  const nodes = compensationsData.edges!.map(it => it!.node)

  return (
    <Box>
      <Typography className={styles.header} variant='h4'>レイズ獲得履歴</Typography>
      <TableContainer className={styles.tableContainer}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={styles.tablehead}>レイズ内容</TableCell>
              <TableCell className={styles.tablehead}>付与日</TableCell>
              <TableCell className={styles.tablehead}>獲得XP</TableCell>
              <TableCell className={styles.tablehead}>獲得AC</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {nodes.map((node, idx) => (
              <TableRow key={idx} className={styles.tableRow}>
                <TableCell className={styles.tablecontent}>{node?.memo}</TableCell>
                <TableCell
                  className={styles.tablecontent}
                >{format(parseISO(node?.createdAt), 'yyyy/MM/dd')}
                </TableCell>
                <TableCell className={styles.tablecontent}>{node?.exp === 0 ? '-' : `${node!.exp}XP`}</TableCell>
                <TableCell
                  className={styles.tablecontent}
                >{node?.coinIssuance ? `${node!.coinIssuance!.coin}AC` : '-'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" className={styles.viewMoreWrap}>
        {hasMore && <Button outlined size={'large'} onClick={onViewMore}>View more</Button>}
      </Box>
    </Box>
  )
}
