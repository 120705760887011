import {serverConfig} from '@/env'
import {
  useTournamentBracketQuery,
} from '@/graphql/client'
import React, {useState} from 'react'

import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
} from '@material-ui/core'

import {
  DebugSwitchTournamentUsersButton,
  TournamentBracketItem,
} from '@/components'

import {useRouter} from 'next/router'
import {LoadingPart} from '../../atoms/loading-part'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import {SingleEliminationBracket, SingleEliminationNode, SingleEliminationNodeFragment} from '@/types/tournament'
import {useIsLg} from '@/hooks/style'

const useTournamentBracketStyle = makeStyles(({spacing}) =>
  createStyles({
    gameDetailTitle: {
      fontSize: '0.75rem',
      letterSpacing: '0.005rem',
      marginBottom: spacing(1),
    },
    gameDetailText: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '0.9375rem',
      letterSpacing: '0.005rem',
    },
    infomationCard: {
      width: '100%',
      paddingLeft: spacing(2),
    },
    infomationIcon: {
      width: 18,
      height: 18,
      marginRight: spacing(2),
    },
    acIcon: {
      marginLeft: '-10px',
    },
    reloadBtn: {
      width: '90px',
      marginBottom: '20px',
      color: '#55C341',
      border: '1px solid rgba(85, 195, 65, 0.5)',
      padding: '5px 15px',
      verticalAlign: 'middle',
    },
    reloadIcon: {
      marginRight: '10px',
      verticalAlign: 'middle',
    },
    progress: {
      marginRight: '10px',
    },
  })
)

type TournamentBracketProps = {
  pageType: 'dashboard' | 'normal'
}

export const TournamentBracket: React.FC<TournamentBracketProps> = ({pageType='normal'}) => {
  const router = useRouter()
  const isLg = useIsLg()

  let tournamentId = ''
  // @fixme router.query.idがstring[]である場合が捌き切れないのでハードコーディング
  if (typeof router.query.id === 'string') tournamentId = router.query.id

  const styles = useTournamentBracketStyle()
  const [isUpdating, setIsUpdating] = useState(false)
  const {data, loading, refetch} = useTournamentBracketQuery({
    variables: {
      id: tournamentId,
    },
  })

  const handleRefetch = () => {
    setIsUpdating(true)
    refetch()
    setTimeout(() => setIsUpdating(false), 500)
  }

  if (loading) {
    return <LoadingPart/>
  }

  const bracket = data?.tournament.bracket as SingleEliminationBracket|null
  if (!bracket?.nodes) {
    return (<></>)
  }

  // ランキングのx回戦ごとにnodeをまとめる
  const nodes: SingleEliminationNode[] = [...bracket.nodes]
  const maxRound = bracket.maxRound

  const rounds: SingleEliminationNodeFragment[][] = []
  for (let i = 1; i <= maxRound; i++) {
    rounds.push(nodes.filter((x) => x.round == i).sort((x, y) => x.matchIndex - y.matchIndex))
  }

  const wrapperWidth = maxRound * 248 + 'px'

  return (
    <>
      {pageType === 'dashboard' &&
        <Button onClick={() => handleRefetch()} size='large' className={styles.reloadBtn} disabled={isUpdating}>
          {isUpdating ?
            <CircularProgress size='21px' className={styles.progress}/> :
            <AutorenewIcon className={styles.reloadIcon}/>
          }
          更新
        </Button>
      }
      {(pageType === 'dashboard' && tournamentId) && (
        !serverConfig.isPrd && <DebugSwitchTournamentUsersButton tournamentId={tournamentId}/>
      )}
      <div style={{maxWidth: isLg ? 'none' :'calc(100vw - 32px)'}}>
        <TournamentBracketItem tournamentId={tournamentId} rounds={rounds} wrapperWidth={wrapperWidth}/>
      </div>
    </>
  )
}
