import React from 'react'
import {Box, CardActionArea, Checkbox, Typography} from '@material-ui/core/'
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles'
import {Avatar, Card, Medal, MedalBackgroundColor} from '@/components/atoms'
import {UserFragment} from '@/graphql/client'
import {truncateFullWidth} from '@/utils'

const useSelectableUserListItemStyle = makeStyles<Theme>(({
  spacing,
}) => createStyles({
  root: {
    position: 'relative',
    overflow: 'hidden',
  },
  border: {
    border: '1px solid rgba(255, 255, 255, 0.12)',
    borderRadius: '4px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '16px 16px 16px 7px', // checkboxのpaddingが9
    height: 80,
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    marginRight: spacing(3),
  },
  xpTextContainer: {
    alignSelf: 'end',
    paddingBottom: 22,
  },
}))

type SelectableUserListItemProps = {
  user: UserFragment
  gameId: string
  selected: boolean
  handleSelect: (user: UserFragment) => void
  outlined?: boolean
}

export const SelectableUserListItem: React.FC<SelectableUserListItemProps> = ({outlined=true, ...props}) => {
  const {user, gameId, selected, handleSelect} = props

  const gameExp = user.gameExps.find(it => it.game.id === gameId)!
  const styles = useSelectableUserListItemStyle({rank: gameExp.rank})

  const getMedalBackground = (): MedalBackgroundColor => {
    switch (gameExp.rank) {
      case 1:
        return MedalBackgroundColor.SILVER
      case 2:
        return MedalBackgroundColor.GRAD_GOLD
      case 3:
        return MedalBackgroundColor.GRAD_GREEN
      case 4:
        return MedalBackgroundColor.GRAD_PURPLE
      default:
        return MedalBackgroundColor.GRAD_RED
    }
  }

  return (
    <Box className={outlined ? styles.border : ''}>
      <Card className={styles.root} onClick={() => handleSelect(user)}>
        <CardActionArea className={styles.container}>
          <Checkbox checked={selected}/>
          <Avatar src={user.profile.thumbnailUrl} className={styles.avatar}/>
          <Box>
            <Typography variant='subtitle1'>{truncateFullWidth(user.profile.displayName, 10)}</Typography>
            <Typography variant='overline'>Lv.{user.level.level}</Typography>
          </Box>
          <Box flexGrow={1}/>
          <Box className={styles.xpTextContainer}>
            <Typography variant='overline' color='textSecondary'>{gameExp.exp}XP</Typography>
          </Box>
        </CardActionArea>
        <Medal background={getMedalBackground()} text={gameExp.title}/>
      </Card>
    </Box>
  )
}
