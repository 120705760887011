import React from 'react'

import {
  List,
  ListProps,
} from '@material-ui/core/'

import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles'

import {
  composeClassNames,
} from '@/utils'

const useDrawerMenuListStyles = makeStyles(() => createStyles({
  menuList: {
    padding: 0,
  },
}))

export const DrawerMenuList: React.FC<ListProps> = ({className, ...props}) => {

  const styles = useDrawerMenuListStyles()

  return (
    <List className={composeClassNames(styles.menuList, className)} {...props} />
  )
}
