import {Button as ButtonBase} from 'components/atoms/button'
import {Typography, createStyles, makeStyles} from '@material-ui/core'
import {ReactElement, useEffect, useState} from 'react'
import {useRouter} from 'next/router'
import {discordConfig} from '@/env/discord'
import {useMyDiscordIdQuery} from '@/graphql/client'
import {Link, LoadingPart} from '../atoms'

const useStyles = makeStyles(({spacing, palette}) =>
  createStyles({
    button: {
      padding: spacing(4),
      paddingTop: spacing(2.5),
      paddingBottom: spacing(2.5),
      // padding'10px 16px',
      backgroundColor: '#5865F2',
      '&:hover': {
        backgroundColor: '#5865F2',
      },
    },
    link: {
      color: palette.primary.main,
    },
  })
)
export const Button = (): ReactElement => {
  const styles = useStyles()
  const [popup, setPopup] = useState<Window|null>()
  const router = useRouter()
  const {data, loading, refetch} = useMyDiscordIdQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() =>{
    if (popup == null) return
    const pollTimer = setInterval(() => {
      if (popup.closed) {
        refetch()
        clearInterval(pollTimer)
      }
    }, 1000)

    return () => {clearInterval(pollTimer)}
  }, [popup, refetch])

  if (router == null || window == null) return <></>
  if (loading) {
    return <LoadingPart padding={0} />
  }

  const discordOauthUrl = 'https://discord.com/api/oauth2/authorize'
  const client_id= discordConfig.clientId
  const redirect_uri = window.location.protocol + '//' + window.location.host + '/oauth/callback/discord'
  const scope = 'identify'
  const oauthUrl = discordOauthUrl +
    `?client_id=${client_id}` +
    `&redirect_uri=${encodeURIComponent(redirect_uri)}`+
    '&response_type=code'+
    `&scope=${encodeURIComponent(scope)}`

  if (data?.me.discordUsername == null) {
    return (
      <ButtonBase
        className={styles.button}
        onClick={() => { setPopup(window.open(oauthUrl)) }}
      >
        <Typography variant="subtitle2">Discord 連携</Typography>
      </ButtonBase>
    )
  }

  return (
    <>
      {data.me.discordUsername}
      <br />
    (<Link className={styles.link} href={'#'} onClick={() => { setPopup(window.open(oauthUrl)) }}>別のアカウントを連携する</Link>)
    </>
  )
}
