import {BracketType, TournamentDetailQuery} from '@/graphql/client'
import {Box, Card, CardContent, Grid, Typography, createStyles, makeStyles} from '@material-ui/core'
import React from 'react'
import {Linkify} from '@/components/atoms/linkify'
import {getUnitTypeLabel} from '@/utils'
import {Link} from '@/components/atoms'
import {format, parseISO} from 'date-fns'
import ja from 'date-fns/locale/ja'

const useTournamentDetailStyle = makeStyles(({palette, spacing}) =>
  createStyles({
    tournamentDetailWrap: {
      whiteSpace:'pre-wrap',
    },
    gameDetailTitle: {
      fontSize: '0.75rem',
      letterSpacing: '0.005rem',
      marginBottom: spacing(1),
    },
    gameDetailText: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '0.9375rem',
      letterSpacing: '0.005rem',
      wordBreak: 'break-word',
    },
    infomationCard: {
      width: '100%',
      paddingLeft: spacing(2),
    },
    infomationIcon: {
      width: 18,
      height: 18,
      marginRight: spacing(2),
    },
    acIcon: {
      marginLeft: '-10px',
    },
    link: {
      color: palette.primary.main,
    },
    precautionsContentTextUnderLine: {
      textDecoration: 'underline',
      margin: 0,
      cursor: 'pointer',
    },
  })
)

type TournamentDetailProp = TournamentDetailQuery

const getBracketTypeLabel = (type: BracketType): string => {
  switch (type) {
    case BracketType.SingleElimination:
      return 'シングルエリミネーション'
    case BracketType.Custom:
      return 'カスタム'
    default:
      return ''
  }
}

export const TournamentDetail = (props: TournamentDetailProp) => {
  const {tournament} = props
  const styles = useTournamentDetailStyle()

  return (
    <Grid container spacing={8} className={styles.tournamentDetailWrap}>
      <Grid item xs={12}>
        <Typography
          className={styles.gameDetailTitle}
          color="textSecondary"
        >
          ゲーム
        </Typography>
        <Typography variant="h4">{tournament.game.title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          className={styles.gameDetailTitle}
          color="textSecondary"
        >
          トーナメント方式
        </Typography>
        <Typography variant="h4">{getBracketTypeLabel(tournament.bracketType)}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          className={styles.gameDetailTitle}
          color="textSecondary"
        >
          対戦形式
        </Typography>
        <Typography variant="h4">{getUnitTypeLabel(tournament.unitType, tournament.bracketType)}</Typography>
      </Grid>
      {tournament.bracketType === BracketType.SingleElimination && (
        <Grid item xs={12}>
          <Typography
            className={styles.gameDetailTitle}
            color="textSecondary"
          >
              勝利条件
          </Typography>
          <Typography variant="h4">
            {tournament.scoreToWin + '勝先取'}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography
          className={styles.gameDetailTitle}
          color="textSecondary"
        >
          抽選方式
        </Typography>
        <Typography variant="h4">
          エントリー先着
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          className={styles.gameDetailTitle}
          color="textSecondary"
        >
          ルール
        </Typography>
        <Typography className={styles.gameDetailText} color="textPrimary">
          <Linkify>{tournament.rules}</Linkify>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          className={styles.gameDetailTitle}
          color="textSecondary"
        >
          賞品・賞金
        </Typography>
        <Typography className={styles.gameDetailText} color="textPrimary">
          <Linkify>{tournament.rewardNote}</Linkify>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              className={styles.gameDetailTitle}
              color="textSecondary"
            >
              連絡先
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <img
                className={styles.infomationIcon}
                src="/images/icon/mail.svg"
                alt="mail"
              />
              <a href={`mailto:${tournament.contactEmail}`} style={{textDecoration: 'none'}}>
                <Typography color="textPrimary" className={styles.link}>{tournament.contactEmail}</Typography>
              </a>
            </Box>
          </Grid>
          {tournament.contactTwitter !== null && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <img
                  className={styles.infomationIcon}
                  src="/images/icon/twitter.svg"
                  alt="twitter"
                />
                <a href={`https://twitter.com/${tournament.contactTwitter}`} style={{textDecoration: 'none'}} target="_blank" rel="noopener noreferrer">
                  <Typography
                    className={styles.link}
                    color="textPrimary"
                  >
                    {`https://twitter.com/${tournament.contactTwitter}`}
                  </Typography>
                </a>
              </Box>
            </Grid>
          )}
          {tournament.contactDiscord &&
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <img
                  className={styles.infomationIcon}
                  src="/images/icon/discord.svg"
                  alt="discord"
                />
                <a href={tournament.contactDiscord} style={{textDecoration: 'none'}} target="_blank" rel="noopener noreferrer">
                  <Typography className={styles.link} color="textPrimary">{tournament.contactDiscord}</Typography>
                </a>
              </Box>
            </Grid>
          }
        </Grid>
      </Grid>
      {
        !!tournament.openTournamentType && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {tournament.openEntryStartsAt && tournament.openEntryEndsAt &&
                <Grid item xs={12}>
                  <Typography
                    className={styles.gameDetailTitle}
                    color="textSecondary"
                  >
                    エントリー期間
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {format(parseISO(tournament.openEntryStartsAt), 'yyyy/MM/dd(eeeee) HH:mm~', {locale: ja})}
                    {format(parseISO(tournament.openEntryEndsAt), 'yyyy/MM/dd(eeeee) HH:mm', {locale: ja})}
                  </Typography>
                </Grid>
              }
              {tournament.openCheckinStartsAt && tournament.openCheckinEndsAt &&
                <Grid item xs={12}>
                  <Typography
                    className={styles.gameDetailTitle}
                    color="textSecondary"
                  >
                    チェックイン期間
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {format(parseISO(tournament.openCheckinStartsAt), 'yyyy/MM/dd(eeeee) HH:mm~', {locale: ja})}
                    {format(parseISO(tournament.openCheckinEndsAt), 'yyyy/MM/dd(eeeee) HH:mm', {locale: ja})}
                  </Typography>
                </Grid>
              }
            </Grid>
          </Grid>
        )
      }
      <Grid item xs={12}>
        <Typography
          className={styles.gameDetailTitle}
          color="textSecondary"
        >
          Adictor運営より
        </Typography>
        <Card className={styles.infomationCard}>
          <CardContent>
            <Box display="flex" alignItems="center">
              <img
                className={styles.acIcon}
                src="/images/icon/ac.png"
                alt="adictor coin"
              />
              <Typography variant="h4" color="textPrimary">
                500 Adictor Coin
              </Typography>
            </Box>
            <Typography className={styles.gameDetailText} color="textPrimary">
              優勝者には500 Adictor Coinを発行します。
              <br />
              ※
              <Link href={'/help/ac'} passHref target="_blank">
                <span className={styles.precautionsContentTextUnderLine}>
                  一部の条件に該当する場合
                </span>
              </Link>
              、付与されないケースがあります。
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
