// 編集の際はソートしてください
const csv = `
clan/already_archived,このクランは解散しました
clan/archived,このクランは解散しました
clan/invalid_cover_url,画像のアップロードに失敗しました
clan/invalid_description,プロフィールが未入力か1000文字を超えています
clan/invalid_logo_url,画像のアップロードに失敗しました
clan/invalid_max_member,現在のメンバー数が最大可能人数を超えています
clan/invalid_name,クラン名が未入力か100文字を超えています
clan/invalid_owner,クランオーナーが無効です
clan/not_a_member,クランメンバーではありません
clan/not_found,クランが見つかりません
clan/owner_cannot_exit,あなたはクランマスターのため脱退できません
clan/owner_cannot_have_role,このユーザーはクランマスターです
clan/reach_max_join,クランの最大所属数を超えています
clan_invite_link/already_clan_member,既にこのクランに参加しています
clan_invite_link/disabled,クラン招待リンクが無効です
clan_invite_link/exceeded,招待リンクの使用回数を超えています
clan_invite_link/expired,招待リンクが有効期限切れです
clan_invite_link/invalid_clan,クラン招待リンクが無効です
clan_invite_link/invalid_expires_on,有効期限は過去に設定できません
clan_invite_link/invalid_max_num,招待数上限が未対応の値です
clan_invite_link/not_found,クラン招待リンクが無効です
clan_invite_link/reach_max_num,クラン人数が上限に達しています
coin_exchange_request/already_approved,申請リクエストは既に承認済みです
coin_exchange_request/already_rejected,申請リクエストは既に却下済みです
coin_exchange_request/coin_less_then_owned,所有ACより少ない必要があります
coin_exchange_request/coin_must_positive,申請するACは0以上である必要があります
coin_exchange_request/invalid_coin_amount,不正なACです
coin_exchange_request/logly_alraedy_submitted,ログリー交換所への申請は月に最大1回までです
coin_exchange_request/type_currently_unavailable,この交換種別は現在利用できません
common/invalid_arguments,不正な引数です
common/invalid_operator,不正なユーザーによる操作です
common/unauthorized,ログインが必要です
game/disabled,ゲームが無効化されています
game/invalid_genre,ジャンルが指定されていません
game/invalid_icon_url,画像のアップロードに失敗しました
game/invalid_title,不正なタイトルです
game/not_found,指定したゲームがありません
game/tournament_exists,開催予定の大会があるため削除できません
game_default_cover/invalid_cover_url,画像のアップロードに失敗しました
game_default_cover/not_found,デフォルトカバー画像がありません
genre/game_exists,ゲームが有効なジャンルは削除できません
genre/invalid_exp_chain,EXP設定に誤りがあります
genre/invalid_rank_order,ランクの順番に誤りがあります
genre/invalid_ranks_length,ランクの個数に誤りがあります
genre/max_exp_out_of_range,ランクの上限EXPを超えています
genre/min_exp_out_of_range,ランクの下限EXPを下回っています
genre/name_required,ジャンルが指定されていません
genre/not_found,ジャンルがありません
genre/rank_out_of_range,ランクの序列が範囲外です
genre/title_required,ランクの肩書が指定されていません
gift/cant_give_to_self,自分自身にギフトを贈ることはできません
gift/insufficient_balance,残高が不足しています
profile/invalid_body,不正な本文です
profile/invalid_display_name,不正な表示名です
profile/invalid_thumbnail_url,不正なサムネイルURLです
profile/invalid_twitter_id,不正なTwitter IDです
sponsor/invalid_icon_url,画像のアップロードに失敗しました
sponsor/not_found,指定したスポンサーがありません
sponsor_campaign/invalid_cover_url,画像のアップロードに失敗しました
sponsor_campaign/not_found,指定したキャンペーンがありません
tournament/already_cancelled,この大会は既に中止されています
tournament/already_entered,大会にエントリー済みです
tournament/already_finished,この大会は既に終了済です
tournament/before_start_time,大会開始予定時刻前です
tournament/entries_not_enough,大会のエントリー数が不十分です
tournament/invalid_contact_email,不正な連絡先です
tournament/invalid_cover_url,不正なカバーURLです
tournament/invalid_entry_condition,大会設定が未完了です
tournament/invalid_max_entry,不正なエントリー最大数です
tournament/invalid_open_tournament_checkin_end_at,オープン大会のチェックイン終了時間が無効です
tournament/invalid_open_tournament_checkin_start_at,オープン大会のチェックイン開始時間が無効です
tournament/invalid_open_tournament_entry_end_at,オープン大会のエントリー終了時間が無効です
tournament/invalid_open_tournament_entry_start_at,オープン大会のエントリー開始時間が無効です
tournament/invalid_open_tournament_type,オープントーナメント種別が誤っています
tournament/invalid_organizer,不正な主催者です
tournament/invalid_reward_note,不正な賞品・賞金です
tournament/invalid_rules,不正なルールです
tournament/invalid_score_to_win,不正な勝利数です
tournament/invalid_teammates,チームメイトに誤りがあります
tournament/invalid_title,不正な大会名です
tournament/invalid_unit_type,不正な対戦形式です
tournament/invalid_unit_type_in_open_tournament,オープン大会ではシングル戦で設定することができません
tournament/invalid_will_start_at,不正な予定開始時刻です
tournament/not_entered,大会にエントリーしていません
tournament/not_found,大会が見つかりません
tournament/not_in_progress,大会が進行中ステータスではありません
tournament/not_open_checkin_period,現在はチェックイン受付期間ではありません
tournament/not_open_entry_period,現在はエントリー受付期間ではありません
tournament/not_public,大会が公開ステータスではありません
tournament/organizer_cannot_entry,主催者は参加できません
tournament/reach_entry_limit,大会のエントリー最大数に到達しました
tournament/too_many_teammates,チームメイトの人数を超過しています
tournament/tournament_type_mismatch,大会種別が一致しません
tournament/unauthorized_set_open_tournament_type,オープン大会の開催権限がありません
tournament_bracket/approve_request_already_submitted,承認リクエストはリクエスト済みです
tournament_bracket/approve_request_not_supported,承認リクエストをサポートしていません
tournament_bracket/cannot_rollback,ロールバックできない対戦です
tournament_bracket/chat_not_supported,チャットをサポートしていません
tournament_bracket/completed,大会は終了しています
tournament_bracket/invalid_chat_body,不正なチャット本文です
tournament_bracket/invalid_eliminated_ids,不正な失格者です
tournament_bracket/invalid_loser,不正な敗者です
tournament_bracket/invalid_node_status,不正な対戦ステータスです
tournament_bracket/invalid_score,不正なスコアです
tournament_bracket/invalid_winner,不正な勝者です
tournament_bracket/node_not_found,対戦が見つかりません
tournament_bracket/non_unit_member_included,対戦メンバー以外が含まれています
tournament_bracket/rollback_not_supported,対戦ロールバックをサポートしていません
tournament_bracket/update_failed,更新に失敗しました
tournament_clan_result/invalid_award_type,不正な順位です
tournament_result/invalid_award_type,不正な順位です
tournament_setting/invalid_max_rank,不正なランクです
tournament_setting/invalid_role,不正なロールです
tournament_setting/invalid_tournament_type,オープン大会種別が誤っています
tournament_setting/invalid_vc,不正なVCです
tournament_setting/required_tournament_type,トーナメントの種類を入力してください
tournament_team/already_entered_clan,既にエントリー済みのクランです
tournament_team/already_entered_user,他クランからプレイヤーまたはマネージャーとしてエントリー済みのユーザーがいます
tournament_team/invalid/tournament,不正な大会です
tournament_team/invalid_clan,不正なクランです
tournament_team/invalid_manager_ids,不正なマネージャーです
tournament_team/invalid_user_id_size,不正なプレイヤーの数です
tournament_team/invalid_user_ids,不正なプレイヤーです
tournament_team/must_include_yourself,自身がチームに含まれている必要があります
tournament_team/non_clan_manager_included,クランマネージャー以外が含まれています
tournament_team/non_clan_member_included,クランメンバー以外が含まれています
tournament_team/not_found,チームが見つかりません
tournament_team/organizer_included,主催者が含まれています
tournament_team/user_duplicated,プレイヤーとマネージャーの両方に含まれているユーザーがいます
user/entries_tournament,エントリー中の大会があります
user/insufficient_ticket,開催チケットが不足しています
user/invalid_discord_code,連携コードが無効です
user/invalid_riot_code,連携コードが無効です
user/invalid_riot_user,ユーザー情報が取得できませんでした
user/not_found,ユーザーが見つかりません
user/organizes_tournament,主催中の大会があります
user/owns_clan,クランマスターになっているクランがあります
`
// 編集の際はソートしてください

export const parsedCSV = csv.trim().split(/\n/).map((row) => row.split(','))

export const table = parsedCSV.reduce((result: { [key:string]: string }, row: string[]) => {
  result[row[0]] = row[1]
  return result
}, {})

export const t = (key: string) => table[key]
