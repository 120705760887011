import React from 'react'

import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles'

import {
  composeClassNames,
} from '@/utils'
import UserIcon from '@material-ui/icons/Person'

const useSquareAvatarStyles = makeStyles(() => createStyles({
  avatar: {
    borderRadius: '4px',
  },
}))

type SquareAvatarProps = {
  src?: string
  alt?: string
  className: string
}

export const SquareAvatar: React.FC<SquareAvatarProps> = ({src, alt='', className, ...props}) => {

  const styles = useSquareAvatarStyles()

  return src ? (
    <img src={src} alt={alt} className={composeClassNames(styles.avatar, className)} {...props} />
  ) : (
    <UserIcon className={composeClassNames(styles.avatar, className)} />
  )
}
