import React from 'react'
import {Box, Button, ButtonProps, Typography, createStyles, makeStyles} from '@material-ui/core'
import {
  TournamentEntryStatusReason,
  TournamentEntryStatusResponse,
  TournamentEntryStatusStatus,
} from '@/graphql/client'
import {Countdown} from '@/components/atoms/countdown'

type EntryButtonProps = {
  entryStatus: TournamentEntryStatusResponse | undefined
  isLoading: boolean
  handleEntry: () => void
  handleEntryExit: () => void
  handleCheckin: () => void
  isAuthenticated: boolean
  userId?: string
  tournamentId?: string
  checkinDue?: Date
  isAfterCheckinEndsAt: boolean
} & ButtonProps

const useLayout = makeStyles(({breakpoints}) =>
  createStyles({
    buttonBottomText: {
      fontSize: '0.75rem',
      letterSpacing: '0.005rem',
      fontWeight: 400,
      position: 'absolute', // 大会タイトル h1 とボタンの水平線がずれないように absolute 配置
      bottom: '-1rem',
    },
    buttonTopText: {
      [breakpoints.up('lg')]: {
        position: 'absolute', // 大会タイトル h1 とボタンの水平線がずれないように absolute 配置
        top: '-1.5em', // subtitle2
      },
    },
  })
)

const helpText = (isAuthenticated: boolean, entryStatus?: TournamentEntryStatusResponse) => {
  if (!isAuthenticated) { return 'ログインしていません' }

  switch (entryStatus?.reason) {
    case TournamentEntryStatusReason.NotBelongToClan:
      return 'クランに加入していません'
    case TournamentEntryStatusReason.AllYourClansEntered:
      return '所属クランがエントリー済みです'
    case TournamentEntryStatusReason.NotEntryPeriod:
      return 'エントリー期間外です'
    default:
      return null
  }
}

export const EntryButton: React.FC<EntryButtonProps> = (props) => {
  const styles = useLayout()

  const isEntry = props.entryStatus?.status === TournamentEntryStatusStatus.Entered
  const isMaxEntry = props.entryStatus?.reason === TournamentEntryStatusReason.ReachEntryMax
  const disabled = props.isLoading || !props.entryStatus || props.entryStatus.status === TournamentEntryStatusStatus.CannotEnter

  /** NOTE: true, false, null の 3 状態 */
  const checkedIn = props.entryStatus?.checkedIn
  const isAfterCheckinEndsAt = props.isAfterCheckinEndsAt
  if (checkedIn) {
    return (
      <Button
        className={props.className}
        classes={props.classes}
        variant="contained"
        color="primary"
        size="large"
        disabled
      >
      チェックイン完了
      </Button>
    )
  } else if (checkedIn === false) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" width="100%" position="relative">
        {props.checkinDue && (
          <Typography variant="subtitle2" color="textSecondary" className={styles.buttonTopText}>
          締め切りまで
            <Countdown time={props.checkinDue} variant="subtitle2" component="span" />
          </Typography>
        )}
        <Button
          className={props.className}
          classes={props.classes}
          variant="contained"
          color="primary"
          size="large"
          onClick={!isAfterCheckinEndsAt ? props.handleCheckin : undefined}
          disabled={isAfterCheckinEndsAt}
        >
          {isAfterCheckinEndsAt? '受付終了':'チェックイン'}
        </Button>
      </Box>
    )
  }

  if (isEntry) {
    return (
      <Button
        className={props.className}
        classes={props.classes}
        variant="contained"
        color={'secondary'}
        size="large"
        onClick={props.handleEntryExit}
        disabled={disabled}
      >
        エントリー解除
      </Button>
    )
  }

  if (isMaxEntry) {
    return (
      <Button
        className={props.className}
        classes={props.classes}
        variant="contained"
        color="primary"
        size="large"
        disabled
      >
        エントリー上限
      </Button>
    )
  }

  const text = helpText(props.isAuthenticated, props.entryStatus)

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%" position="relative">
      <Button
        className={props.className}
        classes={props.classes}
        variant="contained"
        color='primary'
        size="large"
        onClick={props.handleEntry}
        disabled={disabled}
        data-userId={props.userId}
        data-tournamentId={props.tournamentId}
      >
        エントリー
      </Button>
      {text &&
        <Typography
          variant="caption"
          color="textSecondary"
          className={styles.buttonBottomText}
        >
          {text}
        </Typography>
      }
    </Box>
  )
}
