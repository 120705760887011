import {Typography, TypographyProps} from '@material-ui/core'
import {ElementType, useEffect, useState} from 'react'

type CountdownProps<C extends ElementType> = TypographyProps<C, { component?: C }> & {
  time: Date
}
export const Countdown = <E extends ElementType = 'span'>(
  {time, ...rest}: CountdownProps<E>
) => {
  const [countdown, setCountdown] = useState(time.getTime() - Date.now())

  useEffect(() => {
    const interval = setInterval(() => setCountdown(time.getTime() - Date.now()), 1000)
    return () => clearInterval(interval)
  }, [time])

  if (countdown <= 0) {
    return <Typography {...rest}>00:00:00</Typography>
  }

  const hms = [
    Math.floor(countdown / (1000 * 60 * 60)),
    Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60)),
    Math.floor((countdown % (1000 * 60)) / 1000),
  ].map(n => n.toString().padStart(2, '0')).join(':')

  return <Typography {...rest}>{hms}</Typography>
}
