import {useTournamentEntryStatusLazyQuery, useTournamentOpenCheckinMutation} from '@/graphql/client'
import {useSnackbar} from '@/hooks/acknowledge/snackbar'
import {Box, Checkbox, Fade, IconButton, Modal, Typography, makeStyles} from '@material-ui/core'
import {tournamentDetailHeaderType} from '../tournament-detail'
import CloseIcon from '@material-ui/icons/Close'
import {Button, Link} from '@/components/atoms'
import {useState} from 'react'
import {Countdown} from '@/components/atoms/countdown'

type OpenCheckinModalProps = {
  open: boolean
  onClose: () => void
  tournament?: tournamentDetailHeaderType
}

const useStyles = makeStyles(({spacing}) => ({
  modal: {
    justifyContent:'center',
    alignItems: 'center',
    display: 'flex',
    margin: spacing(4),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 16px',
    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
  },
  largeTimer: {
    fontSize: '48px',
    fontFamily: 'Noto Sans JP',
    fontWeight: 'bold',
    lineHeight: '1.5',
  },
  checkbox: {
    paddingLeft: 0,
  },
}))

export const OpenCheckinModal = ({open, onClose, tournament}: OpenCheckinModalProps) => {
  const [tournamentOpenCheckin] = useTournamentOpenCheckinMutation()
  const {snackbar} = useSnackbar()
  const styles = useStyles()
  const [getEntryStatus] = useTournamentEntryStatusLazyQuery({
    fetchPolicy: 'network-only',
    variables: {tournamentId: tournament?.id as string},
  })
  const [checks, setChecks] = useState<[boolean, boolean, boolean, boolean]>([false, false, false, false])
  const canSubmit = checks.every((check) => check)

  if (!tournament) return null

  const handleClose = () => {
    setChecks([false, false, false, false])
    onClose()
  }
  const handleSubmit = async () => {
    if (!tournament?.openTournamentType) return // オープン大会専用

    try {
      await tournamentOpenCheckin({variables: {input: {tournamentId: tournament.id}}})
      snackbar.success(`${tournament.title}へチェックインしました`)
      getEntryStatus()
      handleClose()
    } catch (e) {
      snackbar.failed(`${tournament.title}へのチェックインに失敗しました`, e)
    }
  }

  return (
    <Modal className={styles.modal} open={open} onClose={handleClose}>
      <Fade in={open}>
        <Box
          width="100%"
          maxWidth={444}
          display="flex"
          flexDirection="column"
          borderRadius={4}
          bgcolor="#232323"
        >
          <Box className={styles.header}>
            <Typography variant="h4">チェックイン</Typography>
            <IconButton size="small" onClick={handleClose}><CloseIcon /></IconButton>
          </Box>

          <Box display="flex" alignItems="center" flexDirection="column" padding={10}>
            <Typography align="center" variant="body2">
              チェックイン締め切りまで
            </Typography>
            <Countdown
              time={new Date(tournament.openCheckinEndsAt)}
              align="center"
              className={styles.largeTimer}
            />

            <Box marginY={10} display="flex" flexDirection="column" maxWidth="fit-content">
              {[
                'RiotIDは最新です',
                '日本語で会話可能です',
                '必ず大会に参加します',
                tournament.contactDiscord ? (
                  <>
                    <Link href={tournament.contactDiscord} target='_blank' rel='noreferrer'>
                      <Typography component='span' color="primary">大会のDiscordサーバー</Typography>
                    </Link>
                    に参加済です
                  </>
                ) : '大会のDiscordサーバーに参加済です',
              ].map((text, index) => (
                <label key={index}>
                  <Checkbox
                    color="primary"
                    checked={checks[index]}
                    onChange={e => setChecks(checks.map((c, i) => i === index ? e.target.checked : c) as any)}
                    className={styles.checkbox}
                  />
                  <Typography variant="body1" component="span">{text}</Typography>
                </label>
              ))}
            </Box>

            <Box display="flex" justifyContent="center">
              <Button onClick={handleSubmit} size="large" disabled={!canSubmit}>
                <Typography variant="subtitle2">チェックイン</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}
