import React, {useState} from 'react'
import {CoinExchangeStatus, CoinExchangeType, MyCoinExchangeRequestsQuery} from '@/graphql/client'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import {format, parseISO} from 'date-fns'
import ja from 'date-fns/locale/ja'
import {Button, Center} from '../atoms'
import MutationLoading from '../templates/layouts/mutation-loading'
import {LoadingPart} from '../atoms/loading-part'
import {HistoryRowType} from '../organisms/mypage'
import MessageEmptyState from '../atoms/message-empty-state'

const useCoinExchangeHistoryStyle = makeStyles(({palette, breakpoints}) =>
  createStyles({
    container: {
      margin: '16px -16px 40px -16px',
      width: 'calc(var(--vw, 1vw) * 100)',
      [breakpoints.up('lg')]: {
        margin: '16px 0 40px',
        width: '800px',
      },
    },
    table: {
      overflowX: 'scroll',
      whiteSpace: 'nowrap',
      [breakpoints.up('lg')]: {
        width: '800px',
      },
    },
    tablehead: {
      minWidth: '200px',
      color: palette.text.secondary,
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    },
    tablecontent: {
      color: palette.text.secondary,
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    },
    body: {
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    viewMore: {
      fontSize: '14px',
    },
  })
)

const createTableRow = (coinExhangeRequests: MyCoinExchangeRequestsQuery | undefined) => {
  const rows: HistoryRowType[] = []
  coinExhangeRequests?.myCoinExchangeRequests.edges?.forEach((data) => {
    if (data?.node) {
      rows.push({
        coin: data.node.coin,
        id: data.node.id,
        createdAt: data.node.createdAt,
        exchangeType: data.node.exchangeType,
        status: data.node.status,
      })
    }
  })
  return rows
}


type CoinExchangeHistoryProps = {
  coinExhangeRequests: MyCoinExchangeRequestsQuery | undefined
  isLoading: boolean
  hasMore: boolean | undefined
  fetchMore: any
}

export const CoinExchangeHistory: React.FC<CoinExchangeHistoryProps> = (props) => {
  const {isLoading, coinExhangeRequests, fetchMore, hasMore} = props
  const styles = useCoinExchangeHistoryStyle()
  const [viewMore, setViewMore] = useState<boolean>(false)
  const rows = createTableRow(coinExhangeRequests)

  const onViewMore = async () => {
    setViewMore(true)
    await fetchMore({
      variables: {
        after: coinExhangeRequests?.myCoinExchangeRequests.pageInfo.endCursor,
        first: 6,
      },
    })
  }

  if (isLoading && !viewMore) {
    return <LoadingPart/>
  }

  if (!coinExhangeRequests?.myCoinExchangeRequests.edges || coinExhangeRequests?.myCoinExchangeRequests.edges?.length < 1) {
    return (
      <Box maxWidth={800} paddingTop={7.5}>
        <MessageEmptyState message='AC交換履歴はありません'/>
      </Box>
    )
  }

  const separateNum = (coin: number) => {
    return String(coin).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
  }

  const getExchangeType = (type: CoinExchangeType) => {
    switch (type) {
      case CoinExchangeType.Amazon:
        return 'Amazonギフト券'
      case CoinExchangeType.LoglyAmazon:
        return 'Amazonギフト券 (ログリー)'
      case CoinExchangeType.Itunes:
        return 'App Store & iTunesコード'
      case CoinExchangeType.AppleGift:
        return 'Apple Gift Card'
      case CoinExchangeType.GooglePlay:
        return 'Google Play ギフトコード'
      default:
        return ''
    }
  }

  const getExchangeStatus = (status: CoinExchangeStatus) => {
    switch (status) {
      case CoinExchangeStatus.Pending:
        return 'リクエスト中'
      case CoinExchangeStatus.Approved:
        return '交換済'
      case CoinExchangeStatus.Rejected:
        return '却下'
      default:
        return ''
    }
  }

  return (
    <>
      <TableContainer component={Paper} className={styles.container}>
        <Table className={styles.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={styles.tablehead}>申請日</TableCell>
              <TableCell className={styles.tablehead}>交換AC</TableCell>
              <TableCell className={styles.tablehead}>交換先</TableCell>
              <TableCell className={styles.tablehead}>ステータス</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((history) => (
              <TableRow key={history.id}>
                <TableCell className={styles.tablecontent}>
                  {format(parseISO(history.createdAt), 'yyyy/MM/dd(eeeee) HH:mm', {locale: ja})}
                </TableCell>
                <TableCell className={styles.tablecontent}>
                  {separateNum(history.coin)}AC
                </TableCell>
                <TableCell className={styles.tablecontent}>
                  {getExchangeType(history.exchangeType)}
                </TableCell>
                <TableCell className={styles.tablecontent}>
                  {getExchangeStatus(history.status)}
                </TableCell>
              </TableRow>)
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Center maxWidth={800}>
        {hasMore &&
          <Button outlined onClick={onViewMore} disabled={isLoading} className={styles.viewMore}>View more</Button>
        }
        {isLoading && <MutationLoading />}
      </Center>
    </>
  )
}

export default CoinExchangeHistory
