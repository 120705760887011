import React from 'react'

import {
  SingleRichTextContentAPIResponse,
} from '@/types'

import {
  SingleColumnLayout,
} from '../layouts'

import {
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core'

const useSingleRichTextContentTemplateStyles = makeStyles(({spacing}) => ({
  content: {
    paddingTop: spacing(15),
    marginBottom: spacing(15),
  },
  revisions: {
    marginBottom: spacing(40),
  },
  revisionsTitle: {
    fontWeight: 'bold',
  },
}))

type SingleRichTextContentTemplateProps = SingleRichTextContentAPIResponse

export const SingleRichTextContentTemplate: React.FC<SingleRichTextContentTemplateProps> = ({body, revisions}) => {

  const styles = useSingleRichTextContentTemplateStyles()

  return (
    <SingleColumnLayout>
      <Box className={styles.content} dangerouslySetInnerHTML={{__html: body}} />
      <Box className={styles.revisions}>
        <Typography className={styles.revisionsTitle}>改訂履歴</Typography>
        {revisions.slice().reverse().map(({label, revisionedAt}, i) => (
          <Typography key={String(i)}>{revisionedAt} {label}</Typography>
        ))}
      </Box>
    </SingleColumnLayout>
  )

}
