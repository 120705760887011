import React from 'react'

import {
  makeStyles,
} from '@material-ui/core'

import {
  AppBar,
  Logo,
  MaxWidthPaddingContainer,
} from '../atoms'

import {
  Link,
} from '../atoms'

import {
  composeClassNames,
} from '@/utils'

const usePlainAppBarStyles = makeStyles(() => ({
  root: {
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
}))

type PlainAppBarProps = React.ComponentProps<typeof AppBar>

export const PlainAppBar: React.FC<PlainAppBarProps> = ({className, ...props}) => {

  const styles = usePlainAppBarStyles()

  return (
    <AppBar className={composeClassNames(styles.root, className)} {...props}>
      <MaxWidthPaddingContainer padding={16}>
        <Link href='/'><Logo /></Link>
      </MaxWidthPaddingContainer>
    </AppBar>
  )

}
