import React from 'react'

import {Tab, Tabs, makeStyles} from '@material-ui/core'
import {useIsLg} from '@/hooks'

const useTabMenuStyles = makeStyles(() => ({
  tabs: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
  },
  activeTab: {
    height: 48,
    fontSize: '14px',
  },
  inActiveTab:{
    width: 172,
    height: 48,
    fontSize: '14px',
  },
}))

export type SinglePageTabMenuType = {
  value: number
  label: string
}

type SinglePageTabMenuProps = {
  tabs: SinglePageTabMenuType[]
  tabWidth?: number
  value: number
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleChange: (event: React.ChangeEvent<{}>, newValue: number) => void
}

export const SinglePageTabMenu: React.FC<SinglePageTabMenuProps> = (props: SinglePageTabMenuProps) => {
  const {tabs, tabWidth = 172, value, handleChange} = props
  const styles = useTabMenuStyles()
  const isLg = useIsLg()

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  return (
    <Tabs
      value={value}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleChange}
      aria-label="single page tab"
      classes={{root: styles.tabs}}
      variant="scrollable"
      scrollButtons="off"
    >
      {tabs.map((tab, index) => {
        return (
          <Tab
            style={{width: isLg ? tabWidth : undefined}}
            classes={{root: styles.activeTab}}
            value={tab.value}
            label={tab.label}
            key={index}
            {...a11yProps(index)}
          />
        )
      })}
    </Tabs>
  )
}
