import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  createStyles,
  makeStyles,
} from '@material-ui/core'

const useBattleRecordStyle = makeStyles(({breakpoints, palette}) =>
  createStyles({
    container: {
      margin: '0 -16px 48px',
      width: 'calc(var(--vw, 1vw) * 100)',
      [breakpoints.up('lg')]: {
        margin: 'initial',
        marginBottom: '48px',
        width: '800px',
      },
    },
    table: {
      overflowX: 'scroll',
      whiteSpace: 'nowrap',
      [breakpoints.up('lg')]: {
        width: '800px',
      },
    },
    tablehead: {
      color: palette.text.secondary,
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    },
    border: {
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    tablecontent: {
      paddingTop: '8px',
      paddingBottom: '8px',
      verticalAlign: 'middle',
      color: palette.text.secondary,
      borderBottom: 'none',
    },
    gameImage: {
      display: 'block',
      width: '36px',
      height: '36px',
      objectFit: 'cover',
      borderRadius: '50%',
    },
    viewMore: {
      fontSize: '14px',
    },
  })
)

export const BattleRecord: React.FC = () => {
  const styles = useBattleRecordStyle()
  return (
    <TableContainer component={Paper} className={styles.container}>
      <Table className={styles.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={styles.tablehead}>ゲーム</TableCell>
            <TableCell className={styles.tablehead}>大会名</TableCell>
            <TableCell className={styles.tablehead}>開催日</TableCell>
            <TableCell className={styles.tablehead}>順位</TableCell>
            <TableCell className={styles.tablehead}>獲得XP</TableCell>
            <TableCell className={styles.tablehead}>獲得AC</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={1} className={styles.border}>
            <TableCell className={styles.tablecontent}>
              <img
                className={styles.gameImage}
                src="https://asset.adictor.jp/default/tournament_cover.png"
                alt="Game Image"
              />
            </TableCell>
            <TableCell className={styles.tablecontent}>Call of Duty 王者決定戦</TableCell>
            <TableCell className={styles.tablecontent}>2020/06/01</TableCell>
            <TableCell className={styles.tablecontent}>2位</TableCell>
            <TableCell className={styles.tablecontent}>-200XP</TableCell>
            <TableCell className={styles.tablecontent}>-</TableCell>
          </TableRow>
          <TableRow key={2} className={styles.border}>
            <TableCell className={styles.tablecontent}>
              <img
                className={styles.gameImage}
                src="https://asset.adictor.jp/default/tournament_cover.png"
                alt="Game Image"
              />
            </TableCell>
            <TableCell className={styles.tablecontent}>CoD BTTLESHIP TOURNAMNENT</TableCell>
            <TableCell className={styles.tablecontent}>2020/05/23</TableCell>
            <TableCell className={styles.tablecontent}>参加</TableCell>
            <TableCell className={styles.tablecontent}>100XP</TableCell>
            <TableCell className={styles.tablecontent}>-</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
