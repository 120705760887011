import React from 'react'

import {
  Backdrop,
  Button,
  Fade,
  Grid,
  IconButton,
  Modal,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import {
  composeClassNames,
} from '@/utils'

const useModalStyles = makeStyles(({breakpoints, spacing}) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      width: '343px',
      height: '269px',
      backgroundColor: '#232323',
      borderRadius: '4px',
      padding: spacing(2, 4, 3),
      outline: 0,
      [breakpoints.up('lg')]: {
        width: '444px',
      },
    },
    top: {
      padding: '11px 16px',
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    },
    body: {
      paddingTop: '32px',
    },
    title: {
      fontSize: '18px',
      lineHeight: '27px',
      letterSpacing: '0.005em',
    },
    contents: {
      height: '79px',
    },
    button: {
      width: '101px',
      height: '44px',
      borderRadius: '4px',
      fontSize: '14px',
    },
    cancel: {
      color: '#6C6C6C',
      border: '1px solid #6C6C6C',
    },
  }),
)

type ActionModalProps = {
  open: boolean
  doAction: () => void
  handleClose: () => void
  title: string
  contents: string
  actionButtonStyle: string
  buttonText: string
  userId?: string
  tournamentId?: string
}

export const ActionModal: React.FC<ActionModalProps> = (props) => {
  const {open, doAction, handleClose, title, contents, actionButtonStyle, buttonText, userId, tournamentId} = props
  const styles = useModalStyles()

  const action = () => {
    handleClose()
    doAction()
  }
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div id="transition-modal-title" className={styles.paper}>
            <div className={styles.top}>
              <Grid container spacing={2} justify="space-between" alignItems="center">
                <Grid item>
                  <Typography variant='h4' className={styles.title}>
                    {title}
                  </Typography>
                </Grid>
                <IconButton onClick={handleClose}>
                  <CloseIcon/>
                </IconButton>
              </Grid>
            </div>
            <div id="transition-modal-description" className={styles.body}>
              <Typography className={styles.contents}>
                {contents}
              </Typography>
              <Grid container spacing={2} justify="flex-end">
                <Grid item>
                  <Button
                    className={composeClassNames(styles.button, styles.cancel)}
                    onClick={handleClose}
                  >
                    キャンセル
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={composeClassNames(styles.button, actionButtonStyle)}
                    data-userId={userId}
                    data-tournamentId={tournamentId}
                    onClick={action}
                  >
                    {buttonText}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  )
}
