import React from 'react'

import {
  useIsLg,
} from '@/hooks'

type IfProps = {
  lg: boolean
}

const If: React.FC<IfProps> = ({children, lg}) => {

  const isLg = useIsLg()

  return isLg === lg ? <>{children}</> : null

}

export const IfLg: React.FC = ({children}) => {

  return <If lg>{children}</If>

}

export const IfNotLg: React.FC = ({children}) => {

  return <If lg={false}>{children}</If>

}
