import {
  Card,
  CoinExchangeHistory,
  CoinExchangeModal,
  LoadingError,
  SinglePageTabMenu,
  SinglePageTabMenuType,
  TabPanel,
} from '@/components'
import {LoadingPart} from '../../atoms/loading-part'
import {
  CoinExchangeStatus,
  CoinExchangeType,
  useMyCoinExchangeRequestsQuery,
  useMyWalletQuery,
} from '@/graphql/client'
import {composeClassNames} from '@/utils'
import {Box, Button, Typography, createStyles, makeStyles} from '@material-ui/core'
import React from 'react'

const useWalletStyles = makeStyles(({breakpoints, spacing, palette}) =>
  createStyles({
    card: {
      marginLeft: spacing(-4),
      marginRight: spacing(-4),
      marginBottom: '40px',
      [breakpoints.up('lg')]: {
        margin: 'initial',
        marginBottom: '40px',
        width: '800px',
      },
    },
    title: {
      marginBottom: '16px',
      fontSize: '18px',
      fontWeight: 'bold',
    },
    letter: {
      marginBottom: '6px',
      fontSize: '14px',
      color: palette.text.secondary,
      [breakpoints.up('lg')]: {
        marginBottom: 'initial',
      },
    },
    separation: {
      display: 'block',
      [breakpoints.up('lg')]: {
        display: 'inline',
        paddingLeft: '20px,',
      },
    },
    inner: {
      padding: '16px',
      [breakpoints.up('lg')]: {
        padding: '16px 41px 16px 16px',
      },
    },
    innnerCoinChange: {
      display: 'block',
      [breakpoints.up('lg')]: {
        display: 'flex',
      },
    },
    text: {
      fontSize: '15px',
    },
    buttonWrap: {
      textAlign: 'end',
      [breakpoints.up('lg')]: {
        textAlign: 'initial',
      },
    },
    coinIcon: {
      width: 52,
      height: 52,
      marginRight: spacing(2),
    },
    disclosure: {
      marginTop: '40px',
      fontSize: '12px',
      color: palette.text.secondary,
    },
    notEnough: {
      marginTop: '-32px',
      marginBottom: '14px',
      color: '#F44336',
      fontSize: '12px',
      textAlign: 'right',
      [breakpoints.up('lg')]: {
        width: '800px',
      },
    },
  })
)

export type HistoryRowType = {
  coin: number
  id: string
  createdAt: string
  exchangeType: CoinExchangeType
  status: CoinExchangeStatus
}


const MINIMUM_REDEMPTION_AMAOUNT = 500

export const Wallet: React.FC = () => {
  const styles = useWalletStyles()
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState(1)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const {data: myCoinExchangeData, loading: myCoinExchangeLoading, fetchMore, refetch: refetchMyCoinExchange} = useMyCoinExchangeRequestsQuery({
    variables: {
      first: 6,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true, // これがないとViewMore時にLoadingがtrueにならない
  })

  const {data, error, loading, refetch} = useMyWalletQuery({
    fetchPolicy: 'network-only',
  })
  if (loading) {
    return <LoadingPart/>
  }

  if (error || !data) {
    return <LoadingError/>
  }

  const tabs: SinglePageTabMenuType[] = [
    // {
    //   value: 0,
    //   label: 'AC購入履歴',
    // },
    {
      value: 1,
      label: 'AC交換履歴',
    },
  ]

  const hasMore = myCoinExchangeData?.myCoinExchangeRequests.pageInfo.hasNextPage

  const separateNum = (coin: number) => {
    return String(coin).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
  }

  return (
    <Box paddingTop={7.5}>
      <Card className={styles.card}>
        <Box
          className={styles.inner}
          display="flex"
          justifyContent='space-between'
          alignItems='center'
        >
          <Box display="flex" alignItems='center'>
            <img
              className={styles.coinIcon}
              src="/images/icon/adictor_coin.svg"
              alt="adictorcoin"
            />
            <Typography className={styles.text}>
              Adictor Coin
            </Typography>
          </Box>
          <Box>
            <Typography className={styles.text}>
              {separateNum(data.myWallet.availableCoin)} AC
            </Typography>
          </Box>
        </Box>
      </Card>
      <Typography className={styles.title}>
        ACを交換する
      </Typography>
      <Card className={styles.card}>
        <Box
          className={composeClassNames(styles.inner, styles.innnerCoinChange)}
          display="flex"
          justifyContent='space-between'
          alignItems='center'
        >
          <Box>
            <Typography className={styles.letter}>
              1AC = 1円分のギフト券に交換できます&nbsp;<span className={styles.separation}>※最低{MINIMUM_REDEMPTION_AMAOUNT}ACから交換可能</span>
            </Typography>
          </Box>
          <Box className={styles.buttonWrap}>
            <Button variant="outlined" color="primary" onClick={handleOpen} disabled={MINIMUM_REDEMPTION_AMAOUNT > data.myWallet.availableCoin}>ACを交換する</Button>
          </Box>
        </Box>
      </Card>
      {MINIMUM_REDEMPTION_AMAOUNT > data.myWallet.availableCoin &&
        <Typography className={styles.notEnough}>500ACに達していません</Typography>
      }
      <Typography className={styles.title}>
        履歴
      </Typography>
      <Box maxWidth={800}>
        <SinglePageTabMenu tabs={tabs} tabWidth={140} value={value} handleChange={handleChange}/>
      </Box>
      <TabPanel value={value} index={1}>
        <CoinExchangeHistory
          coinExhangeRequests={myCoinExchangeData}
          isLoading={myCoinExchangeLoading}
          hasMore={hasMore}
          fetchMore={fetchMore}
        />
      </TabPanel>
      <Typography className={styles.disclosure}>
        ※Amazon、Amazon.co.jp およびそれらのロゴは Amazon.com, Inc.またはその関連会社の商標です。<br />
        ※Google Play および Google Play ロゴは Google LLC の商標です。<br />
        ※Apple Gift Cardは、米国およびその他の国で登録されたApple Inc.の商標です。

        {/* 過去の交換種別 (iTunes) 用表記 */}
        <br />※App Store, iTunesは米国およびその他の国々で登録されているApple Inc.の商標です。
        <br />※当キャンペーンはAppleの提供・協賛によるものではありません。
        <br />※App Store &amp; iTunes コードは、日本のiTunes Storeのみでご利用可能です。
      </Typography>

      <CoinExchangeModal
        coin={data.myWallet.availableCoin}
        handleClose={handleClose}
        open={open}
        refetchWallet={refetch}
        refetchMyCoinExchange={refetchMyCoinExchange}
      />
    </Box>
  )
}
