import {
  CustomTournamentBracket,
  CustomTournamentPlayer,
  OpenCustomTournamentBracket,
  OpenCustomTournamentTeam,
  OpenSingleEliminationTournamentBracket,
  OpenSingleEliminationTournamentNode,
  OpenSingleEliminationTournamentTeam,
  OpenTournamentNodeFragment,
  SingleEliminationTournamentBracket,
  SingleEliminationTournamentNode,
  TeamCustomTournamentBracket,
  TeamCustomTournamentTeam,
  TeamSingleEliminationTournamentBracket,
  TeamSingleEliminationTournamentNode,
  TeamSingleEliminationTournamentTeam,
  TeamTournamentNodeFragment,
  TournamentNodeFragment,
  TournamentPlayerFragment,
} from '@/graphql/client'

export type SingleEliminationBracket = SingleEliminationTournamentBracket | TeamSingleEliminationTournamentBracket | OpenSingleEliminationTournamentBracket
export type SingleEliminationNode = SingleEliminationTournamentNode | TeamSingleEliminationTournamentNode | OpenSingleEliminationTournamentNode
export type SingleEliminationNodeFragment = TournamentNodeFragment | TeamTournamentNodeFragment | OpenTournamentNodeFragment
export type SingleEliminationUnitFragment = TournamentPlayerFragment | TeamSingleEliminationTournamentTeam | OpenSingleEliminationTournamentTeam

export type CustomBracket = CustomTournamentBracket | TeamCustomTournamentBracket | OpenCustomTournamentBracket
export type CustomTournamentUnitFragment = CustomTournamentPlayer | TeamCustomTournamentTeam | OpenCustomTournamentTeam

export type TournamentUnitFragment = SingleEliminationUnitFragment | CustomTournamentUnitFragment

// Type Guard

export const isCustomTournamentBracket = (fragment: any): fragment is CustomTournamentBracket => {
  return fragment?.__typename === 'CustomTournamentBracket'
}

export const isTeamCustomTournamentBracket = (fragment: any): fragment is TeamCustomTournamentBracket => {
  return fragment?.__typename === 'TeamCustomTournamentBracket'
}

export const isOpenCustomTournamentBracket = (fragment: any): fragment is OpenCustomTournamentBracket => {
  return fragment?.__typename === 'OpenCustomTournamentBracket'
}

export const isTournamentNodeFragment  = (fragment: any): fragment is SingleEliminationTournamentNode => {
  return fragment?.__typename === 'SingleEliminationTournamentNode'
}

export const isTournamentTeamNodeFragment  = (fragment: any): fragment is TeamSingleEliminationTournamentNode => {
  return fragment?.__typename === 'TeamSingleEliminationTournamentNode'
}

export const isOpenTournamentNodeFragment  = (fragment: any): fragment is OpenSingleEliminationTournamentNode => {
  return fragment?.__typename === 'OpenSingleEliminationTournamentNode'
}

export const isTournamentPlayerFragment = (fragment: any): fragment is TournamentPlayerFragment => {
  return fragment?.__typename === 'SingleEliminationTournamentPlayer'
}

export const isTournamentTeamFragment = (fragment: any): fragment is TeamSingleEliminationTournamentTeam => {
  return fragment?.__typename === 'TeamSingleEliminationTournamentTeam'
}

export const isOpenTournamentTeamFragment = (fragment: any): fragment is OpenSingleEliminationTournamentTeam => {
  return fragment?.__typename === 'OpenSingleEliminationTournamentTeam'
}

export const isCustomTournamentPlayerFragment = (fragment: any): fragment is CustomTournamentPlayer => {
  return fragment?.__typename === 'CustomTournamentPlayer'
}

export const isCustomTournamentTeamFragment = (fragment: any): fragment is TeamCustomTournamentTeam => {
  return fragment?.__typename === 'TeamCustomTournamentTeam'
}

export const isOpenCustomTournamentTeamFragment = (fragment: any): fragment is OpenCustomTournamentTeam => {
  return fragment?.__typename === 'OpenCustomTournamentTeam'
}
