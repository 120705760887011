import React from 'react'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {
  Box,
  Button,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  ClanListItemGrid,
  IfLg,
  IfNotLg,
  Link,
} from '@/components'
import {useSignInRequiredLink} from '@/hooks/auth'
import {ClanFragment, useMyClansQuery, useSignUpCheckQuery} from '@/graphql/client'
import {LoadingPart} from '../../atoms/loading-part'
import {useSnackbar} from '@/hooks/acknowledge'
import {useRouter} from 'next/router'

const useBelongingClanStyle = makeStyles(({palette, breakpoints}) =>
  createStyles({
    root: {
      marginTop: '24px',
      [breakpoints.up('lg')]: {
        marginTop: '43px',
      },
    },
    notMemberRoot: {
      margin: '24px -16px 0',
      padding: '24px 16px 40px',
      borderRadius: '4px',
      background: '#1E1E1E',
      [breakpoints.up('lg')]: {
        margin: 'initial',
        marginTop: '31px',
        padding: '24px 24px 40px',
      },
    },
    title: {
      fontSize: '15px',
      [breakpoints.up('lg')]: {
        fontSize: '18px',
      },
    },
    createBtn: {
      width: '143px',
      height: '44px',
      background: palette.primary.main,
    },
    spCreateBtn: {
      marginTop: '40px',
      width: '100%',
      height: '45px',
      background: palette.primary.main,
    },
    body: {
      marginBottom: '24px',
      fontSize: '14px',
    },
    detail: {
      marginBottom: '32px',
      fontSize: '14px,',
      color: palette.text.secondary,
      textDecorationLine: 'underline',
    },
    searchBtn: {
      marginRight: '12px',
      width: '129px',
      height: '44px',
      color: '#55C341',
      border: '1px solid rgba(85, 195, 65, 0.5)',
    },
  })
)


export const BelongingClan: React.FC = () => {
  const styles = useBelongingClanStyle()

  const {handleRedirect} = useSignInRequiredLink()
  const {snackbar} = useSnackbar()
  const router = useRouter()
  const {data: clansData, loading: clansLoading} = useMyClansQuery()
  const {data: isSignUpData, loading: signUpLoading} = useSignUpCheckQuery()

  if (signUpLoading || clansLoading) {
    return <LoadingPart/>
  }

  const clans = ((clansData?.myClans || []) as ClanFragment[]).filter((it) => !it.isArchived)
  const isSignUp = isSignUpData?.signUpCheck || false

  const handleCreate = () => {
    if (clans.length > 49) {
      snackbar.failed('クランは最大で50個までしか運営・所属できません')
      return
    }

    handleRedirect('/clan/new')()
  }

  const handleDetail = async (clan: ClanFragment) => await router.push(`/clan/${clan.id}/detail`)

  if (!isSignUp || !clans[0]) {
    return (
      <Box className={styles.notMemberRoot}>
        <Typography variant="body2" className={styles.body}>まだ所属しているクランがありません。<br />クランに所属してチーム戦でのバトルを楽しみましょう！</Typography>
        <Link href="https://app.adictor.jp/help/clan">
          <Typography className={styles.detail}>クランとは</Typography>
        </Link>
        <Grid container justify="center">
          {/*<Button className={styles.searchBtn} onClick={() => setValue(0)}>クランを探す</Button>*/}
          <Button className={styles.createBtn} onClick={handleCreate}>新規クラン作成</Button>
        </Grid>
      </Box>
    )
  }

  return (
    <Box className={styles.root}>
      <Grid container justify="space-between" alignItems="center">
        <Typography variant="h4" className={styles.title}>{clans.length}つのクランに所属中</Typography>
        <IfLg>
          <Button className={styles.createBtn} onClick={handleCreate}>新規クラン作成</Button>
        </IfLg>
      </Grid>
      <ClanListItemGrid clans={clans} onClick={handleDetail}/>
      <IfNotLg>
        <Button className={styles.spCreateBtn} onClick={handleCreate}>新規クラン作成</Button>
      </IfNotLg>
    </Box>
  )
}
