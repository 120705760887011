import {Profile, ProfileUpdateInput, useProfileUpdateMutation} from '@/graphql/client'
import {useSnackbar} from '@/hooks/acknowledge'
import {useErrorReport} from '@/hooks/error'
import React, {useCallback} from 'react'
import {Box, CircularProgress, Paper, TextField, Typography} from '@material-ui/core'
import {Button} from '../../atoms'
import {AvatarForm, Card} from '@/components'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {useIsLg} from '@/hooks'
import {Controller, useForm} from 'react-hook-form'
import useAxios from 'axios-hooks'
import {FileRejection, useDropzone} from 'react-dropzone'
import {composeClassNames} from '@/utils'

const useProfileFormStyles = makeStyles(({breakpoints, spacing}) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      // marginTop: spacing(2.25),
      [breakpoints.up('lg')]: {
      },
    },
    card: {
      marginLeft: spacing(-4),
      marginRight: spacing(-4),
      [breakpoints.up('lg')]: {
        margin: 'initial',
        width: '800px',
      },
    },
    title: {
      marginBottom: spacing(2),
    },
    textField: {
      width: '100%',
      [breakpoints.up('lg')]: {
        width: 320,
      },
      marginBottom: spacing(6.5),
    },
    textArea: {
      marginBottom: spacing(6.5),
    },
    select: {
      width: 320,
      marginBottom: spacing(6.5),
    },
    button: {
      width: 89,
      alignSelf: 'center',
    },
    twitterId: {
      marginBottom: spacing(6.5),
    },
    uploadBtn: {
      display: 'none',
    },
    imageUploadWrapper: {
      display: 'inline-block',
      marginBottom: '26px',
    },
    dropzone: {
      width: '100px',
      height: '100px',
      border: 'none',
      background: 'none',
      boxShadow: 'none',
      textAlign: 'center',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    uploading: {
      marginTop: '25%',
    },
    isActive: {
      opacity: 0.4,
    },
    camera: {
      margin: 0,
      position: 'relative',
      top: '-134px',
      width: '100px',
      height: '100px',
      borderRadius: '50%',
      background: 'rgba(0, 0, 0, 0.6)',
      backgroundImage: 'url("/images/camera.png")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: '20px 18px',
    },
  })
)

type ProfileFormProps = {
  profile: Profile
}

type ResultUserThumnail = {
  url: string
}

export const ProfileForm: React.FC<ProfileFormProps> = ({profile}) => {
  const styles = useProfileFormStyles()
  const isLg = useIsLg()
  const {handleSubmit, errors, control, formState, setValue} = useForm<Profile>({
    mode: 'onChange',
    defaultValues: {...profile},
  })
  const [
    profileUpdate,
    {loading: mutationLoading},
  ] = useProfileUpdateMutation()
  const {snackbar} = useSnackbar()
  const {errorReport} = useErrorReport()
  const [imageUrl, setImageUrl] = React.useState('')

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections[0]) {
      const code = fileRejections[0].errors[0].code
      if (code === 'file-too-large') {
        snackbar.failed('アップロードできる画像容量は最大2MBです')
      }
      if (code === 'file-invalid-type') {
        snackbar.failed('アップロードできるファイル形式はpng / jpgです')
      }
    }
    const uploadImage = acceptedFiles[0]
    if (uploadImage) {
      const formData = new FormData()
      formData.append('image', uploadImage)
      try {
        executePost({
          data: formData,
        })
      } catch (e) {
        snackbar.failed('画像アップロードに失敗しました', e)
        errorReport.captureException(e)
      }
    }
  }, [])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: 'image/jpeg, image/png', maxSize: 2097152})

  const [
    {data: postData, loading: postLoading},
    executePost,
  ] = useAxios<ResultUserThumnail>(
    {
      url: '/assets/user_thumbnail',
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
    {manual: true}
  )

  React.useEffect(() => {
    if (postData?.url) {
      setImageUrl(postData.url)
      setValue('thumbnailTmpUrl', postData.url, {shouldDirty: true})
    }
  }, [postData?.url])

  const onSubmit = async (value: ProfileUpdateInput, e: any) => {
    e.preventDefault()
    try {
      await profileUpdate({variables: {input: value}})
      snackbar.success('プロフィールを更新しました')
    } catch (e) {
      snackbar.failed('プロフィールの更新に失敗しました', e)
      errorReport.captureException(e)
    }
  }

  return (
    <Box paddingTop={7.5}>
      <Card className={styles.card}>
        <Box padding={isLg ? 8 : 3.75}>
          <form
            className={styles.root}
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Typography
              className={styles.title}
              variant="subtitle2"
              color="textPrimary"
            >
              プロフィール画像
            </Typography>
            <label className={styles.imageUploadWrapper}>
              <Controller
                name="thumbnailTmpUrl"
                control={control}
                render={() =>
                  <Paper className={styles.dropzone} {...getRootProps()} onClick={(e) => {e.stopPropagation()}}>
                    <input
                      {...getInputProps()}
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      className={styles.uploadBtn}
                      disabled={postLoading}
                    />
                    {(() => {
                      if (postLoading) {
                        return <CircularProgress className={styles.uploading}/>
                      } else if (imageUrl) {
                        return (
                          <>
                            <AvatarForm url={imageUrl}/>
                            <p className={isDragActive ? composeClassNames(styles.camera, styles.isActive) : styles.camera}></p>
                          </>
                        )
                      } else {
                        return (
                          <>
                            <AvatarForm url={profile.thumbnailUrl}/>
                            <p className={isDragActive ? composeClassNames(styles.camera, styles.isActive) : styles.camera}></p>
                          </>
                        )
                      }
                    })()}
                  </Paper>
                }
              />
            </label>
            <Typography
              className={styles.title}
              variant="subtitle2"
              color="textPrimary"
            >
              ユーザーネーム
            </Typography>
            <Controller
              as={
                <TextField
                  variant="outlined"
                  color="primary"
                  className={styles.textField}
                  placeholder="ユーザーネームを入力"
                  error={Boolean(errors.displayName)}
                  helperText={errors.displayName?.message || '1~64文字で入力してください'}
                />
              }
              name="displayName"
              control={control}
              rules={{
                required: true,
                minLength: 1,
                maxLength: 64,
              }}
            />

            <Typography
              className={styles.title}
              variant="subtitle2"
              color="textPrimary"
            >
              Twitter ID
            </Typography>
            <Typography
              className={styles.twitterId}
              variant="body2"
              color="textPrimary"
            >{profile.twitterId}
            </Typography>

            {/* Twitter ID の編集は出来ないようにする */}
            {/*<Controller*/}
            {/*  as={*/}
            {/*    <TextField*/}
            {/*      variant="outlined"*/}
            {/*      className={styles.textField}*/}
            {/*      placeholder="Twitter IDを入力"*/}
            {/*      helperText={errors.twitterId?.message || '15文字以内で入力してください'}*/}
            {/*    />*/}
            {/*  }*/}
            {/*  name="twitterId"*/}
            {/*  control={control}*/}
            {/*  rules={{*/}
            {/*    maxLength: 15,*/}
            {/*    pattern: /^[A-Z0-9_]+$/i,*/}
            {/*  }}*/}
            {/*/>*/}

            <Typography
              className={styles.title}
              variant="subtitle2"
              color="textPrimary"
            >
              プロフィール
            </Typography>
            <Controller
              as={
                <TextField
                  variant="outlined"
                  className={styles.textArea}
                  placeholder="プロフィール文を入力"
                  rows={4}
                  multiline
                  fullWidth
                  error={Boolean(errors.body)}
                  helperText={errors.body?.message || '1,000文字以内で入力してください'}
                />
              }
              name="body"
              control={control}
              rules={{maxLength: 1000}}
            />

            <Button
              type="submit"
              className={styles.button}
              style={{marginBottom: isLg ? 80 : 60}}
              disabled={!(formState.isValid && formState.isDirty) || mutationLoading}
            >
              更新する
            </Button>
          </form>
        </Box>
      </Card>
    </Box>
  )
}
