import React from 'react'

import {Link, Logo, MuiLink} from '../atoms'

import {Box, Divider, Typography, makeStyles} from '@material-ui/core'

import TwitterIcon from '@material-ui/icons/Twitter'
import YoutubeIcon from '@material-ui/icons/YouTube'

import {composeClassNames} from '@/utils'

const useFooterStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(10),
  },
  divider: {
    marginBottom: theme.spacing(10),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },
  logo: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(13.5),
    },
  },
  linkAndCopyrightContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  linkItem: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(8),
    },
  },
  copyright: {
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(8),
      textAlign: 'right',
    },
  },
}))

type FooterProps = React.ComponentProps<typeof Box>

export const Footer: React.FC<FooterProps> = ({className}) => {

  const styles = useFooterStyles()

  return (
    <Box className={composeClassNames(styles.root, className)}>
      <Divider className={styles.divider}/>
      <Box className={styles.container}>
        <Link href='/'>
          <Logo type='white' className={styles.logo}/>
        </Link>
        <Box className={styles.linkAndCopyrightContainer}>
          <Box className={styles.linkContainer}>
            <MuiLink className={styles.linkItem} href='https://special.adictor.jp/'>制作実績</MuiLink>
            <Link className={styles.linkItem} href='/terms'>利用規約</Link>
            <Link className={styles.linkItem} href='/privacy'>プライバシーポリシー</Link>
            <Link className={styles.linkItem} href='/help/' target="_blank" rel="noopener noreferrer">ヘルプページ</Link>
            <MuiLink className={styles.linkItem} href='https://corp.logly.co.jp/contact?type=adictor'>お問い合わせ</MuiLink>
            <MuiLink className={styles.linkItem} href='https://corp.logly.co.jp'>運営会社</MuiLink>
            <MuiLink className={styles.linkItem} href='https://twitter.com/AdictorOfficial'><TwitterIcon/></MuiLink>
            <MuiLink className={styles.linkItem} href='https://www.youtube.com/@Adictor?sub_confirmation=1'><YoutubeIcon/></MuiLink>
          </Box>
          <Typography variant='overline' color='textSecondary' className={styles.copyright}>
              Copyright © 2020 LOGLY, Inc. All Rights Reserved.
          </Typography>
        </Box>
      </Box>
    </Box>
  )

}
