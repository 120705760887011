import React, {useState} from 'react'

import {
  Box,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import {Avatar} from '@/components'

const useAvatarFormStyles = makeStyles(({spacing}) => createStyles({
  root: {
    width: 100,
    height: 100,
    marginBottom: spacing(8.5),
  },
  icon: {
    width: 100,
    height: 100,
    position: 'absolute',
  },
  overlay: {
    position: 'relative',
    width: 100,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    borderRadius: 50,
  },
}))

type AvatarFormProps = {
  url?: string
}

export const AvatarForm: React.FC<AvatarFormProps> = ({url}) => {
  const defaultIconUrl = 'https://asset.adictor.jp/default/icon.png'
  const styles = useAvatarFormStyles()

  const [, setIsShowOverlay] = useState<boolean>(false)

  return (
    <Box
      className={styles.root}
      onMouseOver={() => setIsShowOverlay(true)}
      onMouseLeave={() => setIsShowOverlay(false)}
    >
      <Avatar className={styles.icon} src={url || defaultIconUrl}/>

      {/*{ isShowOverlay &&*/}
      {/*  <div className={styles.overlay}>*/}
      {/*    <img src="/images/camera.svg" alt="camera" />*/}
      {/*  </div>*/}
      {/*}*/}
    </Box>
  )
}
