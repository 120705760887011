import getConfig from 'next/config'

const {publicRuntimeConfig} = getConfig()

export type ApiConfig = {
  apiEndpoint: string
}

export const apiConfig: ApiConfig = {
  apiEndpoint: process.env.NEXT_PUBLIC_API_ENDPOINT || '',
}
