import {useSignUpCheckQuery} from '@/graphql/client'
import {useAuth0} from '@auth0/auth0-react'
import {useRouter} from 'next/router'

export function useSignInRequiredLink() {
  const router = useRouter()
  const {loginWithRedirect} = useAuth0()
  const {data} = useSignUpCheckQuery()

  const redirect = async (path: string) => {
    if (!data?.signUpCheck) {
      await loginWithRedirect({appState: {returnTo: path}})
    } else {
      await router.push(path)
    }
  }

  const handleRedirect = (path: string): (() => Promise<void>) => {
    return async () => {
      await redirect(path)
    }
  }

  return {redirect, handleRedirect}
}
