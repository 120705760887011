import React from 'react'
import {Box, TextField, createStyles, makeStyles} from '@material-ui/core/'
import {Button, LoadingPart} from '@/components'

const useStyle = makeStyles(({spacing}) => {
  return createStyles({
    postForm: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: spacing(8),
      maxWidth: 800,
      '& > .MuiButtonBase-root': {
        fontSize: '14px',
        marginLeft: spacing(4),
        width: '73px',
      },
    },
  })
})

type AnnouncementFieldProps = {
  onSubmit: (body: string) => void
}

export const AnnouncementField: React.FC<AnnouncementFieldProps> = ({onSubmit}) => {
  const styles = useStyle()
  const [loading, setLoading] = React.useState(false)
  const [draft, setDraft] = React.useState('')
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDraft(event.target.value)
  }
  const handleSubmit = () => {
    setLoading(true)
    onSubmit(draft)
    setDraft('')
    setLoading(false)
  }

  return (
    <Box mx='auto' className={styles.postForm}>
      <TextField
        label='アナウンスを投稿する'
        placeholder='テキストを入力'
        variant='outlined'
        size='small'
        value={draft}
        onChange={handleChange}
        multiline
        fullWidth
      />
      <Button size='small' disabled={!draft} onClick={handleSubmit}>
        {loading
          ? <LoadingPart style={{padding: 0, maxHeight: '12px'}}/>
          : '送信'
        }
      </Button>
    </Box>
  )
}
