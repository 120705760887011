import {OpenTournamentType} from '@/graphql/client'

export const getOpenTournamentLabel = (openTournamentType: OpenTournamentType) => {
  switch (openTournamentType) {
    case OpenTournamentType.Hanakin:
      return '花金杯'
    case undefined:
      return
    default:
      return
  }
}

export const getOpenTournamentTeammateLimit = (openTournamentType: OpenTournamentType) => {
  switch (openTournamentType) {
    case OpenTournamentType.Hanakin:
      return 2
  }
}

export const missingRequirementsLabel: {[key: string]: string} = {
  discord: 'Discord ID 連携',
  riot: 'Riot ID 連携',
  tournament_setting: '大会設定',
}
