export type Auth0Config = {
  domain: string
  clientId: string
  audience: string
  redirectUri: string
  logoutUri: string
  scope: string
}

export const auth0Config: Auth0Config = {
  domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN || '',
  clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || '',
  audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE || '',
  redirectUri: typeof window !== 'undefined' ? `${window.location.origin}/callback` : '',
  logoutUri: typeof window !== 'undefined' ? `${window.location.origin}/` : '',
  scope: 'openid',
}
