export type ServerConfig = {
  isDev: boolean
  isStg: boolean
  isPrd: boolean
}

const {
  NEXT_PUBLIC_SERVER_ENV: SERVER_ENV,
} = process.env

export const serverConfig: ServerConfig = {
  isDev: SERVER_ENV === 'development',
  isStg: SERVER_ENV === 'staging',
  isPrd: SERVER_ENV === 'production',
}
