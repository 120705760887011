import React from 'react'
import {ClanListItem} from '@/components'
import {ClanFragment} from '@/graphql/client'
import {
  GridList,
  GridListTile,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core'

const useClanListItemGridStyle = makeStyles(() =>
  createStyles({
    root: {
      marginTop: '24px',
    },
  })
)

type ClanListItemGridProps = {
  clans: ClanFragment[]
  onClick: (clan: ClanFragment) => void
}

export const ClanListItemGrid: React.FC<ClanListItemGridProps> = ({clans, onClick}) => {
  const styles = useClanListItemGridStyle()
  const {spacing} = useTheme()

  return (
    <div className={styles.root}>
      <GridList
        cellHeight={80}
        cols={1}
        spacing={spacing(2)}
      >
        {clans.map((clan, idx) => {
          return (
            <GridListTile key={idx} onClick={() => onClick(clan)}>
              <ClanListItem clan={clan}/>
            </GridListTile>
          )
        })}
      </GridList>
    </div>
  )
}
