import React from 'react'

import {
  Stepper as MuiStepper,
  Step,
  StepConnector,
  StepLabel, TypographyProps, createStyles, makeStyles, withStyles,
} from '@material-ui/core'

type TitleProps = TypographyProps & {
  activeStep: number
  steps: number
}

const Connector = withStyles({
  active: {
    '& $line': {
      backgroundColor: '#55C341',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#55C341',
    },
  },
  line: {
    height: 1,
    border: 0,
    width: 24,
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
  },
})(StepConnector)

const useStepperStyles = makeStyles(() => createStyles({
  step: {
    paddingLeft: 4,
    paddingRight: 4,
  },
  iconContainer: {
    paddingRight: 0,
  },
}))

// NOTE: Stepper内の ① の部分
const stepIcon = (step: number, active: boolean, completed: boolean) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="14"
        cy="14"
        r="13"
        fill={completed ? '#55C341' : ''}
        stroke={active ? '#55C341' : 'white'}
        strokeOpacity={active ? '1.0' : '0.12'}
        strokeWidth="2"
      />
      <text
        className="MuiStepIcon-text"
        x="14"
        y="18"
        textAnchor="middle"
        stroke={active ? '#55C341' : 'white'}
        strokeOpacity={active ? '1.0' : '0.6'}
      >
        {step}
      </text>
    </svg>
  )
}

export const Stepper: React.FC<TitleProps> = (props) => {
  const styles = useStepperStyles()
  const {activeStep, steps} = props

  return (
    <MuiStepper
      activeStep={activeStep}
      connector={<Connector/>}
    >
      {[...Array(steps)].map((_, index) => {
        const active = index === activeStep
        const completed = index < activeStep

        return (
          <Step key={index} classes={{root: styles.step}}>
            <StepLabel
              classes={{iconContainer: styles.iconContainer}}
              StepIconProps={{icon: stepIcon(index+1, active, completed)}}
            />
          </Step>
        )
      })}
    </MuiStepper>
  )
}
