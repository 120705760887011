import {useUserRankingQuery} from '@/graphql/client'
import React from 'react'
import {Box, GridList, GridListTile, Typography, createStyles, makeStyles, useTheme} from '@material-ui/core'
import {UserListItem} from '../../molecules'
import {Link, LoadingError, LoadingPart} from '../../atoms'
import {useIsLg} from '@/hooks'
import {useGameContext} from '@/hooks/game'
import {composeClassNames} from '@/utils'

const useUserRankingStyles = makeStyles(() => createStyles({
  userRankingWrap: {
    marginTop: 32,
  },
  titleWrap: {
    display: 'flex',
    marginBottom: 16,
  },
  spacer: {
    flexGrow: 1,
  },
  directionRow: {
    flexDirection: 'row',
  },
  directionColumn: {
    flexDirection: 'column',
  },
  alignSelfStart: {
    alignSelf: 'flex-start',
  },
  alignSelfEnd: {
    alignSelf: 'flex-end',
  },
}))

export const UserRanking = ({...props}) => {
  const {spacing} = useTheme()
  const styles = useUserRankingStyles()
  const isLg = useIsLg()
  const {gameId} = useGameContext()

  const titleDirection = isLg ? styles.directionRow : styles.directionColumn
  const targetNumAlignSelf = isLg ? styles.alignSelfEnd : styles.alignSelfStart

  const {data, loading, error} = useUserRankingQuery({
    variables: {
      gameId: gameId,
    },
    notifyOnNetworkStatusChange: true,
  })

  if (loading) {
    return <LoadingPart/>
  }

  if (error || !data) {
    return <LoadingError/>
  }

  const userRanking = data.userRanking
  if (!userRanking) {
    return <></>
  }

  return (
    <Box {...props}>
      <Box className={composeClassNames(styles.titleWrap, titleDirection)}>
        <Typography variant='h3'>ユーザーランキング</Typography>
        <Box className={styles.spacer}/>
        <Typography variant='subtitle1' className={targetNumAlignSelf}>参加ユーザー数 {userRanking.targetNum}名</Typography>
      </Box>

      {/* Empty State */}
      {!userRanking.users[0] &&
        <Typography variant='body1'>対象のユーザーが存在しません</Typography>
      }

      {userRanking.users[0] &&
        (
          <GridList
            cellHeight={80}
            cols={1}
            spacing={spacing(2)}
            {...props}
          >
            {userRanking.users.map((user: any, index) => (
              <GridListTile key={index}>
                <Link href='/users/[id]/profile' as={`/users/${user.id}/profile`}>
                  <UserListItem
                    id={user.id}
                    name={user.profile.displayName}
                    level={user.level.level}
                    thumbnailUrl={user.profile.thumbnailUrl}
                    gameExp={user.gameExp}
                  />
                </Link>
              </GridListTile>
            ))}
          </GridList>
        )
      }
    </Box>
  )

}
