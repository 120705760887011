import {
} from '@/components'
import {Card, Typography, makeStyles} from '@material-ui/core'
import React from 'react'

const usePrivilegeCardStyles = makeStyles(({breakpoints}) => ({
  root: {
    width: '100%',
    border: 'none',
    margin: '0 auto 16px',
    padding: '24px 32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [breakpoints.up('lg')]: {
      margin: '0 auto',
      width: 312,
      height: 283,
    },
  },
  title: {
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: 'bold',
    letterSpacing: '0.005em',
    marginTop: '22px',
  },
  content: {
    fontSize: '15px',
    lineHeight: '160%',
    fontWeight: 'normal',
    letterSpacing: '0.005em',
    color: 'rgba(255, 255, 255, 0.6)',
    marginTop: '16px',
    textAlign: 'center',
  },
}))

export type PrivilegeCardProp = {
  title: string
  content: string
  img: string
  imgAlt: string
}

export const PrivilegeCard = (props :PrivilegeCardProp) => {
  const styles = usePrivilegeCardStyles()
  const {title, content, img, imgAlt} = props

  return (
    <Card className={styles.root} elevation={0}>
      <img src={img} alt={imgAlt} />
      <Typography className={styles.title}>{title}</Typography>
      <Typography className={styles.content}>{content}</Typography>
    </Card>
  )
}
