import {
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core'
import React from 'react'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import CopyToClipboard from 'react-copy-to-clipboard'
import {useSnackbar} from '@/hooks/acknowledge'

const useOperationMenuStyle = makeStyles(() =>
  createStyles({
    iconBtn: {
      padding: 0,
    },
    delete: {
      color: '#F44336',
    },
  })
)

type OperationMenuProps = {
  type: 'clanMember' | 'clanDetailMember' | 'clanDetailOwner' | 'clanModal'
  url?: string
  approvingAction: () => void
  deleteAction: () => void
}

export const OperationMenu: React.FC<OperationMenuProps> = (props) => {
  const styles = useOperationMenuStyle()
  const {type, url, approvingAction, deleteAction} = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const {snackbar} = useSnackbar()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleApprove = () => {
    approvingAction()
    handleClose()
  }

  const handleDelete = () => {
    deleteAction()
    handleClose()
  }

  const handleUrlClick = () => {
    snackbar.success('URLをクリップボードにコピーしました')
    handleClose()
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={styles.iconBtn}
      >
        <MoreHorizIcon />
      </IconButton>
      {(type === 'clanDetailMember' || type === 'clanDetailOwner') &&
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {type === 'clanDetailMember' &&
            <MenuItem onClick={handleApprove}>クランから脱退</MenuItem>
          }

          {type === 'clanDetailOwner' &&
            <MenuItem onClick={handleDelete} className={styles.delete}>クランを解散</MenuItem>
          }
        </Menu>
      }
      {type === 'clanModal' &&
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <CopyToClipboard text={url ? url : ''} onCopy={() => handleUrlClick()}>
            <MenuItem>URLをコピー</MenuItem>
          </CopyToClipboard>
          {/*<MenuItem onClick={handleApprove}>有効期限・人数の変更</MenuItem>*/}
          <MenuItem onClick={handleDelete} className={styles.delete}>URLを削除</MenuItem>
        </Menu>
      }
      {type === 'clanMember' &&
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleApprove}>管理者に変更</MenuItem>
          <MenuItem onClick={handleDelete} className={styles.delete}>クランから除名</MenuItem>
        </Menu>
      }
    </div>
  )
}
