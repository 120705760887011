import {useAuth0} from '@auth0/auth0-react'
import * as Sentry from '@sentry/node'

export function useErrorReport() {
  const {user} = useAuth0()

  const captureException = (exception: any) => {
    Sentry.withScope((scope) => {
      scope.setExtra('username', user?.name)

      if (exception.graphQLErrors?.[0]) {
        const error = exception.graphQLErrors[0]
        scope.setExtra('code', error.extensions?.code)
      }

      Sentry.captureException(exception)
    })
  }

  const errorReport = {
    captureException,
  }

  return {errorReport}
}
