import React from 'react'

import NextLink, {
  LinkProps as NextLinkProps,
} from 'next/link'

import {
  MuiLink,
} from './mui-link'

export type LinkProps = React.ComponentProps<typeof MuiLink> & NextLinkProps

export const Link: React.FC<LinkProps> = ({children, href, as, replace, scroll, shallow, prefetch, passHref=true, ...props}) => {
  return (
    <NextLink href={href} as={as} replace={replace} scroll={scroll} shallow={shallow} prefetch={prefetch} passHref={passHref}>
      <MuiLink {...props}>{children}</MuiLink>
    </NextLink>
  )
}
