import {TournamentStatus} from '@/graphql/client'
import React from 'react'

import {
  createStyles,
  makeStyles,
} from '@material-ui/core'

import {
  composeClassNames,
} from '@/utils'

const useStatusStyles = makeStyles(({spacing}) => createStyles({
  status: {
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.005em',
    color: 'rgba(255, 255, 255, 0.6)',
  },
  icon: {
    display: 'inline-block',
    margin: spacing(1),
    verticalAlign: 'middle',
    width: 6,
    height: 6,
    borderRadius: '50%',
  },
  public: {
    background: '#55C341',
  },
  inProgress: {
    background: 'linear-gradient(141.91deg, #A400D3 14.31%, #D200A7 86.06%)',
  },
  finish: {
    background: 'rgba(255, 255, 255, 0.38)',
  },
}))

type HoldingStatusProps = {
  status: TournamentStatus
}

export const HoldingStatus: React.FC<HoldingStatusProps> = ({status}) => {
  const styles = useStatusStyles()
  switch (status) {
    case TournamentStatus.Public:
      return (
        <>
          <span className={composeClassNames(styles.icon, styles.public)}/>
          公開中
        </>
      )
    case TournamentStatus.InProgress:
      return (
        <>
          <span className={composeClassNames(styles.icon, styles.inProgress)}/>
          進行中
        </>
      )
    case TournamentStatus.Finish:
      return (
        <>
          <span className={composeClassNames(styles.icon, styles.finish)}/>
          終了
        </>
      )
    case TournamentStatus.Cancel:
      return (
        <>
          <span className={composeClassNames(styles.icon, styles.finish)}/>
          中止
        </>
      )
    default:
      return (<></>)
  }
}
