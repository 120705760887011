import React from 'react'
import {Box, Typography} from '@material-ui/core'
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles'

const useMedalStyles = makeStyles<Theme, {background: MedalBackgroundColor}>(() => createStyles({
  root: {
    position: 'absolute',
    width: 110,
    height: 22,
    right: -8,
    bottom: 0,
    transform: 'skewX(-35deg)',
    borderTopLeftRadius: 4,
    background: ({background}) => background,
  },
  container: {
    position: 'absolute',
    width: 110,
    height: 22,
    right: -6,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontWeight: 'bold',
    fontSize: 12,
  },
}))

export enum MedalBackgroundColor {
  GRAD_RED = 'linear-gradient(101.11deg, #F44336 -2.55%, #B01E13 95.74%)',
  GRAD_PURPLE = 'linear-gradient(101.31deg, #A501D3 -0.02%, #6E008C 96.59%)',
  GRAD_GREEN = 'linear-gradient(100.51deg, #467654 -0.02%, #244B2F 96.77%)',
  GRAD_GOLD = 'linear-gradient(99.76deg, #F3C000 -0.02%, #D6A800 96.56%)',
  SILVER = 'rgba(255, 255, 255, 0.3)',
  GREEN = 'rgba(85, 195, 65, 1)'
}

type MedalProps =  {
  background: MedalBackgroundColor
  text: string
}

export const Medal: React.FC<MedalProps> = (props) => {
  const {background, text} = props
  const styles = useMedalStyles({background})

  return (
    <>
      <Box className={styles.root} />
      <Box className={styles.container}>
        <Typography className={styles.text}>{text}</Typography>
      </Box>
    </>
  )
}
