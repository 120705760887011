import {
  Button, LoadingError,
} from '@/components'
import {
  TournamentStatus,
  useMeQuery,
  useOrganizedTournamentsQuery,
  useTournamentCancelMutation,
  useTournamentStartMutation,
} from '@/graphql/client'
import {useSnackbar} from '@/hooks/acknowledge'
import {useErrorReport} from '@/hooks/error'
import React, {useState} from 'react'
import {
  Grid,
  List,
  ListItem,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import {format, parseISO} from 'date-fns'
import {Center, HoldingStatus} from '../../atoms'
import {ProcessedTournamentType, Setting, SettingOption, TournamentHistoryModal} from '../../molecules'
import {LoadingPart} from '../../atoms/loading-part'
import MutationLoading from '../../templates/layouts/mutation-loading'
import {NextRouter, useRouter} from 'next/router'
import MessageEmptyState from '../../atoms/message-empty-state'

const useListStyles = makeStyles(({breakpoints, palette}) => createStyles({
  root: {
    padding: 0,
    marginBottom: '40px',
    backgroundColor: palette.contentBackground,
    [breakpoints.up('lg')]: {
      margin: '0 0 40px',
      padding: 0,
    },
  },
  list: {
    flexGrow: 1,
    maxWidth: '962px',
    paddingTop: '18px',
    paddingBottom: '18px',
    margin: 'auto',
    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    '&:last-child': {
      borderBottom: 'none',
    },
    [breakpoints.up('lg')]: {
      padding: '16px 16px 17px',
    },
  },
  img: {
    margin: 'auto',
    display: 'block',
    width: '60px',
    maxHeight: '100%',
    [breakpoints.up('lg')]: {
      width: '87px',
    },
  },
  contentsArea: {
    flex: 1,
    marginLeft: '10px',
    [breakpoints.up('lg')]: {
      marginLeft: '24px',
    },
  },
  listTitle: {
    width: '100%',
    fontSize: '14px',
    [breakpoints.up('lg')]: {
      fontSize: '15px',
    },
  },
  contents: {
    justifyContent: 'center',
  },
  status: {
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.005em',
    color: 'rgba(255, 255, 255, 0.6)',
  },
  date: {
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.005em',
    color: 'rgba(255, 255, 255, 0.6)',
    '&::before': {
      content: '"・"',
      margin: '4px',
      height: '18px',
    },
  },
  settingArea: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [breakpoints.up('lg')]: {
      width: 'initial',
    },
  },
  bullets: {
    margin: '4px 0px',
  },
  button: {
    marginTop: '18px',
    width: '100%',
    height: '33px',
    fontSize: '14px',
    [breakpoints.up('lg')]: {
      margin: '10px',
      width: '131px',
    },
  },
  setting: {
    position: 'absolute',
    top: '15.5px',
    right: '10px',
    [breakpoints.up('lg')]: {
      position: 'relative',
      top: 'initial',
      right: 'initial',
    },
  },
  default: {
    fontSize: '14px',
  },
  cansel: {
    fontSize: '14px',
    color: '#F44336',
  },
  viewMore: {
    width: '106px',
    height: '33px',
    fontSize: '14px',
  },
}))

export const TournamentHistoryContents: React.FC = () => {
  const router = useRouter()
  const {snackbar} = useSnackbar()
  const {errorReport} = useErrorReport()
  const {data: meData} = useMeQuery()

  const [isModalOpen, setModalOpen] = useState(false)
  const [modalType, setModalType] = useState('start')
  const [selectTournament, setSelectTournament] = useState<ProcessedTournamentType | null>(null)

  const handleModalOpen = (type: string, tournament: ProcessedTournamentType) => {
    if (!tournament) {
      return
    }
    if (type === 'start') {
      setModalType('start')
    } else if (type === 'cancel') {
      setModalType('cancel')
    }
    setSelectTournament(tournament)
    setModalOpen(true)
  }
  const handleModalClose = () => {
    setModalOpen(false)
  }


  const [tournamentStart] = useTournamentStartMutation()
  const handleStart = async () => {
    if (!selectTournament) {
      return
    }
    try {
      await tournamentStart({variables: {input: {tournamentId: selectTournament.id}}})
      snackbar.success(`「${selectTournament.title}」を開始しました`)
    } catch (e) {
      snackbar.failed(`「${selectTournament.title}」の開始に失敗しました`, e)
      errorReport.captureException(e)
    }
  }

  const [tournamentCancel] = useTournamentCancelMutation()
  const handleCancel = async () => {
    if (!selectTournament) {
      return
    }
    try {
      await tournamentCancel({variables: {input: {tournamentId: selectTournament.id}}})
      snackbar.success(`「${selectTournament.title}」を中止しました`)
    } catch (e) {
      snackbar.failed(`「${selectTournament.title}」の中止に失敗しました`, e)
      errorReport.captureException(e)
    }
  }


  const styles = useListStyles()

  const {data, error, loading, fetchMore} = useOrganizedTournamentsQuery({
    variables: {
      first: 6,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true, // これがないとViewMore時にLoadingがtrueにならない
  })
  const [viewMore, setViewMore] = useState<boolean>(false)

  const onViewMore = async () => {
    setViewMore(true)
    await fetchMore({
      variables: {
        after: data?.organizedTournaments?.pageInfo?.endCursor,
        first: 6,
      },
    })
  }
  const hasMore = data?.organizedTournaments?.pageInfo?.hasNextPage

  if (loading && !viewMore) {
    return <LoadingPart/>
  }

  if (error) {
    return <LoadingError/>
  }

  const res = data?.organizedTournaments.edges
  if (!res || res.length === 0) {
    return (
      <MessageEmptyState message='主催している大会はありません'/>
    )
  }

  const tournaments: ProcessedTournamentType[] = res.map((data) => {
    return {
      id: data!.node!.id,
      coverUrl: data!.node!.coverUrl,
      status: data!.node!.status,
      title: data!.node!.title,
      willStartAt: data!.node!.willStartAt,
      isPrivate: data?.node?.isPrivate ?? false,
    }
  })

  return (
    <>
      <TournamentHistoryModal
        type={modalType}
        open={isModalOpen}
        handleClose={handleModalClose}
        tournament={selectTournament}
        handleStart={handleStart}
        handleCancel={handleCancel}
        userId={meData?.me.id ?? ''}
      />
      <List className={styles.root}>
        {tournaments.map((tournament) => {
          return (
            <ListItem key={tournament.id} className={styles.list}>
              <Grid container alignItems="center">
                <Grid item>
                  <img
                    className={styles.img}
                    alt="complex"
                    src={tournament.coverUrl}
                  />
                </Grid>
                <Grid item container className={styles.contentsArea} alignItems="center">
                  <Grid item container direction="column" className={styles.contents} alignItems="center">
                    <Grid item className={styles.listTitle}>
                      <Typography variant="subtitle1">
                        {tournament.title}
                      </Typography>
                    </Grid>
                    <Grid item container>
                      <Grid item>
                        <Typography className={styles.status}>
                          <HoldingStatus status={tournament.status}/>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={styles.date}>
                          {format(parseISO(tournament.willStartAt), 'yyyy/MM/dd HH:mm~')}
                        </Typography>
                      </Grid>
                      {tournament.isPrivate &&
                        <Grid item>
                          <Typography className={styles.date}>
                            シークレット
                          </Typography>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={styles.settingArea}>
                  {tournament.status === TournamentStatus.Public &&
                    isPassedWillStartTime(tournament.willStartAt) &&
                    <Button className={styles.button} outlined onClick={() => handleModalOpen('start', tournament)}>
                      大会を開始する
                    </Button>
                  }
                  <SetteingWrapper
                    handleModalOpen={handleModalOpen}
                    tournament={tournament}
                    router={router}
                    iconStyle={styles.setting}
                    defaultlStyle={styles.default}
                    cancelStyle={styles.cansel}
                  />
                </Grid>
              </Grid>
            </ListItem>
          )
        })}
      </List>
      <Center>
        {hasMore && <Button outlined onClick={onViewMore} disabled={loading} className={styles.viewMore}>View more</Button>}
        {loading && <MutationLoading />}
      </Center>
    </>
  )
}


const isPassedWillStartTime = (time: string) => {
  const willStartTime = new Date(time)
  const now = new Date()
  return now > willStartTime
}

type SetteingWrapperProps = {
  handleModalOpen: (arg0: string, arg1: ProcessedTournamentType) => void
  tournament: ProcessedTournamentType
  router: NextRouter
  iconStyle: string
  defaultlStyle: string
  cancelStyle: string
}

const SetteingWrapper: React.FC<SetteingWrapperProps> = (props) => {
  const {handleModalOpen, tournament, router, iconStyle, defaultlStyle, cancelStyle} = props

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const options: SettingOption[] = [
    {
      title: '詳細を見る',
      style: defaultlStyle,
      type: 'detail',
    },
  ]
  if (tournament.status === TournamentStatus.Public) {
    options.push({
      title: '編集する',
      style: defaultlStyle,
      type: 'edit',
    })
  }
  if (tournament.status === TournamentStatus.Public ||
      tournament.status === TournamentStatus.InProgress) {
    options.push({
      title: '中止する',
      style: cancelStyle,
      type: 'cancel',
    })
  }
  const settingOption = JSON.stringify(options)

  const clickAction = (event: React.MouseEvent<HTMLElement>) => {
    const actionType = event.currentTarget.dataset.type
    if (actionType === 'detail') {
      handleClose()
      router.push(`/tournaments/${tournament.id}/detail`)
    } else if (actionType === 'cancel') {
      handleClose()
      handleModalOpen('cancel', tournament)
    } else if (actionType === 'edit') {
      handleClose()
      router.push(`/tournaments/${tournament.id}/edit`)
    }
  }

  return (
    <Setting
      options={settingOption}
      clickAction={clickAction}
      iconStyle={iconStyle}
      isOpen={isOpen}
      handleClick={handleClick}
      handleClose={handleClose}
      anchorEl={anchorEl}
    />
  )
}
