import React from 'react'

import {
  Box,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core/'

import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles'

import {
  Link,
  LinkProps,
} from '../../atoms'
import VerifiedBadge from '@/components/atoms/verified-badge'
import VerifiedAvatar from '../verified-avatar'

const useDrawerMenuListAccountItem = makeStyles(({palette, spacing}) => createStyles({
  menuListAccountItem: {
    backgroundColor: palette.contentBackground,
  },
  menuListAccountItemAvatar: {
    marginRight: spacing(3),
  },
  menuListAccountItemSubtitle: {
    color: palette.text.secondary,
    paddingTop: '4px',
  },
  menuListTicketIcon: {
    verticalAlign: 'middle',
  },
}))


type DrawerListAccountItemProps = LinkProps & {
  name: string
  src?: string
  verifiedOrganizerLevel: number
  monthlyTicketCount: number
}

export const DrawerMenuListAccountItem: React.FC<DrawerListAccountItemProps> = ({src, name, verifiedOrganizerLevel, monthlyTicketCount, ...props}) => {
  const styles = useDrawerMenuListAccountItem()
  const verifiedBadge = verifiedOrganizerLevel > 1 ? <VerifiedBadge /> : null
  return (
    <Link {...props}>
      <ListItem button className={styles.menuListAccountItem}>
        <VerifiedAvatar
          src={src}
          className={styles.menuListAccountItemAvatar}
          verifiedOrganizerLevel={verifiedOrganizerLevel}
        />
        <ListItemText disableTypography>
          <Typography variant='subtitle1'>
            {name} {verifiedBadge}
          </Typography>
          <Box display="flex" alignItems="center">
            <Box mr="6px" title="開催チケット">
              <img className={styles.menuListTicketIcon} width="16px" src="/images/ticket.svg"/>
            </Box>
            <Typography
              className={styles.menuListAccountItemSubtitle}
              variant="body1"
            > : {monthlyTicketCount}
            </Typography>
          </Box>
        </ListItemText>
      </ListItem>
    </Link>
  )
}
