import {
  Box,
  Grid, List, ListItem, ListItemText,
  Typography,
} from '@material-ui/core'
import React, {useState} from 'react'
import {
  Button,
  ClanDetailModal,
  IfLg,
  IfNotLg, LoadingError, MemberListItemGrid,
  OperationMenu,
} from '@/components'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import {useRouter} from 'next/router'
import {UserFragment, useClanQuery, useMeQuery} from '@/graphql/client'
import {LoadingPart} from '../../atoms/loading-part'
import {useSwitchUser} from '@/hooks'
import {serverConfig} from '@/env'
import {BottomSheet} from '@/components/atoms/bottom-sheet'
import Error from 'next/error'

const useDatailLayout = makeStyles(({breakpoints, palette}) =>
  createStyles({
    root: {
      [breakpoints.up('lg')]: {
        margin: 'initial',
        paddingLeft: 'calc((100% - 800px) / 2)',
        paddingRight: 'calc((100% - 800px) / 2)',
      },
    },
    gameImageWrap: {
      marginLeft: '-16px',
      marginRight: '-16px',
      marginBottom: '16px',
      [breakpoints.up('lg')]: {
        margin: 'initial',
        marginBottom: '24px',
      },
    },
    gameImage: {
      width: '100%',
      [breakpoints.up('lg')]: {
        width: '800px',
      },
    },
    clanIcon: {
      width: '60px',
      height: '60px',
      borderRadius: '4px',
    },
    operatingBtn: {
      marginRight: '8px',
      width: '89px',
      height: '33px',
    },
    managementBtn: {
      marginTop: '5px',
      width: '107px',
      height: '33px',
      [breakpoints.up('lg')]: {
        width: '117px',
      },
    },
    clandetail: {
      paddingBottom: '14px',
      fontSize: '14px',
      color: palette.text.secondary,
      [breakpoints.up('lg')]: {
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      },
    },
    spBorder: {
      paddingBottom: '24px',
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    },
    recordTitle: {
      margin: '24px 0 16px',
    },
    number: {
      color: palette.text.secondary,
    },
    applyBtn: {
      width: '117px',
      backgroundColor: palette.primary.main,
      color: '#FFFFFF',
    },
    pendingBtn: {
      backgroundColor: palette.text.disabled,
    },
    sponserIcon: {
      marginRight: '6px',
      marginBottom: '30px',
      width: '195px',
      height: '50px',
      objectFit: 'cover',
    },
    sponserLink: {
      '&:hover': {
        cursor: 'pointer',
        opacity: 0.5,
      },
    },
  })
)

export enum ClanModalTypeEnum {
  DISSOLUTION,
  LEAVE,
  INVITATION,
  INVITATION_COPY,
  INVITATION_OPTION,
  OPTION,
  SETTING,
}

export const ClanDetail = () => {
  const styles = useDatailLayout()
  const router = useRouter()
  const [open, setOpen] = useState<boolean>(false)
  const [modalType, setModalType] = useState<ClanModalTypeEnum>(ClanModalTypeEnum.OPTION)

  const clanId = router.query.id as string
  const {data: clanData, loading: clanLoading} = useClanQuery({variables: {id: clanId}})
  const {data: meData, loading: meLoading} = useMeQuery()

  // member list ----------------------------------------

  const [isOpenSheet, setIsOpenSheet] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<UserFragment|null>(null)
  const {switchUser, resetUser} = useSwitchUser()
  const switchOwner = () => switchUser(clan!.owner.id)

  const handleMemberClick = async (user: UserFragment) => {
    if (serverConfig.isPrd) {
      await handleGoProfile(user.id)
    } else {
      setSelectedUser(user)
      setIsOpenSheet(true)
    }
  }
  const handleSwitchUser = () => switchUser(selectedUser!.id)
  const handleGoProfile = (userId: string) => router.push(`/users/${userId}/profile`)

  // modal ----------------------------------------------

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleOpenWithType = (modalType: ClanModalTypeEnum) => {
    setModalType(modalType)
    handleOpen()
  }

  const handleApprove = () => handleOpenWithType(ClanModalTypeEnum.LEAVE)
  const handleDelete = () => handleOpenWithType(ClanModalTypeEnum.DISSOLUTION)

  const handleEdit = async () => await router.push(`/clan/${clanId}/edit`)
  const handleInvite = () => {
    setModalType(ClanModalTypeEnum.INVITATION_OPTION)
    handleOpen()
  }
  const handleManagement = async () => await router.push(`/clan/${clanId}/management`)

  // component ------------------------------------------

  if (clanLoading || meLoading) {
    return <LoadingPart/>
  }

  if (!clanData) {
    return <LoadingError />
  }

  const clan = clanData?.clan
  const me = meData?.me

  const allMembers: UserFragment[] = [clan!.owner].concat(clan!.members)

  if (!clan) {
    return <Error statusCode={404}/>
  }

  const isOwner = clan.owner.id === me?.id
  const isClanMember = clan.members.map(it => it.id).includes(me?.id || '0')

  return (
    <Box className={styles.root}>
      <Box display="flex" justifyContent="center" maxWidth={800} maxHeight={400} mx="auto" className={styles.gameImageWrap}>
        <img className={styles.gameImage} src={clan.coverUrl} alt="clan cover"/>
      </Box>

      <Box display="flex" maxWidth={800} mb={2}>
        <Box>
          <img src={clan.logoUrl} alt="clan logo" className={styles.clanIcon}/>
        </Box>

        <Box flexGrow={1} pl={3}>
          <Typography variant="h2">{clan.name}</Typography>
          {/*<Typography variant="subtitle1">Lv.12</Typography>*/}
        </Box>

        <IfLg>
          <Box display="flex" alignItems="center">
            {isOwner &&
              <>
                <Button outlined className={styles.operatingBtn} onClick={handleEdit}>編集する</Button>
                <Button outlined className={styles.operatingBtn} onClick={handleInvite}>招待する</Button>
                <OperationMenu type="clanDetailOwner" approvingAction={handleApprove} deleteAction={handleDelete} />
              </>
            }

            {isClanMember &&
              <OperationMenu type="clanDetailMember" approvingAction={handleApprove} deleteAction={handleDelete} />
            }
          </Box>
        </IfLg>
      </Box>

      <Typography variant="body2" className={styles.clandetail}>{clan.description}</Typography>

      <IfNotLg>
        <Box display="flex" alignItems="center" className={styles.spBorder}>
          {isOwner &&
              <>
                <Button outlined className={styles.operatingBtn} onClick={handleEdit}>編集する</Button>
                <Button outlined className={styles.operatingBtn} onClick={handleInvite}>招待する</Button>
                <OperationMenu type="clanDetailOwner" approvingAction={handleApprove} deleteAction={handleDelete} />
              </>
          }
          {isClanMember &&
            <OperationMenu type="clanDetailMember" approvingAction={handleApprove} deleteAction={handleDelete} />
          }
        </Box>
      </IfNotLg>

      {/*<Typography variant="h4" className={styles.recordTitle}>戦績</Typography>*/}
      {/*<BattleRecord />*/}

      <Grid container justify="space-between" alignItems="center">
        <Typography variant="h4" className={styles.recordTitle}>メンバー <span className={styles.number}>({clan.memberNum})</span></Typography>
        {isOwner &&
          <Button outlined className={styles.managementBtn} onClick={handleManagement}>メンバー管理</Button>
        }
      </Grid>

      {!serverConfig.isPrd &&
        <>
          <Button onClick={switchOwner} outlined fullWidth style={{marginBottom: 8}}>クランマスターに切り替え</Button>
          <Button onClick={resetUser} outlined fullWidth style={{marginBottom: 8}}>元のユーザーに戻る</Button>
        </>
      }

      <MemberListItemGrid
        clanOwnerId={clan.owner.id}
        users={allMembers}
        clanUserRoles={clan.clanUserRoles}
        handleClick={handleMemberClick}
      />

      {/*<Typography variant="h4">スポンサー</Typography>*/}

      <ClanDetailModal
        clan={clan}
        handleClose={handleClose}
        modalType={modalType}
        setModalType={setModalType}
        open={open}
      />

      {/* 開発用ユーザー切り替え機能 */}
      <BottomSheet open={isOpenSheet} onClose={() => setIsOpenSheet(false)}>
        <List component="nav" aria-label="secondary mailbox folders">
          <ListItem button onClick={handleSwitchUser}>
            <ListItemText primary="このユーザーに切り替え"/>
          </ListItem>
          <ListItem button onClick={() => { handleGoProfile(selectedUser!.id) }}>
            <ListItemText primary="プロフィールを見る"/>
          </ListItem>
        </List>
      </BottomSheet>
    </Box>
  )
}
