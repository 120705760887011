import {Button as ButtonBase} from 'components/atoms/button'
import {Typography, createStyles, makeStyles} from '@material-ui/core'
import {ReactElement, useEffect, useState} from 'react'
import {useRouter} from 'next/router'
import {rsoConfig} from '@/env/rso'
import {useMyRiotIdQuery} from '@/graphql/client'
import {Link, LoadingPart} from '../atoms'

const useStyles = makeStyles(({spacing, palette}) =>
  createStyles({
    button: {
      padding: spacing(4),
      paddingTop: spacing(2.5),
      paddingBottom: spacing(2.5),
      // padding'10px 16px',
      backgroundColor: '#d1363a',
      '&:hover': {
        backgroundColor: '#d1363a',
      },
    },
    link: {
      color: palette.primary.main,
    },
  })
)
export const Button = (): ReactElement => {
  const styles = useStyles()
  const [popup, setPopup] = useState<Window|null>()
  const router = useRouter()
  const {data, loading, refetch} = useMyRiotIdQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() =>{
    if (popup == null) return
    const pollTimer = setInterval(() => {
      if (popup.closed) {
        refetch()
        clearInterval(pollTimer)
      }
    }, 1000)

    return () => {clearInterval(pollTimer)}
  }, [popup, refetch])

  if (router == null || window == null) return <></>
  if (loading) {
    return <LoadingPart padding={0} />
  }

  const riotOauthUrl = 'https://auth.riotgames.com/authorize'
  const client_id = rsoConfig.clientId
  const redirect_uri = window.location.host === 'localhost:14000'
    ? 'http://app-dev.adictor.jp/oauth/callback/rso'
    : window.location.protocol + '//' + window.location.host + '/oauth/callback/rso'
  const scope = 'openid offline_access'
  const oauthUrl = riotOauthUrl +
    `?client_id=${client_id}` +
    `&redirect_uri=${encodeURIComponent(redirect_uri)}`+
    '&response_type=code'+
    `&scope=${encodeURIComponent(scope)}`

  if (data?.me.riotId == null) {
    return (
      <ButtonBase
        className={styles.button}
        onClick={() => { setPopup(window.open(oauthUrl)) }}
      >
        <Typography variant="subtitle2">Riot ID 連携</Typography>
      </ButtonBase>
    )
  }

  return (
    <>
      {data.me.riotId}
      <br />
      {/*
        連携済みのアカウントでログインしていると、再連携を押しても認可画面がスキップされる
        &prompt=login で認証画面を強制表示させることで、ログイン ID を切り替えやすいようにする
        Riot のドキュメントに乗っていないパラメータなので、問題が出た場合は外すこと
      */}
      (<Link className={styles.link} href='#' onClick={() => { setPopup(window.open(oauthUrl + '&prompt=login')) }}>別のアカウントを連携する</Link>)
    </>
  )
}
