import React, {useState} from 'react'
import {
  CoinExchangeRequestInput,
  CoinExchangeType,
  useCoinExchangeRequestMutation,
} from '@/graphql/client'
import {useSnackbar} from '@/hooks/acknowledge'
import {useErrorReport} from '@/hooks/error'
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import {Controller, useForm} from 'react-hook-form'

const useSelectBoxStyle = makeStyles(() => ({
  root: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 14px',
  },
  icon: {
    fill: 'rgba(255, 255, 255, 0.9)',
  },
}))

const useCoinExchangeModalStyle = makeStyles(({breakpoints, palette}) =>
  createStyles({
    modalBase: {
      borderRadius: '4px',
      position: 'absolute',
      width: 'calc(100vw - 20px)',
      height: 'calc(100vh * 0.95)',
      maxWidth: '444px',
      top: '50%',
      left: '50%',
      background: '#232323',
      transform: 'translate(-50%, -50%)',
      outline: 0,
      overflowY: 'auto',
      [breakpoints.up('lg')]: {
        width: '444px',
        height: 'auto',
        maxHeight: 'calc(100vh * 0.95)',
      },
    },
    text: {
      fontSize: '15px',
    },
    modalHeader: {
      fontSize: '18px',
      lineHeight: '27px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.005em',
      padding: '18px 16px',
      margin: 0,
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      justifyContent: 'space-between',
    },
    modalCloseBtn: {
      border: '0 none',
      margin: '0',
      padding: '0',
      background: 'none',
      outline: 'none',
      cursor: 'pointer',
    },
    modalContents: {
      padding: '41px 16px',
    },
    holdingCoin: {
      marginBottom: '41px',
    },
    itemLabel: {
      marginBottom: '9px',
    },
    form: {
      width: '100%',
      marginBottom: '32px',
    },
    inputEmai: {
      width: '100%',
      height: '40px',
      marginBottom: '32px',
    },
    inputAutoEmai: {
      padding: '0px',
      borderRadius: 'none !important',
      '-webkit-box-shadow': '0 0 0 40px rgb(35, 35, 35) inset !important',
      '-webkit-text-fill-color': 'rgba(255,255,255,0.9)',
    },
    notice: {
      marginBottom: '10px',
      fontSize: '12px',
      color: 'rgba(85, 195, 65, 1)',
      lineHeight: '17.76px',
    },
    disclosure: {
      marginBottom: '40px',
      fontSize: '12px',
      color: palette.text.secondary,
    },
  })
)

const exchangeTypes = [
  {name: 'Amazonギフト券', value: CoinExchangeType.Amazon},
  {name: 'Amazonギフト券', value: CoinExchangeType.LoglyAmazon},
  // #1119: CoinExchangeType.Itunes は提供終了
  {name: 'Google Play ギフトコード', value: CoinExchangeType.GooglePlay},
  {name: 'Apple Gift Card', value: CoinExchangeType.AppleGift},
]

type CoinExchangeModalProps = {
  coin: number
  handleClose: () => void
  open: boolean
  refetchWallet: () => void
  refetchMyCoinExchange: () => void
}

type ExchangeService = 'degico' | 'logly' | ''

export const CoinExchangeModal: React.FC<CoinExchangeModalProps> = ({coin, handleClose, open, refetchWallet, refetchMyCoinExchange}) => {
  const styles = useCoinExchangeModalStyle()
  const selectStyles = useSelectBoxStyle()
  const {snackbar} = useSnackbar()
  const {errorReport} = useErrorReport()

  const [coinExchangeRequest, {loading: mutationLoading}] = useCoinExchangeRequestMutation()
  const {register, handleSubmit, watch, errors, control, formState, setValue} = useForm<CoinExchangeRequestInput>({
    mode: 'onChange',
    defaultValues: {},
  })
  const watchFields = watch(['exchangeType', 'jpy'])

  const [exchangeSerivce, setExchangeService] = useState<ExchangeService>('')

  const onSubmit = async (value: any) => {
    const params = {
      jpy: Number(value.jpy),
      emailAddress: value.emailAddress,
      exchangeType: value.exchangeType,
    }
    try {
      await coinExchangeRequest({variables: {input: {...params}}})
      refetchMyCoinExchange()
      refetchWallet()
      snackbar.success('ACの交換リクエストに成功しました')
      handleClose()
    } catch (e) {
      snackbar.failed('ACの交換リクエストに失敗しました', e)
      errorReport.captureException(e)
    }
  }

  const deliminateNumber = (coin: number) => {
    return String(coin).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
  }

  const handleExchangeServiceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setExchangeService(event.target.value as ExchangeService)
    setValue('exchangeType', '', {shouldValidate: true})
  }

  const getExchangeTypes = () => {
    if (exchangeSerivce === 'logly') {
      return exchangeTypes.filter(it => it.value === CoinExchangeType.LoglyAmazon)
    } else {
      return exchangeTypes.filter(it => it.value !== CoinExchangeType.LoglyAmazon)
    }
  }

  const getExchangeAmounts = (exchangeType: CoinExchangeType) => {
    switch (exchangeType) {
      case CoinExchangeType.Amazon:
        return [500, 1000, 3000, 5000, 10000]
      case CoinExchangeType.Itunes:
        return [500, 1000, 2000]
      case CoinExchangeType.GooglePlay:
        return [500, 1000, 3000, 5000, 10000]
      case CoinExchangeType.AppleGift:
        return [500, 1000, 2000, 3000, 5000, 10000]
      default:
        return []
    }
  }

  const getDegicoExchangeAmountLabel = (amount: number): string => {
    return `${amount}円 (${deliminateNumber(Math.floor(amount * 1.1))}AC)`
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={styles.modalBase}>
        <h2 className={styles.modalHeader}>
          <span>ACを交換する</span>
          <button type={'button'} className={styles.modalCloseBtn} onClick={handleClose}><img src='/images/close.svg' /></button>
        </h2>
        <Box className={styles.modalContents}>
          <Grid container justify="space-between" className={styles.holdingCoin}>
            <Grid item className={styles.text}>
              現在の保有コイン
            </Grid>
            <Grid item className={styles.text}>
              {deliminateNumber(coin)} AC
            </Grid>
          </Grid>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="subtitle1" color="textPrimary" className={styles.itemLabel}>
              交換所
            </Typography>
            <Select
              variant="outlined"
              className={styles.form}
              inputProps={{classes: {root: selectStyles.root, icon: selectStyles.icon}}}
              onChange={handleExchangeServiceChange}
            >
              <MenuItem value={'degico'}>デジコ（手数料10% / 3営業日内発行可)</MenuItem>
              <MenuItem value={'logly'}>ログリー (手数料0% / 毎月末1回のみ発行可)</MenuItem>
            </Select>

            <Typography variant="subtitle1" color="textPrimary" className={styles.itemLabel}>
              交換先
            </Typography>
            <FormControl variant="outlined" className={styles.form}>
              <Controller
                as={
                  <Select
                    inputProps={{classes: {root: selectStyles.root, icon: selectStyles.icon}}}
                  >
                    {getExchangeTypes().map((item, index) => {
                      return (
                        <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
                      )
                    })}
                  </Select>
                }
                inputRef={register}
                name='exchangeType'
                rules={{required: true}}
                control={control}
              />
            </FormControl>

            <Typography variant="subtitle1" color="textPrimary" className={styles.itemLabel}>
              発券希望金額
            </Typography>

            {/* デジコは選択式 */}
            {exchangeSerivce === 'degico' && (
              <FormControl variant="outlined" className={styles.form}>
                <Controller
                  as={
                    <Select displayEmpty inputProps={{classes: {root: selectStyles.root, icon: selectStyles.icon}}}>
                      {getExchangeAmounts(watchFields.exchangeType).map((it, index) => {
                        return (
                          <MenuItem value={it} key={index}>{getDegicoExchangeAmountLabel(it)}</MenuItem>
                        )
                      })}
                    </Select>
                  }
                  inputRef={register}
                  error={Boolean(errors.jpy)}
                  name='jpy'
                  rules={{required: true}}
                  control={control}
                />
              </FormControl>
            )}

            {/* ログリーは入力式 */}
            {exchangeSerivce === 'logly' && (
              <FormControl variant="outlined" className={styles.form}>
                <TextField
                  inputRef={register({required: true, max: 100000, min: 500})}
                  error={Boolean(errors.jpy)}
                  name="jpy"
                  placeholder="500円~100,000円"
                  fullWidth
                  margin="dense"
                  inputProps={{style: {height: '40px', padding: '0 14px'}}}
                  type="number"
                  helperText="500〜100,000の間で指定可能です"
                />
              </FormControl>
            )}

            <Typography variant="subtitle1" color="textPrimary" className={styles.itemLabel}>
              メールアドレス
            </Typography>
            <TextField
              name="emailAddress"
              inputRef={register({required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i})}
              error={Boolean(errors.emailAddress)}
              placeholder="メールアドレス"
              InputProps={{
                style: {height: '40px', padding: '0 14px'},
                classes: {input: styles.inputAutoEmai},
              }}
              className={styles.inputEmai}
            />

            {exchangeSerivce === 'logly' &&
              <Typography className={styles.notice}>
                ※交換リクエストが承認されましたら、メールでご連絡します。<br />※ログリー交換所はAmazonギフト券のみ対応しています。<br />
                ※毎月末に当月のリクエストに対して発行処理を行います。<br />※同月内に複数回リクエストすることはできません。
              </Typography>
            }

            {exchangeSerivce === 'degico' &&
            <Typography className={styles.notice}>
                ※交換リクエストが承認されましたら、メールでご連絡します。<br />※ACは「デジコ」にて発券し、その後ご希望の券種に引き換えとなります。<br />
                ※「デジコ」は各種電子マネーに交換できるデジタルギフトコードです。<br />※1AC＝1デジコポイントとなります。
            </Typography>
            }

            <Typography className={styles.disclosure}>
              ※Amazon、Amazon.co.jp およびそれらのロゴは Amazon.com, Inc.またはその関連会社の商標です。<br />
              ※Google Play および Google Play ロゴは Google LLC の商標です。<br />
              ※Apple Gift Cardは、米国およびその他の国で登録されたApple Inc.の商標です。
            </Typography>

            <Box display='flex' justifyContent='center'>
              <Button type="submit" variant="contained" color="primary" style={{width: '89px', height: '33px'}} disabled={!formState.isValid || mutationLoading}>交換する</Button>
            </Box>
          </form>
        </Box>
      </div>
    </Modal>
  )
}
