import React from 'react'
import {Button, ButtonProps} from '@material-ui/core'

type DashboardButtonProps = {
  handleClick: () => void
} & ButtonProps

export const DashboardButton: React.FC<DashboardButtonProps> = (props) => {
  return (
    <Button
      className={props.className}
      classes={props.classes}
      variant="contained"
      color='primary'
      size="large"
      onClick={props.handleClick}
      disabled={props.disabled}
    >
        管理画面を開く
    </Button>
  )
}
