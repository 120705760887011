import React, {createContext, useContext, useState} from 'react'

export type AuthenticatedType = 'NotInitialized' | 'Unauthenticated' | 'Authenticated'

export type ClientState = {
  authState: AuthenticatedType
  setAuthState: (auth: AuthenticatedType) => void
}

const ClientStateContext = createContext<ClientState>({
  authState: 'NotInitialized',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAuthState: (auth: AuthenticatedType) => {},
})

export const ClientStateProvider: React.FC = ({children}) => {
  const [authState, setAuthState] = useState<AuthenticatedType>('NotInitialized')

  return (
    <ClientStateContext.Provider value={{authState, setAuthState}}>{children}</ClientStateContext.Provider>
  )
}

export const useClientStateContext = () => useContext<ClientState>(ClientStateContext)
