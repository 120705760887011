import React from 'react'
import {
  TournamentDetailQuery,
  useMeQuery,
  useTournamentAnnouncementAddMutation, useTournamentAnnouncementRemoveMutation,
  useTournamentAnnouncementsQuery,
} from '@/graphql/client'
import {Box} from '@material-ui/core/'
import MessageEmptyState from '@/components/atoms/message-empty-state'
import {Announcement, AnnouncementField, LoadingPart} from '@/components'
import {useSnackbar} from '@/hooks/acknowledge'

type TournamentAnnouncementsProps = {
  tournament: TournamentDetailQuery['tournament']
}

export const TournamentAnnouncementsContent = ({tournament}: TournamentAnnouncementsProps) => {
  const {snackbar} = useSnackbar()

  const [tournamentAnnouncementAdd] = useTournamentAnnouncementAddMutation()
  const onSubmit = async (draft: string) => {
    try {
      await tournamentAnnouncementAdd({variables: {input: {body: draft, tournamentId: tournament.id}}})
    } catch (e) {
      snackbar.failed('投稿に失敗しました', e)
    }
    await refetch()
  }

  const [tournamentAnnouncementRemove] = useTournamentAnnouncementRemoveMutation()
  const handleDelete = async (id: string) => {
    try {
      await tournamentAnnouncementRemove({variables: {input: {id}}})
    } catch (e) {
      snackbar.failed('削除に失敗しました', e)
    }
    await refetch()
  }

  const {data: meData, loading: meLoading, error: meError} = useMeQuery()
  const {data, loading, error, refetch} = useTournamentAnnouncementsQuery({
    variables: {tournamentId: tournament.id},
  })
  const currentUserId = meData?.me.id

  const errorCodes = error?.graphQLErrors?.map(e => e.extensions?.code)
  if (errorCodes?.find(e => ['tournament/not_entered', 'common/unauthorized'].includes(e))) {
    return (
      <Box mt={5} maxWidth={800} mx="auto">
        <MessageEmptyState message='アナウンスは主催者・参加者のみ確認することができます。' />
      </Box>
    )
  }
  if (loading || error || !data || meLoading || meError || !currentUserId) return <LoadingPart />

  const announcements = data.tournamentAnnouncement.nodes
  if (!announcements || announcements.length === 0) {
    return (
      <>
        <Box mt={5} maxWidth={800} mx="auto">
          <MessageEmptyState message='まだアナウンスは投稿されていません。' />
        </Box>
        <AnnouncementField onSubmit={onSubmit} />
      </>
    )
  }

  return (
    <>
      <AnnouncementField onSubmit={onSubmit} />
      {announcements.map((announcement, index) => {
        if (!announcement) return null
        return (
          <Announcement
            tournament={tournament}
            announcement={announcement}
            key={index}
            currentUserId={currentUserId}
            onDelete={handleDelete}
          />
        )
      })}
    </>
  )
}
