import React from 'react'

type LogoType = 'default' | 'white'

const logoSrcMap: Record<LogoType, string> = {
  default: '/images/logo.svg',
  white: '/images/logo-white.svg',
}

type LogoProps = Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'src'> & {
  type?: LogoType
}

export const Logo: React.FC<LogoProps> = ({type='default', ...props}) => {

  return (
    <img src={logoSrcMap[type]} height={41} width={141} {...props} />
  )

}
