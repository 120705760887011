import React from 'react'

import {
  IconButton,
  Toolbar,
} from '@material-ui/core/'

import MenuIcon from '@material-ui/icons/Menu'

import {
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles'

import {
  AppBar,
  Button,
  Link,
  Logo,
} from '../../atoms'
import {useAuth0} from '@auth0/auth0-react'
import {useMeQuery} from '@/graphql/client'
import {User} from '@sentry/types'
import {useClientStateContext} from '@/hooks/apollo'

const useStyles = makeStyles<Theme, {drawerWidth: number}>(({breakpoints, spacing}) =>
  createStyles({
    hiddenLg: {
      [breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    toolBar: {
      height: '100%',
      justifyContent: 'space-between',
    },
    loginButton: {
      marginRight: spacing(2),
    },
  }),
)

type MobileAppBarProps = {
  drawerWidth?: number
  onToggleDrawer?: () => void
}

export const MobileAppBar: React.FC<MobileAppBarProps> = ({onToggleDrawer, drawerWidth=250}) => {

  const styles = useStyles({drawerWidth})

  const {authState} = useClientStateContext()
  const {data} = useMeQuery({
    skip: authState !== 'Authenticated',
  })
  const me: User | undefined = data?.me

  const {loginWithRedirect} = useAuth0()
  const handleLogin = () => loginWithRedirect({appState: {returnTo: window.location.pathname}})

  return (
    <AppBar position="fixed" className={styles.hiddenLg}>
      <Toolbar className={styles.toolBar}>
        <Link href='/'>
          <Logo />
        </Link>
        {me ? (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={onToggleDrawer}
            className={styles.hiddenLg}
          >
            <MenuIcon fontSize='large' />
          </IconButton>
        ) : (
          <Button className={styles.loginButton} outlined onClick={handleLogin}>
              ログイン
          </Button>
        )}
      </Toolbar>
    </AppBar>
  )

}
