import React from 'react'
import {TournamentStatus, TournamentUnitType} from '@/graphql/client'

import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core'

import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles'

import UsersIcon from '@material-ui/icons/PeopleOutlined'

import {
  Avatar,
  Link,
  Medal,
  MedalBackgroundColor,
} from '../atoms'

import {
  TournamentTitle,
} from './tournament-title'

import {
  composeClassNames, getMemberCountByUnit, unitTypeSuffix,
} from '@/utils'

type TournamentCardProps = {
  id: string
  title: string
  time: string
  coverPreviewUrl?: string
  status: TournamentStatus
  unitType: TournamentUnitType
  openTournamentType?: string | null
  participants?: {
    current: number
    max: number
  }
  sponsorImage?: string
  sponsorLandingUrl?: string
  verifiedOrganizerLevel: number
}

const useTournamentCardStyles = makeStyles(({palette, spacing}) => createStyles({
  root: {
    width: 312,
    height: 278,
    backgroundColor: palette.contentBackground,
  },
  media: {
    height: 156,
  },
  content: {
    height: 74,
    overflow: 'hidden',
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
  timeText: {
    color: palette.text.secondary,
  },
  actions: {
    height: 48,
    justifyContent: 'space-between',
    padding: 0, // default - 8px
    '&> *:not(:first-child)': {marginLeft: 0}, // override
  },
  action: {
    borderRadius: 0,
    height: 48,
    flexGrow: 1,
    justifyContent: 'left',
    padding: '14px 16px', // default + 8px
  },
  actionSponsor: {
    borderRadius: 0,
    justifyContent: 'right',
    padding: '12px 16px', // default + 8px
  },
  actionItem: {
    display: 'flex',
    flexDirection: 'row',
  },
  participantsIcon: {
    color: palette.text.disabled,
    marginRight: spacing(2),
    width: '19.2px',
    height: '19.2px', // == line-height (12px * 1.6)
  },
  participantsText: {
    color: palette.text.secondary,
    fontSize: '12px',
    '&> .unit': {
      fontSize: '10px',
      marginLeft: '0.3em',
    },
  },
  sponsorIcon: {
    width: 24,
    height: 24,
  },
  sponsorText: {
    color: palette.text.secondary,
    marginRight: spacing(2),
    fontSize: '12px',
    lineHeight: '24px', // == sponsorIcon.height
  },
  medalWrapper: {
    position: 'relative',
  },
}))

export const TournamentCard: React.FC<TournamentCardProps> = ({id, title, time, status, unitType, openTournamentType, coverPreviewUrl, participants, sponsorImage, sponsorLandingUrl, verifiedOrganizerLevel}) => {

  const styles = useTournamentCardStyles()
  const medal = verifiedOrganizerLevel > 1 ? <Medal background={MedalBackgroundColor.GRAD_RED} text={'認定大会'}/> : null

  const sponsorItem = sponsorImage && (
    <div className={styles.actionItem}>
      <Typography className={styles.sponsorText}>
        Sponsored by
      </Typography>
      <Avatar src={sponsorImage} className={styles.sponsorIcon} />
    </div>
  )

  const participantsTabPath = openTournamentType ? 'formed-teams' : unitType === TournamentUnitType.Single ? 'participant' : 'teams'

  return (
    <Card className={composeClassNames(styles.root)} variant='outlined'>
      <Link href='/tournaments/[id]/detail' as={`/tournaments/${id}/detail`}>
        <CardActionArea>
          <Box className={styles.medalWrapper}>
            <CardMedia
              className={styles.media}
              image={coverPreviewUrl}
            />
            {medal}
          </Box>
          <CardContent className={styles.content}>
            <TournamentTitle status={status} time={time}>
              {title}
            </TournamentTitle>
          </CardContent>
        </CardActionArea>
      </Link>
      <CardActions className={styles.actions}>
        <Button href={`/tournaments/${id}/${participantsTabPath}`} className={styles.action}>
          {participants ? (
            <div className={styles.actionItem}>
              <UsersIcon className={styles.participantsIcon} />
              <Typography className={styles.participantsText}>
                {participants.current} / {openTournamentType ? participants.max * getMemberCountByUnit(unitType) :participants.max}
                <span className='unit'> { openTournamentType? '人' :unitTypeSuffix(unitType)}</span>
              </Typography>
            </div>
          ) : <div />}
        </Button>
        {sponsorItem && (
          sponsorLandingUrl
            ? <Button href={sponsorLandingUrl} target='_blank' rel='noopener' className={styles.actionSponsor}>{sponsorItem}</Button>
            : sponsorItem
        )}
      </CardActions>
    </Card>
  )

}
