import {ClanUserRoleFragment, RoleFragment, UserFragment} from '@/graphql/client'
import React from 'react'

import {
  GridList,
  GridListProps,
  GridListTile,
  useTheme,
} from '@material-ui/core'

import {
  MemberListItem,
} from './member-list-item'

type MemberListItemGridProps = GridListProps & {
  clanOwnerId: string
  users: UserFragment[]
  clanUserRoles: ClanUserRoleFragment[]
  handleClick?: (user: UserFragment) => void
}

export const MemberListItemGrid: React.FC<MemberListItemGridProps> = ({clanOwnerId, users, clanUserRoles, style, ...props}) => {
  const {spacing} = useTheme()

  const dispatchHandleClick = (id: string) => {
    const user = users.find((it) => it.id === id)
    if (user) {
      props.handleClick?.(user)
    }
  }

  const getRole = (user: UserFragment): RoleFragment|undefined =>
    clanUserRoles.find(it => it.user.id === user.id)?.role

  return (
    <GridList
      cellHeight={80}
      cols={1}
      spacing={spacing(2)}
      style={{marginTop: 0, ...style}}
      {...props}
    >
      {users.map((user, index) => (
        <GridListTile key={index}>
          <MemberListItem
            user={user}
            role={getRole(user)}
            isOwner={user.id === clanOwnerId}
            handleClick={dispatchHandleClick}
          />
        </GridListTile>
      ))}
    </GridList>
  )
}
