import React from 'react'
import {Modal, Paper, makeStyles} from '@material-ui/core'
import {TeamEntryContent} from '@/components/organisms/tournament-team/team-entry-content'
import {Box, Divider, Typography} from '@material-ui/core/'
import CloseIcon from '@material-ui/icons/Close'
import {
  TournamentTeamEntryAddInput,
  TournamentTeamEntryUpdateInput,
  TournamentTeamFragment,
  TournamentUnitType,
  useTournamentDetailQuery,
  useTournamentEntryStatusLazyQuery,
  useTournamentTeamEntryAddMutation,
  useTournamentTeamEntryUpdateMutation,
} from '@/graphql/client'
import {useSnackbar} from '@/hooks/acknowledge'

const useTeamEntryModalStyles = makeStyles(({spacing}) => ({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: 'calc(100vw - 20px)',
    maxWidth: 600,
  },
  header: {
    height: '65px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: spacing(4),
    marginRight: spacing(4),
  },
  content: {
    margin: spacing(4),
    maxHeight: 'calc(100vh * 0.8)',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
}))

type TeamEntryModalProps = {
  open: boolean
  onClose: () => void
  mode: TeamEntryFormType
  team?: TournamentTeamFragment
  tournamentId: string // 大会エントリーに必要
  gameId: string // 経験値の表示に必要
  unitType: TournamentUnitType // 人数の制限に必要
}

export type TeamEntryFormType = 'new' | 'edit'

export const TeamEntryModal: React.FC<TeamEntryModalProps> = ({mode='new', ...props}) => {
  const styles = useTeamEntryModalStyles()
  const {open, team, onClose, tournamentId, gameId, unitType} = props
  const {snackbar} = useSnackbar()

  const [entryAdd] = useTournamentTeamEntryAddMutation()
  const [entryUpdate] = useTournamentTeamEntryUpdateMutation()
  const [getEntryStatus] = useTournamentEntryStatusLazyQuery({
    fetchPolicy: 'network-only',
    variables: {tournamentId: tournamentId},
  })
  const {refetch: fetchTournament} = useTournamentDetailQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {id: tournamentId},
  })

  const editMode = mode === 'edit'

  const handleSubmit = async (clanId: string, memberIds: string[], managerIds: string[]) => {
    if (editMode) {
      await handleEntryUpdate(memberIds, managerIds)
    } else {
      await handleEntryAdd(clanId, memberIds, managerIds)
    }
    getEntryStatus()
  }

  const handleEntryAdd = async (clanId: string, memberIds: string[], managerIds: string[]) => {
    const input: TournamentTeamEntryAddInput = {
      tournamentId: tournamentId,
      clanId: clanId,
      userIds: memberIds,
      managerIds: managerIds,
    }
    try {
      await entryAdd({variables: {input}})
      await fetchTournament()
      onClose()
      snackbar.success('エントリーしました')
    } catch (e) {
      snackbar.failed('エントリーに失敗しました', e)
    }
  }

  const handleEntryUpdate = async (memberIds: string[], managerIds: string[]) => {
    if (!team) {
      return
    }

    const input: TournamentTeamEntryUpdateInput = {
      tournamentTeamId: team.id,
      userIds: memberIds,
      managerIds: managerIds,
    }
    try {
      await entryUpdate({variables: {input}})
      await fetchTournament()
      onClose()
      snackbar.success('エントリーを更新しました')
    } catch (e) {
      snackbar.failed('エントリーの更新に失敗しました', e)
    }
  }

  return (
    <Modal
      className={styles.modal}
      open={open}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
    >
      <Paper className={styles.container}>
        <Box className={styles.header}>
          <Typography variant={'h3'}>エントリーフォーム</Typography>
          <Box flexGrow={1}/>
          <CloseIcon onClick={onClose}/>
        </Box>
        <Divider/>
        <Box className={styles.content}>
          <TeamEntryContent mode={mode} team={team} gameId={gameId} unitType={unitType} onSubmit={handleSubmit}/>
        </Box>
      </Paper>
    </Modal>
  )
}
