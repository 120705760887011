import React from 'react'

import {
  Box,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'

import {
  Button,
} from '../../../components/atoms'

const useDiscordLinkStyle = makeStyles(() =>
  createStyles({
    discordLink: {
      display: 'block',
      background: '#1E1E1E',
      borderRadius: '4px',
      marginBottom: '24px',
      padding: '17px 24px',
    },
    discordLinkDescription: {
      fontSize: '14px',
      marginBottom: '18px',
    },
    discordLinkButton: {
      display: 'flex',
      justifyContent: 'center',
    },
    discordLinkButtonJoin: {
      border: '1px solid #55C341',
      background: 'none',
      color: '#55C341',
      fontSize: '14px',
      fontWeight: 'bold',
    },
  })
)

type TournamentBattleRoomDiscordLinkProps = {
  contactDiscord: string
}

export const TournamentBattleRoomDiscordLink: React.FC<TournamentBattleRoomDiscordLinkProps> = ({...props}) => {
  const styles = useDiscordLinkStyle()

  return (
    <>
      <Box className={styles.discordLink}>
        <Box className={styles.discordLinkDescription}>
          <Typography>大会中に困ったことが起きたら主催者に相談してみましょう！</Typography>
        </Box>
        <Box className={styles.discordLinkButton}>
          <a href={props.contactDiscord} target='_blank' rel='noreferrer' style={{textDecoration: 'none'}}>
            <Button className={styles.discordLinkButtonJoin}>Discordに参加</Button>
          </a>
        </Box>
      </Box>
    </>
  )
}
