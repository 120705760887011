import React from 'react'

import {
  Theme,
  Typography,
  TypographyProps,
  createStyles,
  makeStyles,
} from '@material-ui/core'

import {
  composeClassNames,
} from '@/utils'

type TitleProps = TypographyProps & {
  noMargin?: boolean
}

const useTitleStyles = makeStyles<Theme, {variant: string, noMargin: boolean}>(({spacing}) => createStyles({
  root: ({variant, noMargin}) => {
    if (noMargin) return {}
    if (['h1', 'h2'].includes(variant)) {
      return {
        marginBottom: spacing(6),
      }
    }
    return {
      marginBottom: spacing(4),
    }
  },
}))

export const Title: React.FC<TitleProps> = ({variant='h1', noMargin=false, className, ...props}) => {

  const styles = useTitleStyles({variant, noMargin})

  return (
    <Typography variant={variant} className={composeClassNames(className, styles.root)} {...props} />
  )

}
