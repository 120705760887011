import {Box, BoxProps, Typography, createStyles, makeStyles} from '@material-ui/core'
import React from 'react'

const useGameItemStyles = makeStyles(({spacing}) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    borderRadius: 4,
    width: 32,
    height: 32,
    marginRight: spacing(2),
  },
  text: {
    marginRight: spacing(3),
  },
}))

type GameItemProps = {
  iconUrl: string
  title: string
} & BoxProps

export const GameItem: React.FC<GameItemProps> = ({title, iconUrl, ...props}) => {
  const styles = useGameItemStyles()
  return (
    <Box className={styles.container} {...props}>
      <img className={styles.image} src={iconUrl} alt={title}/>
      <Typography variant='subtitle2' className={styles.text}>{title}</Typography>
    </Box>
  )
}
