import React, {useState} from 'react'
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import {ClanModalTypeEnum, OperationMenu} from '@/components'
import {useSnackbar} from '@/hooks/acknowledge'
import CopyToClipboard from 'react-copy-to-clipboard'
import {
  ClanArchiveInput,
  ClanDetailFragment,
  ClanExitInput,
  ClanInviteLinkAddInput,
  ClanInviteLinkFragment,
  ClanInviteLinkRemoveInput,
  useClanArchiveMutation,
  useClanExitMutation,
  useClanInviteLinkAddMutation,
  useClanInviteLinkRemoveMutation,
  useClanInviteLinksQuery,
} from '@/graphql/client'
import {useRouter} from 'next/router'

const useSelectBoxStyle = makeStyles(() => ({
  root: {
    width: '200px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 14px',
    borderRadius: '4px',
    '&:focus': {
      borderRadius: '4px',
    },
  },
  icon: {
    fill: 'rgba(255, 255, 255, 0.9)',
  },
}))

const useClanDetailModalStyle = makeStyles(({breakpoints, palette}) =>
  createStyles({
    modalBase: {
      borderRadius: '4px',
      position: 'absolute',
      width: 'calc(100vw - 20px)',
      maxWidth: '444px',
      maxHeight: 'calc(100vh * 0.95)',
      top: '50%',
      left: '50%',
      background: '#232323',
      transform: 'translate(-50%, -50%)',
      outline: 0,
      overflowY: 'auto',
      [breakpoints.up('lg')]: {
        width: '600px',
        maxWidth: 'unset',
      },
    },
    modalHeader: {
      fontSize: '18px',
      lineHeight: '27px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.005em',
      padding: '18px 16px',
      margin: 0,
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      justifyContent: 'space-between',
    },
    modalCloseBtn: {
      border: '0 none',
      margin: '0',
      padding: '0',
      background: 'none',
      outline: 'none',
      cursor: 'pointer',
    },
    modalBody: {
      padding: '32px 16px 40px 16px',
    },
    btnWrap: {
      marginTop: '59px',
    },
    canselBtn: {
      marginRight: '16px',
      width: '115px',
      height: '44px',
      color: '#6C6C6C',
      border: '1px solid #6C6C6C',
      fontSize: '14px',
    },
    redBtn: {
      width: '115px',
      height: '44px',
      background: '#F44336',
      fontSize: '14px',
    },
    greenBtn: {
      width: '115px',
      height: '44px',
      background: '#55C341',
      fontSize: '14px',
    },
    invitationBtn: {
      width: '171px',
      height: '44px',
      border: '1px solid rgba(85, 195, 65, 0.5)',
      color: '#55C341',
      fontSize: '14px',
    },
    copyBtn: {
      width: '157px',
      height: '44px',
      background: '#55C341',
      fontSize: '14px',
    },
    contentsTitle: {
      marginTop: '32px',
    },
    invitationUrl: {
      marginTop: '4px',
      color: 'rgba(255, 255, 255, 0.6)',
    },
    optionBtn: {
      display: 'block',
      margin: '10px auto 0',
      height: '44px',
      fontSize: '14px',
      border: 'none',
      color: 'rgba(255, 255, 255, 0.6)',
    },
    container: {
      margin: '16px auto 0',
      width: 'calc(100vw - 40px)',
      [breakpoints.up('lg')]: {
        margin: 'initial',
        marginTop: '16px',
        width: '568px',
      },
    },
    table: {
      overflowX: 'scroll',
      whiteSpace: 'nowrap',
      [breakpoints.up('lg')]: {
        width: '568px',
      },
    },
    tablehead: {
      color: palette.text.secondary,
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    },
    border: {
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    tablecontent: {
      paddingTop: '17px',
      paddingBottom: '17px',
      verticalAlign: 'middle',
      color: palette.text.secondary,
      borderBottom: 'none',
    },
    formList: {
      marginBottom: '8px',
      fontSize: '14px',
    },
  })
)

export type ClanDetailModalProps = {
  clan: ClanDetailFragment
  handleSubmit?: () => void
  handleClose: () => void
  open: boolean
  modalType: ClanModalTypeEnum
  setModalType: React.Dispatch<React.SetStateAction<ClanModalTypeEnum>>
}

export const ClanDetailModal: React.FC<ClanDetailModalProps> = ({...props}) => {
  const styles = useClanDetailModalStyle()
  const selectStyles = useSelectBoxStyle()
  const {clan, handleClose, modalType, setModalType, open} = props

  const {snackbar} = useSnackbar()
  const router = useRouter()

  const [inviteLinkUuid, setInviteLinkUuid] = useState<string>('')

  // queries ----------------------------------

  const {data: inviteLinksData, refetch: refetchInviteLinks} = useClanInviteLinksQuery({variables: {clanId: clan.id}})

  const inviteLinks = (inviteLinksData?.clanInviteLinks?.edges?.map(it => it && it.node) || []) as ClanInviteLinkFragment[]

  // mutations --------------------------------

  const [clanExit] = useClanExitMutation()
  const [clanArchive] = useClanArchiveMutation()
  const [clanInviteLinkAdd] = useClanInviteLinkAddMutation()
  const [inviteLinkRemove] = useClanInviteLinkRemoveMutation({
    async onCompleted() {
      await refetchInviteLinks()
    },
  })

  const getModalTitle = (): string => {
    switch (modalType) {
      case ClanModalTypeEnum.DISSOLUTION:
        return 'クランの解散'
      case ClanModalTypeEnum.LEAVE:
        return 'クランから脱退'
      case ClanModalTypeEnum.INVITATION_COPY:
        return 'クランにプレイヤーを招待する'
      case ClanModalTypeEnum.OPTION:
        return '招待オプション'
      case ClanModalTypeEnum.SETTING:
        return '招待リンクの設定'
      default:
        return ''
    }
  }

  const getInviteLinkUrl = (uuid: string) => `${window.location.origin}/clan/invite/${uuid}`

  const handleClanExit = async () => {
    const input = {
      clanId: clan.id,
    } as ClanExitInput

    try {
      await clanExit({
        variables: {input},
      })
      handleClose()
      snackbar.success('クランを脱退しました')
    } catch (e) {
      snackbar.failed('脱退に失敗しました', e)
    }
  }

  const handleClanArchive = async () => {
    const input = {
      clanId: clan.id,
    } as ClanArchiveInput

    try {
      await clanArchive({
        variables: {input},
      })
      await router.push('/clan')
      snackbar.success('クランを解散しました')
    } catch (e) {
      snackbar.failed('クランの解散に失敗しました', e)
    }
  }

  const handleClanInviteLinkAdd = async () => {
    try {
      // NOTE: デフォルトでは人数・期限が無制限
      const input: ClanInviteLinkAddInput = {
        clanId: clan.id,
      }
      const res = await clanInviteLinkAdd({variables: {input}})
      setInviteLinkUuid(res.data!.clanInviteLinkAdd!.uuid)
      setModalType(ClanModalTypeEnum.INVITATION_COPY)
    } catch (e) {
      snackbar.failed('招待リンクの発行に失敗しました', e)
    }
  }

  const handleClanInviteLinkOption = async () => {
    try {
      await refetchInviteLinks({clanId: clan.id})
      setModalType(ClanModalTypeEnum.OPTION)
    } catch (e) {
      snackbar.failed('招待リンクの取得に失敗しました', e)
    }
  }

  const dissolutionContents = () => {
    return (
      <>
        <Typography variant="body2">クランを解散すると二度と復元することができなくなります。<br />本当にクランを解散してもよろしいですか？</Typography>
        <Grid container justify="center" className={styles.btnWrap}>
          <Button className={styles.canselBtn} onClick={handleClose}>キャンセル</Button>
          <Button className={styles.redBtn} onClick={handleClanArchive}>解散する</Button>
        </Grid>
      </>
    )
  }

  const leaveContents = () => {
    return (
      <>
        <Typography variant="body2">クランから脱退すると今後{clan.name}のメンバーとしてチーム戦に参加することができなくなってしまいます。<br />本当にクランから脱退しますか？</Typography>
        <Grid container justify="center" className={styles.btnWrap}>
          <Button className={styles.canselBtn} onClick={handleClose}>キャンセル</Button>
          <Button className={styles.redBtn} onClick={handleClanExit}>脱退する</Button>
        </Grid>
      </>
    )
  }

  const handleUrlClick = () => {
    snackbar.success('URLをクリップボードにコピーしました')
    handleClose()
  }

  const invitationContents = () => {
    return (
      <>
        <Typography variant="body2">招待URLを発行した後、クランに追加したいプレイヤーにURLを共有してください。</Typography>
        {modalType === ClanModalTypeEnum.INVITATION_COPY && inviteLinkUuid && (
          <>
            <Typography variant="subtitle1" className={styles.contentsTitle}>招待URL</Typography>
            <Typography variant="caption" className={styles.invitationUrl}>{getInviteLinkUrl(inviteLinkUuid)}</Typography>
          </>
        )}
        <Grid container justify="center" className={styles.btnWrap}>
          {modalType === ClanModalTypeEnum.INVITATION || modalType === ClanModalTypeEnum.INVITATION_OPTION ?
            <Button className={styles.invitationBtn} onClick={handleClanInviteLinkAdd}>招待URLを発行する</Button> :
            (
              <CopyToClipboard text={inviteLinkUuid ? getInviteLinkUrl(inviteLinkUuid) : ''} onCopy={() => handleUrlClick()}>
                <Button className={styles.copyBtn} onClick={handleClose}>招待URLをコピー</Button>
              </CopyToClipboard>
            )
          }
        </Grid>
        {modalType === ClanModalTypeEnum.INVITATION_OPTION &&
          <Button className={styles.optionBtn} onClick={handleClanInviteLinkOption}>招待オプション</Button>
        }
      </>
    )
  }

  const handleInviteLinkSetting = () => {
    setModalType(ClanModalTypeEnum.SETTING)
  }

  const handleInviteLinkDelete = async (link: ClanInviteLinkFragment) => {
    const input: ClanInviteLinkRemoveInput = {
      inviteLinkId: link.id,
    }

    try {
      await inviteLinkRemove({variables: {input}})
      snackbar.success('招待リンクを削除しました')
    } catch (e) {
      snackbar.failed('招待リンクの削除に失敗しました', e)
    }
  }

  const optionContents = () => {
    return (
      <>
        <Typography variant="body2">過去作成した招待URLの一覧です。「操作」からURLのコピー・削除が可能です。</Typography>
        <TableContainer component={Paper} className={styles.container}>
          <Table className={styles.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={styles.tablehead}>URL</TableCell>
                {/*<TableCell className={styles.tablehead}>有効期限</TableCell>*/}
                {/*<TableCell className={styles.tablehead}>有効人数</TableCell>*/}
                <TableCell className={styles.tablehead}>利用</TableCell>
                <TableCell className={styles.tablehead}>操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { inviteLinks.map((link, idx) => {
                return (
                  <TableRow key={idx} className={styles.border}>
                    <TableCell className={styles.tablecontent}>{getInviteLinkUrl(link.uuid)}</TableCell>
                    {/*<TableCell className={styles.tablecontent}>{link.expiresOn || '∞'}</TableCell>*/}
                    {/*<TableCell className={styles.tablecontent}>{link.maxNum || '∞'}</TableCell>*/}
                    <TableCell className={styles.tablehead}>{link.isExpired ? '無効' : '有効'}</TableCell>
                    <TableCell className={styles.tablecontent} align="center">
                      <OperationMenu
                        type="clanModal"
                        url={getInviteLinkUrl(link.uuid)}
                        approvingAction={() => handleInviteLinkSetting()}
                        deleteAction={() => handleInviteLinkDelete(link)}
                      />
                    </TableCell>
                  </TableRow>
                )
              })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  const settingContents = () => {
    return (
      <>
        <Typography variant="body2">下記の招待URLの有効期限・有効人数を編集することができます。<br />https://www.adictor.jp/activation_urls/x-BRf73og0QSAPj84rvPfA</Typography>
        <Box mt={8}>
          <Box mb={6}>
            <Typography variant="subtitle2" color="textPrimary" className={styles.formList}>有効期限</Typography>
            <Select variant="outlined" displayEmpty inputProps={{classes: {root: selectStyles.root, icon: selectStyles.icon}}}>
              <MenuItem value={1}>1日</MenuItem>
              <MenuItem value={7}>7日</MenuItem>
              <MenuItem value={99}>∞</MenuItem>
            </Select>
          </Box>
          <Box>
            <Typography variant="subtitle2" color="textPrimary" className={styles.formList}>有効人数</Typography>
            <Select variant="outlined" displayEmpty inputProps={{classes: {root: selectStyles.root, icon: selectStyles.icon}}}>
              <MenuItem value={16}>16人</MenuItem>
              <MenuItem value={32}>32人</MenuItem>
              <MenuItem value={64}>64人</MenuItem>
              <MenuItem value={128}>128人</MenuItem>
            </Select>
          </Box>
        </Box>
        <Grid container justify="center" className={styles.btnWrap}>
          <Button className={styles.canselBtn} onClick={handleClose}>キャンセル</Button>
          <Button className={styles.greenBtn} onClick={handleClose}>更新する</Button>
        </Grid>
      </>
    )
  }

  const body = (
    <div className={styles.modalBase}>
      <h2 className={styles.modalHeader}>
        {getModalTitle()}
        <button type={'button'} className={styles.modalCloseBtn} onClick={handleClose}><img src='/images/close.svg' /></button>
      </h2>
      <div className={styles.modalBody}>
        {(() => {
          switch (modalType) {
            case ClanModalTypeEnum.DISSOLUTION:
              return dissolutionContents()
            case ClanModalTypeEnum.LEAVE:
              return leaveContents()
            case ClanModalTypeEnum.INVITATION:
            case ClanModalTypeEnum.INVITATION_COPY:
            case ClanModalTypeEnum.INVITATION_OPTION:
              return invitationContents()
            case ClanModalTypeEnum.OPTION:
              return optionContents()
            case ClanModalTypeEnum.SETTING:
              return settingContents()
            default:
              return (<></>)
          }
        })()}
      </div>
    </div>
  )
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="clan-modal"
      aria-describedby="clan-detail-operation"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        {body}
      </Fade>
    </Modal>
  )
}
