import React, {ReactElement} from 'react'

import {
  Box,
  MenuItem,
  Select,
  createStyles,
  makeStyles,
} from '@material-ui/core'

import {GameFragment, useGamesQuery} from '@/graphql/client'
import {useGameContext} from '@/hooks/game'
import {GameItem} from '@/components/molecules/game-item'
import {FeaturedTournamentCardList, TournamentCalendar} from '../organisms'
import {UserRanking} from '../organisms'
import {LoadingPart} from '../atoms'

const useGameTitleStyles = makeStyles(() => createStyles({
  selectIcon: {
    color: 'currentColor',
  },
  disabledSelect: {
    '&.MuiInputBase-root.Mui-disabled': {
      color: 'currentColor',
    },
    '& .MuiSelect-select.Mui-disabled': {
      paddingRight: 0,
    },
    '& .MuiSelect-icon.Mui-disabled': {
      visibility: 'hidden',
    },
  },
  userRankingWrap: {
    marginTop: 32,
  },
}))

export const GameTitle: React.FC = () => {
  const {data, loading, error} = useGamesQuery()

  if (loading || error) {
    return <LoadingPart />
  }

  const games = (data?.games.nodes || []) as GameFragment[]

  return <GameTitleUI games={games} />
}

type GameTitleUIProps = {
  games: GameFragment[]
}

const GameTitleUI = ({games}: GameTitleUIProps): ReactElement => {
  const styles = useGameTitleStyles()
  const {gameId, setGameId, genreId} = useGameContext()
  const enabledGamesInGenre = games.filter(it => it.enabled && it.genre.id == genreId)

  if (!enabledGamesInGenre[0]) {
    return <></>
  }
  const handleGameChange = (event: React.ChangeEvent<{ value: unknown }>) => setGameId(event.target.value as string)
  const genreHasOneGame = enabledGamesInGenre.length === 1
  return (
    <>
      <Select
        classes={{icon: styles.selectIcon}}
        autoWidth
        onChange={handleGameChange}
        value={gameId}
        disabled={genreHasOneGame}
        className={`${genreHasOneGame && styles.disabledSelect}`}
      >
        {enabledGamesInGenre.map((game, idx) => {
          return (
            <MenuItem key={idx} value={game.id}>
              <GameItem iconUrl={game.iconUrl} title={game.title}/>
            </MenuItem>
          )
        })}
      </Select>
      <FeaturedTournamentCardList />
      <TournamentCalendar />

      <Box className={styles.userRankingWrap}>
        <UserRanking />
      </Box>
    </>
  )
}
