import {
  TournamentDetail,
} from '@/components'
import {TournamentDetailQuery} from '@/graphql/client'
import {useIsLg} from '@/hooks'
import React from 'react'
import {Box} from '@material-ui/core'

type TournamentDetailContentProps = TournamentDetailQuery

export const TournamentDetailContent: React.FC<TournamentDetailContentProps> = (props: TournamentDetailContentProps) => {
  const {tournament} = props
  const isLg = useIsLg()

  return (
    <Box mt={isLg ? 10: 0} maxWidth={isLg ? 800: null} mx={isLg ? 'auto' : 0}>
      <Box mt={isLg ? 8.5 : 0}>
        <TournamentDetail tournament={tournament} />
      </Box>
    </Box>
  )
}
