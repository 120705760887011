import {TournamentStatus} from '@/graphql/client'
import {createStyles, makeStyles} from '@material-ui/core'
import React from 'react'
import {ActionModal} from '../molecules'

export type ProcessedTournamentType = {
  id: string
  coverUrl: any
  status: TournamentStatus
  title: string
  willStartAt: any
  isPrivate: boolean
}

export type TournamentHistoryModal = {
  type: string
  open: boolean
  handleStart: () => void
  handleCancel: () => void
  handleClose: () => void
  tournament: ProcessedTournamentType | null
  userId: string
}

const useModalStyles = makeStyles(({palette}) =>
  createStyles({
    startButton: {
      background: palette.primary.main,
    },
    cancelButton: {
      background: '#F44336',
    },
  })
)

export const TournamentHistoryModal: React.FC<TournamentHistoryModal> = (props) => {
  const {type, open, handleStart, handleCancel, handleClose, tournament, userId} = props
  const styles = useModalStyles()
  if (!tournament) {
    return (<></>)
  }

  let title = '大会を開始する'
  let contents = `「${tournament.title}」を開始してもよろしいですか？`
  let buttonText = '開始する'
  let button = styles.startButton
  let doAction = handleStart

  if (type === 'cancel') {
    title = '大会を中止する'
    contents = `「${tournament.title}」を中止してもよろしいですか？`
    buttonText = '中止する'
    button = styles.cancelButton
    doAction = handleCancel
  }

  return (
    <ActionModal
      open={open}
      doAction={doAction}
      handleClose={handleClose}
      title={title}
      contents={contents}
      actionButtonStyle={button}
      buttonText={buttonText}
      data-modalType={type}
      userId={userId}
      tournamentId={tournament.id}
    />
  )
}

