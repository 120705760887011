import React from 'react'
import {Box, BoxProps, CircularProgress} from '@material-ui/core'

export const LoadingPart = ({padding, ...props}: BoxProps) => {
  padding = padding ?? 25

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding={padding}
      {...props}
    >
      <CircularProgress />
    </Box>
  )
}
