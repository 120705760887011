import React from 'react'

import {
  HelpContentsAPIResponse,
} from '@/types'

import {
  Link,
  Title,
} from '@/components/atoms'

import {
  SingleColumnLayout,
} from '@/components/templates/layouts'

import {
  Box,
  Breadcrumbs,
  Typography,
  makeStyles,
} from '@material-ui/core'

const useSingleHelpContentTemplateStyles = makeStyles(({palette, spacing}) => ({
  breadCrumb: {
    marginTop: spacing(15),
    marginBottom: spacing(10),
  },
  content: {
    fontSize: '1.2em',
    color: palette.text.primary,
    marginTop: spacing(10),
    marginBottom: spacing(15),
    '& img': {
      maxWidth: '100%',
    },
    '& a': {
      color: palette.primary.main,
      textDecoration: 'none',
    },
  },
}))

type HelpContentsTemplateProps = HelpContentsAPIResponse

export const SingleHelpContentTemplate: React.FC<HelpContentsTemplateProps> = ({contents}) => {

  const styles = useSingleHelpContentTemplateStyles()
  const help = contents[0]

  return (
    <SingleColumnLayout>
      <Breadcrumbs className={styles.breadCrumb}>
        <Link color="inherit" href="/">
          トップ
        </Link>
        <Link color="inherit" href="/help">
          ヘルプ一覧
        </Link>
        <Typography color="textPrimary">{help.title}</Typography>
      </Breadcrumbs>
      <Title variant='h1'>{help.title}</Title>
      <Box className={styles.content} dangerouslySetInnerHTML={{__html: help.body}} />
    </SingleColumnLayout>
  )

}
